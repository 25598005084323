import React, {Component} from 'react';
import Layout from "../components/Layout"; 
import { verifyUser } from "../services/Auth";
import { toast } from "react-toastify";
import validation from "../actions/validation";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "", 
            token:"",
            loading: false,
            isValid: false
        };
    }
    onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false,
            loading: false
        })
    }
    onSubmit = () => {
        let input = {
            email: this.state.email
        };
        this.setState({
            ...this.state,
            loading: true,
            isValid: true
        }, () => {
            if(validation.email(this.state.email)) {
                verifyUser(input).then((res) => {
                    if(res.status === 201) {
                        const user = res.data.user;
                         this.setState({
                            ...this.state,
                            loading: false,
                            isValid: false,
                            token:user.password_verification_token
                        });
                        toast.success("Please check your email. A new password reset link has been send to your email.");
                        setTimeout(() => {
                            this.props.history.push("/");
                        }, 2000);
                    }else {
                        throw new Error (Object.values(res.response.data).reduce((init, current) => (init + " " + current.toString()), ""));
                    }
                }).catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    toast.error(err.message)
                });
            }else {
                toast.error("Validation Error!");
            }
        })
    } 

    render() {
        return (
            <Layout>
            <div className="padded-intro-section intro-content">
                <h3 className='text-center text-grey mt-5 mb-2'>Forgot Password</h3>
                <p className="text-center text-grey w-75 mx-auto">Please provide your registered email here.</p>
                <div className='d-flex justify-content-center align-items-center my-5'>
                <form className='cta-section p-0' onSubmit={(e) => { e.preventDefault(); }}>
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="form-group mb-4">
                            <label htmlFor="email" className='toggle-label bold'>Enter your registered Email</label>
                            <input type="text"  
                                className={`form-control`} 
                                name="email" 
                                id="email"  
                                onChange={this.onChange}
                            /> 
                        </div> 
                    </div>
                    <div className="col-md-12 align-self-end">
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary w-100 btn-padding--lg text-white" onClick={this.onSubmit}>Submit</button>
                    </div>
                    </div>
                </div>
                </form>
                </div>
            </div>
        </Layout>
           
        );
    }
}
export default ForgotPassword;