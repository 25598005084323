import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { MdMenu, MdClose, MdPowerSettingsNew } from "react-icons/md";
import "./MenuNav.css";
import Menubar from "./Menubar";
import cookie from "../../../actions/cookie";
import auth from "../../../actions/auth";
import env from "../../../config/env.json";
import ReviewRecapModal from "../../../components/Modal/ReviewRecapModal";
import { getRatingRecapStatus } from "../../../services/Rating";
import { toast } from "react-toastify";
import { get } from "../../../services/Profile";
import _ from "lodash";
export default function Navbar({ path }) {
  const history = useHistory();
  const [reviewDetails, setReviewStatus] = useState({
    status: true,
    sessionId: null,
    tutorId: null,
  });
  const [notificationCount, setNotificationCount] = useState(0);
  const logout = () => {
    cookie.deleteCookie("_token");
    cookie.deleteCookie("_session");
    history.push("/");
  };

  const NavLinks = () => (
    <ul className="navigation-links">
      <li className={path === "/tutor" ? "active" : ""}>
        <a
          href="https://app.progresslearning.com/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          Progress Learning Dashboard
        </a>
      </li>
      <li className={path === "/how" ? "active" : ""}>
        <a
          href="https://www.google.com/url?q=https://sistemtutoring.notion.site/SiSTEM-Tutor-Home-73a428df50f7456696231c9186bc55dc&sa=D&source=docs&ust=1659674693587708&usg=AOvVaw1bWp50nTyxIK3U203o4QzB"
          target="_blank"
          rel="noopener noreferrer"
        >
          SiSTEM Tutor Home
        </a>
      </li>
      <li className={path === "/faq" ? "active" : ""}>
        <a
          href={env.LANDING_URI + "/faq-tutor"}
          target="_blank"
          rel="noopener noreferrer"
        >
          FAQs
        </a>
      </li>
      <li className={path === "/help" ? "active" : ""}>
        <a
          href="#"
          onClick={(e) => {
            window.location.href = "mailto:support@sistemtutoring.com";
            e.preventDefault();
          }}
        >
          Help and Support
        </a>
      </li>
    </ul>
  );
  const showNav = () => {
    document.querySelector(".mobile-nav").classList.add("show");
    document.querySelector(".mobile-header--overlay").classList.add("show");
  };
  const hideNav = () => {
    document.querySelector(".mobile-nav").classList.remove("show");
    document.querySelector(".mobile-header--overlay").classList.remove("show");
  };
  const navFunc = () => {
    document.querySelector(".mobile-header--icon").onclick = function () {
      showNav();
    };
    document.querySelector(".mobile-nav--close").onclick = function () {
      hideNav();
    };
    document.querySelector(".mobile-header--overlay").onclick = function () {
      hideNav();
    };
  };
  const checkForReviewPopup = () => {
    getRatingRecapStatus()
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.data;
          const reviewStat = data.status;
          setReviewStatus({
            status: reviewStat,
            sessionId: data.sessionId,
            tutorId: data.tutorId,
            lastSchedule: data.schedule,
          });
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      });
  };
  useEffect(() => {
    navFunc();
    checkForReviewPopup();
    get(auth.check().id).then((res) => {
      if (res.status === 200) {
        var pending = _.filter(res.data.data.schedules, {
          progression_status: 0,
          approved: 0,
          rejected: 0,
          tutor_viewed: 0,
        });
        setNotificationCount(pending.length);
      }
    });
  }, []);

  return (
    <React.Fragment>
      {!reviewDetails.status && (
        <ReviewRecapModal
          sessionId={reviewDetails.sessionId}
          tutorId={reviewDetails.tutorId}
          schedule={reviewDetails.lastSchedule}
        />
      )}

      <header className="fixed-tutor-header desktop-header">
        <div className="container-fluid py-3 py-2">
          <div className="d-flex justify-content-around align-items-center">
            <div className="logo-wrapper">
              <a href={env.LANDING_URI}>
                <img src="/images/logo.svg" alt="site-logo" />
              </a>
            </div>
            <nav className="nav-wrapper">
              <NavLinks />
            </nav>
            <div className="d-md-none d-lg-flex">
              {auth.check().profile_picture ? (
                <img
                  src={auth.check().profile_picture}
                  alt="student-avatar"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
              ) : (
                <div className="icn-circle primary-dark font-weight-bold mr-2 text-capitalize">
                  {auth.check().first_name.split("")[0]}
                </div>
              )}
              <Dropdown className="d-flex align-items-center">
                <Dropdown.Toggle
                  variant="transparent text-grey text-capitalize"
                  id="dropdown-basic"
                >
                  Hello, <b className="text-grey">{auth.check().first_name}</b>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div
                className="noti-icon d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/notification")}
              >
                <img src="/images/bell-icon.png" alt="bell" />
                {notificationCount ? (
                  <span className="noti-count">{notificationCount}</span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="mobile-header">
        <header aria-label="mobile-header" className="fixed-header">
          <div className="d-flex align-items-center h-100 position-relative mobile-headeer-item">
            <div className="mobile-header--icon pointer">
              <MdMenu fontSize="24px" />
            </div>
            <div className="logo-wrapper center-hr">
              <Link to="/">
                <img src="/images/logo.svg" alt="site-logo" />
              </Link>
            </div>
            <div className="d-flex align-items-center pointer" onClick={logout}>
              <MdPowerSettingsNew className="mr-1 text-grey" fontSize="22px" />
            </div>
          </div>
        </header>

        <div className="mobile-header--overlay" />

        <aside className="mobile-nav">
          <div className="mobile-nav--close pointer">
            <div className="middle">
              <Link to="/">
                <img src="/images/logo.svg" alt="site-logo" />
              </Link>
            </div>
            <div className="rounded-circle border bg-light p-2 mr-3">
              <MdClose fontSize="22px" />
            </div>
          </div>
          <div className="nav-wrapper p-3">
          {
            path.indexOf('/tutor/add-profile') == -1 &&

            <Menubar />
          }
            <h6 className="txt-14--light bold">MORE</h6>
            <hr className="mt-2 b-3" />
            <NavLinks />
          </div>
        </aside>
      </div>
    </React.Fragment>
  );
}
