import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import './ParentStudentSubscriptionTable.css';
import moment from "moment";
import { FiAlertOctagon, FiCheckCircle } from 'react-icons/fi';
import $ from "jquery";

const SubscriptionTable = ({students, cancelPlan, onSuccess, role, billingDetails,changeCardDetailsModal}) => {
    const [cancelId, setCancelId] = useState(null);
    const [studentId, setStudentId ] = useState(null);
    const [student,setStudent] =useState(null);
    const history = useHistory();
    
    function cancelSubscription(studentId) {
        setCancelId(studentId);
        $("#cancelSubscription").modal();
    }
    function getPlanAmount(billingDetails){
        let percent_off   = billingDetails?.subscription?.discount?.coupon ? billingDetails?.subscription?.discount?.coupon?.percent_off: 0;
        let amount_off   = billingDetails?.subscription?.discount?.coupon ? billingDetails?.subscription?.discount?.coupon?.amount_off: 0;
        let discount = percent_off?(((billingDetails?.subscription?.plan?.amount)/100)*(percent_off/100)):(amount_off/100);
        let  plan_amount = billingDetails?.subscription_id ? ((billingDetails?.subscription?.plan?.amount)/100)-discount: 0;
        let  converted_amount =plan_amount.toFixed(2);
        return converted_amount;
    }
    function planExpired (billingDetails) {
        setStudentId(billingDetails.id)
        const currentDate = moment();
        const prevCurrentDate = moment(billingDetails.subscription.current_period_end * 1000 ).add(1,'days');
        const diff = prevCurrentDate.diff(currentDate, 'days');
            setStudent(billingDetails)
        if(diff > 0) {
          $("#showSubscription").modal();
        } else {
          history.push(`/pricing?studentId=${billingDetails.id}`);
         }
      }
      function redirectToPricing() {
          history.push(`/pricing?studentId=${studentId}&schedule=true`);
          $('#showSubscription').modal('toggle');
      }
      function subScriptionDateFormate () {
        const subscriptionDate = moment(new Date(student?.subscription?.current_period_end * 1000)).add(1,'days');
        return subscriptionDate.format('MMM DD, YYYY');
      }

    return (
        <>
        <div className="subscription-table">
            <div className="row billing-headings">
                <div className="col student">Name of  student/Plan</div>
                <div className="col plan-status">Status</div>
                <div className="col plan-price">Price</div>
                <div className="col actions">Actions</div>
            </div>
            {role === 'parent' && students && students.map( (stu, i) => { 
                return (
                    <div className="row" key={i}>
                    <div className="col student">
                        <h5 className="name text-grey">
                            {`${stu?.first_name} ${stu?.last_name}`}
                        </h5>
                        {stu?.subscription_id ? <p>{stu?.subscription?.plan?.name? stu?.subscription?.plan?.name.replace("_OLD", " ") :''}</p> : null}
                        {(!stu?.subscription_id && stu.credit!=0)?<p>Pay-As-You-Go</p>:null}
                    </div>
                    <div className="col plan-status">
                        <h5 className="status active">
                            {stu?.subscription_id ? 'Active' : null}
                            {(!stu?.subscription_id && stu.credit!=0)?'Active':null}

                        </h5>
                        <p className="expiry">
                            {stu?.subscription_id ? `Expires ${stu?.subscription?.current_period_end ? moment(new Date(stu?.subscription?.current_period_end * 1000)).format('MMM DD, YYYY'): null}` : null}
                        </p>
                    </div>
                    <div className="col plan-price">
                        <h5 className="price text-grey">
                            {stu?.subscription_id ? `$${getPlanAmount(stu)}` : null}
                        </h5>
                        <span>
                        {stu?.subscription?.discount?.coupon ? `${stu?.subscription?.discount?.coupon?.name} Added` : null}
                        </span>
                        <p className="sessions">
                            {stu?.subscription_id ? `${stu?.credit} credit` : null}
                            {(!stu?.subscription_id && stu.credit!=0)?`${stu?.credit} credit`:null}

                        </p>
                    </div>
                    <div className="col actions">
                        {stu?.subscription_id ? (
                        <>
                        <div className='d-md-flex justify-content-between'>
                            <button 
                                className="button upgrade bg-upgrade mx-1 " 
                                onClick={() => planExpired(stu)}
                            >
                                Upgrade
                            </button>
                            <button 
                               className="button cancel mx-1" 
                               onClick={() => cancelSubscription(stu?.id)}
                            >
                                Cancel
                            </button>
                            </div>
                            <button 
                                className="col-12 text-wrap col-md-12 text-teal bold pointer mt-2" 
                                onClick={() => changeCardDetailsModal(stu?.id)}
                            >
                                Change card details
                            </button>
                        </>) : 
                        (<Link to={`/pricing?studentId=${stu?.id}`}>
                            <button 
                                className='btn btn-primary text-white billing-page-btn py-2'
                            >
                                Purchase Sessions
                            </button>
                        </Link>)}
                        
                    </div>
                    {stu.scheduledSubscriptions[0]  &&
                     <p className='w-100 ml-3 text-teal schedule-text'>
                        Your new subscription plan ({stu.scheduledSubscriptions[0]?.plan_name}) will be on effect from {moment(new Date(stu.scheduledSubscriptions[0]?.execute_at)).format('MMM DD, YYYY')}
                    </p>
                }
                </div>
                )
            })}
            
            {role === 'student' && (
                <div className="row billing-datas">
                <div className="col student">
                    <h5 className="name text-grey">
                        {`${billingDetails?.first_name} ${billingDetails?.last_name}`}
                    </h5>
                    {billingDetails?.subscription_id ? <p>{billingDetails?.subscription?.plan?.name ? billingDetails?.subscription?.plan?.name.replace("_OLD", " ") :''}</p> : null}
                    {(!billingDetails?.subscription_id && billingDetails.credit!=0)? <p>Pay-As-You-Go</p> :null}
                </div>
                <div className="col plan-status">
                    <h5 className="status active">
                        {billingDetails?.subscription_id ? 'Active' : null}
                        {(!billingDetails?.subscription_id && billingDetails.credit!=0)? 'Active' :null}

                    </h5>
                    <p className="expiry">
                        {billingDetails?.subscription_id ? `Expires ${billingDetails?.subscription?.current_period_end ? moment(new Date(billingDetails?.subscription?.current_period_end * 1000)).format('MMM DD, YYYY'): null}` : null}
                    </p>
                </div>
                <div className="col plan-price">
                    <h5 className="price text-grey">
                        {billingDetails?.subscription_id ? `$${getPlanAmount(billingDetails)}` : null}
                    </h5>
                    <span>
                        {billingDetails?.subscription?.discount?.coupon ? `${billingDetails?.subscription?.discount?.coupon?.name} Added` : null}
                    </span>
                    <p className="sessions">
                        {billingDetails?.subscription_id ? `${billingDetails?.credit} credit` : null}
                        {(!billingDetails?.subscription_id && billingDetails.credit!=0)? `${billingDetails?.credit} credit` :null}

                    </p>
                </div>
                <div className="col actions">
                    {billingDetails?.subscription_id ? (
                    <>
                        <div className='d-md-flex justify-content-between'>
                            <button className="button upgrade bg-upgrade text-white" onClick={() => planExpired(billingDetails)}> Upgrade</button>
                            <button className="button cancel" onClick={() => cancelSubscription(billingDetails?.id)}>Cancel</button>
                            </div>
                            <button className="col-12 text-wrap col-md-12 text-teal bold pointer mt-2 border-radius-25" onClick={() => changeCardDetailsModal(billingDetails?.id)}
                            >
                                Change card details
                            </button>
                        
                    </>) : (<Link to={`/pricing`}>
                            <button className='btn btn-primary text-white w-100  billing-page-btn py-2'>Purchase Sessions</button>
                        </Link>)}
                    
                </div>
               
                {billingDetails?.scheduledSubscriptions[0] &&
                <p className='w-100 ml-3 text-teal schedule-text'>
                     Your new subscription plan ({billingDetails.scheduledSubscriptions[0]?.plan_name}) will be on effect from {moment(new Date(billingDetails.scheduledSubscriptions[0]?.execute_at)).format('MMM DD, YYYY')}
                </p>
                }
            </div>
            )}
        </div>
        <div className="modal fade" id="cancelSubscription" tabIndex="-1" role="dialog" aria-labelledby="cancelSubscription" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancel Subscription</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                onSuccess ?
                                <div className="d-flex justify-content-center">
                                    <div className="text-center">
                                        <div className="animation-ctn">
                                            <div className="icon icon--order-success svg mb-5">
                                                <FiCheckCircle color="green" size="120"/>
                                            </div>
                                        </div>
                                        <h2 className="bold mb-2">Subscription Cancelled!</h2>
                                        <p className="mb-4">Your Subscription was successfully cancelled.</p>
                                    </div>
                                </div>
                                :
                                <div className="d-flex justify-content-center">
                                    <div className="text-center">
                                        <div className="animation-ctn">
                                            <div className="icon icon--order-success svg mb-5">
                                                <FiAlertOctagon color="green" size="120"/>
                                            </div>
                                        </div>
                                        <h2 className="bold mb-2 text-grey">Are you sure?</h2>
                                        <p className="mb-4">Your are cancelling your current subscription. Please confirm to continue.</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            {
                                !onSuccess ? 
                                <button type="button" className="btn btn-primary text-white" onClick={() =>cancelPlan(cancelId)}>Confirm</button>
                                : <Link to="/"><button type="button" className="btn btn-primary text-white" data-dismiss="modal" onClick={() => history.push('/')}>Go To Dashboard</button></Link>
                            }
                        </div>
                        </div>
                    </div>
                </div>
                        {/* Subscription schedule modal */}
        <div className="modal fade" id="showSubscription" tabIndex="-1" role="dialog" aria-labelledby="cancelSubscription" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Confirm</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">                                              
                <p className="mb-2 mt-4">
                    {student?.scheduledSubscriptions[0] ?
                      'You had already scheduled an upgrade for ' + moment(new Date(student.scheduledSubscriptions[0]?.execute_at)).format('MMM DD, YYYY') + '. ' : '' } 
                     This upgrade will be applicable only from {subScriptionDateFormate()}. 
                    {student?.scheduledSubscriptions[0] ? ' Do you want to replace it ?': ' Do you want to proceed ?' }
                </p>                      
              </div>  
              <div className="modal-footer border-0">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                <button className='btn btn-primary text-white' onClick={() =>redirectToPricing()}>Yes</button>
              </div>
            </div>
          </div>
        </div>   
        </>
    )
}

export default SubscriptionTable;