import React, {useRef, useState, useEffect} from 'react';
import {  Modal} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import { subscribe } from "../../services/Contact";
import validation from "../../actions/validation";

export default function SubscriptionModal(props) {
    const [isSubmitted, setSubmitFlag] = useState(false);
    const [isValid, setValidFlag] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isDisabled, setDisabled] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault(); 
        let input = {
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            email: emailRef.current.value
        };
        if(validation.email(emailRef.current.value) && validation.text(firstNameRef.current.value) && validation.text(lastNameRef.current.value)){
            setDisabled(true);
            subscribe(input).then((res) => {
                if(res.status === 200) {
                    setSubmitFlag(true) 
                }else {
                    throw new Error (res.response.data.message);
                }
            }).catch((err) => {
            })
        }else{
            setValidFlag(true);
        }
    }
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);

    const hideModal = () => {
        setSubmitFlag(false);
        props.onHide();
    }

    

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
            <div className="close-btn-container">
                <button onClick={hideModal}>
                    <MdClose />
                </button>
                
            </div>
            {!isSubmitted ? (
                <>
                    <div className="header subscribtion-modal">
                        <h2 className="mb-4">Subscribe to SISTEM</h2>
                        <p>Kindly provide your name and email address.</p>
                    </div>
                    <form className="subscribtion-modal-form" onSubmit={onSubmit}>
                        <div className="form-group mb-3">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" className={`form-control light ${(isValid && (validation.text(firstName) ? '' : 'error is-invalid')).toString()}`}  id="firstName" name="firstName" value={firstName} ref={firstNameRef} onChange={e => setFirstName(e.target.value)}/>
                            <div className="invalid-feedback"> Enter a your first name. </div>

                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" className={`form-control light ${(isValid && (validation.text(lastName) ? '' : 'error is-invalid')).toString()}`}  id="lastName" name="lastName" ref={lastNameRef} value={lastName} onChange={e => setLastName(e.target.value)}/>
                            <div className="invalid-feedback"> Enter a your last name. </div>

                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="email">Email Address</label>
                            <input type="text" className={`form-control light ${(isValid && (validation.email(email) ? '' : 'error is-invalid')).toString()}`}  id="email" name="email" ref={emailRef} value={email} onChange={e => setEmail(e.target.value)}/>
                            <div className="invalid-feedback"> Enter a valid email address.</div>

                        </div>
                        <div className="submit-btn-container">
                            <button type="submit" className={`btn btn-primary mt-4 text-white btn-padding ${(isDisabled ? 'disabled' :  '').toString()}`}>
                            Continue
                            </button>
                        </div>
                        
                    </form>
                </>
            ): (
                <div className="success-block">
                    <div className="header">
                        <h3 className="mb-5 f-24">You have successfully signed up to receive SISTEM newsletters</h3>
                        <p>You will be receiving updates from us, from time to time. Not to worry, we won’t spam you.</p>
                    </div>
                    <div className="submit-btn-container">
                        <button type="button" className='btn btn-primary mt-4 text-white btn-padding' onClick={hideModal}>
                            Close
                        </button>
                    </div>
                </div>
            )}
            
        </Modal.Body>
      </Modal>
    );
  }
  