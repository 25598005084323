import axios from "axios";
import { API_END_POINT } from "../config/env.json";

export const registration = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios.post(`${API_END_POINT}/register`, payload, options)
        .then(res => res)
        .catch(err => err);
};

export const login = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios.post(`${API_END_POINT}/login`, payload, options)
        .then(res => res)
        .catch(err => err);
};

export const verifyUser = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios.post(`${API_END_POINT}/verifyUser`, payload, options)
        .then(res => res)
        .catch(err => err);
};