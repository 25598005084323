import axios from "axios";
import { API_END_POINT } from "../config/env.json";
import cookie from "../actions/cookie";

export const findTutor = (filters, page) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/find-tutor?page=${page}`, filters, options)
        .then(res => res)
        .catch(error => error);
};

export const schedule = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/tutor-schedule`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const reSchedule = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/tutor-re-schedule`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const changeStatus = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/schedule-approval`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const findTutorById = (id) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/tutor-details`, {tutor_id: id}, options)
        .then(res => res)
        .catch(error => error);
};


export const scheduleParent = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/parent-student-schedule`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const scheduleStudent = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/student-schedule`, payload, options)
        .then(res => res)
        .catch(error => error);
};


export const changeTutorViewStatus = () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/changeTutorViewStatus`, [],options)
        .then(res => res)
        .catch(error => error);
};
export const sessionMissedReason = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/update-reason`, payload, options)
        .then(res => res)
        .catch(error => error);
};
export const checkProfileCompletion=(data)=>{
    if(data.role_id === "2") {
        if(data.first_name
            && data.last_name
            && data.email
            && data.phone_number
            && data.details.address
            // && data.details.certificates
            && data.details.city
            // && data.details.cover_letter
            && data.details.dob
            && data.details.highest_level_education
            && data.details.how_do_you_hear
            && data.details.occupation
            //&& data.details.profile_picture
            && data.details.resume
            && data.details.state
            && data.details.years_of_tutoring_exp
            && data.details.zip_code
            ) {
                return true;
            } else {
                return false;
            }
    } else {
        return false;
    }
};
export const updateTutorAvailability = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/update-tutor-availability`, payload, options)
        .then(res => res)
        .catch(error => error);
};
export const getTutorAvailability = (id) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/get-tutor-availability/${id}`, options)
        .then(res => res)
        .catch(error => error);
};
export const deleteTutorAvailability = (id) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.delete(`${API_END_POINT}/v1/delete-tutor-availability/${id}`, null, options)
        .then(res => res)
        .catch(error => error);
};
export const deleteTutorSpecificAvailability = (id) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.delete(`${API_END_POINT}/v1/delete-tutor-specific-availability/${id}`, null, options)
        .then(res => res)
        .catch(error => error);
};
export const getTutorAvailabilityDate = (id,date) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/get-availability-by-date/${id}/${date}`, null,options)
        .then(res => res)
        .catch(error => error);
};