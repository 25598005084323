import moment from "moment";
export const formatStartEndTime = (startTimeUtc, endTimeUtc) => {
    const startTime = moment(startTimeUtc).local().format('LT');
    const endTime =   moment(endTimeUtc).local().format('LT');
    return startTime + ' - ' + endTime;
}

export const formatStartTime = (startTimeUtc) => {
    const startTime = moment(startTimeUtc).local().format('LT');
    return startTime;
}

export const formatEndTime = (endTimeUtc) => {
    const endTime =   moment(endTimeUtc).local().format('LT');
    return endTime;
}

export const timeLeft = (endTime) => {
    let then   =  moment(endTime).local().format('DD/MM/YYYY HH:mm:ss');
    let now  = moment().local().format('DD/MM/YYYY HH:mm:ss');
    if(moment(now).isAfter(then)) {
        return 'Time Completed';
    }
    let ms = moment(then,"DD/MM/YYYY HH:mm:ss").diff(moment(now,"DD/MM/YYYY HH:mm:ss"));
    let d = moment.duration(ms);
    let h = Math.floor(d.asHours());
    let s = h > 0 ? h + ' hours ' : '';
        s += moment.utc(ms).format("mm") + ' mins '
        s += moment.utc(ms).format("ss") + ' secs';
    return s ;
}

export const timePassed = (startTime) => {
    let previous   =  moment(startTime).local().format('DD/MM/YYYY HH:mm:ss');
    let now  = moment().local().format('DD/MM/YYYY HH:mm:ss');
    let ms = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(previous,"DD/MM/YYYY HH:mm:ss"));
    let d = moment.duration(ms);
    let h = Math.floor(d.asHours());
    let s = h > 0 ? h + ' hours ' : '';
        s += moment.utc(ms).format("mm") + ' mins '
        s += moment.utc(ms).format("ss") + ' secs';
    return s ;
} 