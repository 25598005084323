/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Calendar from 'react-calendar';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';
import auth from "../../../actions/auth";
import guard from "../../../actions/guard";
import {get} from "../../../services/Profile";
import {toast} from "react-toastify";
import moment from "moment";
import validation from "../../../actions/validation";
import _ from "lodash";
import './Schedule.style.css';
import { states } from '../../../config/variables';
import LoadingSpinner from '../../LoaderComponent/LoadingSpinner';
import env from '../../../config/env.json';
import TimePickerComponent from '../../TimePickerComponent';

import { getOnlineInperson } from "../../../services/Payment";
import { getTutorAvailability,getTutorAvailabilityDate } from "../../../services/Tutor";

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export default class Schedule extends Component {

  constructor(props) {
    const today = new Date()
  	let tomorrow =  new Date()
	tomorrow.setDate(today.getDate() + 1)
    super(props);
    this.state = {
        emptyState: false,
        tutor: {},
        recruter: {},
        date: tomorrow,
        parent_id: null,
        tutor_id: null,
		student_data:[],
        in_person: this.props.payload.in_person?this.props.payload.in_person:"1",
        online: this.props.payload.online?this.props.payload.online:"0",
        address: this.props.payload.address?this.props.payload.address:"",
        state: this.props.payload.state?this.props.payload.state:"",
        recurring_arrangment: this.props.payload.recurring_arrangment?this.props.payload.recurring_arrangment: "0",
		//validation
        isValid: false,
        loading: true,
        prefered_lesson_type: "in_person",
        city: this.props.payload.city?this.props.payload.city:"",
        zip:  this.props.payload.zip?this.props.payload.zip:"",
        address_later: this.props.payload.address_later?this.props.payload.address_later:0,
        start_time: null,
        end_time: null,
        selectedDayMapping: [],
        tutorAvailabilty:[],
        timeErrorMsg:null,
         showOnline: false,
        showInperson: false
    }
  }
  
  componentDidMount() {
    this.getOnlineInpersonDetails()
      get(auth.check().id).then((res) => {
        const today = new Date()
        let tomorrow =  new Date()
        tomorrow.setDate(today.getDate() + 1)
        if(res.status === 200) {
          if(guard.role() == "parent") {
            this.setState({
                ...this.state,
                loading:false,
                parent_id: this.props.parentId,
                tutor_id: this.props.tutorId,
                student_data: [{
                  "student_id": this.props.studentId,
                    "schedule_date": "",
                    "start_time": this.props.payload.student_data?this.props.payload.student_data[0].start_time:"",
                    "end_time": this.props.payload.student_data?this.props.payload.student_data[0].end_time:"",
                    "duration": "",
                    "profile_picture": this.props.student_profile,
                    "student_name": this.props.studentName
                }],
                recruter: res.data.data,
                date :this.props.payload.student_data?new Date(this.props.payload.student_data[0].schedule_date):tomorrow
            });
          }else {
            this.state.student_data.push({
              "student_id": res.data.data.id,
              "schedule_date": "",
              "start_time": this.props.payload.student_data?this.props.payload.student_data[0].start_time:"",
              "end_time": this.props.payload.student_data?this.props.payload.student_data[0].end_time:"",
              "duration": "",
              "profile_picture": res.data.data.details.profile_picture
            });
            this.setState({
                ...this.state,
                loading:false,
                student_data: this.state.student_data,
                date :this.props.payload.student_data?new Date(this.props.payload.student_data[0].schedule_date):tomorrow
            });
          }
        }else {
            toast.error("Something went wrong!");
        }
      }).catch((err) => {
          toast.error("Something went wrong!");
      }) 
      get(this.props.tutorId).then((res) => {
        if(res.status === 200) {
            this.setState({
                ...this.state,
                loading:false,
                tutor:  res.data.data,
                emptyState: false
            });
        }else {
            toast.error("Something went wrong!");
        }
      }).catch((err) => {
        this.setState({
          loading:false
        })
          toast.error("Something went wrong!");
      }) 
      getTutorAvailability(this.props.tutorId).then((res) => {
        if (res.status === 200) {
          this.setState({
            ...this.state,
            tutorAvailabilty: res.data
          }); 
        } 
      }).catch((err) => {
        toast.error(err.message);
      })
      getTutorAvailabilityDate(this.props.tutorId,moment(this.state.date).format('YYYY-MM-DD')).then((res) => {
        if (res.status === 200) {
          this.setState({
            ...this.state,
            selectedDayMapping:res.data.data  
          }); 
        } 
      }).catch((err) => {
        toast.error(err.message);
      })
     // this.getAvailabilty(this.props.tutorId,this.state.date);
  }
   getAvailabilty =async(tutorId,date)=>{
    await getTutorAvailabilityDate(tutorId,moment(date).format('YYYY-MM-DD')).then((res) => {
        if (res.status === 200) {
          this.setState({
            ...this.state,
            selectedDayMapping:res.data.data  
          }); 
        } else {
          return [];
        //   throw new Error(res.response.data.message);
        }
      }).catch((err) => {
        toast.error(err.message);
      })
  }
  getOnlineInpersonDetails = () => {
    let studentId = guard.role() == "parent" ? this.props.studentId:auth.check().id;
    getOnlineInperson(studentId).then((res) => {
      this.setState({
        ...this.state,
        showOnline: res.data.userCreditOnline > 0 ? true : false ,
        showInperson: res.data.userCreditInperson > 0 ? true : false,
        in_person: res.data.userCreditInperson > 0? "1":"0",
        online: (res.data.userCreditOnline > 0 && res.data.userCreditInperson <= 0)?"1" : "0",
        });
    });
  }
  prepareTimeOptions = (tutor,date,currentTime) => {
    let time = null;
    if(_.filter(tutor.day_time_mapping, ['day', days[moment(date).day()]]).length > 0) {
      let filteredDate = _.filter(tutor.day_time_mapping, ['day', days[moment(date).day()]]);
      filteredDate = _.sortBy(filteredDate,'start_time')
      time = filteredDate.map((time, key) => {
        let now = currentTime ? moment(date).set('hour', currentTime.split(':')[0]).set('minute', currentTime.split(':')[1]) :  moment();
        let proposedTime = moment(date).set('hour', time.start_time.split(':')[0]).set('minute', time.start_time.split(':')[1]);
        const msBetweenDates= Math.abs(proposedTime-now);
		    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
		    let isDateTwentyFour = hoursBetweenDates <=24 ? true:false;
		    let isPastTime = proposedTime.isSameOrBefore(now);
        let optionClass = (isPastTime ||isDateTwentyFour)? {'color':'#808080'} : {'color':'#000000'};
        return (<option 
          disabled={isPastTime ||isDateTwentyFour} 
          style={optionClass} 
          value={time.start_time} 
          key={key}>{moment(time.start_time, ["HH.mm"]).format("hh:mm a")}</option>)
      })
    }
    return time;
  }
  tileDisabled = (e) => {  
    const includedInSpecific = _.findIndex(this.state.tutorAvailabilty.data, {available_date: moment(e.date).format("YYYY-MM-DD"),not_available:0}) 
    const notAvailable = _.findIndex(this.state.tutorAvailabilty.data, {available_date: moment(e.date).format("YYYY-MM-DD"),not_available:1}) 
     if(includedInSpecific>=0){
      return false;
    }else if(notAvailable>=0){
      return true;
    }else{
      const day_time_mapping   = this.state.tutorAvailabilty?.day_time_mapping?this.state.tutorAvailabilty?.day_time_mapping:[];
   //   console.log("day_time_mapping",day_time_mapping) 
      let uniqDay = _.uniq(day_time_mapping.map((val) => val.day))
      return _.indexOf(uniqDay, days[moment(e.date).day()]) === -1;
    }
  }

  onChange = (from, e) => {
    if(from !== "") {
      this.state.student_data[0][e.target.name] = e.target.value;
      if(e.target.name === 'start_time') {
        this.state.student_data[0]['end_time'] = ''
      }
      this.setState({
        ...this.state,
        student_data: this.state.student_data,
        isValid: false
    });
    }else {
      if(e.target.name === 'prefered_lesson_type' && e.target.value === 'online') {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          online: "1",
          in_person: "0",
          isValid: false
      });
      } else if(e.target.name === 'prefered_lesson_type' && e.target.value === 'in_person') {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          online: "0",
          in_person: "1",
          isValid: false
      });
      } else {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          isValid: false
      });
      }
      
    }
  }

  studentMap = (student, e) => {
    if (e.target.checked) {
        this.state.student_data.push({
          "student_id": student.student_user.id,
          "schedule_date": "",
          "start_time": "",
          "end_time": "",
          "duration": "",
          "profile_picture": ''
        });
        this.setState({
            ...this.state,
            student_data: this.state.student_data,
            isValid: false
        });
    }else {
        let i = _.findIndex(this.state.student_data, ['student_id', student.student_user.id]);
        this.state.student_data.splice(i, 1);
        this.setState({
            ...this.state,
            student_data: this.state.student_data,
            isValid: false
        });
    }
  }

  startEndTimeValidation = (date,start_time,end_time, validation=true) => {
      const schedule_date = moment(date).format('YYYY-MM-DD');
      let startDate =  schedule_date+ ' '+ start_time;
      let endDate =  schedule_date+ ' '+ end_time;
      
      let startTimeUtc = moment.utc(moment(startDate)).format();
      let endTimeUtc = moment.utc(moment(endDate)).format();
      if( validation) {
        const duration = moment.duration(moment(this.state.end_time, ["HH.mm"]).diff(moment(this.state.start_time, ["HH.mm"]))).asMinutes();
        const validTimeRange = (duration%30==0)?1:0;
        const endTimeIsAfterStart =moment(endTimeUtc).isAfter(startTimeUtc);
        const maxLimitExceed =(duration>240)?0:1;
        const minLimitExceed =(duration<30)?0:1; 
        return (validTimeRange&&endTimeIsAfterStart&&maxLimitExceed&&minLimitExceed);
      } else {
        //const duration = moment.duration(moment(end_time, ["HH.mm"]).diff(moment(start_time, ["HH.mm"]))).hours() ;
        const durationSec = moment(endDate).diff(moment(startDate),'seconds');
        const duration =(durationSec/60).toFixed(2);
        return { schedule_date, endDate, startTimeUtc, endTimeUtc, duration };
      }
      
      
    }

  onSubmit = () => {
   
    const { showInperson,showOnline, date, student_data, in_person, online, address, state, recurring_arrangment, city, zip,address_later } = this.state;
    if(!showInperson && !showOnline) {
      toast.error("You have no credit!");
      return
    }
    let  start_time, end_time  = '';
    if(student_data && student_data.length > 0) {
         ({ start_time, end_time } =  student_data[0]);
    }
    this.setState({
      isValid: true,
      loading: true
    }, () => {

      if(
       student_data.length > 0 && validation.text(recurring_arrangment) 
       && validation.text(start_time) 
       && validation.text(end_time) 
       && ( online === '1' || (in_person === '1' && address_later === 1) || (in_person === '1' && address_later === 0 
       && validation.text(address) 
       && validation.text(state) 
       && validation.text(city) 
       && validation.zipCode(zip)))
       
        ) {
         if(this.startEndTimeValidation(date,start_time,end_time, true)) {
           const {  schedule_date, endDate, startTimeUtc, endTimeUtc, duration  } = this.startEndTimeValidation(date,start_time,end_time, false);
            student_data.forEach((student) => {
              student.schedule_date = schedule_date;
              student.duration =   duration;
              student.start_time_utc = startTimeUtc;
              student.end_time_utc = endTimeUtc;
            })
            this.props.getData({
              student_data,
              in_person,
              online,
              address,
              state,
              recurring_arrangment,
              city,
              zip,address_later
            });
         } else {
          const { duration  } = this.startEndTimeValidation(date,start_time,end_time, false);
          let timeErrorMsg="End time should be greater than start time";
          if(duration%30!=0){ 
            timeErrorMsg="All bookings should have durations of 30 minutes  like 0.5 hour, 1 hour, 1.5 hours etc.";
          }
          if(duration>240){ 
            timeErrorMsg=" 4 hours is the maximum we can offer";
          }
          if(duration<30){ 
            timeErrorMsg="30 minutes is the minimum we can offer";
          }
          this.setState({
            ...this.state,
            loading: false
          })
          toast.error(timeErrorMsg);
        }
        }else {
          this.setState({
            ...this.state,
            loading: false
          })
          toast.error("Validation Error!");
        }
    })
  }
  
  onCheckboxChange =(e)=> {
   
      this.setState({
        ...this.state,
       address_later:e.target.checked ? 1 : 0
    });
  
  }
  updateTime = (type,timeValue,errorTime) => {
   // console.log("errorTime",errorTime)
    //const timeString = new Date(timeValue.$d).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit',second:'2-digit'});
    const timeString = (timeValue!=null&& !errorTime)?moment(timeValue.$d).format("HH:mm"):null;


    if(type === 'Start Time') {
      this.state.student_data[0]['start_time'] = timeString;

      this.setState({
          ...this.state,
          start_time:timeString,
          student_data: this.state.student_data,
          isValid: false
      });

    } else  {
      this.state.student_data[0]['end_time'] = timeString;
      this.setState({
          ...this.state,
          end_time:timeString,
          student_data: this.state.student_data,
          isValid: false
      });

    }
  }
    
  render() {
    const {showInperson,showOnline, address_later,emptyState, tutor, recruter, date, student_data, in_person, online, address, state, recurring_arrangment, isValid, loading, city, zip, start_time, end_time,selectedDayMapping } = this.state;
    const today = new Date()
  	let tomorrow =  new Date()
	  tomorrow.setDate(today.getDate() + 1)
    const day_time_mapping   = this.state.tutorAvailabilty?.day_time_mapping?this.state.tutorAvailabilty?.day_time_mapping:[];
    const specific_time_mapping   = this.state.tutorAvailabilty?.data?this.state.tutorAvailabilty?.data:[];
    //console.log("day_time_mapping",day_time_mapping)
    //console.log("specific_time_mapping",specific_time_mapping)
    return (
      <>
      {loading?<LoadingSpinner/>:
      <div className='container-fluid'>
        <div className="row">
          <div className=" col-md-6 col-lg-7">
            {
              !_.isEmpty(tutor) &&
              <div className="select-date__wrapper">
                <h4 className='bold text-grey'>Select Date</h4>
                <div className="form-group col-12 col-md-8 px-0">
                  <label htmlFor="tutor_name" className='col-form-label text-teal bold'>Tutor</label>
                  <input type="text" disabled className='form-control disabled-input' defaultValue={`${tutor.first_name} ${tutor.last_name}`}/>
                </div>
                {  
                
                  (_.isEmpty(day_time_mapping) &&  _.isEmpty(specific_time_mapping)) ?
                  <span className='text-danger'>Tutor is not available for booking!</span>:
                  <Calendar 
                    className='cal-schedule' 
                    calendarType={"US"}
                    onChange={(date) => {this.setState({ ...this.state, date }),this.getAvailabilty(tutor.id,date)}}
                    value={this.state.date}
                    tileDisabled={this.tileDisabled}
                    minDate={tomorrow}
                    // maxDate={new Date("2020-07-29")}
                  />
                }
              </div>
            }
          </div>
          {
          (!_.isEmpty(day_time_mapping) || !_.isEmpty(specific_time_mapping)) ?
          <div className="col-md-6 col-lg-5">
            <div className="schedule-section">
                <h4 className='bold text-grey'>Schedule</h4>
                {
                  emptyState ? 
                  <div className='py-6'>
                    <p className='text-center px-3'>Select a date on the calendar to schedule tutoring periods. Options will be displayed here.</p>
                  </div>
                  : 
                  <div className='py-4'>
                    <div className="form-group mb-3">
                      <label htmlFor="" className='col-form-label text-teal bold'>Date Selected:</label>
                      <div className="d-md-flex">
                        <p className='tab text-grey text-left mb-2 mb-md-0 mr-4'>{moment(date).format('LL')}</p>
                        <p className='txt-14 text-danger'>* Click date on calendar to change.</p>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="" className='col-form-label text-teal bold'>Students ({student_data.length})</label>
                      <div className="d-flex align-items-center">
                        <div className='image-group mr-3' style={{width: 'auto'}}>
                          {
                            student_data.length > 0 && student_data.map((student, i) => {
                              return (
                              <div style={{display: "flex"}} key={i}>
                                {student.profile_picture ?
                              <img src={student.profile_picture} style={{marginRight: '10px'}} alt="i-2"/>
                              :
                              <img style={{marginRight: '10px'}} src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png?1592828498" alt="i-2"/>}

                              <h3 style={{fontSize: '20px'}} className="text-grey">{student.student_name}</h3>
                             
                              </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>

                    {
                      student_data.length > 0 && student_data.map((student, key) => (
                        <div className="row my-3" key={key}>
                          <div className="col-8">
                            <div className="form-group mb-0">
                              <label htmlFor="start_time" className='txt-14 bold text-teal'>Start times:</label>
                              <TimePickerComponent tutor={tutor} date={date} timeType='Start Time' updateTime={this.updateTime} pickerStartTime={start_time} pickerEndTime={end_time} selectedDayMapping={selectedDayMapping}/>
                              {/* <select name="start_time" id="start_time" 
                              className={`custom-select ${(isValid && (validation.text(student.start_time) ? '' : 'error is-invalid')).toString()}`}
                              value={student.start_time}
                              onChange={this.onChange.bind(this, key)}>
                                <option value="">Select Time</option>
                                { this.prepareTimeOptions(tutor,date) }
                              </select>
                              <div className="invalid-feedback">
                                    Select start time.
                                </div> */}
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="form-group mb-0">
                              <label htmlFor="end_time" className='txt-14 bold text-teal'>End times:</label>
                              <TimePickerComponent tutor={tutor} date={date} timeType='End Time' updateTime={this.updateTime} pickerStartTime={start_time} pickerEndTime={end_time} selectedDayMapping={selectedDayMapping}/>
                              {/* <select name="end_time" id="end_time"
                               className={`custom-select ${(isValid && (validation.text(student.end_time) ? '' : 'error is-invalid')).toString()}`}
                               value={student.end_time}
                               onChange={this.onChange.bind(this, key)}>
                                <option value="">Select Time</option>
                                 { this.prepareTimeOptions(tutor,date) }
                              </select>
                              <div className="invalid-feedback">
                                    Select end time.
                                </div> */}
                            </div>
                          </div>
                          {
                            this.state.end_time && this.state.start_time &&
                            <div className="col-8">
                              <div className="form-group mb-0">
                                <label htmlFor="start_time" className='txt-14 bold text-teal'>Duration(s)</label>
                                  <p className='mt-2'>{moment.duration(moment(this.state.end_time, ["HH.mm"]).diff(moment(this.state.start_time, ["HH.mm"]))).asMinutes()+" minutes"}</p>
                              </div>
                            </div>
                          }
                        </div>
                      ))
                    }
                    
                    <div className="form-group mt-5">
                      <label htmlFor="prefer_location" className='txt-14 bold text-teal'>Would you like in-person tutoring? (You can receive tutoring in your home, a neighborhood coffee shop or a library)</label>
                      {!showInperson && !showOnline &&
                        <p>No Credits!</p>
                      }
                      <div className="d-flex flex-grow-1">
                        {showInperson &&
                          <div className='custom-control custom-radio mr-3 mb-3 mb-md-0'>
                            <input
                                type='radio'
                                className='custom-control-input'
                                id='person'
                                value="in_person"
                                onChange={this.onChange.bind(this, '')}
                                name="prefered_lesson_type"
                                checked={this.state.in_person === "1"}
                            />
                            <label className='custom-control-label error' htmlFor='person'>
                            In-person</label>
                        </div>}
                        {showOnline &&
                        <div className='custom-control custom-radio'>
                            <input
                                type='radio'
                                value="online"
                                className='custom-control-input'
                                 id='online'
                                name='prefered_lesson_type'
                                onChange={this.onChange.bind(this, '')}
                                checked={this.state.online === "1"}
                            />
                            <label className='custom-control-label' htmlFor='online'>
                            Online</label>
                        </div>
                        }
                      </div>
                    </div>
                    { this.state.in_person === "1" &&
                      <div className="d-flex align-items-center my-4">
                        <input aria-label="status" type="checkbox" className='checkbox-address-later' checked={this.state.address_later} id="switch-2" name="noti_2" onClick={this.onCheckboxChange}/>
                        <label htmlFor="switch-2" className='mb-0 ml-2'>I'm not sure, I will plan and let you know</label>
                      </div>
                    }
                     {this.state.in_person === "1" &&  this.state.address_later === 0 ? <>
                    
                     <div className="form-group col-md-8 px-0 mt-4">
                              <label htmlFor="address" className='txt-14 bold text-teal'>Address</label>
                              <input type="text"
                                value={address}
                                name="address"
                                onChange={this.onChange.bind(this, '')}
                                className={`form-control ${(isValid && (validation.text(address) ? '' : 'error is-invalid')).toString()}`}
                                id="address" placeholder="5174 Richmond Avenue" />
                                 <div className="invalid-feedback">
                                    Please enter your address.
                                </div>
                            </div>
                            <div className="form-group col-md-8 px-0 mt-4">
                              <label htmlFor="city" className='txt-14 bold text-teal'>City</label>
                              <input type="text"
                                value={city}
                                name="city"
                                onChange={this.onChange.bind(this, '')}
                                className={`form-control ${(isValid && (validation.text(city) ? '' : 'error is-invalid')).toString()}`}
                                id="city" placeholder="Enter your city.." />
                                <div className="invalid-feedback">
                                    Please enter your city.
                                </div>
                            </div>
                            <div className="form-group col-md-8 px-0">
                                <label htmlFor="state" className='txt-14 bold text-teal'>State</label>
                                <select name="state" id="state"
                                  value={state}
                                  onChange={this.onChange.bind(this, '')}
                                  className={`custom-select ${(isValid && (validation.text(state) ? '' : 'error is-invalid')).toString()}`}>
                                  <option value="">Choose One</option>
                                  <option value="Texas">Texas</option>
                                 
                                  {/* {states.map((state, key) => (
                                    <option key={key} value={state}>{state}</option>
                                  ))} */}
                                </select>
                                <div className="invalid-feedback">
                                    Please choose your state.
                                </div>
                              </div>
                              <div className="form-group col-md-8 px-0 mt-4">
                              <label htmlFor="zip" className='txt-14 bold text-teal'>Zip Code</label>
                              <input type="text"
                                value={zip}
                                name="zip"
                                onChange={this.onChange.bind(this, '')}
                                maxLength="5"
                                className={`form-control ${(isValid && (validation.zipCode(zip) ? '' : 'error is-invalid')).toString()}`}
                                id="zip" placeholder="Enter your zip code.." />
                                <div className="invalid-feedback">
                                please enter zip-code.it should be a maximum of 5 digits
                                </div>
                            </div>
                              </>: null }
                    {/* { this.state.in_person === '1' ?<div className="form-group col-md-8 px-0">
                      <label htmlFor="state" className='txt-14 bold text-teal'>State</label>
                      <select name="state" id="state" 
                      value={state}
                      onChange={this.onChange.bind(this, '')}
                      className={`custom-select ${(isValid && (validation.text(state) ? '' : 'error is-invalid')).toString()}`}>
                        <option value="">Choose One</option>
                        {
                            states.map((state, key) => (
                                <option key={key} value={state}>{state}</option>
                            ))
                        }
                      </select>
                    </div> : null } */}
                    {/* <div className="form-group ">
                      <label htmlFor="recurring" className='txt-14 bold text-teal'>Would you like this to be a recurring arrangement?</label>
                      <div className="d-flex flex-grow-1">
                        <div className='custom-control custom-radio mr-3 mb-3 mb-md-0'>
                            <input
                                type='radio'
                                className='custom-control-input'
                                id='yes'
                                value="1"
                                name="recurring_arrangment"
                                onChange={this.onChange.bind(this, '')}
                                checked={this.state.recurring_arrangment === "1"}
                            />
                            <label className='custom-control-label error' htmlFor='yes'>
                            Yes</label>
                        </div>
                        <div className='custom-control custom-radio'>
                            <input
                                type='radio'
                                value="0"
                                className='custom-control-input'
                                id='no' 
                                name='recurring_arrangment'
                                onChange={this.onChange.bind(this, '')}
                                checked={this.state.recurring_arrangment === "0"}
                            />
                            <label className='custom-control-label' htmlFor='no'>
                            No</label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                }
                <div className="d-flex justify-content-end align-items-center schedule-btn">
                  <button
                    disabled={loading}
                    onClick={this.onSubmit}
                    className={`btn btn-padding px-5 text-white btn-primary`}>
                    Continue
                  </button>
                </div>
            </div>
          </div>
          :
          <div></div>
          }
        </div>
      </div>
  }
      </>
    )
  }
}
