/* eslint-disable */
import React, {Component} from 'react';
import guard from "../actions/guard";
import ParentStudent from "../components/Dashboard/ParentStudent";
import Tutor from "../components/Dashboard/Tutor";
import {  updateLastAccess } from "../services/Profile";
class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            role: null
        }
    }

    componentDidMount() {
        updateLastAccess();
        guard.check(() => {
            this.setState({
                role: guard.role()
            })
        });
    }

    render() {
        console.log(this.state.role)
        return (
          <>
            {
                this.state.role === "tutor" &&
                <Tutor />
            }
            {
                (this.state.role === "parent" || this.state.role === "student") &&
                <ParentStudent />
            }
          </>
        );
    }
}

export default Dashboard;