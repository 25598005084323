
import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify"; 
import validation from "../../actions/validation";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'; 
import { updateTutorAvailability } from "../../services/Tutor";
import moment from "moment"; 
const TutorAvailabiltyModal=({open, info, handleClose,getAvailabilty,type})=>{
 // console.log(info)
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const onSubmit=(e)=>{ 
    //console.log(startTime)
    //console.log(validation.text(endTime))
    if(startTime!=null && endTime!=null){
    const start = moment(startTime.$d).format("HH:mm");
    const end = moment(endTime.$d).format("HH:mm");
    let startTimeUtc = moment.utc(moment(startTime.$d)).format();
    let endTimeUtc = moment.utc(moment(endTime.$d)).format();
    const endTimeIsAfterStart =moment(endTimeUtc).isAfter(startTimeUtc);
    if(endTimeIsAfterStart){
    let date =null;
    if(type==="specific"){
      date = info.startStr
    }else{
      date = info
    }
    const input = {
      date: date,
      startTime: start,
      endTime: end,
      type:type
    };
       updateTutorAvailability(input).then((res) => {
        if (res.status === 200) {
          if(res.data.type=="success"){
            toast.success(res.data.message); 
          }else{
            toast.warning(res.data.message); 
          }
          handleClose();
          setStartTime(null);
          setEndTime(null);
          getAvailabilty();
        } else {
        //   throw new Error(res.response.data.message);
        }
      }).catch((err) => {
        toast.error(err.message);
      })
    }else{
      toast.error("End time should be greater than start time");
    }
    }else { 
      toast.error("Validation Error!");
    }
  }
  const makeUnavailble=(e)=>{
    const date = info.startStr;
    const input = {
      date: date,
      startTime: null,
      endTime: null,
      type:"not_available" 
    };
       updateTutorAvailability(input).then((res) => {
        if (res.status === 200) {
          toast.success("Marked as N/A successfully!"); 
          handleClose();
          setStartTime(null);
          setEndTime(null);
          getAvailabilty();
        } else {
        //   throw new Error(res.response.data.message);
        }
      }).catch((err) => {
        toast.error(err.message);
      })
  } 
  
return(
    <Modal 
        open={open} 
        closeOnOverlayClick={false} center
        onClose={handleClose}
    >
         <button
         type="button" 
         className="close button-styles "
         style={{
          position: 'absolute',
          right:"5%" ,
          top: "5%",
          }} 
         onClick={handleClose}
         ></button>
        <h1 className="rating-header text-center text-grey mb-4 pt-2">Add the tutor available time</h1>
        <p className="mb-2 text-primary model-info-text">{(type==="specific")?"This will override hours for specific date":""}</p> 
       <div className="mb-3">
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Start Time"
        className="mr-2 "
        value={startTime}
        onChange={(newValue) => {
          setStartTime(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      <TimePicker
        label="End Time"
        value={endTime}
        onChange={(newValue) => {
          setEndTime(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
    </div>
    <div className="d-flex justify-content-end ">
    {(type==="specific")?
            <button 
            className="btn btn-primary text-white mr-2"
            onClick={(e)=>makeUnavailble(e)}>
            Mark as N/A
            </button>
            :<></>}
      <button 
            className="btn btn-primary text-white"
            onClick={(e)=>onSubmit(e)}>
            Submit
            </button>
            
            </div>
        </Modal>
)
}
export default TutorAvailabiltyModal;