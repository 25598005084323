/* eslint-disable */
import React, {Component} from 'react';
import _ from "lodash";
import { FaAngleDown } from "react-icons/fa";
import moment from "moment";
import auth from "../../actions/auth";
import { getUserLogs } from "../../services/User";
class NoticeBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activities: [],
        }
    }
    componentDidMount() {
        getUserLogs(auth.check().id).then((res) => {
            this.setState({
                ...this.state,
                activities: res.data.data,
            })

        });
    }
    render() {
        const {activities}=this.state;
        console.log("activities",activities)
        return (
            <div className={`book-tutor-block ${this.props.role === 'tutor' ? 'maxHeight-700':'maxHeight-400  mt-4'}`}  style={{minHeight: 'unset',overflow:'auto'}}>
                <div className="p-3">
                    <h6 className='text-teal bold'>Notice Board</h6>
                </div>
                <div className="card-body position-relative">
                    <div className="timeline">
                        {
                            activities.length > 0 && activities.map((notify, i) => (
                            <div className={`timeline-event ${i == 0 ? 'active':''}`} key={i}>
                                <div className="timeline-event__image"></div>
                                <div className="timeline-event__date">
                                    <div className="d-flex justify-content-between">
                                    <h6 className='bold text-grey'>{notify.title}</h6>
                                        <span className='timeline-time'>{moment(notify.created_at).fromNow()}</span>
                                    </div>
                                    <p className="timeline-data">{notify.action}</p>
                                </div>
                            </div>
                            ))
                        }
                        {
                            activities.length === 0 &&
                            <div className="d-flex justify-content-center align-items-center mb-5">
                                <p className=" font-weight-bold text-grey">No Notification !</p>
                            </div>
                        }
                    </div>
                </div>
                {/* <button className="btn btn-link text-teal position-relative" onClick={() => this.props.history.push("/notification")} style={{ bottom: 20, left: 20, fontSize: "16px", lineHeight: 1, opacity: .9 }}>
                    Go to notifications&nbsp;
                    <FaAngleDown />
                </button> */}
            </div>
        );
    }
}

export default NoticeBoard;