import React, {useState} from 'react';
import Modal from '@material-ui/core/Modal';

const SelectStudent = ({students, open, handleClose, bookTutor}) => {
    const [student, setStudent] = useState(null);
    return <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <div className="modal-container-student">
        <select name="" id="" onChange={(e) => setStudent(e.target.value)}>
            <option value="-1">Select Student</option>
            {students && students.length > 0 && students.map( (student, i) => {
                if(student?.user_credit){
                return <option key={i} value={student?.student_user?.id}>{student?.student_user?.first_name + ' ' + student?.student_user?.last_name}</option>;
            }
            })}
        </select>
        <button className={`btn btn-primary ${student && student > 0 ? '' : 'disabled'}`} onClick={() => {
            if(student && student > 0) {
                const studentDetail = students.find(s => +s.student_user.id === +student);
                bookTutor(student, studentDetail.student_user.first_name + ' ' + studentDetail.student_user.last_name, studentDetail.profile_picture);
            }
                
        }}>Confirm</button>
    </div>
  </Modal>
}

export default SelectStudent;