import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUsercredits } from '../../services/Profile';
import ShowMoreData from '../Modal/ShowDataCommonModal';

const TutorCredits = ({students,role}) => {
    const[userCredits,setUsercredits]=useState(null);
    const[creditValue,setCreditvalue]= useState(false);
    const getData = (studentId)=>{
        getUsercredits (studentId)
          .then((res) => {
            let resultData = res.data.data;
            if (res.status === 200) {
                setUsercredits(resultData);
                setCreditvalue(true);
              } 
          })
          .catch((err) => {
            toast.error("Something went wrong");
          });

    }
   const closeViewCreditModal=()=>{
        setCreditvalue(false);
    }
    return (
        <>
        <div className="tutor-credits">
            <h5 className="title text-grey">Tutorial Hours Remaining</h5>
            <div className="student-container">
                {students.map( (student, i) => {
                    let className = '';
                    switch(student?.subscription?.plan?.name) {
                        case 'Silver': className='silver';
                                        break;
                        case 'Charcoal Plan': className='charcol';
                                        break;
                        case 'Ivory Plan': className='ivory';
                                        break;
                        case 'Turquoise Plan': className='turquoise';
                                            break;
                        case 'Teal': className='teal';
                                        break;
                        default: className='';
                                    break;
                    }
                    return (
                        <div className="student" key={i} style={{justifyContent: student?.subscription_id ? 'space-between' : 'space-between'}}>
                           {role === "parent"?
                           <div className="name text-grey">
                                {`${student?.first_name} ${student?.last_name}`}
                            </div>:null} 
                            {student?.credit>0 ?
                             <button type="button" className="counter-data" onClick={()=>getData(student?.id)}>
                                { student?.credit}
                            </button>
                            :<span class="counter-data">0</span>}
                            {student?.subscription_id ? (
                                <>
                                    
                                    <div className={`button ${className}`}>{student?.subscription?.plan?.name ? student?.subscription?.plan?.name.replace("_OLD", " ") :''}</div>
                                </>
                            ) : (
                                <Link to={`/pricing?studentId=${student?.id}`}><button type="button" className="button subscribe">Subscribe</button></Link>
                            )}
                            
                        </div>
                    )
                })}
            </div>
        </div>
        <ShowMoreData
            openModal={creditValue}
            userCredits={userCredits}
            closeModal={closeViewCreditModal}
            ratingText="Plan type and credit"
        />
        </>
    )
}

export default TutorCredits;