
import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import { sessionMissedReason } from "../../services/Tutor";
import validation from "../../actions/validation";
const ReasonforMissedSession=({open, scheduleId, handleClose})=>{
    const[missedReason,setMissedreason]=useState('')
        const enterReason=(e)=>{
        setMissedreason(e.target.value)
    }
    const onSubmitReason=(e)=>{
        const inputReason = {
            missedReason: missedReason,
            scheduleId: scheduleId,
          };
        if(inputReason && validation.text(missedReason)){
            sessionMissedReason(inputReason).then((res) => {
                if (res.status === 200) {
                  toast.success("Reason added successfully!");
                  window.location.href="/notification";
                } else {
                //   throw new Error(res.response.data.message);
                }
              }).catch((err) => {
                toast.error(err.message);
              })
            
            }
        else {
            toast.error("Validation error!");
          }
        }
return(
    <Modal 
        open={open} 
        closeOnOverlayClick={false} center
        onClose={handleClose}
    >
         <button
         type="button" 
         className="close button-styles "
         style={{
          position: 'absolute',
          right:"5%" ,
          top: "5%",
          }} 
         onClick={handleClose}
         ></button>
        <h1 className="rating-header text-center text-grey mb-2 mt-4 pt-4">Enter reason for the missing session</h1>
       
        <div className="tutorial-recap-form-sub-heading w-100 mt-3 svg-icons-btn"
        >
            <div className="col-12 mt-2 p-0">
                <div className="form-group">

                     <textarea 
                    className={`rating-textarea ${(missedReason&& (validation.text(missedReason) ? '' : 'error is-invalid')).toString()}`} 
                     name="missed_reason" 
                     id="missed_reason" 
                     rows="4"
                     placeholder="Write here...."
                     onChange={(e)=>enterReason(e)}
                     >
                     </textarea>
                </div>
            </div>
        
            <button 
            className="btn btn-primary text-white float-right"
            onClick={(e)=>onSubmitReason(e)}>
            Submit
            </button>
        </div>
        </Modal>
)
}
export default ReasonforMissedSession;