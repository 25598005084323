import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Rating from '@material-ui/lab/Rating';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import validation from "../../actions/validation";
import { InputLabel } from '@material-ui/core';
import { toast } from 'react-toastify';
import guard from '../../actions/guard';
import { addReview, addRecap } from "../../services/Rating";
import moment from "moment";

const ReviewRecapModal = ({ sessionId, tutorId, schedule,reloadPage =false }) => {
  const [open, setOpen] = useState(false);
  const emailInputRef = useRef(null);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [recap, setRecap] = useState({
    subjects_studied:'',
    things_worked:'',
    things_continue:'',
    resources:''
  }); 
  const [isRating, setIsrating] = useState(false);
  const [isReview, setIsreview] = useState(false);
  const [hover, setHover] = useState(-1);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  useEffect(() => {
    onOpenModal();
    setTimeout(() => {
      if (emailInputRef.current)
        emailInputRef.current.focus();
    }, 10)
  }, []);
  
  const labels  = {
    0: 'Please leave a review for your tutor',
    1: 'Very Poor',
    2: 'Poor', 
    3: 'Average',
    4: 'Good', 
    5: 'Very Good',
  };
  
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }
  
  const enterReview = (e) => {
    setIsreview(false)
    setReview(e.target.value)
  }
  const enterRating = (e) => {
    setIsrating(false)
    setRating(parseInt(e.target.value))
  }
  const enterRecap = (e) => {
    setIsreview(false)
    const recapStates = {...recap, [e.target.name] : e.target.value};
    setRecap(recapStates)
  }
  const submitReview = () => {
    const input = {
      rating: rating,
      review: review,
      session_id: sessionId,
      tutor_id: tutorId
    };
    setRating(rating)
    setReview(review)
    if(!rating){
      setIsrating(true)
    }
    if(!review){
    	setIsreview(true)
  	}
    if (input.rating && input.review) {
      if (input.rating >= 1
        && validation.positiveNumber(rating)
        && validation.text(review)) {
        addReview(input).then((res) => {
          console.log(input)
          if (res.status === 200) {
            toast.success("Review added successfully!");
            setOpen(false);
          } else {
            throw new Error(res.response.data.message);
          }
        }).catch((err) => {
          toast.error(err.message);
        })
        onCloseModal();
        if(reloadPage){
          window.location.reload();
        }
      }
      else {
        toast.error("Validation error!");
      }
    }
    else {
      toast.error("Validation error!");
    }

  }
  const submitRecap = () => {
    
    setRecap(recap)
    const inputRecap = {
      recap: recap,
      session_id: sessionId,
      tutor_id: tutorId
    };
    setIsreview(true)
    if (inputRecap.recap) { 
      if (inputRecap.recap && validation.text(recap.subjects_studied) && validation.text(recap.things_worked) && validation.text(recap.things_continue)) {
         addRecap(inputRecap).then((res) => {
          console.log(inputRecap)
          if (res.status === 200) {
            toast.success("Recap added successfully!");
            setOpen(false);
          } else {
            throw new Error(res.response.data.message);
          }
        }).catch((err) => {
          toast.error(err.message);
        })
        onCloseModal();
      }
    }
    else {
      toast.error("Validation error!");
    }
  }

  return (
    <div>
      {/* <button onClick={onOpenModal}>Open modal</button> */}
      {/* this modal for rating */}
      {guard.role() === 'tutor' ?
        <Modal className="rating-modal" open={open} onClose={onCloseModal} closeOnOverlayClick={false}
        showCloseIcon={false}
          center
        >
          <h3 className="rating-header text-center text-grey mb-2">Tutorial recap form</h3>
          <div className="tutorial-recap-form-sub-heading"
          >
            <p className="text-grey mb-4">Kindly fill in details about the just concluded tutorial session.
          These details will be sent to the parent. </p>
          <div className='form-row'>
                        <div className='form-group col-md-6 tutorial-recap-form'>
                            <label htmlFor='date' >Date</label>
                             <div className="form-control light">{moment(schedule.start_time_utc).local().format('MM-DD-YYYY')}</div> 
                        </div>
                        <div className='form-group col-md-3 tutorial-recap-form'>
                            <label htmlFor='start_date' >Start time</label>
                            <div className="form-control light">{moment(schedule.start_time_utc).local().format("hh:mm a")}</div> 
                        </div>
                        <div className='form-group col-md-3 tutorial-recap-form'>
                            <label htmlFor='end_date' >End time</label>
                            <div className="form-control light">{moment(schedule.end_time_utc).local().format("hh:mm a")}</div> 

                        </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-12'>
              <label htmlFor='subjects_studied' className="tutorial-recap-form" >Subject(s) studied</label>
              <textarea className={`rating-textarea form-control light ${(isReview && (validation.text(recap?.subjects_studied) ? '' : 'error is-invalid')).toString()}`} id="subjects_studied" name="subjects_studied" placeholder="Write something ..." onChange={(e) => enterRecap(e)}></textarea>
              {(isReview && (!validation.text(recap?.subjects_studied))) ?
            <div className="invalid-feedback">
              Required Field
            </div> : null}
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-12'>
              <label htmlFor='things_worked' className="tutorial-recap-form" >Things we worked on</label>
              <textarea className={`rating-textarea form-control light ${(isReview && (validation.text(recap?.things_worked) ? '' : 'error is-invalid')).toString()}`} id="things_worked" name="things_worked" placeholder="Write something ..." onChange={(e) => enterRecap(e)}></textarea>
              {(isReview && (!validation.text(recap?.things_worked))) ?
            <div className="invalid-feedback">
              Required Field
            </div> : null}
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-12'>
              <label htmlFor='things_continue' className="tutorial-recap-form" >Things we will continue to work on</label>
              <textarea className={`rating-textarea form-control light ${(isReview && (validation.text(recap?.things_continue) ? '' : 'error is-invalid')).toString()}`}  id="things_continue" name="things_continue" placeholder="Write something ..." onChange={(e) => enterRecap(e)}></textarea>
              {(isReview && (!validation.text(recap?.things_continue))) ?
            <div className="invalid-feedback">
              Required Field
            </div> : null}
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-12'>
              <label htmlFor='resources' className="tutorial-recap-form" >Resources (Add links if any)</label>
              <textarea className={`rating-textarea form-control light`}   id="resources" name="resources" placeholder="Write something ..." onChange={(e) => enterRecap(e)}></textarea>
            </div>
          </div> 
          
          {/* <textarea
            row="10"
            className={`rating-textarea ${(isReview && (validation.text(recap) ? '' : 'error is-invalid')).toString()}`}
            placeholder="write here ..."
            value={recap}
            name ="recap_text"
            onChange={(e) => enterRecap(e)}
          />
          {isReview ?
            <div className="invalid-feedback">
              Please enter recap
            </div> : null} */}
          <button
            className="btn btn-primary text-white float-right mt-3"
            onClick={submitRecap}>
            Submit
          </button>
          </div>
        </Modal>
        : <Modal
            open={open}
            onClose={onCloseModal} 
            closeOnOverlayClick={false} 
            center
            showCloseIcon={true}
           
          >
          <h3 className="rating-header text-center">How will you rate ?</h3>
          <p className="mb-0 text-center">{labels[hover !== -1 ? hover : rating]}</p>
          <Rating
            name="Rating Label"
            value={rating}
            className={`rating-modal-star ${(isRating && (validation.positiveNumber(rating) ? '' : 'error is-invalid')).toString()}`}
            onChange={(e) => enterRating(e)} getLabelText={getLabelText} onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}/>
                    

          {isRating ?
            <div className="invalid-feedback">
              Please give your rating
            </div>
            : null}
          {/* <button className="text-teal m-auto rating-tell-us-button">Tell us more </button> */}
          <textarea
            ref={emailInputRef}
            row="10"
            className={`rating-textarea ${(isReview && (validation.text(review) ? '' : 'error is-invalid')).toString()}`}
            placeholder="Write your review here"
            value={review}
            onChange={(e) => enterReview(e)}
          />
          {isReview ?
            <div className="invalid-feedback">
              Please enter your review
            </div>
            : null}
          <button
            className="btn btn-primary text-white float-right mt-3"
            onClick={submitReview}>
            Save
          </button>
         
        </Modal>
      }
    </div>
  );
};

export default ReviewRecapModal;