import React, { Component } from 'react'
import validation from "../../../actions/validation";
import {toast} from "react-toastify";
import { subjectList } from "../../../services/Common";
import Select from 'react-select';
import { get } from '../../../services/Profile';
import auth from '../../../actions/auth';
import _ from "lodash";
const questions = ['Exam Preparation','Study Support','Homework Help','Mentorship'];

export default class Additional extends Component {

  constructor(props) {
    super(props);
    this.state = {
        complete: false,
        additional_details_for_achive: [],
        about_student: "",
        //validation
        isValid: false,
        loading: false,
        struggle_topics: [],
        struggle_details: "",
        subjectList: [],
        user_struggle_topics:""
    }
  }
  componentDidMount() {
    this.getProfile();
  }
  componentDidUpdate(prevProps) {
    if(this.props.loading !== prevProps.loading || this.props.complete !== prevProps.complete) {
        this.setState({
            ...this.state,
            loading: this.props.loading,
            complete: this.props.complete,
        });
    }
  }
  getProfile = () => {
    this._isMounted = true;
    const studentId = this.props.payload.student_data ? this.props.payload?.student_data[0]?.student_id: null;
    if(studentId!=null){
    get(studentId).then((res) => {
         if(res.status === 200) {
            if(this._isMounted) {
                this.setState({
                    ...this.state,
                    loading:false,
                    user_struggle_topics:res.data.data.student_details.struggle_topics
            }, () => {
                this.fetchSubList();
            });
            }
        }else {
            toast.error("Something went wrong!");
         }
    }).catch((err) => {
       
        toast.error("Something went wrong!");
        console.log("second error block",err);
    })
  }else{
    this.fetchSubList();
  }
}
  onChange = (e) => {
      e.persist();
      if (e.target.type === "checkbox") {
        if (e.target.checked) {
            this.state[e.target.name].push(e.target.value);
            this.setState({
                ...this.state,
                [e.target.name]: this.state[e.target.name],
                isValid: false
            })
        } else {
            let index = this.state[e.target.name].indexOf(e.target.value);
            this.state[e.target.name].splice(index, 1);
            this.setState({
                ...this.state,
                [e.target.name]: this.state[e.target.name],
                isValid: false
            })
        }
      }else {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value,
          isValid: false
        })
      }
  };

  onSubmit = () => {
    const { about_student, struggle_topics, struggle_details} = this.state;
      this.setState({
        ...this.state,
        loading: true,
        isValid: true,
      }, () => {
        if(validation.text(struggle_topics) && validation.text(struggle_details)
           && validation.maxChar(struggle_details,300)
        ) { 
          this.props.getData({
           
            about_student,
            struggle_topics,
            struggle_details
          });
        }else {
          this.setState({
            ...this.state,
            loading: false
          });
          if(!validation.maxChar(struggle_details,300)){
            toast.error("More than 300 characters entered for struggle details.");
          }else{
            toast.error("Please fill in the form.");
          }
        }
      });
  }

  fetchSubList = () => {
    subjectList().then((res) => {
      if (res.status === 200) {
        const userSturggletopics = this.state.user_struggle_topics;
        let dataFormated =[];
        if(userSturggletopics!=null){
	        dataFormated = userSturggletopics.split(",")
	    }
        const subjectNames = Object.values(res.data.subjects).map((item)=>item);
        let userTopics = [];
        const subjectList =_.flatten(subjectNames); 
        dataFormated.length && dataFormated.map(item=>{ 
          _.find(subjectList, (subject) => {
                          if (subject.sub_subject_name === item) {
              userTopics.push({
                                  value:subject.id,
                                  label : item
                              })
                          }
                      });
                })
                this.setState(   {
                  ...this.state,
                  struggle_topics: userTopics
                  });   
        this.setState({
          ...this.state,
          subjectList: Object.values(res.data.subjects).map((sub) => (
              {
                label: sub[0].subject_group_name,
                options: sub.map((inner) => (
                  { value: inner.id, label: inner.sub_subject_name }
                ))
              }
            ))
        })
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  handleMultiChange  = (option) =>  {
    this.setState(
    {
      ...this.state, 
      struggle_topics: option,
      isValid: false
    })
    console.log(this.state)
  }
  render() {
    const { additional_details_for_achive, about_student, complete, loading, isValid, struggle_topics, struggle_details, subjectList,user_struggle_topics } = this.state; 
    return (
      <div>
        {
          !complete ?
          <section className='additional-section px-5 text-grey'>
            <h3 className='text-center text-grey my-5'>Additional Detail</h3>
            <div className="d-md-flex flex-column"> 
            <div className="col-md-6">
            <div className="from-group">
              <label htmlFor="struggle_topics" className='col-form-label'>What topics are you struggling with?</label>
              <Select id='struggle_topics' className={`form-control h-auto border-0 p-0 ${(isValid && (validation.text(struggle_topics) ? '' : 'error is-invalid')).toString()}`} isMulti value = {struggle_topics} onChange={this.handleMultiChange} options={subjectList} />
            </div>
            
            </div>
            <div className="from-group my-3 col-md-6">
              <label htmlFor="struggle_details" className='col-form-label'>Please detail any struggles that you are currently having with your subject(s) (300 characters max.)</label>
              <textarea id="struggle_details" 
              className={`form-control ${(isValid && ((validation.text(struggle_details) && validation.maxChar(struggle_details,300))? '' : 'error is-invalid')).toString()}`}
              onChange={this.onChange} 
              rows="4" name="struggle_details"></textarea>
            </div>
            {/* <div className="col-md-6"> */}
            {/* <div className='form-group mb-5'>
              <label htmlFor='achieve' className='col-form-label '>What do you hope to achieve? Select all that apply</label>
              {
                questions.map((q, key) => (
                  <div className='custom-control custom-checkbox mb-2' key={key}>
                    <input
                      type='checkbox'
                      className='custom-control-input'
                      id={'additional_details_for_achive' + key}
                      onChange={this.onChange} 
                      value={q}
                      name="additional_details_for_achive"
                    />
                    <label className={`custom-control-label checkbox-2 ${(isValid && (additional_details_for_achive.length > 0 ? '' : 'error is-invalid')).toString()}`} htmlFor={'additional_details_for_achive' + key}>
                      {q}
                    </label>
                  </div>
                ))
              }
            </div> */}
            {/* <div className="from-group">
              <label htmlFor="suggest" className='col-form-label'>Tell us a your weak points academically</label>
              <textarea id="suggest" 
              className={`form-control ${(isValid && (validation.text(about_student) ? '' : 'error is-invalid')).toString()}`}
              onChange={this.onChange} 
              rows="4" name="about_student"></textarea>
            </div> */}
            
            {/* </div> */}

            </div>
            <div className='d-flex justify-content-center align-items-center my-5'>
                  <button
                    disabled={loading}
                    onClick={this.onSubmit}
                    className={`btn btn-padding px-5 text-white btn-primary`}>
                    Place Request
                  </button>
            </div>
          </section>
          :
          <section className='additional-section padding-2x'>
            <h3 className='text-center text-grey my-5'>You have successfully booked a tutor</h3>
            <p className="text-center text-grey mx-auto">You will get a confirmation email and a message on your dashboard when your tutor has confirmed availability.</p>
            <div className='d-flex justify-content-center align-items-center my-5'>
                  <button
                    onClick={this.props.back}
                    className={`btn btn-padding px-5 text-white btn-primary`}>
                    Back to Dashboard
                  </button>
            </div>
          </section>
        }
      </div>
    )
  }
}
