export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

export const racicalCategories = [
    'American Indian or Alaska Native',
    'Asian',
    'Black or African American',
    'Hispanic or Latino',
    'Native Hawaiian or Other Pacific Islander',
    'White',
    'I don\'t prefer to say'
];

export const occupations = [
    'Doctor',
    'Engineer',
    'Consultant',
    'Educator',
    'Home Maker',
    'Business'
];

export const disabilityList = [
   "ADD/ADHD",
   "Dyslexia",
   "Dyscalculia",
   "Dysgraphia",
   "Non-Verbal Learning Disabilities",
   "Oral/Written Language Disorder",
   "Specific Reading Comprehension Deficit" ,
   "None"
];

export const majors = [
    'Science',
    'Commerce',
    'Arts'
];

export const dayList = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];

export const educations = [
    'High School',
    'Some College',
    'Teacher',
    'Bachelors Degree',
    'Masters Degree',
    'Doctoral Degree'
];
export const teachingStyle = [
    'Lecture Teaching Style',
    'Blended-Hybrid Teaching Style',
    'Activity Based Teaching Style',
    'Have not taken teaching style quiz yet'

];

export const singleSessionCharges = {
    'SINGLE_SESSION_ONLINE_CHARGE' : 45,
    'SINGLE_SESSION_INPERSON_CHARGE':55,
    'SINGLE_SESSION_ONLINE_CHARGE_OLD' : 55,
    'SINGLE_SESSION_INPERSON_CHARGE_OLD':65,
    'SINGLE_SESSION_TEST_PREP_CHARGE':100
}

export const learningStyle = [
    'Kinesthetic Learner (you would pair well with a tutor/teacher that has an Activity-Based Teaching Style)',
    'Auditory Learner (you would pair well with a tutor/teacher that has a Lecture-Based Teaching Style)',
    'Visual Learner (you would pair well with a tutor/teacher that has a Blended Teaching Style)',


];
export const studentGrade=[
    'Pre-k',
    'Grade 1',
    'Grade 2',
    'Grade 3',
    'Grade 4',
    'Grade 5',
    'Grade 6',
    'Grade 7',
    'Grade 8',
    'Grade 9',
    'Grade 10',
    'Grade 11',
    'Grade 12',
    'College',
    'Graduate School'
]

export const genders=[
    'Male',
    'Female',
    'Others',
    
]
export const numberOfKids=[
    '1',
    '2',
    '3',
    '4',
    '5',

]
export const monthsInnumber=['1','2','3','4','5','6','7','8','9','10','11','12','13',
'14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30',
'31','32','33','34','35','36'
]
export const langaugeForspeak=[
    'French',
    'Spanish', 
    'Vietnamese', 
    'Chinese (Mandarin)', 
    'Arabic', 
    'Urdu', 
    'German', 
    'Russian', 
    'Portuguese',
    'None'
]
export const highestDegree=[
    'Associates Degree',
    'Bachelors Degree', 
    'Masters Degree',
    'Doctoral Degree', 
     'High School Diploma'
]

export const usTimeZones = {
    'Pacific Time' : 'US/Pacific',
    'Mountain Time': 'US/Mountain',
    'Central Time' : 'US/Central',
    'Eastern Time' : 'US/Eastern'
}





