import axios from "axios";
import { API_END_POINT } from "../config/env.json";

export const subscribe = (payload) => {
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return axios.post(`${API_END_POINT}/v1/newsletter`, payload, options)
            .then(res => res)
            .catch(err => err);
};