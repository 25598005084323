import React, {Component} from 'react';
import Layout from "../components/Layout";  
import { toast } from "react-toastify";
import validation from "../actions/validation";
import {updateFromForgotPassword} from "../services/Profile";

class ConfirmPassword extends Component { 
    constructor(props) {
        super(props);
        let token = '';
        if(props.location && props.location.search.includes("token")){
            const query = new URLSearchParams(props.location.search);
            token = query.get("token"); 
        } else {
            this.props.history.push("/");
        }
        this.state = {
            new_password: "",
            new_confirm_password: "",
            isValid: false,
            loading: false,
            password_verification_token:token
        }
    }
    onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false,
            loading: false
        })
    }
    update = () => {
        const { password_verification_token, new_password, new_confirm_password } = this.state;
        let input = {
            new_password: new_password,
            new_confirm_password: new_confirm_password,
            password_verification_token: password_verification_token
        }
        this.setState({
            ...this.state,
            isValid: true,
            loading: true
        }, () => {
            if(validation.passwordStrength(new_password, "medium")
                && validation.passwordStrength(new_confirm_password, "medium")
            ) {
                updateFromForgotPassword(input).then((res) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    if(res.status === 200) {
                        toast.success(res.data.message);
                        setTimeout(() => {
                             this.props.history.push("/");
                        }, 1000);
                    }else {
                        toast.error(res.data.message);
                    }
                }).catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Something went wrong!");
                })
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                })
                toast.error("Validation Error!");
            }
        })
    }

    render() {
        const { new_password, new_confirm_password, isValid, loading } = this.state;
        console.log(this.state)
        return (
            <Layout>
            <div className="confirmation-page intro-content">
                <h3 className='text-center text-grey my-5'>Reset Your Password</h3>
                <div className='my-5'>
                {/* <h5 className='bold serif mb-5'>Password Settings</h5> */}
                <form className='flexible-width' onSubmit={(e) => {e.preventDefault();}}>
                    <div className="row mb-5">
                        <div className="col-md-12">
                            <div className="form-group mb-4">
                                <label htmlFor="new_password" className='toggle-label bold'>Enter new Password</label>
                                <input type="password" 
                                    className={`form-control ${(isValid && ((validation.passwordStrength(new_password, "medium") && new_password === new_confirm_password) ? '' : 'error is-invalid')).toString()}`} 
                                    name="new_password" 
                                    id="newpass" 
                                    onChange={this.onChange}
                                />
                                <div className="invalid-feedback">
                                    Password miss-matching/Can't be empty.
                                </div>
                            </div>
                            <div className="form-group mb-0">
                                <label htmlFor="new_confirm_password" className='toggle-label bold'>Confirm new Password</label>
                                <input type="password" 
                                    className={`form-control ${(isValid && ((validation.passwordStrength(new_confirm_password, "medium") && new_password === new_confirm_password) ? '' : 'error is-invalid')).toString()}`} 
                                    name="new_confirm_password" 
                                    id="confpass" 
                                    onChange={this.onChange}
                                />
                                <div className="invalid-feedback">
                                    Password miss-matching/Can't be empty.
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 align-self-end">
                        <div className='d-flex justify-content-center align-items-center my-5'>
                                <button type="submit" className="btn btn-primary btn-padding--md text-white" disabled={loading} onClick={this.update}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </Layout>
           
        );
    }
}
export default ConfirmPassword;