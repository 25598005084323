import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from "prop-types";
import { MdMenu, MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './Navbar.css';
import env from "../../config/env.json";
import { useLocation } from 'react-router-dom';

export default function Navbar({ path }) {

    const location = useLocation();
    console.log(location.pathname);

    const navFunc = () => {
        const showNav = () => {
            document.querySelector(".mobile-nav").classList.add("show");
            document.querySelector(".mobile-header--overlay").classList.add("show");
        };
        const hideNav = () => {
            document.querySelector(".mobile-nav").classList.remove("show");
            document
                .querySelector(".mobile-header--overlay")
                .classList.remove("show");
        };
        document.querySelector(
            ".mobile-header--icon"
        ).onclick = function() {
            showNav();
        };
        document.querySelector(".mobile-nav--close").onclick = function() {
            hideNav();
        };
        document.querySelector(
            ".mobile-header--overlay"
        ).onclick = function() {
            hideNav();
        };
        document.querySelector(
            ".navigation-links li"
        ).onclick = function() {
            hideNav();
        };
    };

    useEffect(() => {
        // calling navFunc
        navFunc();
    }, []);

    const NavLinks = () => (
        <ul className="navigation-links">
            <li className={path === '/how' ? 'active': ''}><a href={env.LANDING_URI + '/how-it-works'} target="_blank" rel="noopener noreferrer">How it works</a></li>
            <li className={path === '/about' ? 'active': ''}><a href={env.LANDING_URI + '/about'} target="_blank" rel="noopener noreferrer">About</a></li>
            {/* <li className={path === '/faq' ? 'active': ''}><a href={env.LANDING_URI + '/faq'} target="_blank" rel="noopener noreferrer">FAQs</a></li>  */}
            <li className={path === '/contact' ? 'active': ''}><a href={env.LANDING_URI + '/contact'} target="_blank" rel="noopener noreferrer">Contact Us</a></li>
        </ul>
    );

    return (
        <React.Fragment>
            <header className="fixed-header desktop-header">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logo-wrapper">
                        <a href={env.LANDING_URI}>
                            <img src="/images/logo.svg" alt="site-logo" />
                        </a>
                        </div>
                        <nav className="nav-wrapper">
                            <NavLinks />
                        </nav>


                        {location.pathname === "/"  ?  
                        <a href={env.LANDING_URI + '/contact'} target="_blank" rel="noopener noreferrer"> 
                                <div className="nav-cta">
                                <Button variant="outline-dark" className="px-4">Sign Up</Button>
                            </div>
                        </a>
                        :
                        <Link to="/">
                        <div className="nav-cta">
                            <Button variant="outline-dark" className="px-4">Log In</Button>
                        </div>
                        </Link>
                    //     <Link to="/signup">
                    //     <div className="nav-cta">
                    //         <Button variant="outline-dark" className="px-4">Sign Up</Button>

                    //     </div>
                    // </Link> 

                }

                    </div>
                </div>
            </header>
            <div className="mobile-header">
                <header aria-label="mobile-header" className="fixed-header">
                    <div className="d-flex align-items-center h-100 position-relative">
                        <div className="mobile-header--icon pointer">
                            <MdMenu fontSize="24px" />
                        </div>
                        <div className="logo-wrapper center-hr">
                            <Link to="/">
                                <img src="/images/logo.svg" alt="site-logo"/>
                            </Link>
                        </div>
                    </div>
                </header>

                <div className="mobile-header--overlay" />

                <aside className="mobile-nav">
                    <div className="mobile-nav--close pointer">
                        <div className="middle">
                            <Link to="/">
                                <img src="/images/logo.svg" alt="site-logo"/>
                            </Link>
                        </div>
                        <div className="rounded-circle border bg-light p-2 mr-3">
                            <MdClose fontSize="22px" />
                        </div>
                    </div>
                    <div className="nav-wrapper">
                        <NavLinks />
                    </div>
                </aside>
            </div>
        </React.Fragment>
    )
}

Navbar.propTypes = {
    path: PropTypes.string
}