import axios from "axios";
import { API_END_POINT } from "../config/env.json";
import cookie from "../actions/cookie";

export const updateStudentUserDetails = (userId, payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/update-student-user-details/${userId}`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const updateParentUserDetails = (userId, payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/update-parent-user-details/${userId}`, payload, options)
        .then(res => res)
        .catch(error => error);
};

 export const getUserLogs = (uid) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/get-logs/${uid}`, options)
        .then(res => res)
        .catch(error => error);
};