/* eslint-disable */
import React, {Component} from 'react';
import _ from "lodash";
import Calendar from 'react-calendar';
import validation from "../../actions/validation";
import moment from "moment";
import {toast} from "react-toastify";
import {get} from "../../services/Profile";
import {reSchedule} from "../../services/Tutor";
import $ from "jquery";
import TimePickerComponent from '../TimePickerComponent';
import { getTutorAvailability, getTutorAvailabilityDate } from "../../services/Tutor";
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

class Reschedule extends Component {

    constructor(props) {
      const today = new Date()
  	  let tomorrow =  new Date()
	    tomorrow.setDate(today.getDate() + 1)
        super(props);
        this.state = {
            date: tomorrow,
            day_time_mapping: [],
            start_time: "",
            end_time: "",
            rescheduled_reason: "",
            profileLoading: true,

            //validation
            isValid: false,
            loading: false,
            tutor: {},
            selectedDayMapping: [],
            tutorAvailabilty:[],
            timeErrorMsg:null
        }
    }

    componentDidUpdate(prevProp) {
      if(this.props.id && this.props.tutorId && this.props.id !== prevProp.id) {
        this.setState({
          ...this.state,
          day_time_mapping: [],
          start_time: "",
          end_time: "",
          rescheduled_reason: "",
          isValid: false,
          loading: false,
        }, () => {
          // if(this.props.dayTimeMapping) {
          //   this.setState({
          //     ...this.state,
          //     day_time_mapping: this.props.dayTimeMapping,
          //     profileLoading: false
          // });
          // }else {
          //   this.getProfile();
          // }
          this.getProfile();
          getTutorAvailability(this.props.tutorId).then((res) => {
            if (res.status === 200) {
              this.setState({
                ...this.state,
                tutorAvailabilty: res.data
              }); 
            } 
          }).catch((err) => {
            toast.error(err.message);
          })
          getTutorAvailabilityDate(this.props.tutorId,moment(this.state.date).format('YYYY-MM-DD')).then((res) => {
            if (res.status === 200) {
              this.setState({
                ...this.state,
                selectedDayMapping:res.data.data  
              }); 
            } 
          }).catch((err) => {
            toast.error(err.message);
          })
        });
      }
    }

    getProfile = () => {
      get(this.props.tutorId).then((res) => {
          if(res.status === 200) {
              this.setState({
                  ...this.state,
                  day_time_mapping: res.data.data.day_time_mapping,
                  tutor:  res.data.data,
                  profileLoading: false
              });
          }else {
              toast.error("Something went wrong!");
          }
      }).catch((err) => {
          toast.error("Something went wrong!");
      })
    }

    tileDisabled = (e) => {
     // console.log("this.state.tutorAvailabilty.data",this.state.tutorAvailabilty.data)
     // console.log("day_time_mapping",this.state.tutorAvailabilty.day_time_mapping)
      const includedInSpecific = _.findIndex(this.state.tutorAvailabilty.data, {available_date: moment(e.date).format("YYYY-MM-DD"),not_available:0}) 
      const notAvailable = _.findIndex(this.state.tutorAvailabilty.data, {available_date: moment(e.date).format("YYYY-MM-DD"),not_available:1}) 
      if(includedInSpecific>=0){
        return false;
      }else if(notAvailable>=0){
        return true;
      }else{
        const day_time_mapping   = this.state.tutorAvailabilty?.day_time_mapping?this.state.tutorAvailabilty?.day_time_mapping:[];
    //   console.log("day_time_mapping",day_time_mapping) 
        let uniqDay = _.uniq(day_time_mapping.map((val) => val.day))
        return _.indexOf(uniqDay, days[moment(e.date).day()]) === -1;
      }
    }

    prepareTimeOptions = (day_time_mapping,date,currentTime) => {
      let time = null;
      if(_.filter(day_time_mapping, ['day', days[moment(date).day()]]).length > 0) {
        let filteredDate = _.filter(day_time_mapping, ['day', days[moment(date).day()]]);
        filteredDate = _.sortBy(filteredDate,'start_time')
        time = filteredDate.map((time, key) => {
          let now = currentTime ? moment(date).set('hour', currentTime.split(':')[0]).set('minute', currentTime.split(':')[1]) :  moment();
          let proposedTime = moment(date).set('hour', time.start_time.split(':')[0]).set('minute', time.start_time.split(':')[1]);
          const msBetweenDates= Math.abs(proposedTime-now);
          const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
          let isDateTwentyFour = hoursBetweenDates <=24 ? true:false;
          let isPastTime = proposedTime.isSameOrBefore(now);
          let optionClass = (isPastTime ||isDateTwentyFour) ? {'color':'#808080'} : {'color':'#000000'};
          return (<option disabled={(isPastTime ||isDateTwentyFour)} style={optionClass} value={time.start_time} key={key}>{moment(time.start_time, ["HH.mm"]).format("hh:mm a")}</option>)
        })
      }
      return time;
    }

    onChange = (e) => {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        isValid: false
      });
    }
    startEndTimeValidation = (date,start_time,end_time, validation=true) => {
      const schedule_date = moment(date).format('YYYY-MM-DD');
      let startDate =  schedule_date+ ' '+ start_time;
      let endDate =  schedule_date+ ' '+ end_time;
      
      let startTimeUtc = moment.utc(moment(startDate)).format();
      let endTimeUtc = moment.utc(moment(endDate)).format();
      if( validation) {
        const duration = moment.duration(moment(this.state.end_time, ["HH.mm"]).diff(moment(this.state.start_time, ["HH.mm"]))).asMinutes();
        const validTimeRange = (duration%30==0)?1:0;
        const endTimeIsAfterStart =moment(endTimeUtc).isAfter(startTimeUtc);
        const maxLimitExceed =(duration>240)?0:1;
        const minLimitExceed =(duration<30)?0:1; 
        return (validTimeRange&&endTimeIsAfterStart&&maxLimitExceed&&minLimitExceed);
      } else {
        //const duration = moment.duration(moment(end_time, ["HH.mm"]).diff(moment(start_time, ["HH.mm"]))).hours() ;
        const durationSec = moment(endDate).diff(moment(startDate),'seconds');
        const duration =(durationSec/60).toFixed(2);
        return { schedule_date, endDate, startTimeUtc, endTimeUtc, duration };
      }
    }
    onSubmit = () => {
      const { date, start_time, end_time, rescheduled_reason } = this.state;
      this.setState({
        isValid: true,
        loading: true
      }, () => {
        if(validation.text(start_time)
          && validation.text(end_time)
          && validation.text(rescheduled_reason)) {

            if(this.startEndTimeValidation(date,start_time,end_time, true)) {

            const schedule_date = moment(date).format('YYYY-MM-DD');
            let startDate =  schedule_date+ ' '+ start_time;
            let endDate =  schedule_date+ ' '+ end_time;
      
            let startTimeUtc = moment.utc(moment(startDate)).format();
            let endTimeUtc = moment.utc(moment(endDate)).format();
            let input = {
              id: this.props.id,
              schedule_date,
              start_time: start_time,
              end_time: end_time,
              duration:moment.duration(moment(end_time, ["HH.mm"]).diff(moment(start_time, ["HH.mm"]))).asMinutes(),
              is_rescheduled: 1,
              rescheduled_reason: rescheduled_reason,
              approved: 0,
              start_time_utc: startTimeUtc,
              end_time_utc: endTimeUtc
            };
            reSchedule(input).then((res) => {
              this.setState({
                ...this.state,
                loading: false
              });
              if(res.status === 200) {
                  $("#reschedule").modal('hide');
                  if(res.data.type=="success"){
                    toast.success(res.data.message);
                  }else{
                    toast.warning(res.data.message);
                  }
                 
                  this.props.next();
              }else {
                  toast.error("Something went wrong!");
              }
            }).catch((err) => {
                this.setState({
                  ...this.state,
                  loading: false
                });
                toast.error("Something went wrong!");
            })
          }else {
            const {  duration  } = this.startEndTimeValidation(date,start_time,end_time, false);
          let timeErrorMsg="End time should be greater than start time";
          if(duration%30!=0){ 
            timeErrorMsg="All bookings should have durations of 30 minutes  like 0.5 hour, 1 hour, 1.5 hours etc.";
          }
          if(duration>240){ 
            timeErrorMsg=" 4 hours is the maximum we can offer";
          }
          if(duration<30){ 
            timeErrorMsg="30 minutes is the minimum we can offer";
          }
          this.setState({
            ...this.state,
            loading: false
          })
          toast.error(timeErrorMsg);
          }
          }else {
            this.setState({
              ...this.state,
              loading: false
            })
            toast.error("Validation Error!");
          }
      })
    }
    updateTime = (type,timeValue) => {
      const timeString = timeValue!=null?moment(timeValue.$d).format("HH:mm"):null;
      if(type === 'Start Time') {
        this.setState({
            ...this.state,
            start_time:timeString,
             isValid: false
        });
  
      } else  {
        this.setState({
            ...this.state,
            end_time:timeString,
             isValid: false
        });
  
      }
    }
    getAvailabilty =async(tutorId,date)=>{
      await getTutorAvailabilityDate(tutorId,moment(date).format('YYYY-MM-DD')).then((res) => {
          if (res.status === 200) {
            this.setState({
              ...this.state,
              selectedDayMapping:res.data.data  
            }); 
          } else {
            return [];
          //   throw new Error(res.response.data.message);
          }
        }).catch((err) => {
          toast.error(err.message);
        })
    }
    render() {
        const { isValid, loading, date, day_time_mapping, start_time, end_time, rescheduled_reason,tutor,selectedDayMapping } = this.state;
        console.log("date",date)
        const today = new Date()
        let tomorrow =  new Date()
        tomorrow.setDate(today.getDate() + 1)
        return (
            <div className="modal media-modal" id="reschedule">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">

                  <div className="modal-header">
                    <h4 className="modal-title text-grey">Reschedule</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={this.props.close}>&times;</button>
                  </div>

                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="start_time">Pick a date:</label>
                        <Calendar 
                          className='cal-schedule' 
                          calendarType={"US"}
                          onChange={(date) => {this.setState({ ...this.state, date,start_time:null,end_time:null }),this.getAvailabilty(tutor.id,date)}}
                          value={this.state.date}
                          tileDisabled={this.tileDisabled}
                          minDate={tomorrow}
                          // maxDate={new Date("2020-07-29")}
                        />
                      </div>
                      <div className="col-md-6"> 
                        <div className="row my-3">
                          <div className="col-6">
                            <div className="form-group mb-0">
                              <label htmlFor="start_time">Start times:</label>
                              <TimePickerComponent tutor={tutor} date={date} timeType='Start Time' updateTime={this.updateTime} pickerStartTime={start_time} pickerEndTime={end_time} selectedDayMapping={selectedDayMapping}/>

                              {/* <select name="start_time" id="start_time" 
                              className={`custom-select ${(isValid && (validation.text(start_time) ? '' : 'error is-invalid')).toString()}`}
                              value={start_time}
                              onChange={this.onChange.bind(this)}>
                                <option value="">Select Time</option>
                                { this.prepareTimeOptions(day_time_mapping,date) }
                              </select> */}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group mb-0">
                              <label htmlFor="end_time">End times:</label>
                              <TimePickerComponent tutor={tutor} date={date} timeType='End Time' updateTime={this.updateTime} pickerStartTime={start_time} pickerEndTime={end_time} selectedDayMapping={selectedDayMapping} />
                              
                              {/* <select name="end_time" id="end_time"
                              className={`custom-select ${(isValid && (validation.text(end_time) ? '' : 'error is-invalid')).toString()}`}
                              value={end_time}
                              onChange={this.onChange.bind(this)}>
                                <option value="">Select Time</option>
                                { this.prepareTimeOptions(day_time_mapping,date) }
                              </select> */}
                            </div>
                          </div>
                          <div className="col-12 mt-2">
                            <div className="form-group">
                              <label htmlFor="rescheduled_reason">Reason (Required)</label>
                              <textarea className={`form-control ${(isValid && (validation.text(rescheduled_reason) ? '' : 'error is-invalid')).toString()}`} name="rescheduled_reason" id="rescheduled_reason" rows="4" value={rescheduled_reason} onChange={this.onChange.bind(this)}></textarea>
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-primary text-white float-right" onClick={this.onSubmit} disabled={loading}>Reschedule</button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
        );
    }
}

export default Reschedule;