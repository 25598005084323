/* eslint-disable */
import React, {Component} from 'react';
import AuthLayout from "../../components/AuthLayout";
import _ from "lodash";
import ParentStudentProfile from "../../components/Profile/ParentStudentProfile";
import ParentStudentBilling from "../../components/Profile/ParentStudentBilling";
import ParentStudentSecurity from "../../components/Profile/ParentStudentSecurity";
import ParentStudentNotification from "../../components/Profile/ParentStudentNotification";
import ParentStudentDependentSecurity from "../../components/Profile/ParentStudentDependentSecurity";
import guard from "../../actions/guard";

class ParentStudent extends Component {

    constructor(props) {
        super(props);
        const query = new URLSearchParams(location.search);
        const tab = query.get("tab");
        this.state = {
            tab: tab?tab:'profile',
        }
    }

    changeTab = (tab) => {
        this.setState({
            ...this.state,
            tab
        });
    }

    render() {
        const { tab } = this.state;

        return (
            <AuthLayout>
                <div className="auth-container">
                    <div className="profile-container">
                        <header className="profile-header">
                            <button className={`profile-tab ${tab === 'profile' ? 'active': ''}`} onClick={this.changeTab.bind(this, 'profile')}>Profile</button>
                            <button className={`profile-tab ${tab === 'billing' ? 'active': ''}`} onClick={this.changeTab.bind(this, 'billing')}>Billing</button>
                            <button className={`profile-tab ${tab === 'security' ? 'active': ''}`} onClick={this.changeTab.bind(this, 'security')}>Security</button>
                            <button className={`profile-tab ${tab === 'notification' ? 'active': ''}`} onClick={this.changeTab.bind(this, 'notification')}>Notification Settings</button>
                            {guard.role() === 'parent'?
                             <button className={`profile-tab text-capitalize ${tab === 'students-security' ? 'active': ''}`} onClick={this.changeTab.bind(this, 'students-security')} >Student Security</button>
                             : null
                             }
                           
                        </header>
                        <div className="profile-body">
                            {
                                tab === 'profile' && (
                                    <ParentStudentProfile />
                                )
                            }
                            {
                                tab === 'billing' && (
                                    <ParentStudentBilling />
                                )
                            }
                            {
                                tab === 'security' && (
                                    <ParentStudentSecurity />
                                )
                            }
                            {
                                tab === 'notification' && (
                                    <ParentStudentNotification />
                                )
                            }
                            {
                                tab === 'students-security' && (
                                    <ParentStudentDependentSecurity />
                                )
                            }
                        </div>
                        
                    </div>
                </div>
            </AuthLayout>
        );
    }
}

export default ParentStudent;