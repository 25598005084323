import React, {Component} from 'react';
import auth from "../../actions/auth";
import {toast} from "react-toastify";
import {get, put} from "../../services/Profile";
import { updateStudentUserDetails, updateParentUserDetails } from "../../services/User";
import guard from "../../actions/guard";

class ParentStudentNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            //validation
            isValid: false,
            loading: false,
            //image
            profile_picture: "",
            is_message_receive:0,
            is_push_notification_receive:0,
            role: null,
        }
    }

    componentDidMount() {
        guard.check(() => {
            if (guard.role() === "parent") {
                this.setState({
                    ...this.state,
                    role: 'parent'
                })
            } else if(guard.role() === "student") {
                this.setState({
                    ...this.state,
                    role: 'student'
                })
            }
            this.getProfile()
        });
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    first_name: res.data.data.first_name,
                    last_name: res.data.data.last_name,
                    email: res.data.data.email,
                    phone_number: res.data.data.phone_number,
                    student_mapping: res.data.data.student_mapping,
                    profile_picture: res.data.data.details.profile_picture,
                    is_message_receive:res.data.data.details.is_message_receive,
                    is_push_notification_receive:res.data.data.details.is_push_notification_receive
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }
    handleChange(name, event) {
        let isCheckBoxValue = event.currentTarget.checked;
        if(name === 'message') {
            this.setState({
                is_message_receive: isCheckBoxValue
            })
        } else {
             this.setState({
                is_push_notification_receive: isCheckBoxValue
            })
        }
    }
    saveChanges = () => {
        const { is_message_receive, is_push_notification_receive, role } = this.state;

        if( role === 'student') {
            updateStudentUserDetails(auth.check().id,{ is_message_receive, is_push_notification_receive }).then((res) => {
                if(res.status === 200) {
                    toast.success(res.data.message);
                }else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                toast.error("Something went wrong!");
            })
        } else {
             updateParentUserDetails(auth.check().id,{ is_message_receive, is_push_notification_receive }).then((res) => {
                if(res.status === 200) {
                    toast.success(res.data.message);
                }else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                toast.error("Something went wrong!");
        })
        }
       
    }
    render() {
        // const { is_message_receive, is_push_notification_receive } = this.state;
        return (
            <div className="profile-notification">
                <h5 className='bold serif mb-5 text-grey'>Notification Settings</h5>
                <form className='flexible-width' onSubmit={(e) => {e.preventDefault();}}>
                    <div className="d-flex justify-content-between align-items-baseline mb-3">
                        <p htmlFor="switch-1" className='toggle-label'>Would you like to receive notifications to your email?</p>
                        <div className="toggle-switch">
                            <input aria-label="status" checked={false} type="checkbox" id="switch-1" name="noti_1" onChange={this.handleChange.bind(this, "message")}/>
                            <label htmlFor="switch-1">Status</label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p htmlFor="switch-2" className='toggle-label'>Would you like to receive notifications to your phone?</p>
                        <div className="toggle-switch1">
                            <input aria-label="status" checked={false} type="checkbox" id="switch-2" name="noti_2" onChange={this.handleChange.bind(this, "push")}/>
                            <label htmlFor="switch-2">Status</label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end padded-intro-section">
                        <button type="submit" onClick={this.saveChanges} className="btn btn-primary btn-padding--md text-white">Update</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default ParentStudentNotification;