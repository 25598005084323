import React from 'react';
import Layout from "../components/Layout";
import { Link } from 'react-router-dom';
import { Button } from "react-bootstrap";


const Confirmation = (props) => {
const {roleId} =  props.match.params;  
const userRoleId = parseInt(roleId)
    return (
        <Layout>
            <div className="confirmation-page intro-content">
                <h3 className='text-center text-grey my-5'>Account Verified</h3>
                {userRoleId === 2 ?
                 <p className="text-center text-grey w-75 mx-auto">You have successfully verified your account. Please click Continue to continue the tutor application process.</p>:
                 <p className="text-center text-grey w-75 mx-auto">You have successfully verified your account</p>}
               
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <Link to="/">
                        <Button
                            variant='primary'
                            className='btn-padding--lg text-white'
                        >
                            Continue
                        </Button>
                    </Link>
                </div>
            </div>
        </Layout>
    )
}

export default Confirmation;