/* eslint-disable */
import React, { Component } from "react";
import AuthLayout from "../../components/AuthLayout";
import validation from "../../actions/validation";
import { FiCheck } from "react-icons/fi";
import {get,getUpcoming,getCanceled,getCompleted} from "../../services/Profile";
import {
  subscriptionDetails,
  subscriptionDetailsParent,
} from "../../services/Payment";
import Calendar from "react-calendar";
import { FaRegCalendarAlt, FaCheckCircle } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import { Link } from "react-router-dom";
import auth from "../../actions/auth";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";
import RejectModal from "../Modal/Reject";
import RescheduleModal from "../Modal/Reschedule";
import NoticeBoard from "../../components/Notification/NoticeBoard";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import TutorCredits from "./TutorCredits";
import guard from "../../actions/guard";
import { formatStartEndTime } from "../../helpers/utils";
import LoadingSpinner from "../LoaderComponent/LoadingSpinner";
import ViewPricing from '../Modal/ViewPricing';
import env from "../../../src/config/env.json";
import ReviewRecapModal  from "../Modal/ReviewRecapModal";
import Schedule from '../Tutor/BookTutor/Schedule';
import InfiniteScroll from "react-infinite-scroll-component";
class ParentStudent extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pending: [],
      upcoming: [],
      completed: [],
      canceled: [],
      upcomingLoading: true,
      selectedId: null,
      selectedTutorId: null,
      view: "completed",
      isProfileComplete: false,
      students: [],
      billingDetails: null,
      billingDetailsLoading: true,
      role: null,
      showPricingModal :false,
      isOpen:[],
      payload: {},
      completedCurrentPage: 1,
      completedSessionhasMore: true,
      upcomingCurrentPage: 1,
      upcomingSessionhasMore: true,
      canceledCurrentPage: 1,
      canceledSessionhasMore: true
    };
  }

  componentDidMount() {
    this.getProfile();
    this.getUpcomingSessions(true);
		this.getCanceledSessions(true);
		this.getCompletedSessions(true);
    guard.check(() => {
      if (guard.role() === "parent") {
        this.getDetailsParent();
        this.setState({
          ...this.state,
          role: "parent",
        });
      } else if (guard.role() === "student") {
        this.getDetails();
        this.setState({
          ...this.state,
          role: "student",
        });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  checkProfileCompletion(data) {
    if (data.role_id === "4") {
      if (
        data.first_name &&
        data.last_name &&
        data.phone_number &&
        data.race &&
        data.student_details.address &&
        // && data.student_details.current_gpa
        data.student_details.current_grade &&
        data.student_details.current_school &&
        data.student_details.disablities &&
        data.student_details.dob &&
        // && data.student_details.major
        // && data.student_details.special_needs
        data.student_details.state &&
        data.student_details.zip_code
      ) {
        return true;
      } else {
        return false;
      }
    } else if (data.role_id === "3") {
      if (
        data.first_name &&
        data.last_name &&
        data.phone_number &&
        data.race &&
        data.parent_details.address &&
        data.parent_details.dob &&
        data.parent_details.state &&
        data.parent_details.zip_code
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  openReviewRecapModal= (id) => {
       this.setState({
      isOpen: {
         [id]: true
      }
   });
  
  
 }
 bookTutorAgain = (tutorId,studentId) => {
  if(guard.role() == "parent") {
    this.props.history.push('/tutor/schedule/'+tutorId+'/'+studentId);
  }else {
  this.props.history.push('/tutor/schedule/'+tutorId);

  }
 }

  getDetails = () => {
    subscriptionDetails()
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            ...this.state,
            billingDetails: res.data.data,
            billingDetailsLoading: false,
          });
        } else {
          this.setState({
            ...this.state,
            // billingDetailsLoading: false
          });
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          // billingDetailsLoading: false
        });
        toast.error("Something went wrong!");
      });
  };

  getDetailsParent = () => {
    this._isMounted = true;
    subscriptionDetailsParent()
      .then((res) => {
        if (res.status === 200 && this._isMounted) {
          this.setState({
            ...this.state,
            billingDetails: res.data.data,
            billingDetailsLoading: false,
          });
        } else if (this._isMounted) {
          this.setState({
            ...this.state,
            // billingDetailsLoading: false
          });
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          // billingDetailsLoading: false
        });
        toast.error("Something went wrong!");
      });
  };

  getProfile = () => {
    get(auth.check().id).then((res) => {
      console.log(res);
      const currentTime =  moment().format(); 
      if (res.status === 200) {
        const data = res.data.data;
        const isProfileComplete = this.checkProfileCompletion(data);
        this.setState({
          ...this.state,
          students: res.data.data.student_mapping,
          // pending: _.filter(res.data.data.session_scheduled, {
          //   progression_status: 0,
          //   approved: 0,
          //   rejected: 0,
          // }),
          // canceled: res.data.data.cancled_session_scheduled,
          // upcoming: _.filter(res.data.data.session_scheduled, function(value) {
          //   return (value.is_completed==0 && value.approved==1 && (moment.utc(value.end_time_utc).local().format()> currentTime));
          // }),
          // completed: _.filter(res.data.data.session_scheduled, {
          //   is_completed: 1,
          //   approved: 1,
          // }),
          upcomingLoading: false,
          isProfileComplete: isProfileComplete,
        });
        if (!isProfileComplete) {
          this.completeProfile();
        }
      } else {
        toast.error("Something went wrong!");
      }
      // }).catch((err) => {

      //toast.error("Something went wrong!");
    });
  };
  getUpcomingSessions  =(initial = false) => {
		if(initial){
      getUpcoming(auth.check().id,this.state.upcomingCurrentPage).then((res) => {
        
        this.setState({
          ...this.state, 
          upcoming: res.data.data.session_scheduled
        });
      });
    }else{
      getUpcoming(auth.check().id,this.state.upcomingCurrentPage+1).then((res) => {
        if (res.data.data.session_scheduled <=0 ) {
          this.setState({ ...this.state,upcomingSessionhasMore: false });
          return;
        }
        this.setState({
          ...this.state,
          upcomingCurrentPage:this.state.upcomingCurrentPage+1,
          upcoming: this.state.upcoming.concat(res.data.data.session_scheduled) 
        });
      });
    }
	}
	getCanceledSessions  =(initial = false) => {
		if(initial){
      getCanceled(auth.check().id,this.state.canceledCurrentPage).then((res) => {
        
        this.setState({
          ...this.state, 
          canceled: res.data.data.session_scheduled
        });
      });
    }else{
      getCanceled(auth.check().id,this.state.canceledCurrentPage+1).then((res) => {
        if (res.data.data.session_scheduled <=0 ) {
          this.setState({ ...this.state,canceledSessionhasMore: false });
          return;
        }
        this.setState({
          ...this.state,
          canceledCurrentPage:this.state.canceledCurrentPage+1,
          canceled: this.state.canceled.concat(res.data.data.session_scheduled) 
        });
      });
    }
		
	}
	getCompletedSessions  =(initial = false) => {
    if(initial){
      getCompleted(auth.check().id,this.state.completedCurrentPage).then((res) => {
        
        this.setState({
          ...this.state, 
          completed: res.data.data.session_scheduled
        });
      });
    }else{
      getCompleted(auth.check().id,this.state.completedCurrentPage+1).then((res) => {
        if (res.data.data.session_scheduled <=0 ) {
          this.setState({ ...this.state,completedSessionhasMore: false });
          return;
        }
        this.setState({
          ...this.state,
          completedCurrentPage:this.state.completedCurrentPage+1,
          completed: this.state.completed.concat(res.data.data.session_scheduled) 
        });
      });
    }
		
	}

  modalOpen = (id) => {
    this.setState(
      {
        ...this.state,
        selectedId: id,
      },
      () => {
        $("#reject-request").modal();
      }
    );
  };

  modalClose = () => {
    this.setState(
      {
        ...this.state,
        selectedId: null,
      },
      () => {
        $("#reject-request").modal("hide");
      }
    );
  };

  rescheduleClose = () => {
    this.setState(
      {
        ...this.state,
        selectedId: null,
        selectedTutorId: null,
      },
      () => {
        $("#reschedule").modal("hide");
      }
    );
  };

  rescheduleOpen = (id, tutorId) => {
    this.setState(
      {
        ...this.state,
        selectedId: id,
        selectedTutorId: tutorId,
      },
      () => {
        $("#reschedule").modal();
      }
    );
  };

  completeProfile = () => {
    const { history } = this.props;
    history.push("/add-profile");
  };

  openPricingModal = () => {
    this.setState({
      showPricingModal: true
    })
  }
  closePricingModal = () => {
      this.setState({
        showPricingModal: false
      })
  }

  checkUserCredit = () => {
    const {billingDetails} = this.state;
    let userCredit = 0; 
    if(this.state.role === "parent"){
      userCredit = _.sumBy(billingDetails, function(element) { return element.credit; });   
    }else{
      userCredit = billingDetails?.credit? billingDetails?.credit : 0; 
    }
    if(userCredit){
      const { history } = this.props;
      history.push("/tutor/schedule");
    }else{
      this.openPricingModal();
    }
  };
  
  joinClass = (id, schedule) => {
    const classrom = JSON.parse(schedule);
    console.log(classrom)
    const userLink=classrom[0].userLink; 
    window.location = env.MERITHUB_CLASSROOM + '/'+userLink;
}
  render() {
    const {
      isProfileComplete,
      upcoming,
      upcomingLoading,
      canceled,
      selectedId,
      selectedTutorId,
      pending,
      completed,
      view,
      billingDetails,
    } = this.state; 
    console.log('upcoming',upcoming);
		console.log('pending',pending);
		console.log('completed',completed);
    let individualStudent=[];
    if(this.state.role === "student"){
      individualStudent.push(this.state.billingDetails)
    }else{
      individualStudent = this.state.billingDetails
    }
    return (
      <>
        {upcomingLoading ? (
          <LoadingSpinner />
        ) : (
          <AuthLayout>
            <div className="auth-container">
              {!isProfileComplete && (
                <div className="row">
                  <div className="col-md-12">
                    <Alert variant="primary" className="profile-info-alert">
                      <p>For book a tutor please complete your profile.</p>
                      <Button variant="primary" onClick={this.completeProfile}>
                        Complete Profile
                      </Button>
                    </Alert>
                  </div>
                </div>
              )}
              <div className="row">
                <section className="col-lg-8 col-md-7 mb-4">
                  <div className="book-tutor-cta">
                    <div>
                      <h5>{moment().format("LL")}</h5>
                      <p>
                        Click date on calendar to see a schedule or book a tutor
                        here.
                      </p>
                    </div> 
                    {(!isProfileComplete || billingDetails==null)?
                      <button disabled className="btn btn-primary-darker btn-padding text-white"> Book A Tutor </button> :
                      <button onClick={this.checkUserCredit}className="btn btn-primary-darker btn-padding text-white">Book A Tutor</button>
                    }

                  </div>
                  {this.state.billingDetails ?
                    (
                      <TutorCredits 
                      students={individualStudent}
                      role={this.state.role}
                      />
                    )
                    :null}
                  <div className="row">
                    
                    <div className="col-lg-6 col-md-12">
                      <div
                        className="card upcoming-card"
                        style={{
                          maxHeight: 600,
                          minHeight: 600,
                        }}
                      >
                        <div className="card-header py-3 px-4">
                          <div className="d-flex justify-start font-bold text-grey">
                            <div
                              className={`tab ${
                                view === "completed" ? "text-grey" : ""
                              }`}
                              onClick={() =>
                                this.setState({
                                  ...this.state,
                                  view: "completed",
                                })
                              }
                            >
                              Completed Sessions
                            </div>
                            {/* <div
                              className={`tab ${
                                view === "canceled" ? "active" : ""
                              }`}
                              onClick={() =>
                                this.setState({
                                  ...this.state,
                                  view: "canceled",
                                })
                              }
                            >
                              Canceled
                            </div> */}
                          </div>
                        </div>
                        {view === "completed" && (
                          <div className="card-body overflow-x-auto">
                            <InfiniteScroll
          dataLength={completed.length}
          next={this.getCompletedSessions}
          hasMore={this.state.completedSessionhasMore}
          loader={<h4>Loading...</h4>}
          // height={400}
          endMessage={
            <p className="text-center mt-4 d-block">
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
                            {!upcomingLoading &&
                              completed.length > 0 &&
                              completed.map((list, i) => (
                                <div key={i}>
                                  <div className="d-flex align-items-center my-3 justify-content-between">
                                    <div className="mr-2">
                                      {/* <p>Math Tutorial</p> */}
                                      <p className="txt-14--light text-capitalize font-mulishfont-mulish mr-5">
                                        Tutor: &nbsp;
                                        <b>
                                          {list.tutordetails.first_name}{" "}
                                          {list.tutordetails.last_name}
                                        </b>
                                      </p>
                                    </div>
                                    {list.tutordetails.details
                                      .profile_picture ? (
                                      <img
                                        src={
                                          list.tutordetails.details
                                            .profile_picture
                                        }
                                        alt="tutor-avatar"
                                        style={{
                                          width: 50,
                                          height: 50,
                                          borderRadius: "50%",
                                        }}
                                      />
                                    ) : (
                                      <div className="icn-circle primary-dark font-weight-bold mr-2 text-capitalize">
                                        {
                                          list.tutordetails.first_name.split(
                                            ""
                                          )[0]
                                        }
                                      </div>
                                    )}
                                  </div>
                                  <div className="position-relative border-bottom pb-3">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span className="dot-text --primary txt-14--light">
                                      {list.online ? "Online" : "In-person"}
                                    </span>
                                    <p className="txt-14--light text-capitalize mb-2">
                                        Student: &nbsp;
                                        <b>
                                          {list.studentdetails.first_name}{" "}
                                          {list.studentdetails.last_name}
                                        </b>
                                      </p>
                                    </div>
                                    <div className="my-2 d-flex justify-content-between align-items-center ">
                                      <p className="txt-14--light d-flex align-items-center mr-3">
                                        <FaRegCalendarAlt />
                                        &nbsp;
                                        {moment(list.schedule_date).format(
                                          "LL"
                                        )}
                                      </p>
                                      <p className="txt-14--light d-flex align-items-center mb-2">
                                        <MdAccessTime />
                                        &nbsp;
                                        {formatStartEndTime(
                                          list.start_time_utc,
                                          list.end_time_utc
                                        )}
                                      </p>
                                    </div>
                                    <div className="d-block d-md-flex align-items-start my-2 justify-content-between">
                                     
                                      <div className="d-flex align-items-baseline">
                                      <button
                                       className="btn btn-primary text-white mr-3"
                                       onClick=
                                       {this.bookTutorAgain.bind(this, list.tutor_id,list.studentdetails.id)}
                                      >
                                        Book Again 
                                      </button>

                                    {(!list.schedule_session?.review_rating || 
                                     (list.schedule_session?.review_rating && list.schedule_session?.review_rating?.review === null)) &&
                                      <button
                                       className="btn btn-outline-primary"
                                       onClick=
                                       {this.openReviewRecapModal.bind(this, list.id)}
                                      >
                                        Leave a Review
                                      </button>
                                    }
                                    </div>
                                       {  this.state.isOpen[list.id] && 
                                          (
                                          <ReviewRecapModal  
                                          tutorId={list.tutor_id} 
                                          sessionId={list.schedule_session.id}
                                          schedule={list}
                                          reloadPage={true}                                     
                                          />
                                          )
                                        }

                                    </div>
                                  </div>
                                </div>
                              ))}
                            {!upcomingLoading && completed.length === 0 && (
                              <div className="d-flex justify-content-center align-items-center">
                                <p className=" font-weight-bold text-grey">
                                You do not have any completed sessions! Let's Change that! Book a tutor Now!
                                </p>
                              </div>
                            )}
                            {upcomingLoading && (
                              <div className="d-flex justify-content-center align-items-center">
                                <p className=" font-weight-bold">Loading...</p>
                              </div>
                            )}
                            </InfiniteScroll>
                          </div>
                        )}
                        
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div
                        className="card upcoming-card "
                        style={{
                          maxHeight: 600,
                          
                          minHeight: 600,
                        }}
                      >
                        <div className="card-header py-3 px-4">
                          <h6 className="mb-0 text-grey font-mulish f-16">
                            Canceled
                          </h6>
                        </div>
                        
                        <div className="card-body overflow-x-auto">
                        <InfiniteScroll
          dataLength={canceled.length}
          next={this.getCanceledSessions}
          hasMore={this.state.canceledSessionhasMore}
          loader={<h4>Loading...</h4>}
          height={400}
          endMessage={
            <p className="text-center mt-4 d-block">
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
                            {!upcomingLoading &&
                              canceled.length > 0 &&
                              canceled.map((list, i) => (
                                <div key={i}>
                                  <div className="d-flex align-items-center my-3 justify-content-between">
                                    <div className="mr-2">
                                      {/* <p>Math Tutorial</p> */}
                                      <p className="txt-14--light text-capitalize font-mulish">
                                        Tutor: &nbsp;
                                        <b>
                                          {list.tutordetails.first_name}{" "}
                                          {list.tutordetails.last_name}
                                        </b>
                                      </p>
                                    </div>
                                    {list.tutordetails.details
                                      .profile_picture ? (
                                      <img
                                        src={
                                          list.tutordetails.details
                                            .profile_picture
                                        }
                                        alt="tutor-avatar"
                                        style={{
                                          width: 50,
                                          height: 50,
                                          borderRadius: "50%",
                                        }}
                                      />
                                    ) : (
                                      <div className="icn-circle primary-dark font-weight-bold mr-2 text-capitalize">
                                        {
                                          list.tutordetails.first_name.split(
                                            ""
                                          )[0]
                                        }
                                      </div>
                                    )}
                                  </div>
                                  <div className="position-relative border-bottom pb-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                    <span className="dot-text --primary txt-14--light">
                                      {list.online ? "Online" : "In-person"}
                                    </span>
                                    <p className="txt-14--light text-capitalize mb-2">
                                      Student: &nbsp;
                                      <b>
                                        {list.studentdetails.first_name}{" "}
                                        {list.studentdetails.last_name}
                                      </b>
                                    </p>
                                    </div>
                                    <div className="my-2 d-flex justify-content-between align-items-center">
                                      <p className="txt-14--light d-flex align-items-center mr-3">
                                        <FaRegCalendarAlt />
                                        &nbsp;
                                        {moment(list.schedule_date).format(
                                          "LL"
                                        )}
                                      </p>
                                      <p className="txt-14--light d-flex align-items-center">
                                        <MdAccessTime />
                                        &nbsp;
                                        {formatStartEndTime(
                                          list.start_time_utc,
                                          list.end_time_utc
                                        )}
                                      </p>
                                    </div>
                                   
                                    <p className="txt-14--light text-capitalize">
                                      Comment: &nbsp;
                                      <span className="txt-12--light text-capitalize">
                                        <b>{list.rejected_comment}</b>
                                        </span>
                                    </p>
                                  </div>
                                </div>
                              ))}
                            {!upcomingLoading && canceled.length === 0 && (
                              <div className="d-flex justify-content-center align-items-center">
                                <p className=" font-weight-bold text-grey">
                                You do not have any cancelled sessions!Let's Change that!Book a tutor Now!
                                </p>
                              </div>
                            )}
                            {upcomingLoading && (
                              <div className="d-flex justify-content-center align-items-center">
                                <p className=" font-weight-bold">Loading...</p>
                              </div>
                            )}
                             </InfiniteScroll>
                          </div>
                       
                      </div>
                    </div>
                  </div>
                  {/*empty state*/}
                  {/* <div className="card upcoming-card">
                                <div className="card-header p-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="position-relative">
                                            <span className="dot-text --primary">Upcoming Sessions</span>
                                        </div>
                                        <div className="position-relative">
                                            <div className="dot-text --danger">You have a free trial</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="block-container text-center mx-auto my-5">
                                        <h5 className="mb-4">Welcome, John</h5>
                                        <p className="mb-4">It looks lonely in here. Get started by booking a tutor.
                                            The first one is on the house.  😉</p>
                                        <div className="d-flex justify-content-around profile-cta">
                                            <button
                                                className="btn btn-primary-darker text-white"
                                            >
                                                Book tutor with free trial
                                            </button>
                                            <button
                                                className="btn btn-primary text-white"
                                            >
                                                Subscribe to a plan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                </section>

                <section className="col-lg-4 col-sm-5">
                  <section className="right-section">
                    {/* <Calendar className="cal-sidebar" /> */}
                    
                   
                    <div className="p-0 col-md-12 mb-4">
                      <div
                        className="card upcoming-card "
                        style={{
                          maxHeight: 518,
                        
                          minHeight: 518,
                        }}
                      >
                        <div className="card-header py-3 px-4">
                          <h6 className="mb-0 text-grey font-mulish f-16">
                            Upcoming Sessions
                          </h6>
                        </div>
                        <div className="card-body overflow-x-auto">
                        <InfiniteScroll
                          dataLength={upcoming.length}
                          next={this.getUpcomingSessions}
                          hasMore={this.state.upcomingSessionhasMore}
                          loader={<h4>Loading...</h4>}
                          height={400}
                          endMessage={
                            <p className="text-center mt-4 d-block">
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                          {!upcomingLoading &&
                            upcoming.length > 0 &&
                            upcoming.map((list, i) => (
                              <div key={i}>
                                <div className="d-flex align-items-center my-3 justify-content-between">
                                  <div className="mr-2">
                                    {/* <p>Math Tutorial</p> */}
                                    <p className="txt-14--light text-capitalize font-mulish">
                                      Tutor: &nbsp;
                                      <b>
                                        {list.tutordetails.first_name}{" "}
                                        {list.tutordetails.last_name}
                                      </b>
                                    </p>
                                  </div>
                                  {list.tutordetails.details.profile_picture ? (
                                    <img
                                      src={
                                        list.tutordetails.details
                                          .profile_picture
                                      }
                                      alt="tutor-avatar"
                                      style={{
                                        width: 50,
                                        height: 50,
                                        borderRadius: "50%",
                                      }}
                                    />
                                  ) : (
                                    <div className="icn-circle primary-dark font-weight-bold mr-2 text-capitalize">
                                      {
                                        list.tutordetails.first_name.split(
                                          ""
                                        )[0]
                                      }
                                    </div>
                                  )}
                                </div>
                                <div className="position-relative border-bottom">
                                  <div className="d-flex justify-content-between align-items-center">
                                  <span className="dot-text --primary txt-14--light">
                                    {list.online ? "Online" : "In-person"}
                                  </span>
                                  <p className="txt-14--light text-capitalize">
                                    Student: &nbsp;
                                    <b>
                                      {list.studentdetails.first_name}{" "}
                                      {list.studentdetails.last_name}
                                    </b>
                                  </p>
                                  </div>
                                  <div className="my-2 d-flex justify-content-between align-items-baseline">
                                    <p className="txt-14--light d-flex align-items-center mr-3">
                                      <FaRegCalendarAlt />
                                      &nbsp;
                                      {moment(list.schedule_date).format("LL")}
                                    </p>
                                    <p className="txt-14--light d-flex align-items-center">
                                      <MdAccessTime />
                                      &nbsp;
                                      {formatStartEndTime(
                                        list.start_time_utc,
                                        list.end_time_utc
                                      )}
                                    </p>
                                  </div>


                                  <div className="txt-14--light d-block align-items-center my-1">
                                    <button
                                      className="btn btn-outline-danger homepage-buttons mr-3 \"
                                      onClick={this.modalOpen.bind(
                                        this,
                                        list.id
                                      )}
                                    >
                                      Cancel
                                    </button>
                                    {list.progression_status === 1 &&
                                    list.is_completed === 0 ? (
                                      <>
                                        {guard.role() !== "parent" ? (
                                          list.progression_status === 1 &&
                                          list.online ? (
                                            <button
                                              className="btn btn-primary text-white"
                                              onClick={this.joinClass.bind(this, list.id, list.merithub_participant_response)}
                                            >
                                              Join
                                            </button>
                                          ) : null
                                        ) : null}
                                      </>
                                    ) : (
                                      <button
                                        className="btn btn-outline-primary homepage-buttons mr-3 my-3"
                                        onClick={this.rescheduleOpen.bind(
                                          this,
                                          list.id,
                                          list.tutordetails.id
                                        )}
                                      >
                                        Reschedule
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          {!upcomingLoading && upcoming.length === 0 && (
                            <div className="d-flex justify-content-center align-items-center">
                              <p className=" font-weight-bold text-grey text-center">
                                You do not have any upcoming sessions ! Let's Change that ! Book a tutor Now!
                              </p>
                            </div>
                          )}
                          {upcomingLoading && (
                            <div className="d-flex justify-content-center align-items-center">
                              <p className=" font-weight-bold">Loading...</p>
                            </div>
                          )}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                    {console.log(this.state.role)}
                    <NoticeBoard
                      notification={pending}
                      history={this.props.history}
                      role={this.state.role}
                    />
                  </section>
                </section>
              </div>
            </div>
            <ViewPricing open={this.state.showPricingModal} handleClose={this.closePricingModal} students={this.state.billingDetails} role={this.state.role}/>
            <RejectModal
              id={selectedId}
              close={this.modalClose}
              next={this.getProfile}
            />

            <RescheduleModal
              id={selectedId}
              tutorId={selectedTutorId}
              dayTimeMapping={undefined}
              close={this.rescheduleClose}
              next={this.getProfile}
            />
          </AuthLayout>
        )}
      </>
    );
  }
}

export default withRouter(ParentStudent);