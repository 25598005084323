/* eslint-disable */
import React, {Component} from 'react';
import TutorLayout from "../../components/TutorLayout";
import _ from "lodash";
import { FaPlayCircle, FaStar, FaRegStar } from "react-icons/fa";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import VideoModal from "./VideoModal";
import moment from "moment";
import {toast} from "react-toastify";
import {get} from "../../services/Profile";
import {podcast, video, addFav, removeFav, addHistory, getHistory} from "../../services/Media";
import ReactPaginate from 'react-paginate';
import $ from "jquery";
import auth from "../../actions/auth";
import LoadingSpinner from '../LoaderComponent/LoadingSpinner';

const LIMIT = 5;

class Tutor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            video: [],
            videoLoading: true,
            v_total: 0,
            podcast: [],
            podcastLoading: true,
            p_total: 0,
            tab: "podcast",
            videoMeta: null,
            linkedPodcast: [],
            linkedVideo: [],
            historyPodcast: [],
            historyVideo: []
        }
    }

    componentDidMount() {
        this.podcastList(1);
        this.getFavMedia();
        this.getHistory();
    } 
    
    getHistory = () => {
        getHistory().then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    historyPodcast: res.data.data.history.visited_podcast,
                    historyVideo: res.data.data.history.visited_vimeo
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    addHistory = (data) => {
        let input = {
            media_id: this.state.tab === "podcast" ? data.id : data.uri,
            media_type: this.state.tab === "podcast" ? "Podcast" : "Vimeo"
        }
        addHistory(input).then((res) => {
            if (res.status === 200) {
                toast.success("Added to history.");
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    getFavMedia = () => {
        get(auth.check().id).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    linkedPodcast: res.data.data.liked_podcast,
                    linkedVideo: res.data.data.liked_vimeo
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    favourite = (action, data) => {
        if(action) {
            let input = {
                media_id: this.state.tab === "podcast" ? data.id : data.uri,
                media_type: this.state.tab === "podcast" ? "Podcast" : "Vimeo"
            }
            addFav(input).then((res) => {
                if (res.status === 200) {
                    this.getFavMedia();
                    toast.success("Added to favourite.");
                }
            }).catch((err) => {
                console.log(err);
            })
        }else {
            removeFav(data).then((res) => {
                if (res.status === 200) {
                    this.getFavMedia();
                    toast.success("Removed from favourite.");
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    podcastList = (page) => {
        this.setState({
            ...this.state,
            podcastLoading: true
        }, () => {
            podcast(LIMIT, page).then((res) => {
                if (res.status === 200) {
                    this.setState({
                        ...this.state,
                        podcastLoading: false,
                        podcast: res.data.data.data,
                        p_total: res.data.data.total
                    });
                }
            }).catch((err) => {
                console.log(err);
            })
        });
    };

    videoList = (page) => {
        this.setState({
            ...this.state,
            videoLoading: true
        }, () => {
            video(LIMIT, page).then((res) => {
                if (res.status === 200) {
                    this.setState({
                        ...this.state,
                        videoLoading: false,
                        video: res.data.data.body.data,
                        v_total: res.data.data.body.total
                    });
                }
            }).catch((err) => {
                console.log(err);
            })
        });
    };

    changeTab = (tab) => {
        this.setState({
            ...this.state,
            tab
        }, () => {
            if(this.state.tab === 'podcast') {
                this.podcastList(1);
            }else {
                this.videoList(1);
            }
        });
    }

    modalOpen = (meta) => {
        this.setState({
            ...this.state,
            videoMeta: meta
        }, () => {
            $("#vemio-video").modal();
        });
    }

    modalClose = () => {
        this.setState({
            ...this.state,
            videoMeta: null
        }, () => {
            $("#vemio-video").modal('hide');
        });
    }

    render() {
        const { tab, video, podcast, podcastLoading, videoLoading, videoMeta, p_total, v_total, linkedPodcast, linkedVideo, historyPodcast, historyVideo } = this.state;
        
        return (
            <>
            {videoLoading&&podcastLoading?<LoadingSpinner/>:
            <TutorLayout>
                <div className="tutor-auth-container">
                    <div class="main-body-outer">
                        <div class="main-body-sec m-body-left">

                            <div class="back-page"><a href="#">Go To Library</a></div>
                            
                            <section id="tabs" class="dash-tab">   
                                <nav>
                                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a class={`nav-item nav-link ${tab === "podcast" ? 'active' : ''}`} id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true" onClick={this.changeTab.bind(this, 'podcast')}>Podcasts</a>
                                        <a class={`nav-item nav-link ${tab === "k12" ? 'active' : ''}`} id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={this.changeTab.bind(this, 'k12')}>K-12 Videos</a>
                                    </div>
                                </nav>
                                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                    {
                                        tab === "podcast" && podcastLoading?<LoadingSpinner/>:
                                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <h2>All Podcasts</h2>
                                            <div class="podcasts-wrap">
                                                
                                                {
                                                    !podcastLoading && podcast.length > 0 && podcast.map((cast, key) => (
                                                        <div class="podcasts-box" key={key}>
                                                            <div class="podcast-date">{moment(cast.created_at).format("LL")}</div>
                                                            <div class="podcasts-box-info">
                                                                <div class="podcasts-img"><img src={cast.thumbnail} alt="" /></div>
                                                                <div class="podcasts-info">
                                                                    <div class="podcasts-info-top">
                                                                        <h3><a href="#">{cast.title}</a></h3>
                                                                        {/* <div class="podcast-time">12:20</div> */}
                                                                    </div>
                                                                    <p>{cast.description}</p>
                                                                </div>
                                                                <div class="podcasts-action-wrap">
                                                                    <div class="podcasts-media">
                                                                        {
                                                                            (linkedPodcast.length > 0 && _.findIndex(linkedPodcast, (obj) => (obj.media.id == cast.id)) > -1) ?
                                                                            <a onClick={this.favourite.bind(this, false, _.find(linkedPodcast, (obj) => (obj.media.id == cast.id)).id)}><FaStar size={20} color="yellow" /></a>
                                                                            :
                                                                            <a onClick={this.favourite.bind(this, true, cast)}><FaRegStar size={20} color="grey" /></a>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            <audio controls preload="none" autobuffer onPlay={this.addHistory.bind(this, cast)}> 
                                                                <source src={cast.path} type="audio/mpeg"></source>
                                                                Your browser does not support the audio element.
                                                            </audio>
                                                        </div>
                                                    ))
                                                }

                                                <div className="d-flex justify-content-center align-items center">
                                                    <ReactPaginate 
                                                        containerClassName="react-paginate"
                                                        pageClassName="page"
                                                        activeClassName="active"
                                                        previousLabel={<MdNavigateBefore size={24}/>}
                                                        nextLabel={<MdNavigateNext size={24}/>}
                                                        previousClassName={"link"}
                                                        nextClassName={"link"}
                                                        pageCount={Math.ceil(p_total / LIMIT)}
                                                        pageRangeDisplayed={LIMIT}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={(e) => this.podcastList(e.selected + 1)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        tab === "k12" && videoLoading?<LoadingSpinner/>:
                                        <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <h2>K-12 Videos</h2>
                                            <div class="podcasts-wrap">

                                                {
                                                    !videoLoading && video.length > 0 && video.map((video, key) => (
                                                        <div class="podcasts-box" key={key}>
                                                            <div class="podcast-date">{moment(video.created_time).format("LL")}</div>
                                                            <div class="podcasts-box-info">
                                                                <div class="podcasts-img"><img src={video.pictures.sizes[0].link} alt="" /></div>
                                                                <div class="podcasts-info">
                                                                    <div class="podcasts-info-top">
                                                                        <h3><a href="#">{video.name}</a></h3>
                                                                        {/* <div class="podcast-time">12:20</div> */}
                                                                    </div>
                                                                    <p>{video.description}</p>
                                                                </div>
                                                                <div class="podcasts-action-wrap">
                                                                    <div class="podcasts-media">
                                                                        <a href="#" onClick={this.modalOpen.bind(this, video)}><FaPlayCircle size={24} color="grey"/></a>
                                                                    </div>
                                                                    <div class="podcasts-action">
                                                                        {
                                                                            (linkedVideo.length > 0 && _.findIndex(linkedVideo, (obj) => (obj.media.uri == video.uri)) > -1) ?
                                                                            <a onClick={this.favourite.bind(this, false, _.find(linkedVideo, (obj) => (obj.media.uri == video.uri)).id)}><FaStar size={20} color="yellow" /></a>
                                                                            :
                                                                            <a onClick={this.favourite.bind(this, true, video)}><FaRegStar size={20} color="grey" /></a>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                                <div className="d-flex justify-content-center align-items center">
                                                    <ReactPaginate 
                                                        containerClassName="react-paginate"
                                                        pageClassName="page"
                                                        activeClassName="active"
                                                        previousLabel={<MdNavigateBefore size={24}/>}
                                                        nextLabel={<MdNavigateNext size={24}/>}
                                                        previousClassName={"link"}
                                                        nextClassName={"link"}
                                                        pageCount={Math.ceil(v_total / LIMIT)}
                                                        pageRangeDisplayed={LIMIT}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={(e) => this.videoList(e.selected + 1)}
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    }
                                </div>
                            </section>

                        </div>
                        <div class="main-body-sec m-body-right">
                            {
                                tab === "podcast" &&
                                <>
                                    <h2>Listening History</h2>
                                    <div class="podcasts-wrap">
                                        {
                                            historyPodcast.length > 0 && historyPodcast.map((cast, key) => (
                                                <div class="podcasts-box" key={key}>
                                                    <div class="podcast-date">{moment(cast.created_at).format("LL")}</div>
                                                    <div class="podcasts-box-info">
                                                        <div class="podcasts-info">
                                                            <div class="podcasts-info-top">
                                                                <h3><a href="#">{cast.media.title}</a></h3>
                                                            </div>
                                                            <p>{cast.media.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }
                            {
                                tab === "k12" &&
                                <>
                                    <h2>Viewing History</h2>
                                    <div class="podcasts-wrap">
                                        {
                                            historyVideo.length > 0 && historyVideo.map((video, key) => (
                                                <div class="podcasts-box" key={key}>
                                                    <div class="podcast-date">{moment(video.created_time).format("LL")}</div>
                                                    <div class="podcasts-box-info">
                                                        <div class="podcasts-info">
                                                            <div class="podcasts-info-top">
                                                                <h3><a href="#">{video.media.name}</a></h3>
                                                            </div>
                                                            <p>{video.media.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }
                        </div>

                    </div>
                </div>
                <VideoModal video={videoMeta} close={this.modalClose} addHistory={this.addHistory.bind(this)}/>
            </TutorLayout>
    }
            </>
        );
    }
}

export default Tutor;