/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { FiUser, FiCheck, FiLock, FiPhoneForwarded } from "react-icons/fi";
import { racicalCategories } from "../../config/variables";
import moment from "moment";
import DatePicker from "react-datepicker"; 
import { subDays } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
//import momentTimezone from 'moment-timezone';
import {usTimeZones } from '../../config/variables.js'
class TutorS1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            password: "",
            password_confirmation: "",
            role: "Tutor",
            dob: "",
			//validation
			isValid: false,
			loading: false,
            dobPicked:"",
            timezone:""
		};
    }

	onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };
	
    setDob = (date)=>{
        this.setState({
            ...this.state,
            dobPicked: date,
            dob:moment(date).format("YYYY-MM-DD"),
            isValid: false
        })
    }

	onSubmit = () => {
		const { role, first_name, last_name, password_confirmation, password, phone_number, email, dob,timezone } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => { 
                if(validation.ageLimit(dob)
                    && validation.text(first_name)
                    && validation.text(last_name)
                    && validation.email(email)
                    && validation.passwordStrength(password, "medium")
                    && validation.passwordStrength(password_confirmation, "medium")
                    && validation.text(role)
                    && validation.text(dob)
                    && validation.mobile(phone_number)
                    && validation.text(timezone)
                    && password === password_confirmation
                ) {
                    const data = JSON.parse(JSON.stringify(this.state));
                    this.props.getData(_.omit(data, ['isValid', 'loading']));
                    this.setState({
                        ...this.state,
                        loading:false
                    })
                }else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    toast.error("Validation Error!")
                } 
		});
	}

	render() {
		const { first_name, last_name, password_confirmation, password, phone_number, email, dob, isValid, loading, dobPicked,timezone} = this.state;
        //const getAlltimeZones = momentTimezone.tz.zonesForCountry("US")
        const getAlltimeZones = usTimeZones;
        let pswError = '';
        if(!validation.text(password)){
            pswError = "Please enter a valid password."
        }else if(!validation.passwordStrength(password, "medium")){
            pswError = "You have entered a weak password."
        }else{
            pswError = "Password miss-matching."
        }
        let pswConfirmError = '';
        if(!validation.text(password_confirmation)){
            pswConfirmError = "Please confirm your password."
        }else if(password != password_confirmation){
            pswConfirmError = "Password miss-matching."
        }else if(!validation.passwordStrength(password_confirmation, "medium")){
            pswConfirmError = "You have entered a weak password."
        }
		return(
		<React.Fragment>
                <div id='signup-form'>
                    <h3 className='text-grey mb-4'>Tutor Application</h3>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='first_name'>First Name</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='first_name'>
                                        <FiUser />
                                    </span>
                                </div>
                                <input
                                    type='text'
                                    name='first_name'
                                    value={first_name}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && (validation.text(first_name) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                    Please enter your first name.
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='last_name'>Last Name</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='last_name'>
                                        <FiUser />
                                    </span>
                                </div>
                                <input
                                    type='text'
                                    name='last_name'
                                    value={last_name}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && (validation.text(last_name) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                    Please enter your last name.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='email'>Email Address</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='email'>
                                        <img src='/images/mdi-email-edit.svg' alt='email' />
                                    </span>
                                </div>
                                <input type='email'
                                        name='email'
                                        value={email}
                                        onChange={this.onChange}
                                        className={`form-control light ${(isValid && (validation.email(email) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                    Please enter a valid email.
                                </div>
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='phone_number'>Phone Number</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='phone_number'>
                                        <FiPhoneForwarded />
                                    </span>
                                </div>
                                <input type='text'
                                        name='phone_number'
                                        value={phone_number}
                                        onChange={this.onChange}
                                        className={`form-control light ${(isValid && (validation.mobile(phone_number) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                    Please enter valid, 10 digits long mobile no.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='password'>Password</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='password'>
                                        <FiLock />
                                    </span>
                                </div>
                                <input
                                    type='password'
                                    name='password'
                                    value={password}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && ((validation.passwordStrength(password, "medium") && password === password_confirmation) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                { pswError}
                                </div>
                            </div>
                            <div className='d-flex align-items-center text-grey my-1'>
                                <FiCheck className='mr-1' />
                                <div className='txt-14'>One numeric character</div>
                            </div>
                            <div className='d-flex align-items-center text-grey my-1'>
                                <FiCheck className='mr-1' />
                                <div className='txt-14'>Minimum of 8 characters</div>
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='password_confirmation'>Confirm Password</label>
                            <div className='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text' id='password_confirmation'>
                                        <FiLock />
                                    </span>
                                </div>
                                <input
                                    type='password'
                                    name='password_confirmation'
                                    value={password_confirmation}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && ((validation.passwordStrength(password_confirmation, "medium") && password === password_confirmation) ? '' : 'error is-invalid')).toString()}`}
                                />
                                <div className="invalid-feedback">
                                { pswConfirmError}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6  signup-calender'>
                        <label htmlFor='dob' className={`${(isValid && ((validation.text(dob) && validation.ageLimit(dob)) ? '' : 'error is-invalid')).toString()}`}>Date of Birth</label> 
                            <DatePicker placeholderText="mm-dd-yyyy" dateFormat="MM-dd-yyyy"  name='dob' id="dob" selected={dobPicked} maxDate={subDays(new Date(), 1)} onChange={this.setDob} dropdownMode="select"  showMonthDropdown showYearDropdown className={`form-control light ${(isValid && ((validation.text(dob) && validation.ageLimit(dob)) ? '' : 'error is-invalid')).toString()}`}/>
                            <div className="invalid-feedback">
                                Select your dob ( You should be atleast 13 years old ).
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                             <label htmlFor='timezone'>Time Zone</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(timezone) ? '' : 'error is-invalid')).toString()}`}
                                id="timezone"
                                name="timezone"
                                value={timezone} 
                                onChange={this.onChange}>
                                <option value="">Choose One</option>
                                   
                               
                                {Object.keys(getAlltimeZones).map((timeZoneName, key) => (
                                    <option key={key} value={getAlltimeZones[timeZoneName]} >{timeZoneName}</option>
                                ))}


                            
                             </select>
                        <div className="invalid-feedback">
                            Select time zone.
                        </div>
                    </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center my-5'>
                        <button
                            disabled={loading}
                            className='btn btn-primary btn-padding--lg text-white'
                            onClick={this.onSubmit}
                        >
                            Continue
                        </button>
                    </div>
                </div>
		</React.Fragment>
		);
	}
}

export default TutorS1;