import React, {useEffect, useState} from 'react';
import StudentProfileForm from '../components/ProfileInfoForm/StudentProfileForm';
import ParentProfileForm from '../components/ProfileInfoForm/ParentProfileForm';
import AuthLayout from "../components/AuthLayout";
import {get} from '../services/Profile';
import {toast} from "react-toastify";
import auth from "../actions/auth";
import LoadingSpinner from '../components/LoaderComponent/LoadingSpinner';

const ParentStudentProfileForm = (props) => {
    const [role, setRole] = useState(null);
    const [details, setDetails] = useState(null);
    const[loading,setLoading]=useState(true);

    useEffect(() => {
        get(auth.check().id).then((res) => {
            console.log(res)
            
            if(res.status === 200) {
                const data = res.data.data;
                setLoading(false)
                setRole(data.role_id);
                setDetails({
                    ...res.data.data
                })
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            setLoading(false)
            toast.error("Something went wrong!");
        })
    }, [])
    return (
        <>
            {loading? <LoadingSpinner/>:
                 <AuthLayout>
                 {role && role === "4" && (<StudentProfileForm studentData={details} {...props} />)}
                 {role && role === "3" && (<ParentProfileForm parentData={details} {...props} />)}
             </AuthLayout>
            }
            
        </>
            
        );
}

export default ParentStudentProfileForm;