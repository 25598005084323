/* eslint-disable no-lone-blocks */
import React, { useEffect } from 'react';
import Rating from '@material-ui/lab/Rating';
import LinearProgress from '@material-ui/core/LinearProgress';

const AverageRating = ({tutorAvgRating, usersRatingCount}) => {
    const ratingTitle =(tutorAvgRating)=>{
        const postfix = 'Rating'
            switch (tutorAvgRating) {
                case 1:      
                return 'Very Poor '+ postfix
                case 2:     
                return 'Poor ' + postfix
                case 3: 
                return 'Average ' + postfix
                case 4: 
                return 'Good ' + postfix
                case 5: 
                return 'Very Good ' + postfix
                default:
                    return console.log('no rating value');
                }
            }
    const ratingClass =(rating)=>{
    const postfix = '-star'
        switch (rating) {
            case 1:      
            return 'one'+ postfix
            case 2:     
            return 'two'+ postfix
            case 3: 
            return 'three' + postfix
            case 4: 
            return 'four' + postfix
            case 5: 
            return 'five' + postfix
            default:
                return console.log('no rating value');
            }
        }
    const ratingName =(rating)=>{
        switch (rating) {
            case 1:      
            return "one"
            case 2:     
            return "two"
            case 3: 
            return "three"
            case 4: 
            return "four"
            case 5: 
            return "five"
            default:
                return console.log('no rating value number');
            }
        }
         const sumOfUserCount = Object.values(usersRatingCount).reduce((r, { users }) => r + users, 0);
         const totalCount= 5
    return (
        <div className="average-rating-container">
            <h4 className="title">{ ratingTitle(tutorAvgRating)}</h4>
            <h2 className="average-rating">{tutorAvgRating}</h2>
            <Rating name="read-only" value={tutorAvgRating} readOnly  className='rating-content'/>
            <div className="number-rating">
                 {[...Array(totalCount)].map((elementInArray, index) => {
                        const rateArr =  usersRatingCount.filter((item) => item.rating === index + 1)
                        let ratingValue = index+1;
                        let userCount = 0;
                        let ratingPerCount = 0;
                        if(rateArr.length > 0) {
                                userCount = rateArr[0].users;
                                ratingPerCount =parseInt((userCount/sumOfUserCount)*100)
                            }
                                return(
                                    <div className={ratingClass(ratingValue)} key={index}>
                                        <h5>{ratingValue}</h5>
                                        <Rating defaultValue={ratingValue} max={ratingValue} name={ratingName(ratingValue)} readOnly />
                                        <LinearProgress variant="determinate" value={ratingPerCount} />
                                        <h5>{userCount}</h5>
                                    </div>
                                )
                        }  
                )}

            </div>
        </div>
    )
}

export default AverageRating;