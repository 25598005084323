/* eslint-disable */
import { useState, useEffect } from "react";

const useMediaStream = (client, filter) => {
    const [localStream, setLocalStream] = useState(undefined);
    const [remoteStream, setremoteStream] = useState(undefined);

    useEffect(() => {
        let mounted = true;
        // add when subscribed
        const addRemote = (evt) => {
            if (!mounted) {
                return;
            }
            const { stream } = evt;
            if (stream) {
                setremoteStream(stream);
            }
        };
        // remove stream
        const removeRemote = (evt) => {
            const { stream } = evt;
            if (stream) {
                setremoteStream(undefined);
            }
        };
        // subscribe when added
        const doSub = (evt) => {
            if (!mounted) {
                return;
            }
            if (filter) {
                if (filter(evt.stream.getId())) {
                    client.subscribe(evt.stream);
                }
            } else {
                client.subscribe(evt.stream);
            }
        };
        // add when published
        const addLocal = (evt) => {
            if (!mounted) {
                return;
            }
            const { stream } = evt;
            const stop = stream.stop;
            const close = stream.close;
            stream.close = (func => () => {
                func()
                setLocalStream(undefined);
            })(close);
            stream.stop = (func => () => {
                func()
                setLocalStream(undefined);
            })(stop);
            setLocalStream(stream)
        };

        const report = (evt) => {
            alert("peer " + evt.type)
        };

        if (client) {
            client.on("stream-published", addLocal);
            client.on("stream-added", doSub);
            client.on("stream-subscribed", addRemote);
            client.on("peer-leave", removeRemote);
            client.on("stream-removed", removeRemote);
            client.on("mute-audio", report);
            client.on("unmute-audio", report);
            client.on("mute-video", report);
            client.on("unmute-video", report);
        }

        return () => {
            mounted = false;
            if (client) {
                console.log("Client: ", client)
                console.log("Client GatewayClient: ", client.gatewayClient)
                // Maintains the list of users based on the various network events.
                client.gatewayClient.removeEventListener("stream-published", addLocal);
                client.gatewayClient.removeEventListener("stream-added", doSub);
                client.gatewayClient.removeEventListener("stream-subscribed", addRemote);
                client.gatewayClient.removeEventListener("peer-leave", removeRemote);
                client.gatewayClient.removeEventListener("stream-removed", removeRemote);
                client.gatewayClient.removeEventListener("mute-audio", report);
                client.gatewayClient.removeEventListener("unmute-audio", report);
                client.gatewayClient.removeEventListener("mute-video", report);
                client.gatewayClient.removeEventListener("unmute-video", report);
            }
        };
    }, [client, filter]);

    return [localStream, remoteStream, [localStream].concat(remoteStream)];
};

export default useMediaStream;