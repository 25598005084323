import React from 'react';
import './TutorInfo.css';
import Rating from '@material-ui/lab/Rating';
// import moment from 'moment';

const TutorInfo = ({value, tutorDetail, bookTutor,tutorReviews}) => {
    const tutorTeachingstyle = tutorDetail.details?.teaching_style;
    const subList=tutorDetail.subject_mapping;
    // const currentYear = moment().format('YYYY');
    const tutorAvgRating = tutorReviews?.avgRating ? tutorReviews.avgRating : 0;
    const usersRatingCount = tutorReviews?.usersRatingCount ? tutorReviews.usersRatingCount : [];
    const totalReviews = Object.values(usersRatingCount).reduce((r, { users }) => r + users, 0);
    // const tutorSubjects =  subList.map((item)=> {
    //     return (item.subject.sub_subject_name && item.subject.sub_subject_name === "Other")?item.other_subject:item.subject.sub_subject_name;
    // }).reduce((a,b)=>a+', '+b);
    return <>
        <div className="tutor-detail">
                    <div className="profile-info">
                        <div className="profile-image-wrapper">
                            <img src={tutorDetail?.details?.profile_picture} alt="tutor-1" className='mr-md-3'/>
                        </div>
                        <div className="info">
                            <h3 className="tutor-name">{`${tutorDetail?.first_name} ${tutorDetail?.last_name.split("")[0]}`}</h3>
                            <div className="rating">
                                <Rating name="read-only" value={tutorAvgRating} readOnly />
                                <p>({totalReviews} reviews mm)</p>
                            </div >
                            {/* <div className="subjects">
                                <p style={{ color: '#7A7A7A' }}>
                                <img src="/images/book.svg" alt="" style={{marginRight:"5px"}} />
                                { tutorSubjects}
                                </p>
                            </div> */}
                            <div className="subjects text-justify">
                                <p style={{ color: '#7A7A7A' }}>
                                <img src="/images/book.svg" alt="" style={{marginRight:"5px"}} />
                                    { subList.map((item,index,arr)=>(item.subject.sub_subject_name && item.subject.sub_subject_name === "Other"?item.other_subject+(index !==(arr.length-1)? ', ': ''):item.subject.sub_subject_name+(index !==(arr.length-1)? ', ': '')
                                        ))}
                                </p>
                            </div>
                            <div className="subjects">
                                <p style={{ color: '#7A7A7A' }}>
                                <img src="/images/pen.svg" alt="" style={{marginRight:"5px"}} />
                                { tutorTeachingstyle}
                                </p>
                            </div>
                           <div className="location">
                                <img src="/images/Vector.svg" alt="" />
                                <p>{`${tutorDetail?.details?.state}, ${tutorDetail?.details?.city}, ${tutorDetail?.details?.zip_code}`}</p>
                            </div>
                            <div className="bio-detail">
                                <h4 className="title">Bio</h4>
                                <p>{tutorDetail?.details?.bio}</p>
                            </div>
                            <button type="button" onClick={bookTutor} className="btn">{`Book ${tutorDetail?.first_name}`}</button>
                        </div>
                        <div className="about-tutor">
                            <h4 className="title">
                            About this tutor
                            </h4>
                            <div>
                                <div className="row">
                                    <div className="col">
                                        <h5>Tutoring Experience :</h5>
                                        <p>{tutorDetail?.details?.years_of_tutoring_exp} Year</p>
                                    </div>
                                    <div className="col-md">
                                        <h5>Highest Level Of Qualification:</h5>
                                        <p>{tutorDetail?.details?.highest_level_education}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md mb-sm-1rem">
                                        <h5>Teaching Style:</h5>
                                        <p>{tutorTeachingstyle}</p>
                                    </div>
                                    <div className="col-md">
                                        <h5>Session Prefence:</h5>
                                        {tutorDetail?.details?.preference === 'Both'?<p>(In-Person,Online)</p>:
                                        <p>{tutorDetail?.details?.preference}</p>}

                                    </div>
                                </div>
                            
                                
                                 {/* <div className="row">
                                    <div className="col">
                                        <h5>Address:</h5>
                                        <p>{tutorDetail?.details?.zip_code}, {tutorDetail?.details?.city}, {tutorDetail?.details?.state}</p>

                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
    </>
}

export default TutorInfo;