import React, {useState} from 'react'
// import PropTypes from 'prop-types'
import { Button} from 'react-bootstrap';
import { FaTwitter, FaFacebookF, FaYoutube, FaInstagram} from 'react-icons/fa';
import {MdEmail} from 'react-icons/md';
// import WhiteCurveSVG from '../WhiteCurveSVG';
import './Footer.style.css';
import { Link, withRouter } from 'react-router-dom';
import SubscriptionModal from './SubscribtionModal';
import env from "../../config/env.json";

function Footer(props) {

  const [modalShow, setModalShow] = useState(false);
  return (
    <footer id="footer">
      {/* <WhiteCurveSVG bg={props.match.path === '/' ? '#FFF': '#F5F6F8'} /> */}
      <section className="curve-footer">
        <div className="container padded-intro-section">
          <div className="top-section">
            <div className="left-section">
              <div className="footer-logo">
                  <img src="/images/logo.svg" alt="brand-logo" width="184px" />
                </div>
                <h3 className="h3-newsletter">Get our newsletter</h3>
                <p className="p-newsletter">Subscribe to our newsletters to get access to news, promos and offers. </p>
                {/* <InputGroup className="mb-4">
                  <FormControl
                    placeholder="Your email address"
                    aria-label="Email-subscribe"
                    aria-describedby="email-subscribe-cta"
                    name="adssas"
                    id="2"
                    onChange={(e) => {e.persist(); console.log(e.target.value)}}
                  />
                  <InputGroup.Append>
                    <Button variant="primary" className="btn-padding" style={{color: '#FFFFFF'}}>Subscribe</Button>
                  </InputGroup.Append>
                </InputGroup> */}
                <Button variant="primary" className="btn-padding subscribe-btn" 
                onClick={() => setModalShow(true)}
                style={{color: '#FFFFFF'}}><MdEmail />Subscribe</Button>
            </div>
            <div className="right-section">
              <div className="footer-grid">
              <a href={env.LANDING_URI + '/how-it-works'} target="_blank">How it works</a>
                  {/* <Link href="/how-it-works">
                    <button className="dropdown-toggle" type="button" id="dropdownMenu2"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Library
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <button className="dropdown-item" type="button">
                          <Link href="/">Test</Link></button>
                        <button className="dropdown-item" type="button">
                          <Link href="/">Test</Link></button>
                    </div>
                  </Link> */}
                  <a href="https://www.google.com/url?q=https://sistemswag.myshopify.com/&sa=D&source=docs&ust=1659095308547593&usg=AOvVaw2i2CL7neWJljmas7Syq7br" target="_blank">Shop</a>
                  <a href={env.LANDING_URI + '/contact'} target="_blank">Contact Us</a>
                  <a href={env.LANDING_URI + '/services'} target="_blank">Services</a>
                  <a href={env.LANDING_URI + '/faq-student'} target="_blank">FAQs for Students</a>
                  <a href={env.LANDING_URI + '/terms'} target="_blank">Terms and Conditions</a>
                  <a href={env.LANDING_URI + '/about'} target="_blank">About</a>
                  <a href={env.LANDING_URI + '/faq-tutor'} target="_blank">FAQs for Tutors</a>
                  <a href={env.LANDING_URI + '/privacy-policy'} target="_blank">Privacy Policy</a>
                  <a href="https://sistem-tutoring-agency.breezy.hr/" target="_blank">Our Tutors</a>
                </div>
              <div className="icon-container">
              <img src="/images/tutors-2021 1.png" alt="" style={{width:'100px'}} />
                <img src="/images/footer_icon_1.png" alt="" />
                <img src="/images/footer_icon_2.png" alt="" />
                <img src="/images/footer_icon_3.png" alt="" />
              </div>
            </div>
          </div>

            <hr className="line"/>
            <div className="d-md-flex justify-content-md-between align-items-center">
              <div className="text-center text-md-left mb-3 mb-md-0 copy-right">&copy; Sistem Tutoring Agency. All Rights Reserved.</div>
              <div className="social-cta">


              <a href="https://twitter.com/SiSTEMtutoring">
  
                <div role="button" className="rounded-circle icon-wrapper mr-3">
                  <FaTwitter  fontSize="14px" className="m-2" />
                </div>

                </a>

                <a href="https://www.facebook.com/sistemtutoring">

                <div role="button" className="rounded-circle icon-wrapper mr-3">
                  <FaFacebookF  fontSize="14px" className="m-2" />
                </div>

                </a>
                <a href="https://www.youtube.com/channel/UCFik_MegxQSSqwaStJXarwQ">



                <div role="button" className="rounded-circle icon-wrapper mr-3">
                  <FaYoutube fontSize="14px"  className="m-2"/>
                </div>

                </a>
                <a href="https://www.instagram.com/sistemtutoring/">

                <div role="button" className="rounded-circle icon-wrapper">
                  <FaInstagram fontSize="14px"  className="m-2"/>
                </div>

                </a>

              </div>
            </div>
        </div>
      </section>
      <SubscriptionModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </footer>
  )
}

// Footer.propTypes = {

// }

export default withRouter(Footer);

