import axios from "axios";
import cookie from "../actions/cookie.js";
import { API_END_POINT } from "../config/env.json";

export const getRatingRecapStatus= () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/get-review-status`, options)
        .then(res => res)
        .catch(error => error);
};

export const addReview= (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/add-review-rating`,  payload, options)
        .then(res => res)
        .catch(error => error);
};

export const addRecap= (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/add-recap`, payload, options)
        .then(res => res)
        .catch(error => error);
};
export const getTutorReviews= (uid) => {
    const options = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios.get(`${API_END_POINT}/v1/get-tutor-reviews/${uid}`, options)
        .then(res => res)
        .catch(error => error);
};
