import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

export default function Sidebar({ path, mode }) {
  return (
    <React.Fragment>
      {/* Desktop Sidebar */}
      <aside className={`icon-sidebar ${mode === 'list' ? 'list-style' : 'bx-small'}`}>
        <Link to="/dashboard" className='sidebar-nav-item'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <path d="M7.70828 0H1.45828C0.654144 0 0 0.654144 0 1.45828V5.20828C0 6.01257 0.654144 6.66672 1.45828 6.66672H7.70828C8.51257 6.66672 9.16672 6.01257 9.16672 5.20828V1.45828C9.16672 0.654144 8.51257 0 7.70828 0Z" fill="#B8B8B8" />
              <path d="M7.70828 8.3335H1.45828C0.654144 8.3335 0 8.98764 0 9.79193V18.5419C0 19.3461 0.654144 20.0002 1.45828 20.0002H7.70828C8.51257 20.0002 9.16672 19.3461 9.16672 18.5419V9.79193C9.16672 8.98764 8.51257 8.3335 7.70828 8.3335Z" fill="#B8B8B8" />
              <path d="M18.5419 13.3335H12.2919C11.4876 13.3335 10.8335 13.9876 10.8335 14.7919V18.5419C10.8335 19.3461 11.4876 20.0002 12.2919 20.0002H18.5419C19.3461 20.0002 20.0002 19.3461 20.0002 18.5419V14.7919C20.0002 13.9876 19.3461 13.3335 18.5419 13.3335Z" fill="#B8B8B8" />
              <path d="M18.5419 0H12.2919C11.4876 0 10.8335 0.654144 10.8335 1.45828V10.2083C10.8335 11.0126 11.4876 11.6667 12.2919 11.6667H18.5419C19.3461 11.6667 20.0002 11.0126 20.0002 10.2083V1.45828C20.0002 0.654144 19.3461 0 18.5419 0V0Z" fill="#B8B8B8" />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className="nav-text">Dashboard</span>
        </Link>
        {/* <Link to="/dashboard" className='sidebar-nav-item'>
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip1)">
            <path d="M13.7587 23.5657C13.759 23.5657 13.7614 23.565 13.7617 23.565V5.28596C13.4585 5.13097 10.1762 2.51401 5.3722 2.2599L4.57031 2.21826V20.5025L5.29557 20.5374C10.522 20.7869 13.1376 23.2491 13.7587 23.5657Z" fill="#B8B8B8"/>
            <path d="M15.2852 4.9622V23.2504C16.4284 23.049 18.1258 22.8287 20.1612 22.8439C23.3597 22.8677 25.3824 23.4495 26 23.5492V5.27832C25.7493 5.25034 23.4696 4.5815 20.1612 4.54179C18.7178 4.52442 17.0648 4.63461 15.2852 4.9622ZM17.5703 18.1346V9.19477L24.2755 13.6646L17.5703 18.1346Z" fill="#B8B8B8"/>
            <path d="M19.0938 12.041V15.2887L21.5292 13.6649L19.0938 12.041Z" fill="#B8B8B8"/>
            <path d="M9.65418 22.9803C8.51932 22.529 7.03239 22.1455 5.2227 22.0594L3.04688 21.9545V4.83008C1.29528 5.09043 0.358922 5.37181 0 5.45261V23.7815L0.946207 23.5442C3.77223 22.8385 6.76528 22.6544 9.65418 22.9803Z" fill="#B8B8B8"/>
            </g>
            <defs>
            <clipPath id="clip1">
            <rect width="26" height="26" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            <span className="nav-text">Whiteboard</span>
          </Link> */}
        {/* <Link to="/whiteboard" className='sidebar-nav-item my-3'>
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5129_8202)">
              <path d="M23.2967 16.8749H22.5467V1.40624H23.2967C23.6851 1.40624 23.9999 1.09143 23.9999 0.703121C23.9999 0.314811 23.6851 0 23.2967 0H0.703121C0.314811 0 0 0.314811 0 0.703121C0 1.09143 0.314811 1.40624 0.703121 1.40624H1.45312V16.8749H0.703121C0.314811 16.8749 0 17.1897 0 17.578C0 17.9663 0.314811 18.2811 0.703121 18.2811H6.88426C6.87287 18.326 5.69336 23.1241 5.69219 23.1288C5.58334 23.5715 5.91869 24 6.37553 24C6.6916 24 6.9789 23.7853 7.05774 23.4646L7.64077 21.0936H16.359L16.9421 23.4646C17.021 23.7854 17.3082 24 17.6243 24C18.0817 24 18.4164 23.5711 18.3077 23.1289C18.0403 22.0416 17.3851 19.377 17.1156 18.2812H23.2967C23.685 18.2812 23.9998 17.9664 23.9998 17.5781C23.9998 17.1898 23.6851 16.8749 23.2967 16.8749ZM2.85936 1.40624H21.1405V16.8749H2.85936V1.40624ZM16.0133 19.6874H7.98656L8.33236 18.2811H15.6675L16.0133 19.6874Z" fill="#7A7A7A" />
            </g>
            <defs>
              <clipPath id="clip0_5129_8202">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className="nav-text">Practice Virtual Classroom</span>
        </Link> */}
        {/* <Link to="#" className='sidebar-nav-item'>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
          viewBox="0 0 60 60" fill="#AFAFAF" width="27" height="27">
        <path d="M30,1.5c-16.542,0-30,12.112-30,27c0,5.205,1.647,10.246,4.768,14.604c-0.591,6.537-2.175,11.39-4.475,13.689
          c-0.304,0.304-0.38,0.769-0.188,1.153C0.276,58.289,0.625,58.5,1,58.5c0.046,0,0.093-0.003,0.14-0.01
          c0.405-0.057,9.813-1.412,16.617-5.338C21.622,54.711,25.738,55.5,30,55.5c16.542,0,30-12.112,30-27S46.542,1.5,30,1.5z"/>

        </svg>
          <span className="nav-text">Chat</span>
        </Link> */}
        <Link to="/notification" className='sidebar-nav-item'>
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.0362 3.85693H0.964844L13.5006 14.1825L26.178 3.88586C26.1316 3.8723 26.0842 3.86264 26.0362 3.85693Z" fill="#AFAFAF" />
            <path d="M14.1084 16.1768C13.7532 16.4678 13.242 16.4678 12.8867 16.1768L0 5.56006V22.1785C0 22.7111 0.431725 23.1429 0.964304 23.1429H26.0357C26.5683 23.1429 27 22.7111 27 22.1785V5.70278L14.1084 16.1768Z" fill="#AFAFAF" />
          </svg>
          <span className="nav-text">Requests</span>
        </Link>

        <Link to="/profile" className='sidebar-nav-item'>
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7798 22.957C22.7312 19.846 21.762 17.0172 20.0388 14.9691C18.3256 12.9335 16.0039 11.8125 13.5005 11.8125C10.997 11.8125 8.67526 12.9335 6.96208 14.9691C5.24051 17.0151 4.27194 19.8399 4.22131 22.947C5.19479 23.4347 9.22317 25.3125 13.5005 25.3125C18.1257 25.3125 21.877 23.4457 22.7798 22.957Z" fill="#B8B8B8" />
            <path d="M13.5 10.9688C16.0629 10.9688 18.1406 8.89107 18.1406 6.32812C18.1406 3.76518 16.0629 1.6875 13.5 1.6875C10.9371 1.6875 8.85938 3.76518 8.85938 6.32812C8.85938 8.89107 10.9371 10.9688 13.5 10.9688Z" fill="#B8B8B8" />
          </svg>
          <span className="nav-text">Account</span>
        </Link>
      </aside>
    </React.Fragment>
  )
}


Sidebar.propTypes = {
  path: PropTypes.string
}