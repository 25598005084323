/* eslint-disable */
import React, { Component } from "react";
import PropTypes, { number } from "prop-types";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { majors } from "../../config/variables";
import Select from 'react-select';
import { subjectList } from "../../services/Common";


class StudentS2 extends Component {
	constructor(props) {
		super(props);
        console.log(this.props)
		this.state = {
            current_school :this.props.studentData?.student_details?.current_school? this.props.studentData?.student_details?.current_school  :'',
            current_grade :this.props.studentData?.student_details?.current_grade? this.props.studentData?.student_details?.current_grade  :'',
            current_gpa :this.props.studentData?.student_details?.current_gpa? this.props.studentData?.student_details?.current_gpa  :'',
            major :this.props.studentData?.student_details?.major? this.props.studentData?.student_details?.major  :'',
            struggle_topics:[],
            subjectList: [],
			//validation
			isValid: false,
			loading: false,
            user_struggle_topics:this.props.studentData?.student_details?.struggle_topics? this.props.studentData?.student_details?.struggle_topics  :'',
		};
    }
    componentDidMount() {
        this.fetchSubList();
      }

    componentDidUpdate(prevProps) {
        if(this.props.loading != prevProps.loading) {
            this.setState({
                ...this.state,
                loading: this.props.loading,
            });
        }
    }
   
    preventMinus = (e) => {
        if (e.charCode === 45) {
            e.preventDefault();
        }
        else if(e.charCode === 43){
            e.preventDefault();
        }
    };
    onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };
	
	onSubmit = () => {
		const { major, current_school, current_grade, current_gpa,struggle_topics } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
            if(validation.text(major)
				&& validation.text(current_school)
				&& validation.text(current_grade)
				&& validation.currentGpa(current_gpa)
                && validation.text(struggle_topics)
                ) {
                const data = JSON.parse(JSON.stringify(this.state));
                this.props.getData(_.omit(data, ['isValid', 'loading']));
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                });
                toast.error("Validation Error!")
            }
		});
	}
    fetchSubList = () => {
        subjectList().then((res) => {
            if (res.status === 200) {
              const userSturggletopics = this.state.user_struggle_topics;
              const dataFormated = userSturggletopics.split(",")
              const subjectNames = Object.values(res.data.subjects).map((item)=>item);
              let userTopics = [];
              const subjectList =_.flatten(subjectNames); 
              dataFormated.length && dataFormated.map(item=>{ 
                _.find(subjectList, (subject) => {
                                if (subject.sub_subject_name === item) {
                    userTopics.push({
                                        value:subject.id,
                                        label : item
                                    })
                                }
                            });
                      })
                      this.setState(   {
                        ...this.state,
                        struggle_topics: userTopics
                        });   
              this.setState({
                ...this.state,
                subjectList: Object.values(res.data.subjects).map((sub) => (
                    {
                      label: sub[0].subject_group_name,
                      options: sub.map((inner) => (
                        { value: inner.id, label: inner.sub_subject_name }
                      ))
                    }
                  ))
              })
            }
          }).catch((err) => {
            console.log(err);
          })
      }
      handleMultiChange  = (option) =>  {
        this.setState(
        {
          ...this.state, 
          struggle_topics: option,
          isValid: false
        })
      }
	render() {
		const { major, current_school, current_grade, current_gpa, isValid, loading,subjectList,struggle_topics,user_struggle_topics} = this.state;
        return(
		<React.Fragment>
            <h3 className='text-center text-grey mb-4'>Academic Details</h3>
            <div id='signup-form'>
                <div className='form-row'>
                <div className='form-group col-md-6'>
                        <label htmlFor='current_school'>Current School</label>
                        <input
                            type='text'
                            name='current_school'
                            id="current_school"
                            value={current_school}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(current_school) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Type your school name.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='major'>Major <span className="sub-text">(Please put N/A if this does not apply to you)</span></label>
                        <input
                            type='text'
                            name='major'
                            id="major"
                            value={major}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(major) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Select your major.
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='current_grade'>Current Grade</label>
                        <input
                            type='text'
                            name='current_grade'
                            id="current_grade"
                            value={current_grade}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(current_grade) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Grade is required.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='current_gpa'>Current GPA <span className="sub-text">(Please put N/A if this does not apply to you)</span></label>
                        <input
                            type='text'
                            name='current_gpa'
                            id="current_gpa"
                            value={current_gpa}
                            min="0" 
                            onChange={this.onChange}
                            onKeyPress={this.preventMinus}
                            className={`form-control light ${(isValid && (validation.currentGpa(current_gpa) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            GPA is required.
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="from-group col-md-6">
                        <label htmlFor="struggle_topics" className='struggling-topics'>What subjects are you struggling in?</label>
                        <Select
                        id='struggle_topics' 
                        className={`form-control h-auto border-0 p-0 ${(isValid && (validation.text(struggle_topics) ? '' : 'error is-invalid')).toString()}`} isMulti 
                        value = {struggle_topics} 
                        onChange={this.handleMultiChange} 
                        options={subjectList} />
                        <div className="invalid-feedback">
                            Struggling subjects are required.
                    	</div>
                    </div>
                </div>

				<div className='d-flex justify-content-center align-items-center my-5'>
					<button
						disabled={loading}
						className='btn btn-primary btn-padding--lg text-white'
						onClick={this.onSubmit}
					>
						Continue
					</button>
				</div>
            </div>
        </React.Fragment>
        );
	}
}

export default StudentS2;