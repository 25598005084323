/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import { disabilityList, majors, states, racicalCategories, studentGrade } from "../../config/variables";
import DropZone from "../DropZone";
import moment from "moment";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays,addDays } from 'date-fns';
//import momentTimezone from 'moment-timezone';
import {usTimeZones } from '../../config/variables.js'
import { subjectList } from "../../services/Common";
class ParentS2 extends Component {
	constructor(props) {
		// const hobbyList = [
		// 	{id: "Reading", name: "Reading"},
		// 	{id: "Watching movies", name: "Watching movies"},
		// 	{id: "Swimming", name: "Swimming"},
		// 	{id: "Dancing", name: "Dancing"},
		// 	{id: "Travelling", name: "Travelling"},
		// 	{id: "Playing sports", name: "Playing sports"},
		// 	{id: "Musical instruments", name: "Musical instruments"},
		// 	{id: "Chess", name: "Chess"},
		// 	{id: "Writing", name: "Writing"},
		// 	{id: "Acting", name: "Acting"},
		// 	{id: "Others", name: "Others"},
		// ]
		super(props);
		
		const parentEmail = this.props.parentData?.email ? this.props.parentData?.email :'';
		const  getUsername= parentEmail.split("@")[0];
		const  emailService= parentEmail.split("@")[1];
		const random = Math.floor(100 + Math.random() * 900);
		const studentMail = getUsername+"+1"+random+"@"+emailService;
		
		this.state = {
			students: [{
				first_name: "",
				last_name: "",
				email: studentMail,
				phone_number: "",
				password: "",
				password_confirmation: "",
				role: "Student",
				gender: "",
				race: "",
				state: "",
				address: "",
				zip_code: "",
				dob: "",
				disablities: "",
				current_school: "",
				major: "",
				//image
				profile_picture: "",
				special_needs: "",
				current_grade: "",
				current_gpa: "",
				hobbies: [],
				parent_name: this.props.parentData?.first_name? this.props.parentData?.first_name :'',
				parent_phone_number: this.props.parentData?.phone_number? this.props.parentData?.phone_number :'',
				parent_email: this.props.parentData?.email? this.props.parentData?.email :'',
				timezone:"",
				contact_teacher:"",
				extra_info:"",
				tutoring_interest:"",
				individual_learning:"",
				tutoringPicked: "",
				tutoring_start_date:"",
				teacher_name:"",
				teacher_phone_number:  "",
				teacher_email: "",
				teacher_subject: "",
				struggle_topics:[],
			}],
			//validation
			isValid: false,
			loading: false,
			//hobbyList: hobbyList,
			parentEmail : this.props.parentData?.email ? this.props.parentData?.email :'',
			subjectList: [],
		};
	}

	componentDidMount() {
        this.fetchSubList();
      }
	componentDidUpdate(prevProps) {
        if(this.props.loading != prevProps.loading) {
            this.setState({
                ...this.state,
                loading: this.props.loading,
            });
        }
    }

    onHobbyChange = (i,hobby,e) => {
    	e.persist();
		if (e.target.type === "checkbox") {
			
			const checkValue = e.target.value ? e.target.value.split( ' ')[0] : '';
            if (e.target.checked) {
                this.state.students[i][e.target.name].push(checkValue);
                this.setState({
                    ...this.state,
                    // [e.target.name]: this.state[e.target.name],
                    isValid: false
                })
            } else {
                let index = this.state.students[i][e.target.name].indexOf(checkValue);
                this.state.students[i][e.target.name].splice(index, 1);
                this.setState({
                    ...this.state,
                    // [e.target.name]: this.state[e.target.name],
                    isValid: false
                })
            }
        }
    }
	preventMinus = (e) => {
        if (e.charCode === 45) {
            e.preventDefault();
        }
		else if(e.charCode === 43){
            e.preventDefault();
        }
    };
    

	onChange = (i, e) => {
		e.persist();
		if (e.target.type === "checkbox") {
            if (e.target.checked) {
                this.state.students[i][e.target.name].push(e.target.value);
                this.setState({
                    ...this.state,
                    // [e.target.name]: this.state[e.target.name],
                    isValid: false
                })
            } else {
                let index = this.state.students[i][e.target.name].indexOf(e.target.value);
                this.state.students[i][e.target.name].splice(index, 1);
                this.setState({
                    ...this.state,
                    // [e.target.name]: this.state[e.target.name],
                    isValid: false
                })
            }
        } else {
			this.state.students[i][e.target.name] = e.target.value;
			this.setState({
				...this.state,
				isValid: false
			})
		}
    };
	
	handleMultiChange  = (i, e) =>  {
		console.log(e)
        this.state.students[i]['struggle_topics'] = e;
			this.setState({
				...this.state,
				isValid: false
			})
      }
	setDob = (i,date)=>{ 
		this.state.students[i]['dobPicked'] = date;
		this.state.students[i]['dob'] = moment(date).format("YYYY-MM-DD");
		this.setState({
			...this.state,
			isValid: false
		})
    }
	setTurorinBegins = (i,date) => {
		this.state.students[i]['tutoringPicked'] = date;
		this.state.students[i]['tutoring_start_date'] = moment(date).format("YYYY-MM-DD");
		this.setState({
			...this.state,
			isValid: false
		})
    }
	onSubmit = () => {
		const { students } = this.state;
		let flag = false;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
			for(let i = 0; i < students.length; i++) {
				if(validation.text(students[i].first_name)
					&& validation.text(students[i].last_name)
					&& validation.mobile(students[i].phone_number)
					&& validation.email(students[i].email)
					&& validation.passwordStrength(students[i].password, "medium")
					&& validation.passwordStrength(students[i].password_confirmation, "medium")
					&& validation.text(students[i].role)
					&& students[i].password === students[i].password_confirmation
					&& validation.text(students[i].state)
					&& validation.text(students[i].dob)
					&& validation.text(students[i].address)
					&& validation.text(students[i].gender)
					&& validation.zipCode(students[i].zip_code)
					&& validation.text(students[i].race)
					&& validation.text(students[i].disablities)
					&& validation.text(students[i].current_school)
					// && validation.text(students[i].major)
					// && validation.text(students[i].special_needs)
					&& validation.text(students[i].current_grade)
					&& validation.text(students[i].timezone)
					// && validation.text(students[i].current_gpa)
					&& validation.text(students[i].contact_teacher)
					&& validation.text(students[i].extra_info)
					&& validation.text(students[i].tutoring_interest)
					&& validation.text(students[i].individual_learning)
					&& validation.text(students[i].tutoring_start_date)
					&& validation.text(students[i].struggle_topics)
				) {
					flag = true;
				}else {
					flag = false;
					break;
				}
			}
			if(flag) {
				this.props.getData(students);
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Validation Error!");
			}
		});
	}

	getFile = (data) => {
		if(data.resp) {
			this.state.students[data.prefix].profile_picture = data.resp;
			this.setState({
				...this.state,
				loading: data.loading
			});
		}
	}

	addStudent = () => {
	
		const { students } = this.state;
		const count = students.length+1;
		const parentEmail = this.props.parentData?.email ? this.props.parentData?.email :'';
		const  getUsername= parentEmail.split("@")[0];
		const  emailService= parentEmail.split("@")[1];
		const random = Math.floor(100 + Math.random() * 900);
		const studentMail = getUsername+"+"+count+random+"@"+emailService;

		let data = {
			first_name: "",
			last_name: "",
			email: studentMail,
			phone_number: "",
			password: "",
			password_confirmation: "",
			role: "Student",
			gender: "",
			race: "",
			state: "",
			address: "",
			zip_code: "",
			dob: "",
			disablities: "",
			current_school: "",
			major: "",
			//image
			profile_picture: "",
			special_needs: "",
			current_grade: "",
            current_gpa: "",
			hobbies: [],
			dobPicked:"",
			parent_name: this.props.parentData?.first_name? this.props.parentData?.first_name :'',
			parent_phone_number: this.props.parentData?.phone_number? this.props.parentData?.phone_number :'',
			parent_email: this.props.parentData?.email? this.props.parentData?.email :'',
			timezone:"",
			contact_teacher:"",
            extra_info:"",
            tutoring_interest:"",
            individual_learning:"",
            tutoringPicked: "",
            tutoring_start_date:"",
			teacher_name: "",
			teacher_phone_number:  "",
			teacher_email: "",
            teacher_subject: "",
			struggle_topics: "",
		};
		this.setState({
            ...this.state,
			students: _.concat(this.state.students, data)
        });
	}
	selectDisabilities=(values,index)=>{
		let studentDisabilities=[];
		values.map((item,index)=>{
			if(item){
				studentDisabilities = [...studentDisabilities, {
					label:item.label,
					value:item.value,
					key: index
				}]; 
			}
		})
		this.setState({
		...this.state, 
		...this.state.students[index].disablities=studentDisabilities
		})
	}
	fetchSubList = () => {
        subjectList().then((res) => {
            console.log(res)
          if (res.status === 200) {
            this.setState({
              ...this.state,
              subjectList: Object.values(res.data.subjects).map((sub) => (
                  {
                    label: sub[0].subject_group_name,
                    options: sub.map((inner) => (
                      { value: inner.id, label: inner.sub_subject_name }
                    ))
                  }
                ))
            })
          }
        }).catch((err) => {
          console.log(err);
        })
      }
	render() {
		const { students, isValid, loading,subjectList } = this.state;
		const yesterday = moment().add(-1, 'days');
		const maxdate = moment(yesterday).format('YYYY-MM-DD');
		//const getAlltimeZones = momentTimezone.tz.zonesForCountry("US")
		const getAlltimeZones = usTimeZones;
		return(
		<React.Fragment>
			<h3 className='text-center text-grey mb-2'>Student Information</h3>
			<div id='signup-form'>
				{
					students.map((student, index) => {
						let pswError = '';
						if(!validation.text(student.password)){
							pswError = "Please enter a valid password."
						}else if(!validation.passwordStrength(student.password, "medium")){
							pswError = "You have entered a weak password."
						}else{
							pswError = "Password miss-matching."
						}
						let pswConfirmError = '';
						if(!validation.text(student.password_confirmation)){
							pswConfirmError = "Please confirm your password."
						}else if(student.password != student.password_confirmation){
							pswConfirmError = "Password miss-matching."
						}else if(!validation.passwordStrength(student.password_confirmation, "medium")){
							pswConfirmError = "You have entered a weak password."
						}
						return <section key={index}>
						<div className="mb-3"><span className="font-weight-bold">Student {index + 1}</span></div>
						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label htmlFor='first_name'>First Name</label>
								<div className='input-group'>
									<input
										type='text'
										name='first_name'
										value={student.first_name}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.first_name) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Please enter your first name.
									</div>
								</div>
							</div>
							<div className='form-group col-md-6'>
								<label htmlFor='last_name'>Last Name</label>
								<div className='input-group'>
									<input
										type='text'
										name='last_name'
										value={student.last_name}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.last_name) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Please enter your last name.
									</div>
								</div>
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label htmlFor='email'>Email Address</label>
								<div className='input-group'>
									<input type='email'
											name='email'
											value={student.email}
											disabled
											className={`form-control light ${(isValid && (validation.email(student.email) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Please enter a valid email.
									</div>
								</div>
							</div>
							<div className='form-group col-md-6'>
								<label htmlFor='phone_number'>Phone Number</label>
								<div className='input-group'>
									<input type='text'
											name='phone_number'
											value={student.phone_number}
											onChange={this.onChange.bind(this, index)}
											className={`form-control light ${(isValid && (validation.mobile(student.phone_number) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Please enter valid, 10 digits long mobile no.
									</div>
								</div>
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label htmlFor='password'>Password</label>
								<div className='input-group'>
									<input
										type='password'
										name='password'
										value={student.password}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && ((validation.passwordStrength(student.password, "medium") && student.password === student.password_confirmation) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										{pswError}
									</div>
								</div>
							</div>
							<div className='form-group col-md-6'>
								<label htmlFor='password_confirmation'>Confirm Password</label>
								<div className='input-group'>
									<input
										type='password'
										name='password_confirmation'
										value={student.password_confirmation}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && ((validation.passwordStrength(student.password_confirmation, "medium") && student.password === student.password_confirmation) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
									{pswConfirmError}
									</div>
								</div>
							</div>
						</div>
							<div className='form-row'>
								<div className='form-group col-md-6'>
									<label htmlFor='state'>State of residence</label>
									<select className={`custom-select form-control light ${(isValid && (validation.text(student.state) ? '' : 'error is-invalid')).toString()}`}
											id="state" value={student.state}
											name="state"
											onChange={this.onChange.bind(this, index)}>
										<option value="">Choose One</option>
										{
											states.map((state, key) => (
												<option key={key} value={state}>{state}</option>
											))
										}
									</select>
									<div className="invalid-feedback">
										Select your state.
									</div>
								</div>
								<div className='form-group col-md-6 signup-calender'>
									<label htmlFor='dob' className={` ${(isValid && (validation.text(student.dob) ? '' : 'error is-invalid')).toString()}`}>Date of Birth</label> 
									<DatePicker placeholderText="mm-dd-yyyy" dateFormat="MM-dd-yyyy" name='dob' id="dob" selected={student.dobPicked} maxDate={subDays(new Date(), 1)} onChange={this.setDob.bind(this, index)} dropdownMode="select"  showMonthDropdown showYearDropdown className={`form-control light ${(isValid && (validation.text(student.dob) ? '' : 'error is-invalid')).toString()}`}/>

									<div className="invalid-feedback">
										Select your dob.
									</div>
								</div>
							</div>
							<div className='form-row'>
								<div className='form-group col-md-6'>
									<label htmlFor='address'>Address</label>
									<input
										type='text'
										name='address'
										id="address"
										value={student.address}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.address) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Type your address.
									</div>
								</div>
								<div className='form-group col-md-6'>
									<label htmlFor='gender'>Gender</label>
									<select className={`custom-select form-control light ${(isValid && (validation.text(student.gender) ? '' : 'error is-invalid')).toString()}`}
											id="gender" name="gender" value={student.gender}
											onChange={this.onChange.bind(this, index)}>
										<option value="">Choose One</option>
										<option value="Male">Male</option>
										<option value="Female">Female</option>
										<option value="Others">Others</option>
										<option value="I don't prefer to say">I don't prefer to say</option>
									</select>
									<div className="invalid-feedback">
										Select your gender.
									</div>
								</div>
							</div>
							<div className='form-row'>
								<div className='form-group col-md-6'>
									<label htmlFor='zip_code'>Zip Code</label>
									<input
										type='text'
										name='zip_code'
										id="zip_code"
										value={student.zip_code}
										maxLength="5"
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.zipCode(student.zip_code) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
									please enter zip-code.it should be a maximum of 5 digits
									</div>
								</div>
								<div className='form-group col-md-6'>
									<label htmlFor='race'>Race</label>
									<select className={`custom-select form-control light ${(isValid && (validation.text(student.race) ? '' : 'error is-invalid')).toString()}`}
											id="race" name="race" value={student.race} onChange={this.onChange.bind(this, index)}>
										<option value="">Choose One</option>
										{
											racicalCategories.map((race, key) => (
												<option key={key} value={race}>{race}</option>
											))
										}
									</select>
									<div className="invalid-feedback">
										Select your race.
									</div>
								</div>
							</div>
							<div className='form-row mb-3'>
								<div className='form-group col-md-6'>
									<label htmlFor='disablities'>Disabilities</label>
									<Select id='disablities' 
										className={`${(isValid && (validation.text(student.disablities) ? '' : 'error is-invalid')).toString()}`}isMulti
										value={student.disablities} 
										onChange={(value)=>this.selectDisabilities(value,index)}
										options={
										disabilityList.map((items,index) => {
										return {
										label: items,
										value: items,
										key: index
										}
										})
										}
										/>
									<div className="invalid-feedback">
										If you don't have any, select none.
									</div>
								</div>
								<div className='form-group col-md-6'>
									<label htmlFor='current_school'>Current School</label>
									<input
										type='text'
										name='current_school'
										id="current_school"
										value={student.current_school}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.current_school) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Type your school name.
									</div>
								</div>
							</div>
							<div className='form-row'>
								{/* <div className='form-group col-md-6'>
									<label htmlFor='major'>Major</label>
									<input
										type='text'
										name='major'
										id="major"
										value={student.major}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.major) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Type your major.
									</div>
								</div> */}
								<div className='form-group col-md-6'>
                        		<label htmlFor='timezone'>Time Zone</label>
									<select className={`custom-select form-control light ${(isValid && (validation.text(student.timezone) ? '' : 'error is-invalid')).toString()}`}
									id="timezone"
									name="timezone"
									value={student.timezone} 
									onChange={this.onChange.bind(this,index)}>
									<option value="">Choose One</option>
										{Object.keys(getAlltimeZones).map((timeZoneName, key) => (
                                            <option key={key} value={getAlltimeZones[timeZoneName]} >{timeZoneName}</option>
                                        ))}
									</select>
                        		<div className="invalid-feedback">
                            	Select time zone.
                       		 </div>
                    		</div>
							<div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='current_grade'>Grade</label>
                                        <select
                                            id="current_grade" 
                                            value={student?.current_grade}
                                            name="current_grade"
                                            className={`custom-select ${(isValid && (validation.text(student?.current_grade) ? '' : 'error is-invalid')).toString()}`}
                                            onChange={this.onChange.bind(this,index)}>
                                            <option value="">Choose One</option>
                                            {
                                                studentGrade.map((studentgrade, key) => (
                                                    <option key={key} value={studentgrade}>{studentgrade}</option>
                                                ))
                                            }
                                        </select>
                                        <div className="invalid-feedback">
                                            Please enter your Grade.
                                        </div>
                                    </div>
							</div>
							<div className='form-row'>
							{/* <div className='form-group col-md-6'>
								<label htmlFor='current_gpa'>Current GPA (Prior to start of session)</label>
								<input
									type='number'
									name='current_gpa'
									id="current_gpa"
									min="0"
									value={student.current_gpa}
									onChange={this.onChange.bind(this, index)}
									onKeyPress={this.preventMinus}
									className={`form-control light ${(isValid && (validation.text(student.current_gpa) ? '' : 'error is-invalid')).toString()}`}
								/>
								<div className="invalid-feedback">
									GPA is required.
								</div>
							</div> */}
								{/* <div className='form-group col-md-6'>
									<label htmlFor='special_needs'>Do you have any special needs?</label>
									<input
										type='text'
										name='special_needs'
										id="special_needs"
										value={student.special_needs}
										onChange={this.onChange.bind(this, index)}
										className={`form-control light ${(isValid && (validation.text(student.special_needs) ? '' : 'error is-invalid')).toString()}`}
									/>
									<div className="invalid-feedback">
										Type your need (if any) or none.
									</div>
								</div> */}
							</div>
							<div className='form-row'>
							<div className='form-group col-md-12'>
                                            <label htmlFor='contact_teacher'>SiSTEM Tutoring Agency aligns students learning plans with their classroom instruction by collaborating with their teachers at school. Do you give SiSTEM permission to contact your student's teacher?  </label>
                                            <select className={`custom-select form-control light ${(isValid && (validation.text(student.contact_teacher) ? '' : 'error is-invalid')).toString()}`}
                                                    id="contact_teacher" name="contact_teacher" value={student.contact_teacher}
                                                    onChange={this.onChange.bind(this, index)}>
                                                <option value="">Choose One</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div> 
							</div>
							{student.contact_teacher === "yes" ? <>
                            
                            <div className='form-row'>
                            <div className='form-group col-md-6'>
                        <label htmlFor='teacher_name'>Teacher's Name <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='teacher_name'
                            id="teacher_name"
                            value={student.teacher_name}
                            onChange={this.onChange.bind(this, index)}
                            className={`form-control light `}
                         /> 
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='teacher_phone_number'>Teacher's Phone Number <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='teacher_phone_number'
                            id="teacher_phone_number"
                            value={student.teacher_phone_number}
                            onChange={this.onChange.bind(this, index)}
                            className={`form-control light `}
                         /> 
                    </div>
                            <div className='form-group col-md-6'>
                                <label htmlFor='teacher_email'>Teacher's Email <span className="sub-text">(optional)</span></label>
                                <input
                                    type='email'
                                    name='teacher_email'
                                    id="teacher_email"
                                    value={student.teacher_email}
                                    onChange={this.onChange.bind(this, index)}
                                    className={`form-control light `}
                                /> 
                            </div>
                            <div className='form-group col-md-6'>
                        <label htmlFor='teacher_subject'>Subject they teach <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='teacher_subject'
                            id="teacher_subject"
                            value={student.teacher_subject}
                            onChange={this.onChange.bind(this, index)}
                            className={`form-control light `}
                         /> 
                    </div>
                                
                            </div></>: null }
							<div className='form-row'>
							<div className='form-group col-md-12'>
                                            <label htmlFor='individual_learning'>Will you or your student be needing an individualized learning plan from SiSTEM Tutoring Agency?</label>
                                            <select className={`custom-select form-control light ${(isValid && (validation.text(student.individual_learning) ? '' : 'error is-invalid')).toString()}`}
                                                    id="individual_learning" name="individual_learning" value={student.individual_learning}
                                                    onChange={this.onChange.bind(this, index)}>
                                                <option value="">Choose One</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div>
							</div>

							<div className="form-row">
								<div className="from-group col-md-6">
									<label htmlFor="struggle_topics" className='struggling-topics'>What subjects are you struggling in?</label>
									<Select
									id='struggle_topics' 
									className={`form-control h-auto border-0 p-0 ${(isValid && (validation.text(student.struggle_topics) ? '' : 'error is-invalid')).toString()}`} isMulti 
									value = {student.struggle_topics} 
									onChange={this.handleMultiChange.bind(this, index)} 
									options={subjectList} />
									<div className="invalid-feedback">
										Struggling subjects are required.
									</div>
								</div>
							</div>
							<div className='form-row'>
							<div className='form-group col-md-6 signup-calender'>
                                            <label htmlFor='tutoring_start_date' className={`${(isValid && (validation.text(student.tutoring_start_date) ? '' : 'error is-invalid')).toString()}`}>When would you like tutoring to begin?</label>
                                            <DatePicker placeholderText="mm-dd-yyyy" dateFormat="MM-dd-yyyy" name='tutoring_start_date' id="tutoring_start_date" selected={student.tutoringPicked} minDate={addDays(new Date(), 1)} onChange={this.setTurorinBegins.bind(this, index)} dropdownMode="select" showMonthDropdown showYearDropdown className={`form-control light ${(isValid && (validation.text(student.tutoring_start_date)  ? '' : 'error is-invalid')).toString()}`} />
                                            <div className="invalid-feedback">
                                                Select a date.
                                            </div>
                                        </div> 
							</div>
							<div className='form-row'>
							<div className='form-group col-md-12'>
                                            <label htmlFor='tutoring_interest'>Are you interested in in-person tutoring, online tutoring or both?</label>
                                            <select className={`custom-select form-control light ${(isValid && (validation.text(student.tutoring_interest) ? '' : 'error is-invalid')).toString()}`}
                                                    id="tutoring_interest" name="tutoring_interest" value={student.tutoring_interest}
                                                    onChange={this.onChange.bind(this, index)}>
                                                <option value="">Choose One</option>
                                                <option value="in-person">In-person</option>
                                                <option value="online">Online</option>
                                                <option value="both">Both</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div>
							</div>
							<div className='form-row'>
							<div className='form-group col-md-12'>
                                            <label htmlFor='extra_info'>Is there anything else you'd like to share with us?</label>
                                            <textarea className={`form-control ${(this.state.isValid && (validation.text(student.extra_info) ? '' : 'error is-invalid')).toString()}`} name="extra_info" id="extra_info" rows="3" value={student.extra_info}  onChange={this.onChange.bind(this, index)}></textarea>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div> 
							</div>
							{/* <div className='form-row'>
								<div className='form-group col-md-12' style={{paddingTop: '30px', paddingBottom: '30px'}}>
									<label htmlFor='hobbies'>Choose hobbies and interests</label>
									<div className="checkbox-grid-hobbies">
										{this.state.hobbyList.map((hobby, key) => {
											return (
												<div className='custom-control custom-checkbox' key={key}>
													<input
														type='checkbox'
														className='custom-control-input'
														id={hobby.id + index}
														value={hobby.id +' '+index}
														name="hobbies"
														onChange={this.onHobbyChange.bind(this, index, hobby.id)}
														
													/>
													<label className={`custom-control-label checkbox-2`} htmlFor={hobby.id + index}>
														{hobby.name}
													</label>
												</div>
											)
										})}
									</div>
								</div>
							</div> */}
							<div className="text-center">
								<label className="mb-1">
									Upload clear photograph 
								</label>
								<DropZone 
									maxSize={5} 
									response={this.getFile.bind(this)} 
									prefix={index} 
									mimeTypes="image/png, image/jpeg, image/jpg"
								/>
							</div>
						</section>
					})
				}
				<div className='d-flex justify-content-center align-items-center mt-5'>
					<button
						disabled={loading}
						className='btn btn-link btn-padding--lg text-primary'
						onClick={this.addStudent}
					>
						<FaPlus size={24}/> ADD STUDENT
					</button>
				</div>
				<div className='d-flex justify-content-center align-items-center mb-5'>
					<button
						disabled={loading}
						className='btn btn-primary btn-padding--lg text-white'
						onClick={this.onSubmit}
					>
						Continue
					</button>
				</div>
			</div>
		</React.Fragment>
		);
	}
}

export default ParentS2;