/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import DropZone from "../DropZone";

class TutorS6 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			resume: props.payload.resume?props.payload.resume : "",
			profile_picture: props.payload.profile_picture?props.payload.profile_picture : "",
			certificates: props.payload.certificates?props.payload.certificates : "",
			// cover_letter: props.payload.cover_letter?props.payload.cover_letter : "",
			//validation
			isValid: false,
			loading: false,
		};
	}

	componentDidUpdate(prevProps) {
        if(this.props.loading != prevProps.loading) {
            this.setState({
                ...this.state,
                loading: this.props.loading,
            });
        }
    }
	
	onSubmit = () => {
		const { profile_picture, certificates, cover_letter, resume } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
			if(validation.text(resume)
				//&& validation.text(profile_picture)
				// && validation.text(certificates)
				// && validation.text(cover_letter)
			) {
				const data = JSON.parse(JSON.stringify(this.state));
				this.props.getData(_.omit(data, ['isValid', 'loading']));
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Upload docements / images first.");
			}
		});
	}

	getFile = (data) => {
		if(data.resp) {
			this.setState({
				...this.state,
				loading: data.loading,
				[data.prefix]: data.resp
			});
		}
	}

	render() {
		const { profile_picture, resume, certificates, cover_letter, loading } = this.state;
		const maxFileSize = 10;
		return(
		<React.Fragment>
			<div id='signup-form'>
				<div className="mb-3">
					<label className="mb-1 d-flex justify-content-center font-weight-bold text-grey">
						Upload Your Photo
					</label>
					<DropZone 
						maxSize={maxFileSize} 
						existingFile={profile_picture}
						response={this.getFile.bind(this)} 
						prefix={"profile_picture"} 
						mimeTypes="image/png, image/jpeg, image/jpg"
						uploadMessage="Optional: upload a picture in PNG, JPEG, JPG format. The file must be under 10mb in size."

					/>
				</div>

				<div className="mb-3">
					<label className="mb-1  pl-1 d-flex justify-content-center font-weight-bold text-grey">
						Upload Resume
					</label>
					<DropZone 
						maxSize={maxFileSize} 
						existingFile={resume}
						response={this.getFile.bind(this)} 
						prefix={"resume"} 
						mimeTypes="image/png, image/jpeg, image/jpg, application/pdf"
						uploadMessage="To proceed, upload a resume in PDF, PNG, JPEG, JPG format. The file must be under 10mb in size."
					/>
				</div>

				{/* <div className="mb-3">
					<label className="mb-1  pl-1 d-flex justify-content-center font-weight-bold text-grey">
						Upload Cover Letter
					</label>
					<DropZone 
						maxSize={maxFileSize}
						existingFile={cover_letter} 
						response={this.getFile.bind(this)} 
						prefix={"cover_letter"} 
						mimeTypes="image/png, image/jpeg, image/jpg, application/pdf"
					/>
				</div> */}

				{/* <div className="mb-3">
					<label className="mb-1  pl-1 d-flex justify-content-center font-weight-bold text-grey">
					Upload Transcript/Teaching Certificate
					</label>
					<label className="mb-2 p-1 d-flex justify-content-center">
						If you are a certifed teacher, please provide your certificate or most recent transcript can be an unofficial copy 
					</label>
					<DropZone 
						maxSize={maxFileSize} 
						existingFile={certificates}
						response={this.getFile.bind(this)} 
						prefix={"certificates"} 
						mimeTypes="image/png, image/jpeg, image/jpg, application/pdf"
					/>
				</div> */}
				<div className='d-flex justify-content-center align-items-center my-5'>
					<button
						disabled={loading}
						className='btn btn-primary btn-padding--lg text-white'
						onClick={this.onSubmit}
					>
						Continue
					</button>
				</div>
			</div>
		</React.Fragment>
		);
	}
}

export default TutorS6;