/* eslint-disable */
import React, { Component } from 'react'
import BlankAuthLayout from '../components/BlankAuthLayout'
import {FiCheck} from 'react-icons/fi'
import { Link,useHistory } from 'react-router-dom';
import TutorListing from '../components/Tutor/BookTutor/TutorListing';
import Schedule from '../components/Tutor/BookTutor/Schedule';
import Additional from '../components/Tutor/BookTutor/Additional';
import guard from "../actions/guard";
import { scheduleParent, scheduleStudent } from "../services/Tutor";
import {toast} from "react-toastify";
import { confirm } from "react-confirm-box";
import { Modal } from 'react-responsive-modal';
import { singleSessionCharges } from "../config/variables";
import { get } from "../services/Profile";
export default class BookTutorBuilder extends Component {

    constructor(props) {
        super(props);
        const { tutorId } = props.match.params;
        const { prevStudentId } = props.match.params; 
        this.state = {
            step: 0,
            payload: {},
            loading: false,
            complete: false,
            role: null,
            tutorId: typeof tutorId !== 'undefined'? tutorId : '',
            singleSessionOption :false,
            prevStudentId: typeof prevStudentId !== 'undefined'? prevStudentId : '',
            bookAgain :null
        }
    }
    
    componentDidMount() {
        guard.check(() => {
            if (guard.role() !== "tutor") {
                if(guard.role() === "parent") {
                    if(this.state.prevStudentId){
                        get(this.state.prevStudentId).then((res) => {
                            if (res.status === 200) {
                              const studentDetail = res.data.data;
                              this.setState({
                                ...this.state,
                                step: 1,
                                role: "parent",
                                bookAgain :studentDetail
                            })
                            } else {
                              toast.error("Something went wrong!");
                            }
                          }).catch((err) => {
                            toast.error("Something went wrong!");
                          })
                    }else{
                    this.setState({
                        ...this.state,
                        step: 1,
                        role: "parent"
                    })
                    }
                } else if(guard.role() === "student") {
                    this.setState({
                        ...this.state,
                        step: 1,
                        role: "student"
                    })
                }
                
            } else {
              this.props.history.push("/dashboard");
            }
        });
    }

    getData = (data) => {
        const { step } = this.state;
        if(step === 1) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    ...data
                },
                step: 2
            });
        }else if(step === 2) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    ...data
                },
                step: 3
            });
        }else if(step === 3) {
            this.setState({
                ...this.state,
                loading: true,
                payload: {
                    ...this.state.payload,
                    ...data
                }
            }, () => {
                    this.schedule();
            });
        } 
    }
    confirmSigleSession=()=> {
        
        return new Promise((resolve) => {
            
        });
     }
    schedule = async function(){
        const { payload } = this.state;
        const sessionPreferred = (payload.in_person == 1) ? "In Person": "Online"
        const options = {
            render: (message, onConfirm, onCancel) => {
              return (
                <Modal  
                open={true} 
                closeOnOverlayClick={false} center
                showCloseIcon={false}>
                <div className="modal-header">
					<h4 className="modal-title">You do not have enough credit for {sessionPreferred} tutoring?</h4>
                </div>
                <div className="modal-body">
                  <p> {message} </p>
                  <div className="add-dialog-buttons">
                  <button className="btn btn-primary text-white" onClick={onConfirm}> Yes </button>
                  <button className="btn btn-primary text-white" onClick={onCancel}> No </button>
                  </div>
                </div>
                </Modal>
              );
            }
          };
        if(this.state.role === "parent") {
            scheduleParent(payload).then(async(res) => {
                if(res.status === 200) {
                    if(res.data.type==="success"){
                        toast.success(res.data.message);
                         this.setState({
                        ...this.state,
                        complete: true
                    });

                    }else{
                       if(res.data.responseStatus=="1"){
                        
                        this.setState({
                            ...this.state,
                            singleSessionOption: true
                        });
                        payload.singlepaytime =   Math.floor(100 + Math.random() * 900);
                        localStorage.setItem('singleSession', JSON.stringify(payload));
                        let planid = (payload.in_person==1) ? 0: 1;
                        let planName = (payload.in_person==1) ? "In Person": "Online";
                        let singleSessionAmount = (planid==1) ? singleSessionCharges.SINGLE_SESSION_ONLINE_CHARGE :singleSessionCharges.SINGLE_SESSION_INPERSON_CHARGE;
                        const goSigleSession= await confirm(`${res.data.message} . If you want to proceed for an ${planName} single session payment of $${singleSessionAmount}?`, options);
                        if (goSigleSession) {
                            this.props.history.push("/payment/" + btoa(planid) + "?studentId=" + payload.student_data[0].student_id+"&singleSessionPay=true&token="+payload.singlepaytime);
                        }else{
                            localStorage.removeItem("singleSession");
                            setTimeout( () => {
                                this.setState({
                                ...this.state,
                                    step: 1,
                                })
                            },1000);
                        }
                       }else{
                        toast.warning(res.data.message);
                        setTimeout( () => {
                            this.setState({
                            ...this.state,
                                step: 1,
                            })
                        },1000);
                       }
                    }
                }else {
                    throw new Error (Object.values(res.response.data).reduce((init, current) => (init + " " + current.toString()), ""));
                }
            }).catch((err) => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                toast.error(err.message);
            });
        } else if(this.state.role === "student") {
            scheduleStudent(payload).then(async(res) => {
                if(res.status === 200) {
                   if(res.data.type==="success"){
                        toast.success(res.data.message);
                         this.setState({
                        ...this.state,
                        complete: true
                    });

                    }else{
                        
                        if(res.data.responseStatus=="1"){
                            payload.singlepaytime =   Math.floor(100 + Math.random() * 900);
                            localStorage.setItem('singleSession', JSON.stringify(payload)); 
                            console.log(payload.in_person)
                            let planid = (payload.in_person==1) ? 0: 1;
                            let planName = (payload.in_person==1) ? "In Person": "Online";
                            let singleSessionAmount = (planid==1) ? singleSessionCharges.SINGLE_SESSION_ONLINE_CHARGE :singleSessionCharges.SINGLE_SESSION_INPERSON_CHARGE;
                            const goSigleSession= await confirm(`${res.data.message} . If you want to proceed for an ${planName} single session payment of $${singleSessionAmount}?`, options);
                            if (goSigleSession) {
                                this.props.history.push("/payment/" + btoa(planid) + "?singleSessionPay=true&token="+payload.singlepaytime);
                            }else{
                                localStorage.removeItem("singleSession");
                                setTimeout( () => {
                                    this.setState({
                                    ...this.state,
                                        step: 1,
                                    })
                                },1000);
                            }
                        }else{
                            toast.warning(res.data.message);
                            setTimeout( () => {
                                this.setState({
                                ...this.state,
                                    step: 1,
                                })
                            },1000);
                        }
                    }
                   
                }else {
                    throw new Error (Object.values(res.response.data).reduce((init, current) => (init + " " + current.toString()), ""));
                }
            }).catch((err) => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                toast.error(err.message);
            });
        }
        
    };
    back= (e) => {
         const { step } = this.state;
         const {tutorId} = this.state;
        if(step === 2) {
            this.setState({
                ...this.state,
                step: 1,
                tutorId:''
            })
            this.props.history.push('/tutor/schedule');
         }
         if(step === 2 && tutorId){
            this.setState({
                ...this.state,
                tutorId:tutorId

            })
                this.props.history.push('/tutor/detail/'+tutorId);

        }
         }

    backtoSchedule = (e) => {
        const { step } = this.state;
        const {tutorId} = this.state;
        if(step === 3){
            this.setState({
                ...this.state,
                step: 2,
            })
                this.props.history.push('/tutor/schedule');
          }        
    }
 render() {
        const { step, loading, payload, complete, tutorId,bookAgain } = this.state;
        return (
            <BlankAuthLayout>
                <header className='tutor-list__header'>
                    <div className="logo-wrapper">
                        <Link to="/">
                            <img src="/images/logo.svg" alt="site-logo"/>
                        </Link>
                    </div>
                    
                    <div className='d-flex align-items-center align-items-lg-end'>
                    {
                      step === 1 ?
                      <h6 className="txt-14 text-teal bold mb-0 pointer" onClick={() => this.props.history.push("/dashboard")}>BACK TO DASHBOARD</h6>
                      :null
                    }
                    {step === 2 ?
                        
                    <h6 className="txt-14 text-teal bold mb-0 pointer" onClick={(e)=> { e.preventDefault(); this.back(e)} }>BACK</h6>
                    : null
                    }
                     {step === 3 && !complete?
                        
                        <h6 className="txt-14 text-teal bold mb-0 pointer" onClick={(e)=> { e.preventDefault(); this.backtoSchedule(e)} }>BACK</h6>
                        : null
                    }   
                    </div>
                </header>
                <section className='position-relative'>
                <div className='step-nav'>
                        <ul>
                            {
                                [...Array(3)].map((el, index) => (
                                    <li className={`${step >= index + 1 ? "active" : ""}`} key={index}>
                                        {step > index + 1 ? (
                                            <span>
                                                <FiCheck />
                                            </span>
                                        ) : (
                                                <span>{index + 1}</span>
                                            )}
                                        {index + 1 === 1 && 'Filter based on your needs'}
                                        {index + 1 === 2 && 'Schedule periods'}
                                        {index + 1 === 3 && 'Additional Details'}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    {step === 1 &&
                        <TutorListing 
                        tutorId= {tutorId} 
                        getData={this.getData} 
                        payload={payload}
                        history={this.props.history}
                        bookAgain={bookAgain}/>
                    } 
                    {step === 2 &&
                        <Schedule 
                        getData={this.getData} 
                        tutorId={payload.tutor_id} 
                        studentId={payload.student_id} 
                        parentId={payload.parent_id} 
                        studentName={payload.student_name} 
                        studentProfile={payload.student_profile}
                        payload={payload}/>
                    } 
                    {step === 3 &&
                        <Additional 
                        getData={this.getData} 
                        loading={loading} 
                        complete={complete} 
                        back={() => this.props.history.push("/dashboard")}
                        payload={payload}/>
                    } 
                </section>
            </BlankAuthLayout>
        )
    }
}
