import axios from "axios";
import cookie from "../actions/cookie.js";
import { API_END_POINT } from "../config/env.json";

export const getCoupons = async () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    try {
        const res = await axios.get(`${API_END_POINT}/v1/get-coupons`, options);
        return res;
    } catch (error) {
        return error;
    }
};