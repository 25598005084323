/* eslint-disable */
/***
 *
 * Set cookie, get cookie and delete cookie
 *
 * According to HTTP Cookie specs, semi-colons, commas, equals signs, and white spaces are not allowed characters.
 * But using these functions you can use all special chars.
 * Function names are self explanatory.
 *
 * Author; Arijit Deb Roy
 * Date: 9th aug,2019
 *
 * @param: name, value, days
 * @return: cookie value | null | undefined
 *
 */

const Cookie = {

    getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? decodeURIComponent(v[2]) : null;
    },

    setCookie(name, value, days, minutes=0) {
        var d = new Date;
        if(minutes>0){
            d.setTime(d.getTime() + (minutes * 60 * 1000));
 
        }else{
            d.setTime(d.getTime() + 24*60*60*1000*parseInt(days));

        }
        document.cookie = name + "=" + encodeURIComponent(value) + ";path=/;expires=" + d.toGMTString();
    },

    deleteCookie(name) {
        this.setCookie(name, '', -1);
    }

};

export default Cookie;

