/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { states } from "../../config/variables";
import { racicalCategories } from "../../config/variables";

class TutorS2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			state: props.payload.state?props.payload.state : "",
            address: props.payload.address?props.payload.address :"",
            zip_code: props.payload.zip_code?props.payload.zip_code:"",
            city: props.payload.city?props.payload.city:"",
			//validation
			isValid: false,
			loading: false,
            how_do_you_hear: props.payload.how_do_you_hear?props.payload.how_do_you_hear:"",
            // gender: props.payload.gender?props.payload.gender:"",
			// race: props.payload.race?props.payload.race:""
		};
	}

	onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };
	
	onSubmit = () => {
		const { state, city, zip_code, address, how_do_you_hear } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
			if(validation.text(state)
                && validation.text(city)
                && validation.zipCode(zip_code)
                && validation.text(address)
                // && validation.text(race)
                // && validation.text(gender)
                && validation.text(how_do_you_hear)
			) {
				const data = JSON.parse(JSON.stringify(this.state));
				this.props.getData(_.omit(data, ['isValid', 'loading']));
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Validation Error!")
			}
		});
	}

	render() {
		const { state, city, zip_code, address,  how_do_you_hear, isValid, loading} = this.state;

		return(
		<React.Fragment>
                <div id='signup-form'>
                    <div className='form-row'>
                    <div className='form-group col-md-6'>
                            <label htmlFor='address'>Address</label>
                            <input
                                type='text'
                                name='address'
                                id="address"
                                value={address}
                                onChange={this.onChange}
                                className={`form-control light ${(isValid && (validation.text(address) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                Type your address.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='city'>City</label>
                            <input
                                type='text'
                                name='city'
                                id="city"
                                value={city}
                                onChange={this.onChange}
                                className={`form-control light ${(isValid && (validation.text(city) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                Type your city.
                            </div>
                        </div>

                        
                    </div>
                    <div className='form-row'>
                    <div className='form-group col-md-6'>
                            <label htmlFor='state'>State of residence</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(state) ? '' : 'error is-invalid')).toString()}`}
                                    id="state" value={state}
                                    name="state"
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                {
                                    states.map((state, key) => (
                                        <option key={key} value={state}>{state}</option>
                                    ))
                                }
                            </select>
                            <div className="invalid-feedback">
                                Select your state.
                            </div>
                        </div>
                        
                        <div className='form-group col-md-6'>
                            <label htmlFor='zip_code'>Zip code</label>
                            <input
                                type='text'
                                name='zip_code'
                                id="zip_code"
                                value={zip_code}
                                onChange={this.onChange}
                                maxLength="5"
                                className={`form-control light ${(isValid && (validation.zipCode(zip_code) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                            please enter zip-code.it should be a maximum of 5 digits
                            </div>
                        </div>
                        
                    </div>
                    {/* <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='gender'>Gender</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(gender) ? '' : 'error is-invalid')).toString()}`}
                                    id="gender" name="gender" value={gender}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                                <option value="I don't prefer to say">I don't prefer to say</option>
                            </select>
                            <div className="invalid-feedback">
                                Select your gender.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='race'>Race</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(race) ? '' : 'error is-invalid')).toString()}`}
                                    id="race" name="race" value={race} onChange={this.onChange}>
                                <option value="">Choose One</option>
                                {
                                    racicalCategories.map((race, key) => (
                                        <option key={key} value={race}>{race}</option>
                                    ))
                                }
                            </select>
                            <div className="invalid-feedback">
                                Select your race.
                            </div>
                        </div>
                    </div> */}
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='how_do_you_hear'>How did you hear about us?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(how_do_you_hear) ? '' : 'error is-invalid')).toString()}`}
                                    id="how_do_you_hear" name="how_do_you_hear" value={how_do_you_hear}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Google">Google</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Current School">Current School</option>
                                <option value="Twitter">Twitter</option>
                                <option value="LinkedIn">LinkedIn</option>
                                <option value="SiSTEM_employee">A SiSTEM employee</option>
                                <option value="Other">Other</option>
                                <option value="Ads">Ads</option>
                            </select>
                            <div className="invalid-feedback">
                                Please select one
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center my-5'>
                        <button
                            disabled={loading}
                            className='btn btn-primary btn-padding--lg text-white'
                            onClick={this.onSubmit}
                        >
                            Continue
                        </button>
                    </div>
                </div>
		</React.Fragment>
		);
	}
}

export default TutorS2;