import React from 'react';
import Rating from '@material-ui/lab/Rating';

const WhatRatingMeans = () => {
    return (
        <div className="rating-chart">
            <h4 className="title">What ratings mean</h4>
            <div>
                <div>
                    <h5>Very Good</h5>
                    <Rating defaultValue={5} name="five" />
                </div>
                <div>
                    <h5>Good</h5>
                    <Rating defaultValue={4} max={4} name="four"/>
                </div>
                <div>
                    <h5>Average</h5>
                    <Rating defaultValue={3} max={3} name="three"/>
                </div>
                <div>
                    <h5>Poor</h5>
                    <Rating defaultValue={2} max={2} name="two"/>
                </div>
                <div>
                    <h5>Very Poor</h5>
                    <Rating defaultValue={1} max={1} name="one"/>
                </div>
            </div>
        </div>
    )
}

export default WhatRatingMeans;