import React from 'react';
import Layout from "../components/Layout";
import { Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { useEffect } from 'react';

const Confirmation = () => {
    useEffect(()=>{
        window.scroll(0,0);
    })
    return (
        <Layout>
            <div className="confirmation-page intro-content">
                <h3 className='text-center text-grey my-5'>Account Confirmation</h3>
                <p className="text-center text-grey w-75 mx-auto">A confirmation email has been sent to the email address you provided.</p>
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <Link to="/">
                        <Button
                            variant='primary'
                            className='btn-padding--lg text-white'
                        >
                            Done
                        </Button>
                    </Link>
                </div>
            </div>
        </Layout>
    )
}

export default Confirmation;