
import React from "react";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";
const ShowMoreData=({openModal,closeModal,data,ratingText,userCredits})=>{
return(
        <div>
          <Modal
            closeOnOverlayClick={false} center
            open={openModal} 
            onClose={closeModal}
          > 
          <>
              <button 
              className="close button-styles"
              style={{
              position: 'absolute',
              right:"5%" ,
              top: "5%",
              }} 
              onClick={closeModal}
              >
              </button>
            </> 
             
            <div className="modal-responsive-class">
            <h6>{ratingText}</h6>
            <p>{data}</p>
            {userCredits && userCredits.length?(userCredits.map( (userCredits) => {
                    let planName = '';
                    switch(userCredits.plan_type) {
                        case 'pro': planName='In person';
                                        break;
                        case 'basic': planName='Online';
                                        break;
                        case 'pro-pay-as-you-go': planName='Inperson-pay-as-you-go';
                                        break;
                        case 'basic-pay-as-you-go': planName='Online-pay-as-yo-go';
                                            break;
                        case 'test-prep': planName='Test prep';
                           break; 
                        case 'test-prep-pay-as-you-go': planName='Test-prep-pay-as-you-go';
                           break;
                        default: planName='';
                                    break;
                    }
                    return (
                      
                        <>
                        {userCredits.credit>0?
                        <div>{planName}&nbsp;&nbsp;:&nbsp;&nbsp; {userCredits.credit}</div>
                        :null}
                        </>
                    )
                })):null}
           
           </div>
          </Modal>
        </div>
    )

}
export default ShowMoreData;