import React, {Component} from 'react';
import validation from "../../actions/validation";
import {toast} from "react-toastify";
import auth from "../../actions/auth";
import {updatePass} from "../../services/Profile";

class ParentStudentSecurity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            old_password: "",
            new_password: "",
            new_confirm_password: "",
            //validation
            isValid: false,
            loading: false
        }
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        });
    };

    update = () => {
        const { old_password, new_password, new_confirm_password } = this.state;
        let input = {
            new_password: new_password,
            new_confirm_password: new_confirm_password
        }
        this.setState({
            ...this.state,
            isValid: true,
            loading: true
        }, () => {
            if(validation.passwordStrength(old_password, "medium")
                && validation.passwordStrength(new_password, "medium")
                && validation.passwordStrength(new_confirm_password, "medium")
            ) {
                updatePass(auth.check().id, input).then((res) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    if(res.status === 200) {
                        toast.success(res.data.message);
                    }else {
                        toast.error(res.data.message);
                    }
                }).catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Something went wrong!");
                })
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                })
                toast.error("Validation Error!");
            }
        })
    }

    render() {
        const { old_password, new_password, new_confirm_password, isValid, loading } = this.state;
        let pswError = '';
        if(!validation.text(new_password)){
            pswError = "Please enter a valid password."
        }else if(!validation.passwordStrength(new_password, "medium")){
            pswError = "You have entered a weak password."
        }else{
            pswError = "Password miss-matching."
        }
        let pswConfirmError = '';
        if(!validation.text(new_confirm_password)){
            pswConfirmError = "Please confirm your password."
        }else if(new_password != new_confirm_password){
            pswConfirmError = "Password miss-matching."
        }else if(!validation.passwordStrength(new_confirm_password, "medium")){
            pswConfirmError = "You have entered a weak password."
        }
        return (
            <div className="profile-security">
                <h5 className='bold serif mb-5 text-grey'>Password Settings</h5>
                <form className='flexible-width' onSubmit={(e) => {e.preventDefault();}}>
                    <div className="row mb-5">
                        <div className="col-md-12">
                            <div className="form-group mb-4 p-0 col-md-6">
                                <label htmlFor="old_password" className='toggle-label bold'>Enter old Password</label>
                                <input type="password"  
                                    className={`form-control ${(isValid && ((validation.passwordStrength(old_password, "medium")) ? '' : 'error is-invalid')).toString()}`} 
                                    name="old_password" 
                                    id="currentpass" 
                                    onChange={this.onChange}
                                />
                                <div className="invalid-feedback">
                                    Entert Old Password.
                                </div>
                            </div>
                            <div class="d-md-flex">
                            <div className="form-group mb-4 col-md-6 p-0 mr-4">
                                <label htmlFor="new_password" className='toggle-label bold'>Enter new Password</label>
                                <input type="password" 
                                    className={`form-control ${(isValid && ((validation.passwordStrength(new_password, "medium") && new_password === new_confirm_password) ? '' : 'error is-invalid')).toString()}`} 
                                    name="new_password" 
                                    id="newpass" 
                                    onChange={this.onChange}
                                />
                                <div className="invalid-feedback">
                                {pswError}
                                </div>
                            </div>
                            <div className="form-group mb-0 col-md-6 p-0">
                                <label htmlFor="new_confirm_password" className='toggle-label bold'>Confirm new Password</label>
                                <input type="password" 
                                    className={`form-control ${(isValid && ((validation.passwordStrength(new_confirm_password, "medium") && new_password === new_confirm_password) ? '' : 'error is-invalid')).toString()}`} 
                                    name="new_confirm_password" 
                                    id="confpass" 
                                    onChange={this.onChange}
                                />
                                <div className="invalid-feedback">
                                {pswConfirmError}
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-12 p-0 ml-2 align-self-end mt-3">
                            <div className="d-flex justify-content-end mt-5 mt-md-auto">
                                <button type="submit" className="btn btn-primary btn-padding--md text-white" disabled={loading} onClick={this.update}>Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ParentStudentSecurity;