import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { MdMenu, MdClose, MdPowerSettingsNew } from "react-icons/md";
import "./MenuNav.css";

import cookie from "../../../actions/cookie";
import auth from "../../../actions/auth";
import env from "../../../config/env.json";
import ReviewRecapModal from "../../../components/Modal/ReviewRecapModal";
import { getRatingRecapStatus } from "../../../services/Rating";
import { toast } from "react-toastify";
import { get } from "../../../services/Profile";
import _ from "lodash";

export default function Menubar({ path, mode }) {
  const history = useHistory();
  const [reviewDetails, setReviewStatus] = useState({
    status: true,
    sessionId: null,
    tutorId: null,
  });
  const [notificationCount, setNotificationCount] = useState(0);
  const logout = () => {
    cookie.deleteCookie("_token");
    cookie.deleteCookie("_session");
    history.push("/");
  };
  return (
    <React.Fragment>
      {/* Desktop Menubar */}
      <menu className={`tutor-menubar `}>
        <div>
          <p className="font-weight-bold text-grey">Tutor Dashboard</p>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-md-center align-items-start align-items-md-center tutor-datas">
          <Link
            to="/dashboard"
            className={`menu-item ${
              path === "/tutor/dashboard" ? "active" : ""
            }`}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M6.93745 0H1.31245C0.58873 0 0 0.58873 0 1.31245V4.68745C0 5.41132 0.58873 6.00005 1.31245 6.00005H6.93745C7.66132 6.00005 8.25005 5.41132 8.25005 4.68745V1.31245C8.25005 0.58873 7.66132 0 6.93745 0Z"
                  fill="#009BA7"
                />
                <path
                  d="M6.93745 7.5H1.31245C0.58873 7.5 0 8.08873 0 8.81259V16.6876C0 17.4113 0.58873 18 1.31245 18H6.93745C7.66132 18 8.25005 17.4113 8.25005 16.6876V8.81259C8.25005 8.08873 7.66132 7.5 6.93745 7.5Z"
                  fill="#009BA7"
                />
                <path
                  d="M16.6878 12H11.0628C10.339 12 9.75024 12.5887 9.75024 13.3126V16.6876C9.75024 17.4113 10.339 18 11.0628 18H16.6878C17.4116 18 18.0003 17.4113 18.0003 16.6876V13.3126C18.0003 12.5887 17.4116 12 16.6878 12Z"
                  fill="#009BA7"
                />
                <path
                  d="M16.8376 0H11.2126C10.4888 0 9.90002 0.58873 9.90002 1.31245V9.18745C9.90002 9.91132 10.4888 10.5 11.2126 10.5H16.8376C17.5613 10.5 18.1501 9.91132 18.1501 9.18745V1.31245C18.1501 0.58873 17.5613 0 16.8376 0V0Z"
                  fill="#009BA7"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="nav-text">Dashboard</div>
          </Link>
          <Link
            to="/session"
            className={`menu-item ${path === "/tutor/sessons" ? "active" : ""}`}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip1)">
                <path
                  d="M6.37766 15.9268C6.26457 15.9186 6.15074 15.9144 6.03625 15.9144H2.05965H1.47371V4.46191H0V17.3756H9.40934L9.27707 17.245C8.48973 16.4674 7.47066 16.0064 6.37766 15.9268Z"
                  fill="#009BA7"
                />
                <path
                  d="M6.03617 2.62305H2.64551V14.7428H6.03621C7.26453 14.7428 8.43609 15.1244 9.41402 15.8306V4.08891L9.27703 3.95359C8.4082 3.09562 7.25727 2.62305 6.03617 2.62305ZM7.37969 12.0011H4.6798V10.8293H7.37969V12.0011ZM7.37969 9.36563H4.6798V8.19375H7.37969V9.36563ZM7.37969 6.73012H4.6798V5.55824H7.37969V6.73012Z"
                  fill="#009BA7"
                />
                <path
                  d="M17.3544 14.7428V2.62305H13.9637C12.7426 2.62305 11.5917 3.09562 10.7229 3.95363L10.5859 4.08891V15.8306C11.5639 15.1243 12.7354 14.7427 13.9637 14.7427H17.3544V14.7428ZM12.6202 5.55824H15.3201V6.73012H12.6202V5.55824ZM12.6202 8.19375H15.3201V9.36562H12.6202V8.19375ZM12.6202 10.8293H15.3201V12.0011H12.6202V10.8293Z"
                  fill="#009BA7"
                />
                <path
                  d="M18.5263 4.46191V15.9144H13.9638C12.7427 15.9144 11.5918 16.387 10.7229 17.245L10.5907 17.3756H20V4.46191H18.5263Z"
                  fill="#009BA7"
                />
              </g>
              <defs>
                <clipPath id="clip1">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="nav-text">Sessions</div>
          </Link>
          <Link
            to="/notification"
            className={`menu-item ${path === "/notification" ? "active" : ""}`}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.3572 2.57129H0.642944L9.00009 9.455L17.4517 2.59057C17.4208 2.58153 17.3892 2.57509 17.3572 2.57129Z"
                fill="#009BA7"
              />
              <path
                d="M9.40562 10.0779C9.16877 10.2718 8.82799 10.2718 8.59114 10.0779L0 3V14.079C0 14.434 0.287816 14.7219 0.642869 14.7219H17.3571C17.7122 14.7219 18 14.434 18 14.079V3.09515L9.40562 10.0779Z"
                fill="#009BA7"
              />
            </svg>
            <div className="nav-text">Requests</div>
          </Link>
          {/* <Link to="/media"className={`menu-item ${path === '/tutor/Media' ? 'active' : ''}`}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.06641 5.46112L11.9665 9.00009L7.06641 12.5391V5.46112Z" fill="#009BA7"/>
            <path d="M9 18C4.03738 18 0 13.9626 0 9C0 4.03738 4.03738 0 9 0C13.9626 0 18 4.03738 18 9C18 13.9626 13.9626 18 9 18ZM13.1759 8.57246L6.8478 4.00215C6.75622 3.93602 6.64787 3.90231 6.53899 3.90231C6.45697 3.90231 6.37471 3.9214 6.29898 3.96014C6.12267 4.05032 6.01172 4.23165 6.01172 4.42969V13.5703C6.01172 13.7683 6.12267 13.9497 6.29898 14.0398C6.47529 14.13 6.68725 14.1138 6.8478 13.9978L13.1759 9.4275C13.3132 9.32836 13.3945 9.16931 13.3945 9C13.3945 8.83069 13.3132 8.67161 13.1759 8.57246Z" fill="#009BA7"/>
            </svg>
            <div className="nav-text">Media</div>
          </Link> */}

          {/* <Link to="/profile"className={`menu-item ${path === '/tutor/whiteboard' ? 'active' : ''}`}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip2)">
            <path d="M9.52527 16.3151C9.52545 16.3151 9.52713 16.3147 9.52734 16.3147V3.65992C9.31743 3.55263 7.04503 1.74088 3.71921 1.56496L3.16406 1.53613V14.1945L3.66616 14.2186C7.28445 14.3913 9.09527 16.096 9.52527 16.3151Z" fill="#009BA7"/>
            <path d="M10.582 3.43582V16.0969C11.3735 15.9575 12.5486 15.805 13.9578 15.8155C16.1721 15.832 17.5725 16.2347 18 16.3037V3.65467C17.8264 3.6353 16.2482 3.17226 13.9578 3.14476C12.9585 3.13274 11.8141 3.20903 10.582 3.43582ZM12.1641 12.5551V6.36606L16.8061 9.46058L12.1641 12.5551Z" fill="#009BA7"/>
            <path d="M13.2188 8.33594V10.5844L14.9048 9.46016L13.2188 8.33594Z" fill="#009BA7"/>
            <path d="M6.68366 15.9093C5.89799 15.5968 4.86858 15.3314 3.61572 15.2717L2.10938 15.1991V3.34375C0.896731 3.524 0.248484 3.7188 0 3.77473V16.464L0.655066 16.2997C2.61155 15.8111 4.68366 15.6837 6.68366 15.9093Z" fill="#009BA7"/>
            </g>
            <defs>
            <clipPath id="clip2">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            <div className="nav-text">Whiteboard</div>
          </Link> */}
          <Link
            to="/profile"
            className={`menu-item ${path === "/tutor/profile" ? "active" : ""}`}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.1865 15.3047C15.1542 13.2307 14.508 11.3448 13.3592 9.97942C12.2171 8.62235 10.6692 7.875 9.0003 7.875C7.33137 7.875 5.78351 8.62235 4.64138 9.97942C3.49367 11.3434 2.84796 13.2266 2.81421 15.298C3.46319 15.6231 6.14878 16.875 9.0003 16.875C12.0838 16.875 14.5847 15.6305 15.1865 15.3047Z"
                fill="#009BA7"
              />
              <path
                d="M9 7.3125C10.7086 7.3125 12.0938 5.92738 12.0938 4.21875C12.0938 2.51012 10.7086 1.125 9 1.125C7.29137 1.125 5.90625 2.51012 5.90625 4.21875C5.90625 5.92738 7.29137 7.3125 9 7.3125Z"
                fill="#009BA7"
              />
            </svg>
            <div className="nav-text">Account</div>
          </Link>
          <div
            className="d-md-flex d-lg-none align-items-center pointer"
            onClick={logout}
          >
            <MdPowerSettingsNew className="mr-1 text-grey" fontSize="22px" />
          </div>
        </div>

        <div></div>
      </menu>
    </React.Fragment>
  );
}

Menubar.propTypes = {
  path: PropTypes.string,
};
