
import React, { Component } from 'react'
import Layout from '../components/Layout';
import {InputGroup, FormControl, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cookie from "../actions/cookie";
import validation from "../actions/validation";
import { login } from "../services/Auth";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import LoadingSpinner from '../components/LoaderComponent/LoadingSpinner';
import { checkProfileCompletion } from '../services/Tutor';
import ApprovalStatus from '../components/Modal/ApprovalStatusModal';

export default class Login extends Component {

        constructor(props) {
        super(props);
        this.state = {
        email: "",
        password: "",
        loading: false,
        isValid: false,
        approved:null,
        isProfileComplete:false,
        showUserapproval:false,
        userRole:null,
        first_name:"",
        last_name:"",
        phone_number:"",
        role_id:""

        };
    }

    componentDidMount() {
        if(!cookie.getCookie("_token")) {

        }else {
            this.props.history.push("/dashboard");
        }
        }
    gotoApprovalmodal=()=>{

            this.setState({
            ...this.state,
            showUserapproval:true,
            })	  
        }
    completeProfile= () => {
        const {history} = this.props;
        history.push('/tutor/add-profile');
        }
    checkuserProfilecompletion=(data)=>{
        let tutorsData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
        role_id:data.role_id,
        details: {
        address:data.tutorData.address,
        city:data.tutorData.city,
        dob:data.tutorData.dob,
        highest_level_education:data.tutorData.highest_level_education,
        how_do_you_hear:data.tutorData.how_do_you_hear,
        occupation:data.tutorData.occupation,
        resume:data.tutorData.resume,
        state:data.tutorData.state,
        years_of_tutoring_exp:data.tutorData.years_of_tutoring_exp,
        zip_code:data.tutorData.zip_code
        }
        }
            if(tutorsData){
            const checkingCompletion = checkProfileCompletion(tutorsData);
            return checkingCompletion
            }
        }

 onSubmit = () => {
        let input = {
            email: this.state.email,
            password: this.state.password,
            };
                this.setState({
                ...this.state,
                isValid: true
                }, () => {
                    if(validation.email(this.state.email)
                    && validation.text(this.state.password)) {
                    this.setState({
                    ...this.state,
                    loading:true
                    })
            login(input).then((res) => {
                if(res.data.token) {
                cookie.setCookie("_token", res.data.token, 1);
                cookie.setCookie("_session", btoa(unescape(encodeURIComponent(JSON.stringify(res.data.user)))), 1);
                //toast.success("Welcome back!");
                const data = res.data.user;
                const approved = res.data.user.approved;
                const userRole = res.data.user.role_id;
                this.setState({
                ...this.state,
                loading: false,
                isValid: false,
                userRole:userRole,
                approved:approved,
                });
                if(userRole === "2"){
                        const isProfileComplete= this.checkuserProfilecompletion(data);
                        this.setState({
                        ...this.state,
                        isProfileComplete:isProfileComplete,
                        })
                        if(!isProfileComplete){
                        this.completeProfile();
                        }
                        else{
                            if(approved === 0){
                                this.gotoApprovalmodal();
                                }
                            else{
                                this.props.history.push("/dashboard");
                            }
                        }
                    }
                else{
                    this.props.history.push("/dashboard");
                }
            
            }
            else {
                this.setState({
                ...this.state,
                loading: false,
                });
                    toast.error(res.data.message);
                    //throw new Error (res.response.data.message);
                }
                }).catch((err) => {
                    this.setState({
                    ...this.state,
                    loading: false,
                    });
                    toast.error(err.message);
                })
             }else {
                toast.error("Validation Error!");
                }
                })
            }

 onChange = (e) => {
        e.persist();
            this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false,
            loading: false
            })
        }

 render() {
        const { password, email, loading, isValid,showUserapproval} = this.state;
        return (
        <>
        {loading ? <LoadingSpinner/>:
            <Layout>
                <div className="layout-container bg-bubble">
                <section className="padded-intro-section">
                <div className="intro-content">
                <h3 className="text-center text-grey mb-4">Sign In</h3>
                <div className="cta-section">
                <InputGroup className="mb-4">
                <FormControl className={`light ${(isValid && (validation.email(email) ? '' : 'error is-invalid')).toString()}`}
                placeholder="Username"
                aria-label="username"
                aria-describedby="user-name-input"
                name="email"
                value={email}
                onChange={this.onChange}
                />
                <InputGroup.Append>
                <InputGroup.Text id="user-icon">
                <img src="/images/userIcon.svg" alt="username" />
                </InputGroup.Text>
                </InputGroup.Append>
                <div className="invalid-feedback">
                Enter valid email.
                </div>
                </InputGroup>
                <InputGroup className="mb-4">
                <FormControl className={`light ${(isValid && (validation.text(password) ? '' : 'error is-invalid')).toString()}`}
                type="password"
                placeholder="Password"
                aria-label="password"
                aria-describedby="password-input"
                name="password"
                value={password}
                onChange={this.onChange}
                />
                <InputGroup.Append>
                <InputGroup.Text id="lock-icon">
                <img src="/images/Password.svg" alt="password" />
                </InputGroup.Text>
                </InputGroup.Append>
                <div className="invalid-feedback">
                Enter your password.
                </div>
                </InputGroup>
                <div className="mb-4">
                <Button variant="primary" className="btn-padding text-white px-5 w-100" disabled={loading} onClick={this.onSubmit}>Sign In</Button>
                <Link to="/forgotPassword" className="text-grey font-weight-bolder mt-3 d-block text-center">Forgot password?</Link>
                </div>

                </div>
                </div>
                </section>
                </div>
                {/* } */}
            </Layout>
        }
       <ApprovalStatus
		openModal={showUserapproval}
		modalText="Sorry! Your account is not approved yet. Please try again later."
		/>
    </>
    )
    }
    }

    Login.propTypes = {
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        };
