import React, { useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import  helper from "../../../actions/validation";
import { Link } from 'react-router-dom';
import ShowMoreData from '../../Modal/ShowDataCommonModal';

const Review = ({review}) => {
      const [open, setOpen] = useState(false);
      const openModal=()=>{
          setOpen(true)
      }
     const closeModal = (e) => {
         e.stopPropagation()
         setOpen(false)
        
      }
      const ratingTitle =(ratingData)=>{
            switch (ratingData) {
                case 1:      
                return 'Very Poor '
                case 2:     
                return 'Poor ' 
                case 3: 
                return 'Average ' 
                case 4: 
                return 'Good ' 
                case 5: 
                return 'Very Good ' 
                default:
                    return console.log('no rating value');
                }
            }  
    const date = review?.created_at? helper.dateFormat(review.created_at) :'';
    const userReviews =review?.review
    const maximumLength = 150;
    return (
        <div className="review">
            <div>
               
                <h5 className='text-capitalize'>{review?.first_name} {review?.last_name.split("")[0]}</h5>
                <h5>{date}</h5>
            </div>
            
            
            <Rating name="read-only" value={review?.rating} readOnly />
            {userReviews.length > maximumLength?
                <>
                    <p>{`${userReviews.substring(0, maximumLength-100)}...`}</p>
                    
                        <Link
                            style={{ color: '#009BA7' }}
                            to='#'
                            onClick={openModal}>
                            Show More
                            <ShowMoreData
                            openModal={open}
                            data={userReviews}
                            ratingText={ratingTitle(review?.rating)}
                            closeModal={closeModal}
                            />
                        </Link>
                </> :
                <p>{userReviews}</p>
                
            }
        </div>
    )
}

export default Review;