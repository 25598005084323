import React, {Component} from 'react';
import _ from "lodash";
import {toast} from "react-toastify";
import auth from "../../actions/auth";
import {get, availability} from "../../services/Profile";
import LoadingSpinner from '../LoaderComponent/LoadingSpinner';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from "moment";
import interactionPlugin from '@fullcalendar/interaction'
import TutorAvailabiltyModal from '../Modal/TutorAvailabiltyModal';
import { getTutorAvailability, deleteTutorAvailability, deleteTutorSpecificAvailability} from "../../services/Tutor";
import Table from 'react-bootstrap/Table';
import { confirm } from "react-confirm-box";
import { Modal } from 'react-responsive-modal';
import $ from "jquery";
class ParentStudentSecurity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            day: ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            time: ['00:00:00', '01:00:00', '02:00:00', '03:00:00', '04:00:00', '05:00:00', '06:00:00', '07:00:00', '08:00:00', '09:00:00', '10:00:00', '11:00:00', '12:00:00', '13:00:00', '14:00:00', '15:00:00', '16:00:00', '17:00:00', '18:00:00', '19:00:00', '20:00:00', '21:00:00', '22:00:00', '23:00:00'],
            day_time_mapping: [],
            //validation
            isValid: false,
            loading: true,
            weekendsVisible: true,
            currentEvents: [],
            calendarStartDate:null,
            calendarEndDate:null,
            tutorAvailablity:[],
            addAvailbilty:false,
            selectDateInfo:null,
            initialEvents:null,
            addAvailbiltyForDay:false, 
            availabiltyType:null    
        }
    }
    componentDidMount() {
        //this.getProfile();
        this.getAvailabilty();
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    loading:false,
                    day_time_mapping: res.data.data.day_time_mapping
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            this.setState({
                loading:false
            })
            toast.error("Something went wrong!");
        })
    }

    onChange = (all=false, e) => {

        e.persist();
        const { day, time } = this.state;
        if(e.target && e.target.type === "checkbox") {
            if(all) {
                if (e.target.checked) {
                    
                    time.forEach((val, key) => {
                        if(this.state[e.target.name] && this.state[e.target.name].filter( item => item.day === day[e.target.value] && item.start_time === val ).length === 0 )

                        {
                            this.state[e.target.name].push({
                                day: day[e.target.value],
                                start_time: val
                            })
                        }
                    });

                    this.setState({
                        ...this.state,
                        [e.target.name]: this.state[e.target.name],
                        isValid: false
                    });
                    
                }else {
                    let data = _.reject(this.state[e.target.name], ['day', day[e.target.value]]);
                    this.setState({
                        ...this.state,
                        [e.target.name]: data,
                        isValid: false
                    });
                }
            }else {
                if (e.target.checked) {
                    let index = e.target.value.split("-");
                    this.state[e.target.name].push({
                        day: day[index[1]],
                        start_time: time[index[0]]
                    });
                    this.setState({
                        ...this.state,
                        [e.target.name]: this.state[e.target.name],
                        isValid: false
                    });
                }else {
                    let index = e.target.value.split("-");
                    let i = _.findIndex(this.state[e.target.name], {day: day[index[1]], start_time: time[index[0]]});
                    this.state[e.target.name].splice(i, 1);
                    this.setState({
                        ...this.state,
                        [e.target.name]: this.state[e.target.name],
                        isValid: false
                    });
                }
            }
        }
    };

    update = () => {
        const { day_time_mapping } = this.state;
        let input = {
            day_time_mapping: day_time_mapping,
        }
        this.setState({
            ...this.state,
            isValid: true,
            loading: true
        }, () => {
            if(day_time_mapping.length > 0) {
                availability(auth.check().id, input).then((res) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    if(res.status === 200) {
                        toast.success(res.data.message);
                    }else {
                        toast.error(res.data.message);
                    }
                }).catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Something went wrong!");
                })
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                })
                toast.error("Select min one slot before update!");
            }
        })
    }
      getDaysBetweenDates = (startDate, endDate)=> {
        var dates = [];
        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');
        while(currDate.add(1, 'days').diff(lastDate) < 0) {
          //  console.log(currDate.toDate());
            dates.push(currDate.clone().toDate());
        }
        return dates;
    };
     
    getAvailabilty=()=>{
        let specificDates = [];
        var tutorAvailablity = [];
        getTutorAvailability(auth.check().id).then((res) => {
           // console.log("res.data.data",res.data.data)
            Object.values(res.data.data).map((availability) => { 
                tutorAvailablity.push( {
                    id:availability.id,
                    title: availability.not_available ? "Not Available" :moment(availability.start_time, ["HH.mm"]).format("hh:mm a") +"-"+moment(availability.end_time, ["HH.mm"]).format("hh:mm a"),
                    start: moment(availability.available_date).format("YYYY-MM-DD"),
                    edit:true,
                    color: availability.not_available ?'#ef3e3e':'#5ee1e8',
                    size:'9px'
                });
                specificDates.push({"date":moment(availability.available_date).toLocaleString()}); //get the specific date timings
            })
            var dateList = this.getDaysBetweenDates(this.state.calendarStartDate, this.state.calendarEndDate);
            var days= [];
            dateList.map((val ) => {
                let includedInSpecific =false;
                includedInSpecific = _.findIndex(specificDates, {date: moment(val).toLocaleString()}) 
                if(includedInSpecific>=0){return;} //if the specific date timings available exclude week timings
                var dt = moment(val, "YYYY-MM-DD HH:mm:ss");
                var day = dt.format('dddd');
                var available =_.filter(res.data.day_time_mapping, {day: day})
                available.map((avail ) => {
                    tutorAvailablity.push({
                        id:avail.id,
                        title: moment(avail.start_time, ["HH.mm"]).format("hh:mm a") +"-"+moment(avail.end_time, ["HH.mm"]).format("hh:mm a"), 
                        start: moment(val).format("YYYY-MM-DD"),
                        edit:false,
                        color: '#7A7A7A'
                    });
                });
            
            });
            const INITIAL_EVENTS = tutorAvailablity;
            this.setState({
                ...this.state,
                loading:false,
                initialEvents:INITIAL_EVENTS,
                day_time_mapping:res.data.day_time_mapping
            })
            return INITIAL_EVENTS;
        })
    }
    
    handleWeekendsToggle = () => {
        this.setState({
          weekendsVisible: !this.state.weekendsVisible
        })
    }
    handleEventClick = async(clickInfo) => {
        const options = {
            render: (message, onConfirm, onCancel) => {
              return (
                <Modal open={true} closeOnOverlayClick={false} center onClose={onCancel}>
                <div className="modal-header">
                    <h4 className="modal-title">Confirm</h4>
                </div>
                <div className="modal-body">
                  <div className="confirmation-text"> {message} </div>
                  <div className="add-dialog-buttons">
                  <button className="btn btn-primary text-white" onClick={onCancel}> No </button>
                  <button className="btn btn-primary text-white" onClick={onConfirm}> Yes </button>
                  </div>
                </div>
                </Modal>
              );
            }
          };
        console.log(clickInfo?.event)
        if(clickInfo?.event?._def?.extendedProps?.edit){
            const isConfirm= await confirm('Are you sure you want to remove this entry?', options);
            if (isConfirm) {
                const id=clickInfo?.event?._def?.publicId?clickInfo?.event?._def?.publicId:null;
                if(id!=null){
                    deleteTutorSpecificAvailability(id).then((res) => {
                        if(res.status === 200) {
                            toast.success("Deleted Successfully!");
                        }else {
                            toast.error("Something went wrong!");
                        }
                        this.getAvailabilty();
                    });
                }else{
                    toast.error("Something went wrong!")
                }
            }
        }else{
            toast.warning("Cannot remove it from here. Please try from week calendar!");
        }
        
      }
      handleDateSelect = (selectInfo) => {
        //console.log("here")
        this.setState({
            addAvailbilty:true,
            selectDateInfo:selectInfo,
            availabiltyType:"specific"
        }) 
      }
      closeAvailbiltyModal = () => {
        this.setState({
            addAvailbilty:false
        })
    }
    addTime = (avaibleDay) => {
        this.setState({
            addAvailbilty:true,
            selectDateInfo:avaibleDay,
            availabiltyType:"common"
        }) 
    }
    removeTime = async(id) => {
         const options = {
            render: (message, onConfirm, onCancel) => {
              return (
                <Modal open={true} closeOnOverlayClick={false} center onClose={onCancel}>
                <div className="modal-header">
                    <h4 className="modal-title">Confirm</h4>
                </div>
                <div className="modal-body">
                  <div className="confirmation-text"> {message} </div>
                  <div className="add-dialog-buttons">
                  <button className="btn btn-secondary min-w-btn text-white" onClick={onCancel}> No </button>

                  <button className="btn btn-primary min-w-btn text-white" onClick={onConfirm}> Yes </button>
                  </div>
                </div>
                </Modal>
              );
            }
          };
         const isConfirm=  await confirm('Are you sure you want to remove this entry?', options);
        if (isConfirm) {
            deleteTutorAvailability(id).then((res) => {
                if(res.status === 200) {
                    toast.success("Deleted Successfully!");
                }else {
                    toast.error("Something went wrong!");
                }
                this.getAvailabilty();
            });
        }
       }
    render() {
        const { day, time, day_time_mapping, loading, isValid, addAvailbilty,selectDateInfo,initialEvents,availabiltyType } = this.state;
        return (
            <>
            {loading?<LoadingSpinner/>:
            <div className='demo-app'> 
            <p className='mb-2 form-group'><label>* This group has regular hours for every week </label></p>
            <div className="tutor-availability">
            <table  className='table table-bordered'>
      <thead>
                <tr className='d-flex'>
                <td scope="col" width="10" className='text-center w-14'>Sun</td>
                <td scope="col" width="10" className='text-center w-14'>Mon</td>
                <td scope="col" width="10" className='text-center w-14'>Tue</td>
                <td scope="col" width="10" className='text-center w-14'>Wed</td>
                <td scope="col" width="10" className='text-center w-14'>Thu</td>
                <td scope="col" width="10" className='text-center w-14'>Fri</td>
                <td scope="col" width="10" className='text-center w-14'>Sat</td>
                </tr>
                </thead>
                <tr className='d-flex'>
                {day.map((val, i) => {
                    return (<td  className='w-14' scope="row">
                        <span className="calendar-events-icon baseline pointer"  onClick={this.addTime.bind(this,val)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className=" w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                        {_.filter(day_time_mapping, ['day', val]).map((schedule, index) => (
                            <div  className="calendar-daygrid-event eventFont">
                                <a onClick={this.removeTime.bind(this,schedule.id)}>
                                {moment(schedule.start_time, ["HH.mm"]).format("hh:mm a") +"-"+moment(schedule.end_time, ["HH.mm"]).format("hh:mm a")}
                                </a>
                            </div>
                        ))}
                        </td>); 
                    })
                }
                </tr>
            </table>
            </div>
            <div className='demo-app-main mt-4 d-block'>
            <p className='mb-2 form-group'><label>*Override hours for specific dates?</label></p>
              <FullCalendar
                plugins={[dayGridPlugin,interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  //right: 'dayGridMonth'
                }}
                selectable={true}
                initialView='dayGridMonth'
                weekends={this.state.weekendsVisible}
                events={initialEvents} // alternatively, use the `events` setting to fetch from a feed
                datesSet={(dateInfo) => {
                    this.setState({
                        ...this.state,
                        calendarStartDate:dateInfo.start,
                        calendarEndDate:dateInfo.end,
                    }, () => {
                        this.getAvailabilty();
                    }); 
                    
                }}
                dayCellContent={(info, create) =>{ 
                    const element = create('span', { id: "fc-day-span-"+moment(info.date).format('X'), class:""}, info.dayNumberText);
                   return element;
                }}
                dayCellDidMount={(info) =>{
                    let element="<span class='fullcalendar-events-icon baseline pointer'><svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' className=' w-6 h-6'><path stroke-linecap='round' stroke-linejoin='round' d='M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z' /></svg></span>";
                    const selectDate = info.date;
                    const today = moment().format("YYYY-MM-DD");
                    //console.log("selectDate",selectDate) 
                    if(moment(selectDate).isSameOrAfter(today))
                    {
                        $('#fc-day-span-'+moment(info.date).format('X')).parent().parent().prepend(element);
                    } 
                    
                }}
                  eventClick={this.handleEventClick}
                  eventClassNames='eventFont'
                 // select={this.handleDateSelect}
                  select={(start, end, allDay) =>{
                   // console.log("start",start)
                    const selectDate = start.startStr;
                    const today = moment().format("YYYY-MM-DD");
                    //console.log("selectDate",selectDate)
                    //console.log("today",today)
                    if(moment(selectDate).isSameOrAfter(today))
                    {
                        this.handleDateSelect(start);
                    } 
                 }}
              />
            </div>
            <TutorAvailabiltyModal
                    open={addAvailbilty} 
                    info={selectDateInfo} 
                    handleClose={this.closeAvailbiltyModal}
                    getAvailabilty={this.getAvailabilty}
                    type={availabiltyType}
                />
          </div>
            // <div className="tutor-availability">
            //     <h5 className='bold serif text-grey mb-2'>Availability</h5>
            //     <p className='mb-5'>Tick times you are available to tutor.</p>
            //     <div className="availability-wrapper">
            //     <section className="availability-grid">
            //         <div className="day-row d-none d-md-block">
            //         <div className="day text-grey">MON</div>
            //         <div className="day text-grey">TUE</div>
            //         <div className="day text-grey">WED</div>
            //         <div className="day text-grey">THUR</div>
            //         <div className="day text-grey">FRI</div>
            //         <div className="day text-grey">SAT</div>
            //         <div className="day text-grey">SUN</div>
            //         </div>
            //         <div className="day-row d-grid d-md-none">
            //         <div className="day text-grey">M</div>
            //         <div className="day text-grey">T</div>
            //         <div className="day text-grey">W</div>
            //         <div className="day text-grey">T</div>
            //         <div className="day text-grey">F</div>
            //         <div className="day text-grey">S</div>
            //         <div className="day text-grey">S</div>
            //         </div>
            //         <div className="time-col">
            //         <div className="time"></div>
            //         <div className="time w-75 text-teal">Select entire column</div>
            //         <div className="time text-grey">12:00AM</div>
            //         <div className="time text-grey">1:00AM</div>
            //         <div className="time text-grey">2:00AM</div>
            //         <div className="time text-grey">3:00AM</div>
            //         <div className="time text-grey">4:00AM</div>
            //         <div className="time text-grey">5:00AM</div>
            //         <div className="time text-grey">6:00AM</div>
            //         <div className="time text-grey">7:00AM</div>
            //         <div className="time text-grey">8:00AM</div>
            //         <div className="time text-grey">9:00AM</div>
            //         <div className="time text-grey">10:00AM</div>
            //         <div className="time text-grey">11:00AM</div>
            //         <div className="time text-grey">12:00PM</div>
            //         <div className="time text-grey">1:00PM</div>
            //         <div className="time text-grey">2:00PM</div>
            //         <div className="time text-grey">3:00PM</div>
            //         <div className="time text-grey">4:00PM</div>
            //         <div className="time text-grey">5:00PM</div>
            //         <div className="time text-grey">6:00PM</div>
            //         <div className="time text-grey">7:00PM</div>
            //         <div className="time text-grey">8:00PM</div>
            //         <div className="time text-grey">9:00PM</div>
            //         <div className="time text-grey">10:00PM</div>
            //         <div className="time text-grey">11:00PM</div>
            //         </div>
            //         <div className="checkbox-grid">
            //         {
            //             day.map((val, i) => (
            //             <div className="custom-control custom-checkbox" key={i}>
            //                 <input type="checkbox" className="custom-control-input" id={`check_all_${i}`} name="day_time_mapping"
            //                 checked={_.filter(day_time_mapping, ['day', val]).length === 24}
            //                 value={i} onChange={this.onChange.bind(this, true)}/>
            //                 <label className={`custom-control-label checkbox-2 ${(isValid && (day_time_mapping.length > 0 ? '' : 'error is-invalid')).toString()}`} htmlFor={`check_all_${i}`}></label>
            //             </div>
            //             ))
            //         }
            //         {
            //             time.map((time, index) => (
            //                 day.map((day, key) => (
            //                     <div className="custom-control custom-checkbox" key={`${index}-${key}`}>
            //                         <input type="checkbox" className="custom-control-input" id={`check_${index}-${key}`}
            //                         checked={_.findIndex(day_time_mapping, {day: day, start_time: time}) > -1}
            //                         name="day_time_mapping" value={`${index}-${key}`} onChange={this.onChange.bind(this, false)}/>
            //                         <label className={`custom-control-label checkbox-2 ${(isValid && (day_time_mapping.length > 0 ? '' : 'error is-invalid')).toString()}`} htmlFor={`check_${index}-${key}`}></label>
            //                     </div>
            //                 ))
            //             ))
            //         }
            //         </div>
            //         <div className="ingrid-position d-flex justify-content-end mt-5">
            //         <button type="submit" className="btn btn-primary btn-padding--md text-white btn-sm-100"
            //             onClick={this.update}
            //             disabled={loading}
            //         >Update</button>
            //         </div>
            //     </section>
            //     </div>
            // </div>
            
    }
            </>
        );
    }
}

export default ParentStudentSecurity;