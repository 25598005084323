/* eslint-disable */
import React, {Component} from 'react';
import _ from "lodash"; 
import {get} from "../../services/Profile";   
import {toast} from "react-toastify";

class BookingDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            schedules:[],
            isValid: false,
            loading: false,
        }
    }

    componentDidUpdate(prevProp) {
        if(this.props.id && this.props.tutorId && this.props.id !== prevProp.id) {
        this.getProfile();
        }
    }

    getProfile = () => {
       const scheduleId = this.props.id;
        get(this.props.tutorId).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    schedules: _.filter(res.data.data.schedules, function(value) {
                        return (value.id==scheduleId);
                    })
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }
    close = () => {
        this.setState({
            ...this.state,
            schedules: []           
        });
       this.props.close();
    }

    

    render() {
        const { isValid, loading, schedules } = this.state;
        // const additionaldetail=  schedules.map((item)=>item.additional_details_for_achive)
        // const json = Object.assign({},additionaldetail);
         return (
            <div className="modal media-modal" id="bookingdetails">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">

                  <div className="modal-header">
                    <h4 className="modal-title text-grey">Student Details</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={this.close}>&times;</button>
                  </div>

                  <div className="modal-body">
                    
                    {schedules.length > 0 && schedules.map((list, index) => (
                        <div>
                        <div className="row">
                        <div className="col-md-4">Student Name</div>
                        <div className="col-md-1">:</div>
                        <div className="col-md-7">{list.studentdetails.first_name} {list.studentdetails.last_name}</div>
                        <div className="col-md-4">Phone</div>
                        <div className="col-md-1">:</div>
                        <div className="col-md-7">{list.studentdetails.phone_number}</div>
                        <div className="col-md-4">Email</div>
                        <div className="col-md-1">:</div>
                        <div className="col-md-7">{list.studentdetails.email}</div>
                        </div>
                        { (list.in_person==1) ? (
                        <div className="row">
                        <div className="col-md-4">Address</div>
                        <div className="col-md-1">:</div>
                        <div className="col-md-7">{list.address}</div>
                        <div className="col-md-4">City</div>
                        <div className="col-md-1">:</div>
                        <div className="col-md-7">{list.city}</div>
                        <div className="col-md-4">State</div>
                        <div className="col-md-1">:</div>
                        <div className="col-md-7">{list.state}</div>
                        <div className="col-md-4">Zip code</div>
                        <div className="col-md-1">:</div>
                        <div className="col-md-7">{list.zip_code}</div>
                        </div>
                        ):null}
                          <div className="row">
                        <div className="col-md-4">What do hope to achieve</div>
                        <div className="col-md-1">:</div>
                        <div className="col-md-7">{list.additional_details_for_achive}</div>
                        <div className="col-md-4">Struggling topics</div>
                        <div className="col-md-1">:</div>
                        <div className="col-md-7">{list.struggle_topics}</div>
                        <div className="col-md-4">Struggling details</div>
                        <div className="col-md-1">:</div>
                        <div className="col-md-7">{list.struggle_details}</div>

                        </div>    
                        </div>                          
                        ))
                    }
                    
                  </div>

                </div>
              </div>
            </div>
        );
    }
}

export default BookingDetails;