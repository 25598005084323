/* eslint-disable */
import React, { Component } from "react";
import TutorLayout from "../../components/TutorLayout";
import { FiCheck } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import {get,getUpcoming,getPending,getCompleted} from "../../services/Profile";
import Calendar from 'react-calendar';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Badge from "../../components/Tutor/Badge";
import RequestAlert from '../../components/Tutor/RequestAlert';
import NoticeBoard from '../../components/Notification/NoticeBoard';
import auth from "../../actions/auth";
import {toast} from "react-toastify";
import moment from "moment";
import _, { toInteger } from  "lodash";
import { withRouter } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { formatStartEndTime } from '../../helpers/utils';
import LoadingSpinner from '../LoaderComponent/LoadingSpinner';
import { Link } from "react-router-dom"; 
import { checkProfileCompletion } from "../../services/Tutor";
import ApprovalStatus from "../Modal/ApprovalStatusModal"; 
import BookingDetails from "../Modal/BookingDetails";
import $ from "jquery";

import InfiniteScroll from "react-infinite-scroll-component";

class TutorDashboard extends Component {

	constructor(props) {
        super(props);
		const upcomingPerRow  = 4;

        this.state = {
			pending: [],
			upcoming: [],
			completed: [],
            scheduleLoading: true,
            trigger: false,
			isProfileComplete: false,
			approved:null,
			openModal:false,
			userRole:null,
			isOpen:[],
			dayTimeMapping: [],
			selectedId: null,
            selectedTutorId: null,
			completedCurrentPage: 1,
			completedSessionhasMore: true,
			upcomingCurrentPage: 1,
			upcomingSessionhasMore: true,
			pendingSessionCurrentPage: 1,
			pendingSessionhasMore: true
        }
    }
	getProfileCompletion=(data)=>{
		 const getCompletion = checkProfileCompletion(data);
		 return getCompletion;
	}
    componentDidMount() {
        this.getProfile();
		this.getUpcomingSessions(true);
		this.getPendingSessions(true);
		this.getCompletedSessions(true);
    }

	

	gotoApprovalmodal=()=>{
		this.setState({
			openModal:true,
		})
    }

    getProfile = () => {
		console.log('get profile',auth.check().id)
        get(auth.check().id).then((res) => {
			const currentTime =  moment().format(); 
            if(res.status === 200) {
				const data = res.data.data;
				const approved = res.data.data.approved;
				const userRole = res.data.data.role_id;
				const isProfileComplete = this.getProfileCompletion(data);
                this.setState({
					...this.state,
 
					// pending: _.filter(res.data.data.schedules, function(value){
					// 	return(value.progression_status == 0 && value.approved == 0 && value.rejected == 0 && value.cancel==0 && value.rescheduled_by!=auth.check().id)
					// }),
					// upcoming: _.filter(res.data.data.schedules, function(value) {
					// 	return (value.is_completed==0 && value.approved==1 && (moment.utc(value.start_time_utc).local().format()> currentTime));
					//   }), 
					  
					// completed: _.filter(res.data.data.schedules, function(value){
						
					// 	return(value.is_completed==1 && value.approved==1 &&(
					// 		moment().startOf('isoWeek').subtract(1, 'days').format() < moment.utc(value.end_time_utc).local().format() && 
					// 		moment.utc(value.end_time_utc).local().format() < moment().endOf('isoWeek').subtract(1, 'days').format()
							
					// 	))
					// },
					// ),
                    scheduleLoading: false,
					isProfileComplete: isProfileComplete,
					approved:approved,
					userRole:userRole

				});
				if(!isProfileComplete){
					this.completeProfile();
				}
				else
				{ 
					
					if(approved === 0)
					{
					 this.gotoApprovalmodal();
					}
					
				}
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
			console.log(err)
            toast.error("Something went wrong!");
        })
    }

	getUpcomingSessions  =(initial = false) => {
		if(initial){
      getUpcoming(auth.check().id,this.state.upcomingCurrentPage).then((res) => {
        
        this.setState({
          ...this.state, 
          upcoming: res.data.data.session_scheduled
        });
      });
    }else{
      getUpcoming(auth.check().id,this.state.upcomingCurrentPage+1).then((res) => {
        if (res.data.data.session_scheduled <=0 ) {
          this.setState({ ...this.state,upcomingSessionhasMore: false ,scheduleLoading: false});
          return;
        }
        this.setState({
          ...this.state,
          upcomingCurrentPage:this.state.upcomingCurrentPage+1,
          upcoming: this.state.upcoming.concat(res.data.data.session_scheduled),
		  scheduleLoading: false
        });
      });
    }
	}
	getPendingSessions  =(initial = false) => {
		if(initial){
			getPending(auth.check().id,this.state.pendingSessionCurrentPage).then((res) => {			  
			  this.setState({
				...this.state, 
				pending: res.data.data.session_scheduled
			  });
			});
		  }else{
			getPending(auth.check().id,this.state.pendingSessionCurrentPage+1).then((res) => {
			  if (res.data.data.session_scheduled <=0 ) {
				this.setState({ ...this.state,pendingSessionhasMore: false });
				return;
			  }
			  this.setState({
				...this.state,
				pendingSessionCurrentPage:this.state.pendingSessionCurrentPage+1,
				pending: this.state.pending.concat(res.data.data.session_scheduled) 
			  });
			});
		  }
	}
	getCompletedSessions  =(initial = false) => {
		if(initial){
		  getCompleted(auth.check().id,this.state.completedCurrentPage).then((res) => {
			
			this.setState({
			  ...this.state, 
			  completed: res.data.data.session_scheduled
			});
		  });
		}else{
		  getCompleted(auth.check().id,this.state.completedCurrentPage+1).then((res) => {
			if (res.data.data.session_scheduled <=0 ) {
			  this.setState({ ...this.state,completedSessionhasMore: false });
			  return;
			}
			this.setState({
			  ...this.state,
			  completedCurrentPage:this.state.completedCurrentPage+1,
			  completed: this.state.completed.concat(res.data.data.session_scheduled) 
			});
		  });
		}
			
		}
	

	openAlert = () => {
		this.setState({
			trigger: true
		})
	}

	closeAlert = () => {
		this.setState({
			trigger: false
		})
	}

	completeProfile= () => {
        const {history} = this.props;
        history.push('/tutor/add-profile');
    }
    openViewProfile= (id, tutorId) => {
		this.setState({
            ...this.state,
            selectedId: id,
            selectedTutorId: tutorId
        }, () => {
            $("#bookingdetails").modal();
        });
	}
	bookingDetailsClose = () => {
        this.setState({
            ...this.state,
            selectedId: null,
            selectedTutorId: null
        }, () => {
            $("#bookingdetails").modal('hide');
        });
    }
	render() { 
		const { scheduleLoading, upcoming, pending,listInnerRef, completed, isProfileComplete,openModal,userRole, next, selectedId, selectedTutorId } = this.state;
        console.log('completed session',completed)
		return (
			<>
			{scheduleLoading? <LoadingSpinner/>:
			<TutorLayout>
				<div className="tutor-auth-container">
					{!isProfileComplete && (
						<div className="row">
							<div className="col-md-12">
								<Alert variant="primary"className="profile-info-alert">
									<p>For book a tutor please complete your profile.</p>
									<Button variant="primary" onClick={this.completeProfile}>Complete Profile</Button>
								</Alert>
							</div>
						</div>
					)}
					<div className="tutor-dashboard-grid">
						<section className="mb-4">
							<div className="stat-card-grid">
								<div className="stat-card teal">
										<h6>Upcoming Tutorials</h6>
										<h2>{upcoming.length}</h2>
								</div>
								<div className="stat-card ">
									{/* <h6>Number of Tutorial Hours Tracked Last Month</h6> */}
									<h6>Completed Tutorial This Week</h6>
									<h2>{completed.length}</h2>
								</div>
								<div className="stat-card grey">
									<h6>Pending Tutorial Requests</h6>
									<h2>{pending.length}</h2>
								</div>
							</div>
							<div className="upcoming-completed-grid">
									<div className="card upcoming-card" style={{ minHeight: 600 }}>
										<div className="card-header py-3 px-4">
											<h6 className="mb-0 text-teal">Upcoming sessions</h6>
										</div>
										<div className="card-body">
										<InfiniteScroll
											dataLength={upcoming.length}
											next={this.getUpcomingSessions}
											hasMore={this.state.upcomingSessionhasMore}
											loader={<h4>Loading...</h4>}
											//   height={400}
											endMessage={
												<p className="text-center mt-4 d-block">
												<b>Yay! You have seen it all</b>
												</p>
											}
											>
											{
												!scheduleLoading && upcoming.length > 0 && upcoming.map((list, i) => (
													<div className='upcoming-row gap-0' key={i}>
														<p className='text-grey font-weight-bold align-self-start'>{i+1}.</p>
														<div className='txt-14--light'>
															<div className="d-flex align-items-start  mb-3">
																<p className='text-grey mb-2 mr-2 text-capitalize'>
																	{list.studentdetails.first_name} {list.studentdetails.last_name} 
																</p> 
																<div className="d-flex flex-column justify-content-center align-items-center pointer" onClick={this.openViewProfile.bind(this, list.id, list.tutordetails.id)}>
																	<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
																		<path fill="none" stroke="#000" stroke-width="2" d="M12,17 C9.27272727,17 6,14.2222222 6,12 C6,9.77777778 9.27272727,7 12,7 C14.7272727,7 18,9.77777778 18,12 C18,14.2222222 14.7272727,17 12,17 Z M11,12 C11,12.55225 11.44775,13 12,13 C12.55225,13 13,12.55225 13,12 C13,11.44775 12.55225,11 12,11 C11.44775,11 11,11.44775 11,12 Z"/>
																	</svg>
                                                             	</div>
																{/*<Badge type="pin" badgeText={list.online ? 'Online' : 'In-person'} fontStyle='normal' className="d-none d-md-block" />*/}
															</div>
															<div className="badge-grid">
																<Badge type="teal" badgeText={formatStartEndTime(list.start_time_utc, list.end_time_utc)} fontStyle="bold"  className='mr-2'/>
																<Badge type="clear" badgeText={moment(list.schedule_date).format('LL')} className="mr-2"/>
																<Badge type="pin" badgeText={list.online ? 'Online' : 'In-person'} fontStyle='normal' className="d-block" />
															</div>
														</div>
														<div className='image-group'>
															{
                                                                list.tutordetails.details.profile_picture ? 
                                                                <img src={list.tutordetails.details.profile_picture} alt="tutor-avatar" style={{ borderRadius: '50%'}}/>
                                                                :
																<div className="icn-circle primary-dark font-weight-bold mr-2 text-capitalize">{list.tutordetails.first_name.split("")[0]}</div>
                                                            }
															{
                                                                list.studentdetails.student_details.profile_picture ? 
                                                                <img src={list.studentdetails.student_details.profile_picture} alt="student-avatar" style={{borderRadius: '50%'}}/>
                                                                :
																<div className="icn-circle primary-dark font-weight-bold mr-2 text-capitalize">{list.studentdetails.first_name.split("")[0]}</div>
                                                            }
														</div>
														{/* <div className='pointer' onClick={this.openAlert}>
															<BsThreeDotsVertical />
														</div> */}
													</div>
												))
											}
											{
                                                !scheduleLoading && upcoming.length === 0 &&
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className=" font-weight-bold text-grey">No Records !</p>
                                                </div>
                                            }
                                            {
                                                scheduleLoading &&
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className=" font-weight-bold">Loading...</p>
                                                </div>
                                            }
											</InfiniteScroll>
										</div>
									</div>
								    
									<div className="card upcoming-card" style={{ minHeight: 600 }}>
										<div className="card-header py-3 px-4">
											<h6 className="mb-0 text-teal">Completed Sessions</h6>
										</div>
										<div className="card-body">
										<InfiniteScroll
											dataLength={completed.length}
											next={this.getCompletedSessions}
											hasMore={this.state.completedSessionhasMore}
											loader={<h4>Loading...</h4>}
											//   height={400}
											endMessage={
												<p className="text-center mt-4 d-block">
												<b>Yay! You have seen it all</b>
												</p>
											}
											>
											{
												!scheduleLoading && completed.length > 0 && completed.map((list, i) => (
												<div className='upcoming-row' key={i}>
													<div className='text-teal font-weight-bold align-self-start'>
														<FaCheckCircle  />
													</div>
													<div className='txt-14--light'>
														<div className="d-flex align-items-start  mb-3">
															<p className='text-grey mb-2 mr-2 text-capitalize'>
																{list.studentdetails.first_name} {list.studentdetails.last_name}
															</p>
															<div className="d-flex flex-column justify-content-center align-items-center pointer" onClick={this.openViewProfile.bind(this, list.id, list.tutordetails.id)}>
																<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
																	<path fill="none" stroke="#000" stroke-width="2" d="M12,17 C9.27272727,17 6,14.2222222 6,12 C6,9.77777778 9.27272727,7 12,7 C14.7272727,7 18,9.77777778 18,12 C18,14.2222222 14.7272727,17 12,17 Z M11,12 C11,12.55225 11.44775,13 12,13 C12.55225,13 13,12.55225 13,12 C13,11.44775 12.55225,11 12,11 C11.44775,11 11,11.44775 11,12 Z"/>
																</svg>
                                                             </div>
 															{/*<Badge type="pin" badgeText={list.online ? 'Online' : 'In-person'} fontStyle='normal' className="d-none d-md-block" />*/}
														</div>
														<div className="badge-grid">
															<Badge type="clear" badgeText={formatStartEndTime(list.start_time_utc, list.end_time_utc)} fontStyle="bold"  className='mr-2'/>
															<Badge type="clear" badgeText={moment(list.schedule_date).format('LL')} className="mr-2"/>
															<Badge type="pin" badgeText={list.online ? 'Online' : 'In-person'} fontStyle='normal' className="d-block" />
														</div>
													</div>
													<div className='image-group'>
														{
															list.tutordetails.details.profile_picture ? 
															<img src={list.tutordetails.details.profile_picture} alt="tutor-avatar" style={{borderRadius: '50%'}}/>
															:
															<div className="icn-circle primary-dark font-weight-bold mr-2 text-capitalize">{list.tutordetails.first_name.split("")[0]}</div>
														}
														{
															list.studentdetails.student_details.profile_picture ? 
															<img src={list.studentdetails.student_details.profile_picture} alt="student-avatar" style={{borderRadius: '50%'}}/>
															:
															<div className="icn-circle primary-dark font-weight-bold mr-2 text-capitalize">{list.studentdetails.first_name.split("")[0]}</div>
														}
													</div>
												</div>
												))
											}
											{
                                                !scheduleLoading && completed.length === 0 &&
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className=" font-weight-bold text-grey">No Records !</p>
                                                </div>
                                            }
                                            {
                                                scheduleLoading &&
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className=" font-weight-bold">Loading...</p>
                                                </div>
                                            }
											 </InfiniteScroll>
										</div>
									</div>
							</div>
							<div className="d-block d-md-none">
								<Button variant="primary" className="text-white w-100">
									<Link to="/session"> See Calendar </Link>
								</Button>							
							</div>
						</section>
						<section className="right-section d-none d-md-flex d-lg-block">
							{/* <Calendar className='cal-sidebar' /> */}
							{console.log(userRole)}
							<NoticeBoard 
							notification={pending} 
							history={this.props.history}
							role='tutor'
							/>
						</section>
					</div>
				</div>
				<BookingDetails 
	              id={selectedId} 
			      tutorId={selectedTutorId}
				  close={this.bookingDetailsClose}  
				  next={this.getProfile}
				/>
				{/* <RequestAlert show={trigger} closeFunc={this.closeAlert} /> */}
			</TutorLayout>
		} 		
		<ApprovalStatus
		openModal={openModal}
		modalText="Sorry! Your account is not approved yet. Please try again later."
		/>

	</>
		);
	}
}

export default withRouter(TutorDashboard);