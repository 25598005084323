/* eslint-disable */
import React, {Component} from 'react';
import _ from "lodash";
import {toast} from "react-toastify";
import {changeStatus} from "../../services/Tutor";
import validation from "../../actions/validation";
import $ from "jquery";

class Reject extends Component {

    constructor(props) {
        super(props);
        this.state = {
          rejected_comment: "",

          //validation
          isValid: false,
          loading: false,
        }
    }

    componentDidUpdate(prevProp) {
      if(this.props.id !== prevProp.id) {
        this.setState({
          ...this.state,
          rejected_comment: "",
          isValid: false,
          loading: false,
        });
      }
    }

    status = () => {
      this.setState({
        isValid: true,
        loading: true
      }, () => {
        if(validation.text(this.state.rejected_comment)) {
          let input = {
            id: this.props.id,
            rejected: 1,
            rejected_comment: this.state.rejected_comment,
            userRole: this.props.userRole,
          }
          changeStatus(input).then((res) => {
            this.setState({
              ...this.state,
              loading: false
            });
            if(res.status === 200) {
                toast.success("Rejected");
                $("#reject-request").modal('hide');
                this.props.next();
            }else {
                toast.error("Something went wrong!");
            }
          }).catch((err) => {
            this.setState({
              ...this.state,
              loading: false
            });
            toast.error("Something went wrong!");
          });
        }else {
          this.setState({
            ...this.state,
            loading: false
          })
          toast.error("Validation Error!");
        }
      });
    }

    render() {
        
        return (
            <div className="modal media-modal" id="reject-request" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog modal-sm">
                <div className="modal-content">

                  <div className="modal-header">
                    <h4 className="modal-title">Reject Request</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={this.props.close}>&times;</button>
                  </div>

                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="rejected_comment">Comment (Required)</label>
                      <textarea className={`form-control ${(this.state.isValid && (validation.text(this.state.rejected_comment) ? '' : 'error is-invalid')).toString()}`} name="rejected_comment" id="rejected_comment" rows="3" value={this.state.rejected_comment} onChange={(e) => this.setState({...this.state, rejected_comment: e.target.value})}></textarea>
                    </div>
                    <button className="btn btn-primary text-white float-right" onClick={this.status} disabled={this.state.loading}>Confirm</button>
                  </div>

                </div>
              </div>
            </div>
        );
    }
}

export default Reject;