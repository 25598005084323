/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import Select from 'react-select'
import { FiUser, FiCheck, FiLock, FiPhoneForwarded } from "react-icons/fi";
import { langaugeForspeak, monthsInnumber, racicalCategories } from "../../config/variables";
import { tutorServiceList } from "../../services/Common";

class TutorS5 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			work_in_usa: props.payload.additional_details?props.payload.additional_details.work_in_usa : "",
            criminal_records: props.payload.additional_details?props.payload.additional_details.criminal_records :"",
            student_with_disability: props.payload.additional_details?props.payload.additional_details.student_with_disability :"",
            reliable_transportation: props.payload.additional_details?props.payload.additional_details.reliable_transportation :"",
            group_tutoring: props.payload.additional_details?props.payload.additional_details.group_tutoring :"",
            home_school_help: props.payload.additional_details?props.payload.additional_details.home_school_help :"",
            required_hours: props.payload.additional_details?props.payload.additional_details.required_hours :"",
            languages: props.payload.additional_details?props.payload.additional_details.languages :"",
            other_informations: props.payload.additional_details?props.payload.additional_details.other_informations :"",
			how_long: props.payload.additional_details?props.payload.additional_details.how_long :"",
            tutor_service: props.payload.additional_details?props.payload.additional_details.tutor_service :[],
            serviceList: [],
            //validation
			isValid: false,
			loading: false,
            interestedServices: []
		};
	}
    componentDidMount() {
        this.fetchServiceList();
    }

    fetchServiceList = () => {
        tutorServiceList().then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    serviceList: Object.values(res.data.services)
                })
                if(this.state.serviceList.length > 0) {
                    this.state.serviceList.map((service, key) => {
                    this.state.interestedServices.push({"value": service.id, "label": service.services});
                  })
                }
            }
        }).catch((err) => {
            console.log(err);
        })
    }
	onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };
    handleMultiChange  = (option) =>  {
        this.setState(
        {
          ...this.state, 
          tutor_service: option,
          isValid: false
        })

      }
	onSubmit = () => {
        const { work_in_usa, criminal_records, student_with_disability, reliable_transportation, group_tutoring, home_school_help, required_hours, languages, other_informations,how_long, tutor_service } = this.state;
        this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
			if(validation.text(work_in_usa)
                && validation.text(criminal_records)
                && validation.text(student_with_disability)
                && validation.text(reliable_transportation)
                // && validation.text(group_tutoring)
                // && validation.text(home_school_help) 
                && validation.text(languages)
                && validation.text(other_informations)
                && validation.text(how_long)
                && validation.text(tutor_service)
			) {
				const data = JSON.parse(JSON.stringify(this.state));
				this.props.getData(_.omit(data, ['isValid', 'loading']));
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Validation Error!")
			}
		});
	}

	render() {
		const { work_in_usa, criminal_records, student_with_disability, reliable_transportation, group_tutoring, home_school_help, required_hours, languages, other_informations, isValid, loading,how_long, tutor_service,serviceList,interestedServices} = this.state;
      
		return(
		<React.Fragment>
                <div id='signup-form tutor-step'>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='work_in_usa' >Are you eligible to work in the USA?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(work_in_usa) ? '' : 'error is-invalid')).toString()}`}
                                    id="work_in_usa" name="work_in_usa" value={work_in_usa}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='criminal_records'>If hired, do you consent to having a background check performed?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(criminal_records) ? '' : 'error is-invalid')).toString()}`}
                                    id="criminal_records" name="criminal_records" value={criminal_records} onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='student_with_disability'>Have you worked with students with disabilities?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(student_with_disability) ? '' : 'error is-invalid')).toString()}`}
                                    id="student_with_disability" name="student_with_disability" value={student_with_disability}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='reliable_transportation' >If interested in in-person tutoring, do you have reliable transportation?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(reliable_transportation) ? '' : 'error is-invalid')).toString()}`}
                                    id="reliable_transportation" name="reliable_transportation" value={reliable_transportation}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>
                    {/* <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='group_tutoring' >Are you interested in group tutoring?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(group_tutoring) ? '' : 'error is-invalid')).toString()}`}
                                    id="group_tutoring" name="group_tutoring" value={group_tutoring}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='home_school_help'>Are you interested in home schooling help?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(home_school_help) ? '' : 'error is-invalid')).toString()}`}
                                    id="home_school_help" name="home_school_help" value={home_school_help}
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div> */}
                    <div className='form-row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor='how_long'>How long can you commit to tutoring with us?<span className="sub-text">(in months)</span></label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(how_long) ? '' : 'error is-invalid')).toString()}`}
                                    id="how_long" 
                                    value={how_long}
                                    name="how_long"
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                {
                                    monthsInnumber.map((months, key) => (
                                        <option key={key} value={months}>{months}</option>
                                    ))
                                }
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label htmlFor='languages'>What languages do you speak?</label>
                            <select className={`custom-select form-control light ${(isValid && (validation.text(languages) ? '' : 'error is-invalid')).toString()}`}
                                    id="languages" 
                                    value={languages}
                                    name="languages"
                                    onChange={this.onChange}>
                                <option value="">Choose One</option>
                                {
                                    langaugeForspeak.map((langauges, key) => (
                                        <option key={key} value={langauges}>{langauges}</option>
                                    ))
                                }
                            </select>
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                    <div className='form-group col-md-6'>
                            <label htmlFor='other_informations'>Any other information we should know?</label>
                            <input
                                type='text'
                                name='other_informations'
                                id="other_informations"
                                value={other_informations}
                                onChange={this.onChange}
                                className={`form-control light ${(isValid && (validation.text(other_informations) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div>
                     
                        <div className='form-group col-md-6'>
                            <label htmlFor='tutor_service'>What type of service are you interested in?</label>
                            {/* <select 
                                className={`custom-select form-control light ${(isValid && (validation.text(tutor_service) ? '' : 'error is-invalid')).toString()}`}
                               name="tutor_service" value={tutor_service}
                                onChange={this.onChange}
                            >                                 
                                <option value="">Choose One</option>
                                {
                serviceList.length > 0 && serviceList.map((service, key) => { 
                    const includesValue = tutor_service.includes(service.id)
                    return (
                                <option key={key} value={service.id} selected={includesValue}>{service.services}</option>
                    )})}
                            </select> */}
                   
                    
                            <Select
                                id='struggle_topics' 
                                name="tutor_service"
                                closeMenuOnSelect={true} 
                                isMulti 
                                options={interestedServices}                                
                                className={`form-control h-auto border-0 p-0 ${(isValid && (validation.text(tutor_service) ? '' : 'error is-invalid')).toString()}`}
                                onChange={this.handleMultiChange}
                            />
                     

                            <div className="invalid-feedback">
                                This field is required.
                            </div>
                        </div> 
                    </div>   
                    <div className='d-flex justify-content-center align-items-center my-5'>
                        <button
                            disabled={loading}
                            className='btn btn-primary btn-padding--lg text-white'
                            onClick={this.onSubmit}
                        >
                            Continue
                        </button>
                    </div>
                </div>
		</React.Fragment>
		);
	}
}

export default TutorS5;