import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import BlankAuthLayout from '../components/BlankAuthLayout';
import guard from "../actions/guard";
import {useHistory, Link} from 'react-router-dom';
import TutorDetail from '../components/BookTutorDetail/TutorInfo';
import TutorReviews from '../components/BookTutorDetail/TutorReviews';
import { findTutorById } from "../services/Tutor";
import './BookTutorDetail.css';
import LoadingSpinner from '../components/LoaderComponent/LoadingSpinner';
import {getTutorReviews} from "../services/Rating";
const BookTutorDetail = () => {

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(4);
    const [tutorDetail, setTutorDetail] = useState(null);
    const [tutorReviews, setTutorReviews] = useState({});
    const[loading,setLoading]=useState(true);
    const history = useHistory();
    const {id} = useParams();
    useEffect(() => {
        if (guard.role() === "tutor") {
            history.push('/dashboard')
        } else {
            if(!id) return;
            getTutorById(id);
        }
    }, [history, id]);

    const getTutorById = (id) => {
        findTutorById(id).then(res => {
            setTutorDetail(res.data.data)
            getTutorReviewsById(id);
            setLoading(false)

        })
    }
    const getTutorReviewsById = (id) => {
        getTutorReviews(id).then(res => {
            setTutorReviews(res.data.data)
        })
    }

    

    const back= () => {
        history.push('/tutor/schedule');
    }

    const bookTutor = () => {
        history.push('/tutor/schedule/'+id);
    }

    return (
        <>
        {loading?<LoadingSpinner/>:
        <BlankAuthLayout>
            <div className="tutor-detail-container">
                <header className='header'>
                        <div className="logo-wrapper">
                            <Link to="/">
                                <img src="/images/logo.svg" alt="site-logo"/>
                            </Link>
                        </div>
                        <h4 className="title d-none d-md-block">Tutor’s Profile</h4>
                        <button className="btn btn-primary btn-padding--md text-white mb-3 mx-0 mx-md-0 text-capitalize"
                        onClick={back}>Back to Results</button>
      
                        
                </header>
                {tutorDetail && (<>
                    <h4 className="title d-block d-md-none">Tutor’s Profile</h4>
                    <TutorDetail value={value} bookTutor={bookTutor} tutorDetail={tutorDetail} tutorReviews={tutorReviews}/>
                    <TutorReviews tutorReviews={tutorReviews}/>
                </>)}
                
            </div>
        </BlankAuthLayout>
}
        </>
    );
}

export default BookTutorDetail;