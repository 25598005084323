import React from 'react';
import Review from './Review';
import AverageRating from './AverageRating';
import WhatRatingMeans from './WhatRatingMeans';
import './index.css';

const TutorReviews = ({tutorReviews}) => {  
    const tutorAvgRating = tutorReviews?.avgRating ? tutorReviews.avgRating : 0;
    const usersRatingCount = tutorReviews?.usersRatingCount ? tutorReviews.usersRatingCount : [];
    return (
        <div className="tutor-review">
            <h3 className="title">
                Most recent reviews
            </h3>
            <div>
                <div className="reviews-container">
                    {tutorReviews?.reviews?.map(review => (
                        <Review review={review} />
                    ))}
                </div>
                <div className="rating-info">
                    <AverageRating tutorAvgRating={tutorAvgRating} usersRatingCount={usersRatingCount}/>
                    <WhatRatingMeans />
                </div>
            </div>
        </div>
    )
}

export default TutorReviews;