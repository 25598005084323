import axios from "axios";
import cookie from "../actions/cookie.js";
import { API_END_POINT } from "../config/env.json";

export const get = (uid) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/profile/${uid}`, options)
        .then(res => res)
        .catch(error => error);
};

export const getUpcoming = (uid,currentPage) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
        
    };
    return axios.get(`${API_END_POINT}/v1/upcoming/${uid}/${currentPage}`, options)
        .then(res => res)
        .catch(error => error);
    
};
export const getPending = (uid,currentPage) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
        
    };
    return axios.get(`${API_END_POINT}/v1/pending/${uid}/${currentPage}`, options)
        .then(res => res)
        .catch(error => error);
    
};
export const getCompleted = (uid,currentPage) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
        
    };
    return axios.get(`${API_END_POINT}/v1/completed/${uid}/${currentPage}`, options)
        .then(res => res)
        .catch(error => error);
    
};
export const getCanceled = (uid,currentPage) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
        
    };
    return axios.get(`${API_END_POINT}/v1/cancel/${uid}/${currentPage}`, options)
        .then(res => res)
        .catch(error => error);
}
export const put = (uid, payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/update-profile/${uid}`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const updatePass = (uid, payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/profile-change-password/${uid}`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const availability = (uid, payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/availability/${uid}`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const updateBankDetails = (uid, payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.post(`${API_END_POINT}/v1/bank-details/${uid}`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const updateProfile = (payload, userId) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/update-profile/${userId}`, payload, options)
        .then(res => res)
        .catch(err => err);
};

export const updateDependentStudentPass = (uid, payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/update-student-password`, payload, options)
        .then(res => res)
        .catch(error => error);
};

export const updateFromForgotPassword = (payload) => {
    const options = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return axios.put(`${API_END_POINT}/v1/updatePassword`, payload, options)
        .then(res => res)
        .catch(err => err);
};

export const getUsercredits = (uid) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.get(`${API_END_POINT}/v1/getAll-credits-data/${uid}`, options)
        .then(res => res)
        .catch(error => error);
};

export const updateLastAccess = (payload=[]) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookie.getCookie('_token')}`,
        }
    };
    return axios.put(`${API_END_POINT}/v1/update-last-access`, payload, options)
        .then(res => res)
        .catch(error => error);
};