/* eslint-disable */
import React, { Component } from 'react';
import TutorLayout from "../../components/TutorLayout";
import TutorProfile from "../../components/Profile/TutorProfile";
import TutorAvailability from "../../components/Profile/TutorAvailability";
import TutorBank from "../../components/Profile/TutorBank";
import TutorSecurity from "../../components/Profile/TutorSecurity";

class Tutor extends Component {

  state = {
    tab: 'profile',
  }

  changeTab = (tab) => {
    this.setState({
      ...this.state,
      tab
    });
  }

  render() {
    const { tab } = this.state;
    
    return (
      <TutorLayout>
        <div className="tutor-auth-container p-0">
          <section className="tutor-profile-grid">
          <a className={` d-flex align-items-center back-to-account d-md-none ${tab === 'back' ? 'active' : ''}`} href='/dashboard'>
             <svg width="10" height="14" className="mr-2" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.84359 6.00254L7.33276 1.51317C7.45637 1.38985 7.52437 1.22498 7.52437 1.04917C7.52437 0.873268 7.45637 0.708488 7.33276 0.584976L6.93939 0.191805C6.81598 0.0680976 6.651 0 6.4752 0C6.29939 0 6.13461 0.0680976 6.0111 0.191805L0.666027 5.53678C0.542027 5.66068 0.474124 5.82624 0.474612 6.00224C0.474124 6.17902 0.541929 6.34439 0.666027 6.46839L6.00612 11.8082C6.12964 11.9319 6.29442 12 6.47032 12C6.64612 12 6.8109 11.9319 6.93451 11.8082L7.32778 11.415C7.58368 11.1591 7.58368 10.7425 7.32778 10.4867L2.84359 6.00254Z" fill="#2A2A2A"/>
            </svg>
            Back to Account
          </a>
            <aside className="tutor-profile-header">
              <button className={`tutor-profile-tab ${tab === 'profile' ? 'active' : ''}`} onClick={this.changeTab.bind(this, 'profile')}>Profile Settings</button>
              <button className={`tutor-profile-tab ${tab === 'availability' ? 'active' : ''}`} onClick={this.changeTab.bind(this, 'availability')}>Availability</button>
              {/* <button className={`tutor-profile-tab ${tab === 'bank' ? 'active' : ''}`} onClick={this.changeTab.bind(this, 'bank')}>Bank Details</button> */}
              <button className={`tutor-profile-tab ${tab === 'security' ? 'active' : ''}`} onClick={this.changeTab.bind(this, 'security')}>Security & Notification</button>
              <a className={`tutor-profile-tab d-none d-md-block ${tab === 'back' ? 'active' : ''}`} href='/dashboard'>Back</a>
            </aside>
            <div className='profile-container'>
              <div className="profile-body">
                {
                  tab === 'profile' && (
                    <TutorProfile />
                  )
                }
                {
                  tab === 'availability' && (
                    <TutorAvailability />
                  )
                }
                {
                  tab === 'bank' && (
                    <TutorBank />
                  )
                }
                {
                  tab === 'security' && (
                    <TutorSecurity />
                  )
                }
              </div>
            </div>
          </section>
        </div>
      </TutorLayout>
    )
  }
}

export default Tutor;
