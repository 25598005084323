/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import BlankAuthLayout from '../components/BlankAuthLayout';
import _ from "lodash";
import guard from "../actions/guard";
import { allPlans, upgradeSubscription, upgradeSubscriptionParent, subscriptionDetails, subscriptionDetailsParent } from "../services/Payment";
import { getCoupons } from "../services/Coupon";
import { withRouter } from 'react-router-dom';
import { toast } from "react-toastify";
import { FiAlertOctagon, FiCheckCircle } from 'react-icons/fi';
import auth from "../actions/auth";
import $ from "jquery";
import { Link } from 'react-router-dom';
import { useQuery } from '../hooks';
import './Pricing.css';
import env from "../config/env.json";
import { singleSessionCharges } from "../config/variables";
import { confirm } from "react-confirm-box";
import { Modal } from 'react-responsive-modal';

class Pricing extends Component {

    constructor(props) {
        super(props);
        const { location } = props;
        const query = new URLSearchParams(location.search);
        const schedule = query.get("schedule");
        const studentId = query.get("studentId");
        console.log(studentId);
        this.state = {
            plans: {},
            plansLoading: true,
            onSuccess: false,
            loading: false,
            planId: null,
            studentId: studentId,
            students: null,
            billingDetailsLoading: true,
            billingDetails: null,
            role: null,
            selectedPlanType: 'pro',
            coupons: null,
            schedule: schedule ? schedule : false
        };
    }

    componentDidMount() {
        guard.check(() => {
            if (guard.role() === "tutor") {
                this.props.history.push("/dashboard");
            } else {
                this.getAllPlans();
                if (guard.role() === "parent") {
                    this.setState({
                        ...this.state,
                        role: 'parent'
                    })
                    this.getDetailsParent();
                    this.getAssignedCoupons();
                } else if (guard.role() === "student") {
                    this.setState({
                        ...this.state,
                        role: 'student'
                    })
                    this.getDetails();
                    this.getAssignedCoupons();
                }
            }
        });
    }

    getDetails = () => {
        subscriptionDetails().then((res) => {
            console.log(res)
            if (res.status === 200) {
                this.setState({
                    ...this.state,
                    billingDetails: res.data.data,
                    billingDetailsLoading: false
                });
            } else {
                this.setState({
                    ...this.state,
                    // billingDetailsLoading: false
                });
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            this.setState({
                ...this.state,
                // billingDetailsLoading: false
            });
            toast.error("Something went wrong!");
        })
    }

    getDetailsParent = () => {
        subscriptionDetailsParent().then((res) => {
            console.log('parent', res)
            if (res.status === 200) {
                this.setState({
                    ...this.state,
                    students: res.data.data,
                    billingDetailsLoading: false
                });
            } else {
                this.setState({
                    ...this.state,
                    // billingDetailsLoading: false
                });
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            this.setState({
                ...this.state,
                // billingDetailsLoading: false
            });
            toast.error("Something went wrong!");
        })
    }

    getAllPlans = () => {
        allPlans().then((res) => {
            if (res.status === 200) {
                console.log('plans', res.data.data.data);
                let planList = Object.values(res.data.data.data); 
                planList.sort((product1, product2) =>  Number(product1.product.metadata.order) - Number(product2.product.metadata.order));
                const plans = { basic: [], pro: [],test_prep:[] };
                planList.forEach(plan => {
                    if (plan.product.metadata.plan_type === 'pro') {
                        plans.pro.push(plan);
                    }else if (plan.product.metadata.plan_type === 'test-prep') {
                        plans.test_prep.push(plan);
                    } else {
                        plans.basic.push(plan);
                    }
                })

                this.setState({
                    ...this.state,
                    plans: plans,
                    plansLoading: false
                });
            } else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    paymentGo = (id) => {
        this.setState({
            ...this.state,
            planId: id
        });
        if (this.state.role === 'parent') {
            let couponName = null;
            if(this.state.coupons!=null && this.state.coupons.coupon_name){
                couponName = btoa(this.state.coupons.coupon_name);
            } 
            const stu = this.state.students.find(s => +s.id === +this.state.studentId)
            if (stu.subscription_plan_id) {
                $("#changeSubscription").modal();
            } else {
                if(couponName===null){
                    this.props.history.push("/payment/" + btoa(id) + "?studentId=" + this.state.studentId);
                }else{
                    this.props.history.push("/payment/" + btoa(id) + "?studentId=" + this.state.studentId+"&coupon=" + couponName);
                }
            }
        } else if (this.state.role === 'student') {
            if (this.state.billingDetails.subscription_plan_id) {
                $("#changeSubscription").modal();
            } else {
                this.props.history.push("/payment/" + btoa(id));
            }
        }

    }

    upgrade = () => {
        this.setState({
            ...this.state,
            onSuccess: false,
            loading: true
        }, () => {
            let input = {
                "plan_id": this.state.planId,
                "schedule": this.state.schedule
            };
            if (this.state.role === 'parent') {
                input.student_id = this.state.studentId;
                upgradeSubscriptionParent(input).then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            ...this.state,
                            onSuccess: true,
                            loading: false
                        });
                        auth.update("subscription_plan_id", res.data.data.subscription_plan_id);
                        auth.update("subscription_id", res.data.data.subscription_id);
                        auth.update("card_id", res.data.data.card_id);
                    } else {
                        toast.error("Something went wrong!");
                    }
                }).catch((err) => {
                    toast.error("Something went wrong!");
                })
            } else {
                upgradeSubscription(input).then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            ...this.state,
                            onSuccess: true,
                            loading: false
                        });
                        auth.update("subscription_plan_id", res.data.data.subscription_plan_id);
                        auth.update("subscription_id", res.data.data.subscription_id);
                        auth.update("card_id", res.data.data.card_id);
                    } else {
                        toast.error("Something went wrong!");
                    }
                }).catch((err) => {
                    toast.error("Something went wrong!");
                })
            }

        });
    }

    getClassName(planeName) {
        switch (planeName) {
            case 'Charcoal':
                return 'charcoal';
            case 'Ivory':
                return 'ivory';
            case 'Turquoise':
                return 'turquoise';
            case 'Teal':
                return 'teal';
            case 'Silver':
                return 'silver';
            case 'Platinum':
                return 'platinum';    
            default:
                return '';
        }
    }

    getAssignedCoupons = async () => {
        await getCoupons().then((res) => {
            if (res.status === 200) {
                const coupons = res.data.data
                this.setState({
                    ...this.state,
                    coupons: coupons
                });
            } else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    singleSessionGo = async(id) => {
        const options = {
            render: (message, onConfirm, onCancel) => {
              return (
                <Modal open={true} closeOnOverlayClick={false} center>
                <div className="modal-header">
                    <h4 className="modal-title">Confirm</h4>
                </div>
                <div className="modal-body">
                  <div className="confirmation-text"> {message} </div>
                  <div className="add-dialog-buttons">
                  <button className="btn btn-primary text-white" onClick={onConfirm}> Yes </button>
                  <button className="btn btn-primary text-white" onClick={onCancel}> No </button>
                  </div>
                </div>
                </Modal>
              );
            }
          };
         const isConfirm= this.state.coupons != null ? await confirm('No coupons are available for this plan. Do you want to continue?', options) : true;
        if (isConfirm) {
            this.setState({
                ...this.state,
                planId: id
            });
            if (this.state.role === 'parent') {
                this.props.history.push("/payment/" + btoa(id) + "?studentId=" + this.state.studentId+"&singleSessionPay=true");
            } else if (this.state.role === 'student') {
                this.props.history.push("/payment/" + btoa(id) + "?singleSessionPay=true");
            }
        }
    
    }
    render() {
        const { plans, plansLoading, onSuccess, loading, billingDetailsLoading, coupons } = this.state;

        return (
            <BlankAuthLayout>
                <div className="pricing-page">
                    <div className="header">
                        <div className="center-info">
                            {/* <p>TUTORING SUBSCRIPTION PLANS</p> */}
                            <h2>Pricing</h2>
                            <h4>Flexible subscription plans and pay-as-you-go plans</h4>
                            <h6>Questions about pricing? Call us! 832-240-5288</h6>
                        </div>
                    </div>
                    <div className="main-body">
                        {coupons != null ?
                            <span class="book-tutor-cta">You have a coupon {coupons.coupon_name} in your account. The amount will be deducted from your purchase.</span>
                            : <span></span>}

                        <div className="tabs">
                            <div className="tab-header">
                                
                                <div className={`tab ${this.state.selectedPlanType === 'pro' ? 'active' : ''}`} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        selectedPlanType: 'pro'
                                    })
                                }}>
                                    <strong>In-Person Private Tutoring</strong>
                                </div>
                                <div className={`tab ${this.state.selectedPlanType === 'basic' ? 'active' : ''}`} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        selectedPlanType: 'basic'
                                    })
                                }}>
                                    <strong>Virtual Private Tutoring</strong>
                                </div>
                                <div className={`tab ${this.state.selectedPlanType === 'testPrep' ? 'active' : ''}`} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        selectedPlanType: 'testPrep'
                                    })
                                }}>
                                    <strong>Test Prep Tutoring</strong>
                                </div>
                                {/* <div className={`tab ${this.state.selectedPlanType === 'campusImmersion' ? 'active' : ''}`} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        selectedPlanType: 'campusImmersion'
                                    })
                                }}>
                                    <strong>Campus Immersion</strong>
                                </div> */}
                                <div className={`tab ${this.state.selectedPlanType === 'tutoringPartnerships' ? 'active' : ''}`} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        selectedPlanType: 'tutoringPartnerships'
                                    })
                                }}>
                                    <strong>Tutoring Partnerships</strong>
                                </div>
                            </div>
                            
                            <div className="tab-content">
                                {!plansLoading && !billingDetailsLoading ?
                                
                                    <>
                                        {this.state.selectedPlanType === 'basic' && (
                                            <>
                                             <p className="include-text text-center">All tutoring plans include your choice of Math, English, Science, or Foreign Language subject. Need a personalized learning plan for your child? Give us a call to take our reading and math assessment for FREE! 832-240-5288.
                                            {/* <p class="sub-head">Virtual Tutoring coming September 2022!</p> */}
                                            </p>
                                            <div className="grid">
                                                        <div key="0" className="plan">
                                                            <div className="header">
                                                                Pay-As-You-Go
                                                                <h6>1 hour</h6>
                                                            </div>
                                                            
                                                            <div className="price ">
                                                            <span className="price-actual">${singleSessionCharges.SINGLE_SESSION_ONLINE_CHARGE_OLD}</span>
                                                                <h3><span>$</span>  {singleSessionCharges.SINGLE_SESSION_ONLINE_CHARGE} / hour</h3>
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className="description">
                                                            <ul>
                                                                <li>Not ready to commit?</li>
                                                            </ul>
                                                            </div>
                                                            <div className="button-wrapper">
                                                            {env.ONLINE_SESSION?
                                                                <button className="button" onClick={this.singleSessionGo.bind(this,1)}>Select This Plan</button>
                                                                :<button className="button-disabled" >Select This Plan</button>
                                                            }
                                                                </div>
                                                        </div>
                                                {this.state?.plans?.basic.map((p, i) => {
                                                    return (
                                                        <div key={i} className={`plan ${this.getClassName(p.name)}`}>
                                                            <div className="header">
                                                                {p?.name}
                                                                <h6>{p?.product?.description}</h6>
                                                            </div>
                                                            <div className="price">
                                                            {p?.product?.metadata?.actual_price?<span className="price-actual">${p?.product?.metadata?.actual_price}</span>:<></>}

                                                                {p?.amount && <h3><span>$</span> {p?.amount / 100}</h3>}
                                                                {/* {p?.amount && <h5>( $ {(p?.amount / (100 * p?.product?.metadata?.credit))?.toFixed(2)} / hour )</h5>} */}
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className="description">
                                                            <ul>
                                                                {p?.product?.metadata?.description_1?<li>{p?.product?.metadata?.description_1}</li>:<></>}
                                                                {p?.product?.metadata?.description_2?<li>{p?.product?.metadata?.description_2}</li>:<></>}
                                                            </ul>
                                                            </div>
                                                            <div className="button-wrapper">
                                                            {env.ONLINE_SESSION?
                                                                <button className="button" onClick={this.paymentGo.bind(this, p.id)}>Select This Plan</button>
                                                                :<button className="button-disabled" >Select This Plan</button>
                                                            }
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            </>
                                        )}

                                        {this.state.selectedPlanType === 'pro' && (
                                            <>
                                             <p className="include-text text-center">All tutoring plans include your choice of Math, English, Science, or Foreign Language subject. Need a personalized learning plan for your child? Give us a call to take our reading and math assessment for FREE! 832-240-5288.
                                              </p>

                                            <div className="grid">
                                            <div key="0" className="plan">
                                                            <div className="header">
                                                                Pay-As-You-Go
                                                                <h6>1 hour</h6>
                                                            </div>
                                                            <div className="price">
                                                            <span className="price-actual">${singleSessionCharges.SINGLE_SESSION_INPERSON_CHARGE_OLD}</span>

                                                                <h3><span>$</span>  {singleSessionCharges.SINGLE_SESSION_INPERSON_CHARGE} / hour</h3>
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className="description">
                                                            <ul>
                                                                <li>Not ready to commit?</li>
                                                            </ul>
                                                            </div>
                                                            <div className="button-wrapper">
                                                            <button className="button" onClick={this.singleSessionGo.bind(this,0)}>Select This Plan</button>
                                                            </div>
                                                        </div>
                                                {this.state?.plans?.pro.map(p => {
                                                    return (
                                                        <div className={`plan ${this.getClassName(p.name)}`}>
                                                            <div className="header">
                                                                {p?.name}
                                                                <h6>{p?.product?.description}</h6>
                                                            </div>
                                                            <div className="price">
                                                                {p?.product?.metadata?.actual_price?<span className="price-actual">${p?.product?.metadata?.actual_price}</span>:<></>}
                                                                {p?.amount && <h3><span>$</span> {p?.amount / 100}</h3>}
                                                                {/* {p?.amount && <h5>( $ {(p?.amount / (100 * p?.product?.metadata?.credit))?.toFixed(2)} / hour )</h5>} */}
                                                                <button class="button">{p?.product?.metadata?.highlight}</button>

                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className="description">
                                                            <ul>
                                                                {p?.product?.metadata?.description_1?<li>{p?.product?.metadata?.description_1}</li>:<></>}
                                                                {p?.product?.metadata?.description_2?<li>{p?.product?.metadata?.description_2}</li>:<></>}
                                                                </ul>
                                                            </div>
                                                            <div className="button-wrapper">
                                                                <button className="button" onClick={this.paymentGo.bind(this, p.id)}>Select This Plan</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                
                                            </div>
                                            </>
                                        )}

                                        {this.state.selectedPlanType === 'testPrep' && (
                                            <>
                                              <p className="include-text text-center text-center">
                                              We know standardized testing can be stressful, but SiSTEM Tutoring can help you be successful! 
                                              Our amazing tutors can help tutor the ACT, SAT, GRE, PCAT, DAT, OAT, MCAT, GMAT or NCLEX!<br/>
                                              {/* <strong>Coming Soon in January 2023!</strong> */}
                                              </p>
                                            {/* <div className="grid grid-two">
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                        Pay-As-You-Go
                                                    </div>
                                                    <div className="price">
                                                        <h3><span>$</span> 100 / hour</h3>
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="description">
                                                        <ul>
                                                            <li>Not ready to commit?</li> 
                                                        </ul>
                                                    </div> 
                                                </div> 
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                    10 Hours Of Tutoring
                                                    </div>
                                                    <div className="price">
                                                        <h3><span>10 hours of tutoring is just $800</span></h3>
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="description">
                                                        <ul>
                                                        <li>One-time payment</li> 
                                                        <li>Hours expire after 6 months</li> 
                                                        </ul>
                                                    </div> 
                                                </div> 
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                    20 Hours Of Tutoring
                                                    </div>
                                                    <div className="price">
                                                        <h3><span>20 hours of tutoring is just $1500</span></h3>
                                                        <button className="button">Most Popular</button>

                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="description">
                                                        <ul>
                                                        <li>One-time payment</li> 
                                                        <li>Hours expire after 6 months</li> 
                                                        </ul>
                                                    </div> 
                                                </div> 
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                    30 Hours Of Tutoring
                                                    </div>
                                                    <div className="price">
                                                        <h3><span>30 hours of tutoring is just $2100</span></h3>
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="description">
                                                        <ul>
                                                        <li>One-time payment</li> 
                                                        <li>Hours expire after 6 months</li> 
                                                        </ul>
                                                    </div> 
                                                </div> 
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                    40 Hours Of Tutoring
                                                    </div>
                                                    <div className="price">
                                                        <h3><span>40 hours of tutoring is just $2600</span></h3>
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="description">
                                                        <ul>
                                                        <li>One-time payment</li> 
                                                        <li>Hours expire after 6 months</li> 
                                                        </ul>
                                                    </div> 
                                                </div> 
                                            </div> */}
                                            <div className="grid">
                                            <div key="0" className="plan">
                                                            <div className="header">
                                                                Pay-As-You-Go
                                                                <h6>1 hour</h6>
                                                            </div>
                                                            <div className="price">

                                                                <h3><span>$</span>  {singleSessionCharges.SINGLE_SESSION_TEST_PREP_CHARGE} / hour</h3>
                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className="description">
                                                            <ul>
                                                                <li>Not ready to commit?</li>
                                                            </ul>
                                                            </div>
                                                            <div className="button-wrapper">
                                                            <button className="button" onClick={this.singleSessionGo.bind(this,2)}>Select This Plan</button>
                                                            </div>
                                                        </div>
                                                {this.state?.plans?.test_prep.map(p => {
                                                    return (
                                                        <div className={`plan pay-as-go`}>
                                                            <div className="header">
                                                                {p?.name}
                                                                <h6>{p?.product?.description}</h6>
                                                            </div>
                                                            <div className="price">
                                                                {p?.product?.metadata?.actual_price?<span className="price-actual">${p?.product?.metadata?.actual_price}</span>:<></>}
                                                                {p?.amount && <h3><span>$</span> {p?.amount / 100}</h3>}
                                                                {/* {p?.amount && <h5>( $ {(p?.amount / (100 * p?.product?.metadata?.credit))?.toFixed(2)} / hour )</h5>} */}
                                                                {p?.product?.metadata?.highlight && <button class="button">{p?.product?.metadata?.highlight}</button>}

                                                            </div>
                                                            <div className="divider"></div>
                                                            <div className="description">
                                                            <ul>
                                                                {p?.product?.metadata?.description_1?<li>{p?.product?.metadata?.description_1}</li>:<></>}
                                                                {p?.product?.metadata?.description_2?<li>{p?.product?.metadata?.description_2}</li>:<></>}
                                                                </ul>
                                                            </div>
                                                            <div className="button-wrapper">
                                                                <button className="button" onClick={this.paymentGo.bind(this, p.id)}>Select This Plan</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                
                                            </div>
                                            </>
                                        )}
                                        {this.state.selectedPlanType === 'campusImmersion' && (
                                             <>
                                             <p className="include-text text-center">
                                                <a target="_blank" href={env.LANDING_URI + '/contact'}>Contact Us To Get Started</a>
                                             </p>
                                            <div className="grid grid-one">
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                    In-Person Tutoring
                                                    </div> 
                                                    <div className="description">
                                                        <ul>
                                                            <li>Price ranges between $6.40/hour to $11.20/hour per student</li> 
                                                            <li>Groups of 3-5 students per session</li> 
                                                            <li>For students in grades K-12</li> 
                                                            <li>Students will meet with their tutor during the school day only</li> 
                                                            <li>Students will have the same schedule each week</li> 
                                                            <li>Students will work with the same tutor everytime!</li> 
                                                         </ul>
                                                    </div> 
                                                </div> 
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                    Virtual Tutoring
                                                    </div> 
                                                    <div className="description">
                                                        <ul>
                                                        <li>Available for students starting September 1, 2022</li> 
                                                        <li>Flat rate of $35/hour/student</li> 
                                                        <li>For students in grades K-12</li> 
                                                        <li>Students will login using their email and password supplied by the district</li> 
                                                        <li>Students can meet with their tutor before school, during school, after school, weekends, and/or holidays </li> 
                                                        <li>Students will have the same tutoring schedule each week</li> 
                                                        <li>Students will be already matched to a tutor and will work 1-on-1 with the same tutor every time!</li> 
                                                        
                                                        </ul>
                                                    </div> 
                                                </div> 
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                    In addition to providing our tutoring services, we can:
                                                    </div> 
                                                    <div className="description">
                                                        <ul>
                                                        <li>Set up a tutoring schedule that works for your campus</li> 
                                                        <li>Train the tutors according to your campus’ needs</li> 
                                                        <li>For students in grades K-12</li> 
                                                        <li>Create weekly lesson plans that help keep students, tutors, teachers and admin on the same page regarding what the students are learning</li> 
                                                        <li>Create assessments to check student progress/mastery in certain TEKS </li> 
                                                        <li>Send reports to teachers, admin </li> 
                                                        <li>We can supply our own tutoring materials/resource (Imagine Learning - MyPath Interventional Resource) </li> 
                                                        <li>Introduce parents/guardians to SiSTEM Tutoring by communicating student progress and growth throughout the duration tutoring program</li> 
                                                        </ul>
                                                    </div> 
                                                </div> 
                                            </div>
                                            </>
                                        )}
                                        {this.state.selectedPlanType === 'tutoringPartnerships' && (
                                            <div className="grid grid-one">
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                    Non-Profit Organizations
                                                    </div> 
                                                    <p className="description">
                                                    Offering tutoring to non-profit employees and their households at a discounted rate
                                                    </p>
                                                    <div className="price">
                                                    <a class="button" target="_blank" href={env.LANDING_URI + '/contact'}>Contact Us To Learn More</a>
                                                    </div>
                                                </div> 
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                    Government Agencies
                                                    </div>   
                                                    <p className="description">
                                                    Offering tutoring to government entities such as city workforces and  federal agencie
                                                    </p>
                                                    <div className="price">
                                                    <a class="button" target="_blank" href={env.LANDING_URI + '/contact'}>Contact Us To Get Started</a>
                                                    </div>
                                                </div> 
                                                <div className="plan pay-as-go">
                                                    <div className="header">
                                                    Companies
                                                    </div> 
                                                    <p className="description">
                                                    Offering tutoring through employee assistance and benefit programs
                                                    </p>
                                                    <div className="price">
                                                    <a class="button" target="_blank" href={env.LANDING_URI + '/contact'}>Contact Us For More Info</a>
                                                    </div>
                                                </div> 
                                            </div>
                                        )}
                                    </> :
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <p className=" font-weight-bold">Loading...</p>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="changeSubscription" tabIndex="-1" role="dialog" aria-labelledby="changeSubscription" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-grey">Change Subscription</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {
                                    onSuccess ?
                                        <div className="d-flex justify-content-center">
                                            <div className="text-center">
                                                <div className="animation-ctn">
                                                    <div className="icon icon--order-success svg mb-5">
                                                        <FiCheckCircle color="green" size="120" />
                                                    </div>
                                                </div>
                                                <h2 className="bold mb-2 text-grey">Subscription Successful!</h2>
                                                 
                                                {coupons != null ?
                                            	<h2 className="bold mb-2">{coupons.coupon_name} added in payment</h2>:''
                                                }
                                                 
                                                <p className="mb-4">Your Subscription was successfully processed. Check your email for your receipt.</p>
                                            </div>
                                        </div>
                                        :
                                        <div className="d-flex justify-content-center">
                                            <div className="text-center">
                                                <div className="animation-ctn">
                                                    <div className="icon icon--order-success svg mb-5">
                                                        <FiAlertOctagon color="green" size="120" />
                                                    </div>
                                                </div>
                                                <h2 className="bold mb-2 text-grey">Are you sure?</h2>
                                                <p className="mb-4">Your are changing your current subscription. Please confirm to continue.</p>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className="modal-footer">
                                {
                                    !onSuccess ?
                                        <button type="button" className="btn btn-primary text-white" disabled={loading} onClick={this.upgrade}>Confirm</button>
                                        : <Link to="/"><button type="button" className="btn btn-primary text-white" data-dismiss="modal" onClick={() => this.props.history.push('/')}>Go To Dashboard</button></Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </BlankAuthLayout>
        )
    }
}

export default withRouter(Pricing);