/* eslint-disable */
import React, {Component} from 'react';
import _ from "lodash";
import Player from '@vimeo/player';

class VideoModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidUpdate(prevProp) {
      if(this.props.video !== null && this.props.video.uri !== prevProp.video?.uri) {
        var player = new Player("sistem-vimeo");	
        player.on('play', () => {
          this.props.addHistory(this.props.video);
        }); 
      }
    }

    render() {
        
        return (
            <div className="modal media-modal" id="vemio-video">
              <div className="modal-dialog">
                <div className="modal-content">

                  <div className="modal-header">
                    <h4 className="modal-title">K-12 Video</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={this.props.close}>&times;</button>
                  </div>

                  <div className="modal-body">
                    { 
                      this.props.video ?
                        <div data-vimeo-url={`https://player.vimeo.com/video/${this.props.video.uri.split("/")[2]}`} id="sistem-vimeo"></div>
                        :
                        <p className="text-grey">Loading...</p>
                    }
                  </div>

                </div>
              </div>
            </div>
        );
    }
}

export default VideoModal;