import React, {Component} from 'react';
import validation from "../../actions/validation";
import {toast} from "react-toastify";
import auth from "../../actions/auth";
import {get, updateBankDetails} from "../../services/Profile";
import LoadingSpinner from '../LoaderComponent/LoadingSpinner';

class ParentStudentSecurity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type_of_account: "",
            account_number: "",
            account_name: "",
            routing_number: "",
            //validation
            isValid: false,
            loading: true
        }
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    loading: false,
                    type_of_account: res.data.data.bank_detail ? res.data.data.bank_detail.type_of_account : '',
                    account_number: res.data.data.bank_detail ? res.data.data.bank_detail.account_number : '',
                    account_name: res.data.data.bank_detail ? res.data.data.bank_detail.account_name : '',
                    routing_number: res.data.data.bank_detail ? res.data.data.bank_detail.routing_number : '',
                  
                });
            }else {
                
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            this.setState({
                loading: false
            })
            toast.error("Something went wrong!");
        })
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        });
    };

    update = () => {
        const { type_of_account, account_name, account_number, routing_number } = this.state;
        let input = {
            type_of_account: type_of_account,
            account_name: account_name,
            account_number: account_number,
            routing_number: routing_number
        }
        this.setState({
            ...this.state,
            isValid: true,
            loading: true
        }, () => {
            if(validation.text(type_of_account)
                && validation.text(account_name)
                && validation.text(account_number)
                && validation.text(routing_number)
            ) {
                updateBankDetails(auth.check().id, input).then((res) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    if(res.status === 200) {
                        toast.success("Bank Details saved successfully!");
                    }else {
                        toast.error("Something went wrong!");
                    }
                }).catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Something went wrong!");
                })
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                })
                toast.error("Validation Error!");
            }
        })
    }

    render() {
        const { type_of_account, account_name, account_number, routing_number, isValid, loading } = this.state;

        return (
            <>
            {loading?<LoadingSpinner/>:
            <div className="profile-security tutor-bank">
                <h5 className='bold serif text-grey mb-5'>Bank Details</h5>
                <form className='flexible-width' onSubmit={(e) => { e.preventDefault(); }}>
                <label className='txt-14 text-teal bold mb-3'>Select field to update your bank account details</label>
                <div className="row">
                    <div className="form-group col-md-6">
                    <label htmlFor="typeacc" className='txt-14'>Type of Account</label>
                    <select name="type_of_account" id="typeacc" value={type_of_account} onChange={this.onChange}
                        className={`custom-select ${(isValid && (validation.text(type_of_account) ? '' : 'error is-invalid')).toString()}`}
                    >
                        <option value="">Please select</option>
                        <option value="saving">Saving</option>
                        <option value="current">Current</option>
                    </select>
                    </div>
                    <div className="form-group col-md-6">
                    <label htmlFor="accname" className='txt-14'>Name on Account</label>
                    <input type="text" 
                        value={account_name}
                        name="account_name"
                        onChange={this.onChange}
                        className={`form-control ${(isValid && (validation.text(account_name) ? '' : 'error is-invalid')).toString()}`}
                        id="routing" placeholder="Name" />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                    <label htmlFor="routing" className='txt-14'>Routing Number</label>
                    <input type="text" 
                        value={routing_number}
                        onChange={this.onChange}
                        name="routing_number"
                        className={`form-control ${(isValid && (validation.text(routing_number) ? '' : 'error is-invalid')).toString()}`}
                        id="routing" placeholder="24519" />
                    </div>
                    <div className="form-group col-md-6">
                    <label htmlFor="acc_no" className='txt-14'>Account Number</label>
                    <input type="text" 
                        value={account_number}
                        name="account_number"
                        onChange={this.onChange}
                        className={`form-control ${(isValid && (validation.text(account_number) ? '' : 'error is-invalid')).toString()}`}
                        id="acc_no" placeholder="013766777" />
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-5">
                    <button type="submit" className="btn btn-primary btn-padding--md text-white btn-sm-100" disabled={loading} onClick={this.update}>Update</button>
                </div>
                </form>
            </div>
    }
            </>
        );
    }
}

export default ParentStudentSecurity;