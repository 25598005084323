import React, { Component } from "react";
import guard from "../../actions/guard";
//import ParentStudent from "../../components/WhiteBoard/ParentStudent";


class WhiteBoardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        guard.check(() => {
            this.setState({
                role: guard.role()
            })
        });
    }
    render() {
        return (
            <>
                { 
                    //<ParentStudent /> 
                }
            </>
        );
    }
}

export default WhiteBoardPage;