import React, { useReducer, useState, useEffect } from "react";

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from "moment";
import _ from "lodash";
//import { toBeRequired } from '@testing-library/jest-dom';
//import { toast } from "react-toastify"; 
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export default function TimePickerComponent(props) {
  //console.log("props",props)
  const {tutor,date,timeType, updateTime,pickerStartTime,pickerEndTime,selectedDayMapping} = props;
  const [value, setValue] = React.useState(null);
  const [startTimeHour, setStartTimeHour] = React.useState(null);
  const [selectedTime, setselectedTime] = React.useState(null);
  const [dayWiseAvailableTime, setDayWiseAvailableTime] = useState([]);
  const [formattedStartEndTime, setFormattedStartEndTime] = useState([]);
  const [dayOfDate, setDayOfDate] = useState([]);
  const [monthOfDate, setMonthOfDate] = useState([]);
  const [yearOfDate, setYearOfDate] = useState([]); 
  const [currentError, setCurrentError] = useState(null);
  const [errorTime, setErrorTime] = useState(false);
  
  let disable = false;
 
  if(timeType === 'End Time' && pickerStartTime==null){
    disable =true
  }
  useEffect( () => {
   //const selectedDayMapping = tutor.day_time_mapping.filter( (schedule ) => schedule.day === moment(date).format('dddd') );
    const  day = date.getDate(); 
    const  month = date.getMonth();
    const  fullYear = date.getFullYear();

    setDayOfDate(day);
    setMonthOfDate(month);
    setYearOfDate(fullYear);

    const formattedTimeArr = [];

    selectedDayMapping.map( (schedule) => {
      const startTime = schedule.start_time.split(":");
      const endTime   = schedule.end_time.split(":");
      const startDate  = new Date(fullYear,month,day,startTime[0],startTime[1],startTime[2]);
      const endDate  = new Date(fullYear,month,day,endTime[0],endTime[1],endTime[2]);
      formattedTimeArr.push({
         startDate,
         endDate

      })
    
    })
    setDayWiseAvailableTime(selectedDayMapping);
    setFormattedStartEndTime(formattedTimeArr);
    setValue(null); 
  },[date,selectedDayMapping])

  
  const prepareAvailableTime = (timeValue, clockType) => {
    let scheduleArray =[]; 
    let minuteArray =[];
   
    if(dayWiseAvailableTime.length == 0){
      return true
    } 
    //console.log("selectedDayMapping",selectedDayMapping)
    let disabledTimeValue = true; 
    if(timeType === 'End Time' && pickerStartTime==null){
        return disabledTimeValue;
    }
    if(clockType === 'hours'){
      const currentHourDate = new Date(yearOfDate,monthOfDate,dayOfDate,timeValue,'00','00');
      //console.log("formattedStartEndTime",formattedStartEndTime)
      let formattedStartEndTime2 =formattedStartEndTime;
      let formattedStartEndTime1 = _.orderBy(formattedStartEndTime2, ['startDate'],['asc']);
    //console.log("formattedStartEndTime1",formattedStartEndTime1)
    let prevEnd = null;
      let count= 0;
      let timeRangeArray =[];
       formattedStartEndTime1.map( (k) => {
        let f =k;
         if(moment(prevEnd).isSame(moment(f.startDate))){ 
         // console.log("found",f.startDate,"==",f.endDate)
          timeRangeArray[count-1].endDate=f.endDate;
        }else{
          timeRangeArray.push({"startDate":f.startDate,"endDate":f.endDate})
         count=count+1;
        }
        prevEnd = f.endDate;
       })
      //console.log("timeRangeArray",timeRangeArray);
      if(timeType === 'End Time'){
        const selectedStart = new Date(yearOfDate,monthOfDate,dayOfDate,pickerStartTime.split(':')[0],pickerStartTime.split(':')[1]);
       // console.log("selectedStart",selectedStart)
        const availbleTimerange = _.filter(timeRangeArray, function(item) {
          //console.log("currentHourDate",moment(selectedStart))
          //console.log("item.startDate",moment(item.startDate))
          //console.log("item.endDate",moment(item.endDate))
          return _.every([ _.inRange(
            moment(selectedStart).format('X'), 
            moment(item.startDate).format('X'), 
            moment(item.endDate).format('X')
          ) ]);
      })
     //console.log("availbleTimerange",availbleTimerange)
      availbleTimerange.map( (formattedDate) => {
         if(timeType === 'End Time' && (currentHourDate.getHours() >= new Date(selectedStart).getHours() && currentHourDate.getHours() <=  new Date(formattedDate.endDate).getHours())) {
            disabledTimeValue = false;
          }
 
       })
      }else{
      formattedStartEndTime.map( (formattedDate) => {
        if(timeType === 'Start Time' && ( currentHourDate.getHours() ===  new Date(formattedDate.startDate).getHours()  ) || (currentHourDate.getHours() ===  new Date(formattedDate.endDate).getHours() && new Date(formattedDate.endDate).getMinutes() > 5) || (currentHourDate.getHours() >=  new Date(formattedDate.startDate).getHours() && currentHourDate.getHours() <  new Date(formattedDate.endDate).getHours())) {
           disabledTimeValue = false;
        }
        //  } else if(timeType === 'End Time' && (currentHourDate.getHours() ===  new Date(formattedDate.endDate).getHours() || currentHourDate.getHours() ===  new Date(formattedDate.startDate).getHours())) {
        //    disabledTimeValue = false;
        //  }

      })
    }
    return disabledTimeValue;

    } else if(clockType === 'minutes') {
      const currentMinuteHourDate = new Date(yearOfDate,monthOfDate,dayOfDate,startTimeHour,timeValue,'00');
  
        formattedStartEndTime.map( (formattedDate) => {
           if(timeType === 'Start Time' && currentMinuteHourDate.getTime() >=  new Date(formattedDate.startDate).getTime() && currentMinuteHourDate.getTime() <=  new Date(formattedDate.endDate).getTime()) {
             disabledTimeValue = false;
           } else if(timeType === 'End Time' && currentMinuteHourDate.getTime() >=  new Date(formattedDate.startDate).getTime() && currentMinuteHourDate.getTime() <=  new Date(formattedDate.endDate).getTime()) {
             disabledTimeValue = false;
           }

        })
      return disabledTimeValue;

    }

  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
       <TimePicker
          renderInput={(params) => <TextField {...params} error={errorTime} helperText={currentError ?? currentError}/> }
          label=""
          ampm={true}
          value={value}
          onChange={(newValue) => {
            updateTime( timeType, newValue,errorTime)
            setValue(newValue);
            setStartTimeHour(newValue ? newValue.$H:0); 
          }}
          closeOnSelect={true}
          shouldDisableTime={prepareAvailableTime}
          disabled={disable} 
          onError={(reason, value) => {
            if (reason) { 
              setCurrentError("Not an available time!");
             // console.log("Error",true)
              setErrorTime(true);
              updateTime( timeType, value,true)
            } else {
              setCurrentError(null);
             // console.log("Error",false)
              updateTime( timeType, value,false)
              setErrorTime(false);
            }
          }}
        />
    </LocalizationProvider>
  );
}