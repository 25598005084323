/* eslint-disable */
import React, { Component } from "react";
import TutorLayout from "../components/TutorLayout";
import { FaTrashAlt, FaUserAlt, FaMapMarkerAlt, FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import NoticeBoard from '../components/Notification/NoticeBoard';
import RescheduleModal from "../components/Modal/Reschedule";
import {get} from "../services/Profile";
import { startSession, endSession, endInpersonSession, cancelSession } from "../services/Common";
import Calendar from 'react-calendar';
import auth from "../actions/auth";
import validation from "../actions/validation";
import guard from "../actions/guard";
import {toast} from "react-toastify";
import moment from "moment";
import { withRouter } from "react-router-dom";
import _ from  "lodash";
import $ from "jquery";
import { formatStartEndTime } from '../helpers/utils';
import LoadingSpinner from "../components/LoaderComponent/LoadingSpinner";
import ReviewRecapModal from '../components/Modal/ReviewRecapModal'
import { confirm } from "react-confirm-box";
import { Modal } from 'react-responsive-modal';
import env from "../config/env.json";

class Session extends Component {

	constructor(props) {
        super(props);
        this.state = {
            pending: [],
            schedules: [],
            scheduleLoading: true,
            selectedId: null,
            selectedTutorId: null,
            dayTimeMapping: [],
            remainingTime: 0,
            counter: null,
            schedule: {},
            recapStatus:false,
            recapSession: null,
            completedSchedule:{}
        }
    }

    componentDidMount() {
        guard.check(() => {
            if (guard.role() === "tutor") {
                this.getProfile();
            }else {
                this.props.history.push("/dashboard");
            }
        });
    }

    getProfile = () => {
        get(auth.check().id).then((res) => {
            const dateLimit = moment().subtract(7,'d').format();
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    pending: _.filter(res.data.data.schedules, {progression_status: 0, approved: 0, rejected: 0, cancel:0}),
                    schedules: _.filter(res.data.data.schedules, function(value) {
                        return (value.approved==1 && value.is_completed==0 && value.cancel==0 && (moment.utc(value.start_time_utc).local().format() >= dateLimit));
                    }),                    
                    dayTimeMapping: res.data.data.day_time_mapping,
                    schedule: _.find(res.data.data.schedules, {progression_status: 1, is_completed: 0}),
                    scheduleLoading: false
                }, () => {
                    if(this.state.schedule) {
                        if(!this.state.schedule.online) {
                            if(moment(moment(this.state.schedule.schedule_date).format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD"))
                                && moment(moment(`${this.state.schedule.schedule_date} ${this.state.schedule.end_time}`).format("YYYY-MM-DD HH:mm:ss")).isSameOrAfter(moment().format("YYYY-MM-DD HH:mm:ss"))
                                && moment.duration(moment(`${this.state.schedule.schedule_date} ${this.state.schedule.end_time}`, ["YYYY-MM-DD HH.mm"]).diff(moment(moment().format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds() > 0
                            ) {
                                this.setState({
                                    ...this.state,
                                    remainingTime: moment.duration(moment(`${this.state.schedule.schedule_date} ${this.state.schedule.end_time}`, ["YYYY-MM-DD HH.mm"]).diff(moment(moment().format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds()
                                }, () => {
                                    this.timer();
                                });
                            }
                            else {
 								// this.end(this.state.schedule.id);
                            }
                            
                        }
                    }
                });
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        })
    }

    rescheduleClose = () => {
        this.setState({
            ...this.state,
            selectedId: null,
            selectedTutorId: null
        }, () => {
            $("#reschedule").modal('hide');
        });
    }

    rescheduleOpen = (id, tutorId) => {
        this.setState({
            ...this.state,
            selectedId: id,
            selectedTutorId: tutorId
        }, () => {
            $("#reschedule").modal();
        });
    }

    start = (id, type, schedule_date, start_time, end_time) => {
        if (this.state.schedule) {
            toast.info("One session is active! Please end the active session first.");
        } else {

            let input = {
                "schedule_id": id,
                "start_time": moment().format('YYYY-MM-DD HH:mm:ss')
            }
            let errMessage = '';
            let preventSession = false;
            const scheduleDate = moment(schedule_date);
            const scheduleDateTime = `${schedule_date} ${end_time}`;
            const scheduleDateTimeMoment = moment(scheduleDateTime);
            const currentTime = moment();
            if (!moment(scheduleDate.format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD"))) {
                errMessage = 'Schedule date is not upto date!';
                preventSession = true;
            }
            else if (!moment(scheduleDateTimeMoment.format("YYYY-MM-DD HH:mm:ss")).isSameOrAfter(currentTime.format("YYYY-MM-DD HH:mm:ss"))) {
                errMessage = 'Schedule time is over!';
                preventSession = true;

            }
            else if (!moment.duration(moment(scheduleDateTime, ["YYYY-MM-DD HH.mm"]).diff(moment(currentTime.format("YYYY-MM-DD HH:mm:ss"), ["YYYY-MM-DD HH.mm"]))).asSeconds() > 0) {
                errMessage = 'Minimum duration not met!';
                preventSession = true;

            }

            if (!preventSession) {
                startSession(input).then((res) => {
                    if(res.status === 201) {
                        toast.success("Starting your session...");

                         if(type === "online") {
                            this.props.history.push("/room/" + btoa(id))
                         } else{

                            this.setState({
                                ...this.state,
                                schedule: res.data.schedule,
                                remainingTime: moment.duration(moment(res.data.schedule.end_time, ["HH.mm"]).diff(moment(moment().format("HH:mm:ss"), ["HH.mm"]))).asSeconds()
                            }, () => {
                                this.getProfile();
                            });
                         }
                    }else if(res.status === 205) {
                        toast.error("Cannot start the session! Sessions rescheduled, please refresh the page for updates.");

                    }else {
                        toast.error("Something went wrong!");
                    }
                }).catch((err) => {
                    toast.error("Something went wrong!");
                });
            } else {
                toast.error(errMessage);
            }

        }
    }

    end = (id) => {
        let input = {
            "id": _.find(this.state.schedules, ['id', id]).schedule_session.id,
            "schedule_id": id,
            "end_time":  moment().format('YYYY-MM-DD HH:mm:ss')
        }
        endSession(input).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    recapStatus: true,
                    recapSession :_.find(this.state.schedules, ['id', id]).schedule_session.id,
                    completedSchedule : res.data
                }, () => {
                    this.getProfile();
                });
                toast.success("Session completed successful.");
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            toast.error("Something went wrong!");
        });
    }

    timer = () => {
        let counter = setInterval(() => {
            if(this.state.remainingTime > 0) {
                this.setState({
                    ...this.state,
                    remainingTime: this.state.remainingTime - 1
                });
            }else {
                clearInterval(this.state.counter);
                this.end(this.state.schedule.id);
            }
        }, 1000);
        this.setState({
            ...this.state,
            counter
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.counter);
    }

    endInperson = (id,schedule_date,start_time) => {
        let input = {
            "schedule_id": id,
            "end_time": moment().format('YYYY-MM-DD HH:mm:ss'),
            "start_time":schedule_date+" "+start_time
        } 
        endInpersonSession(input).then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    recapStatus: true,
                    recapSession :res.data.id,
                    completedSchedule : res.data
                }, () => {
                    this.getProfile();
                });
                toast.success("Session completed successfully.");
            }else {
                toast.error("Something went wrong!");
            }
        }).catch((err) => {
            console.log(err)
            toast.error("Something went wrong!");
        });
    }

    cancel= async(id) => {
        let input = {
            "schedule_id": id 
        } 
        const options = {
            render: (message, onConfirm, onCancel) => {
              return (
                <Modal open={true} closeOnOverlayClick={false} center>
                <div className="modal-header">
                    <h4 className="modal-title">Confirm</h4>
                </div>
                <div className="modal-body">
                  <p className="align-left"> {message} </p>
                  <div className="add-dialog-buttons">
                  <button className="btn btn-primary text-white" onClick={onConfirm}> Yes </button>
                  <button className="btn btn-primary text-white" onClick={onCancel}> No </button>
                  </div>
                </div>
                </Modal>
              );
            }
          };
        const isConfirm= await confirm('Are you sure?', options);
        if (isConfirm) {
            cancelSession(input).then((res) => {
                if(res.status === 200) {
                    this.getProfile();
                    toast.success("Session cancelled successfully.");
                }else {
                    toast.error("Something went wrong!");
                }
            }).catch((err) => { 
                toast.error("Something went wrong!");
            });
        }
    }
    startClass = (id, schedule) => {
        const classrom = JSON.parse(schedule);
        if(classrom!=null){
            const hostLink=classrom.hostLink; 
            window.location = env.MERITHUB_CLASSROOM + '/'+hostLink;
        }else{
            toast.error("Something went wrong!");
        }
    }
	render() {
		const { scheduleLoading, schedules, pending, selectedId, selectedTutorId, dayTimeMapping, remainingTime, recapStatus, recapSession, completedSchedule } = this.state;
		return (
            <>
            {scheduleLoading?<LoadingSpinner/>:
			<TutorLayout>
                { recapStatus && <ReviewRecapModal sessionId={recapSession} tutorId={auth.check().id} schedule={completedSchedule}/>}

				<div className="tutor-auth-container">
                    <div className="tutoring-wrap">
                        <div className="row tutor-section">
                            <div className="col-lg-9 col-md-12 col-sm-12">
                                <div className="tutoring-list">
                                    <div className="tutoring-date">{moment().format("LL")}</div>
                                    {
                                        !scheduleLoading && schedules.length > 0 && schedules.map((list, i) => (
                                            <div className="tutoring-list-box  tutor-section-box1"   key={i}>
                                              <div className="col-9  tutor-section-box1">
                                                <div className="d-flex d-sm-flex col-lg-4 tutor-box-padding">
                                                <div className="tutoring-name col-lg-10 col-md-8">
                                                    {/* <div className="t-name">{list.studentdetails.email}</div> */}
                                                    <div className="s-name text-capitalize">Student: {list.studentdetails.first_name} {list.studentdetails.last_name} </div>
                                                </div>
                                                <div className="tutoring-pic col-lg-2 col-md-2 w-25">
                                                    {
                                                        list.studentdetails.student_details.profile_picture ? 
                                                        <img src={list.studentdetails.student_details.profile_picture} alt="tutor-avatar" className="tutor-profile-image"/>
                                                        :
                                                        <div className="icn-circle primary-dark font-weight-bold mr-2 text-capitalize" style={{width: 50, height: 50, borderRadius: '50%'}}>{list.studentdetails.first_name.split("")[0]}</div>
                                                    }
                                                </div>
                                                </div>
                                                <div className="tutoring-date-time col-lg-8 col-md-6">
                                                    <div className="t-online"><span className="active"></span>{list.online ? 'Online' : 'In-person'}</div>
                                                    <div className="date-time">
                                                        <span className="text-grey"><FaRegCalendarAlt /> {moment(list.schedule_date).format('LL')}</span>
                                                        <span className="text-grey"><FaRegClock /> {formatStartEndTime(list.start_time_utc, list.end_time_utc)}</span>
                                                    </div>
                                                </div>
                                             </div>
                                            <div className="col-3">
                                                <div className="col-lg-3 col-md-4 tutor-section-box1">
                                                {
                                                    list.online ?
                                                    <>
                                                        {
                                                            list.progression_status === 0 ?
                                                            <div className="t-bnt col-md-3 text-right p-0">
                                                                {/* <button className="btn btn-primary mr-2 my-2 tutor-buttons"  onClick={this.start.bind(this, list.id, "online",list.schedule_date,list.start_time,list.end_time)}>Start</button> */}
                                                                <button className="btn btn-primary mr-2 my-2 tutor-buttons"  onClick={this.startClass.bind(this, list.id, list.merithub_schedule_response)}>Start</button>
                                                                <button className="btn btn-primary mr-2 my-2 tutor-buttons"  onClick={this.cancel.bind(this, list.id)}>Cancel</button>
                                                                <button className="btn btn-outline-primary tutor-buttons" onClick={this.rescheduleOpen.bind(this, list.id, list.tutordetails.id)}>Reschedule</button>
                                                            </div>
                                                            :
                                                            <div className="t-bnt">
                                                                <button className="btn btn-secondary mr-2" onClick={this.startClass.bind(this, list.id, list.merithub_schedule_response)}>In Progress</button>
                                                                {/* <button className="btn btn-link" onClick={this.end.bind(this, list.id)}>Done</button> */}
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                             
                                                            <div className="t-bnt">
                                                                 {
                                                                 (moment().diff(moment.utc(list.start_time_utc).local().format()) > 0 )&&
                                                                
                                                                <button className="btn btn-primary mr-2 my-2 tutor-buttons"  onClick={this.endInperson.bind(this, list.id,list.schedule_date,list.start_time)}>Recap/Done</button>
                                                                 }
                                                                <button className="btn btn-primary mr-2 my-2 tutor-buttons"  onClick={this.cancel.bind(this, list.id)}>Cancel</button>
                                                                <button className="btn btn-outline-primary tutor-buttons" onClick={this.rescheduleOpen.bind(this, list.id, list.tutordetails.id)}>Reschedule</button>
                                                             
                                                                </div> 
                                                        }
                                                    </>
                                                }
                                                
                                                </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {
                                        !scheduleLoading && schedules.length === 0 &&
                                        <div className="d-flex justify-content-center align-items-center">
                                            <p className=" font-weight-bold">No Records!</p>
                                        </div>
                                    }
                                    {
                                        scheduleLoading &&
                                        <div className="d-flex justify-content-center align-items-center">
                                            <p className=" font-weight-bold">Loading...</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <section className="right-section">
                                    <Calendar className='cal-sidebar'  />
                                    <NoticeBoard notification={pending} history={this.props.history}/>
                                </section>
                            </div>
                        </div>
                    </div> 
                    
                </div>

                <RescheduleModal 
                    id={selectedId} 
                    tutorId={selectedTutorId} 
                    dayTimeMapping={dayTimeMapping} 
                    close={this.rescheduleClose} 
                    next={this.getProfile}
                />

			</TutorLayout>
    }
            </>
		);
	}
}

export default withRouter(Session);
