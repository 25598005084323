/* eslint-disable */
import React, { Component } from "react";
import ParentS1 from "../SignUp/ParentS1";
import ParentS2 from "../SignUp/ParentS2";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import StepperNavigation from '../StepperNavigation';
import {updateProfile} from '../../services/Profile';
import auth from "../../actions/auth";
import {toast} from "react-toastify";
import LoadingSpinner from "../LoaderComponent/LoadingSpinner";
import { FaAngleLeft } from "react-icons/fa";

class ParentProfileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            payload: {},
            loading: false
        };
    }

    componentDidMount() {
       
    };

    getData = (data) => {
        const { step } = this.state;
        if(step === 1) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    ...data
                },
                step: 2
            });
        } else if(step == 2) {
            this.setState({
                ...this.state,
                payload: {
                    ...this.state.payload,
                    student: data
                }
            }, () => {
                this.updateProfile();
            });
        }
    }

    updateProfile() {
        const { payload } = this.state;
        const updatedPayload = {
            basic: {
                first_name: this.props.parentData.first_name,
                last_name: this.props.parentData.last_name,
                phone_number: payload.phone_number,
                race: payload.race,
                gender: payload.gender
            },
            details: {
                state: payload.state,
                address: payload.address,
                zip_code: payload.zip_code,
                occupation: payload.occupation,
                gender: payload.gender,
                profile_picture: payload.profile_picture,
                number_of_kids: payload.student ? payload.student.length : 0,
                // student: payload.student
                student: payload.student ? payload.student.map(stu => {
                    return {
                        basic: {
                            first_name: stu.first_name,
                            last_name: stu.last_name,
                            phone_number: stu.phone_number ,
                            race: stu.race,
                            gender: stu.gender,
                            timezone: stu.timezone
                        },
                        details: {
                            // parent_id: "2",
                            state: stu.state,
                            address: stu.address,
                            zip_code: stu.zip_code,
                            disablities: stu.disablities,
                            current_school: stu.current_school,
                            current_grade: stu.current_grade,
                            current_gpa: stu.current_gpa,
                            special_needs: stu.special_ne1eds,
                            major: stu.major,
                            profile_picture: stu.profile_picture,
                            dob: stu.dob,
                            email: stu.email,
                            password: stu.password,
                            password_confirmation: stu.password_confirmation,
                            role: stu.role,
                            gender: stu.gender,
                            hobbies: stu.hobbies,
                            parent_name:stu.parent_name,
                            parent_phone_number:stu.parent_phone_number,
                            parent_email:stu.parent_email,
                            contact_teacher:stu.contact_teacher=="yes"?1:0,
                            extra_info:stu.extra_info,
                            tutoring_interest:stu.tutoring_interest,
                            individual_learning:stu.individual_learning=="yes"?1:0,   
                            tutoring_start_date:stu.tutoring_start_date,
                            teacher_name:stu.teacher_name, 
                            teacher_email:stu.teacher_email,
                            teacher_phone_number:stu.teacher_phone_number,
                            teacher_subject:stu.teacher_subject,
                            struggle_topics:stu.struggle_topics,
                            },
                    }
                }) : []
            },
        }
        updateProfile(updatedPayload, auth.check().id).then((res) => {
            this.setState({
                loading:true
            })
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    loading:false,
                    step: 3
                });
                auth.update("profile_picture", payload.profile_picture);
                toast.success("Profile Updated Successfully!");
            }else {
                throw new Error (Object.values(res.response.data).reduce((init, current) => (init + " " + current.toString()), ""));
            }
        }).catch((err) => {
            this.setState({
                ...this.state,
                loading: false
            });
            toast.error(err.message)
        });
    }
    stepBack = () => { 
        this.setState({
            ...this.state,
            step: this.state.step - 1
        });
    }
    render() {
        const { step, loading, payload } = this.state;

        return (
            <>
            {loading?<LoadingSpinner/>:
            <div className='layout-container bg-bubble'>
                <section className={`container signup-grid ${step === 0 ? '': '--onlyform'}`}>
                    <div className='form-content'>
                    
                        <StepperNavigation step={step} numberOfSteps={2} />
                        <div className="hide-on--mobile  d-none d-md-block w-25">
                        <div className="d-flex justify-content-between align-items-center">
                             {step == 2  &&(<h6 className="pointer text-grey" onClick={this.stepBack}><FaAngleLeft />Go Back</h6>)}
                        </div>
                        </div>
                         <div className="hide-on--desktop d-block d-md-none">
                            <div className="d-flex justify-content-between align-items-center hide-on--desktop">
                                {step == 2  &&(<h6 className="pointer text-grey" onClick={this.stepBack}><FaAngleLeft />Go Back</h6>)}
                            </div>
                        </div>
                        {
                            (step === 1) &&
                            <ParentS1 parentData={this.props.parentData} getData={this.getData} payload={payload}/>
                        }
                        {
                            (step === 2) &&
                            <ParentS2 parentData={this.props.parentData} getData={this.getData} loading={loading}/>
                        }
                        {
                            step === 3 &&
                            <div className="intro-content">
                                <h3 className='text-center text-grey my-5'>Your profile is now complete!</h3>
                                <p className="text-center text-grey w-75 mx-auto">Click done to head to your dashboard and get started on finding a tutor!</p>
                                <div className='d-flex justify-content-center align-items-center my-5'>
                                    <Link to="/">
                                        <Button
                                            variant='primary'
                                            className='btn-padding--lg text-white'
                                        >
                                            Done
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </div>
    }
            </>
        );
    }
}

ParentProfileForm.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default ParentProfileForm;