
import React from "react";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";
import cookie from "../../actions/cookie";
import { useState } from "react";

const ApprovalStatus=({openModal,modalText})=>{
const[modalOpen,setModalopen]=useState(false)
  React.useEffect(() => {
    if(openModal) {
      setModalopen(true);
      setTimeout(() => {
        setModalopen(false);
          cookie.deleteCookie("_token");
          cookie.deleteCookie("_session");
          window.location.href="/";
        }, 5000);

    }
  }, [openModal]);
return(
        <div>
          <Modal
            closeOnOverlayClick={false} center
            open={modalOpen} 
            showCloseIcon={false}
            > 
            <div className="modal-responsive-class">
            <h6>{modalText}</h6>
            </div>
          </Modal>
        </div>
    )

}
export default ApprovalStatus;