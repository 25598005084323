import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Sidebar from "./Sidebar";
import { MdMenu, MdClose, MdPowerSettingsNew } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import cookie from "../../actions/cookie";
import auth from "../../actions/auth";
import "./Navbar.css";
import ReviewRecapModal from "../../components/Modal/ReviewRecapModal";
import { getRatingRecapStatus } from "../../services/Rating";
import { toast } from "react-toastify";
import env from "../../config/env.json";
import { userCreditdetails } from "../../services/Payment";

export default function AuthNav({ path}) {
  const[userCreditdata,setUsercreditdata] = useState(null);
  const getDetailsParent = () => {
    userCreditdetails()
      .then((res) => {
        if (res.status === 200) {
          setUsercreditdata(res.data);
          } 
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      });
  };
const history = useHistory();
  const [reviewDetails, setReviewStatus] = useState({
    status: true,
    sessionId: null,
    tutorId: null,
  });
  const NavLinks = () => (
    <ul className="navigation-links">
        <li className={userCreditdata > 0 ? '' : 'hidden-text'}>
        <a href="https://app.progresslearning.com/login" target="_blank" rel="noopener noreferrer">
        Progress Learning Dashboard 
        </a>
      </li>
    <li className={userCreditdata > 0 ? '' : 'hidden-text'}>
        <a href="https://www.google.com/url?q=https://sistemtutoring.notion.site/SiSTEM-Tutor-Home-73a428df50f7456696231c9186bc55dc&sa=D&source=docs&ust=1659674693587708&usg=AOvVaw1bWp50nTyxIK3U203o4QzB" target="_blank" rel="noopener noreferrer">
        SiSTEM Scholar Community
         </a>
      </li>
     
      <li className={path === '/faq' ? 'active' : ''}><a href={env.LANDING_URI + '/faq-tutor'} target="_blank" rel="noopener noreferrer">FAQs</a></li>
      <li className={path === "/help" ? "active" : ""}>
      <a
          href="#"
          onClick={(e) => {
            window.location.href = "mailto:support@sistemtutoring.com";
            e.preventDefault();
          }}
        >
          Help and Support
        </a>
      </li>
      {/* <li className={path === '/help' ? 'active' : ''}><a href={env.LANDING_URI + '/contact'} target="_blank" rel="noopener noreferrer">Help and Support</a></li> */}
    </ul>
  );
  const navFunc = () => {
    const showNav = () => {
      document.querySelector(".mobile-nav").classList.add("show");
      document.querySelector(".mobile-header--overlay").classList.add("show");
    };
    const hideNav = () => {
      document.querySelector(".mobile-nav").classList.remove("show");
      document
        .querySelector(".mobile-header--overlay")
        .classList.remove("show");
    };
    document.querySelector(".mobile-header--icon").onclick = function () {
      showNav();
    };
    document.querySelector(".mobile-nav--close").onclick = function () {
      hideNav();
    };
    document.querySelector(".mobile-header--overlay").onclick = function () {
      hideNav();
    };
    document.querySelector("aside .sidebar-nav-item").onclick = function () {
      hideNav();
    };
  };
  const checkForReviewPopup = () => {
    getRatingRecapStatus()
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.data;
          const reviewStat = data.status;
          setReviewStatus({
            status: reviewStat,
            sessionId: data.sessionId,
            tutorId: data.tutorId,
            lastSchedule: data.schedule,
          });
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      });
  };

  useEffect(() => {
    // calling navFunc
    navFunc();
    getDetailsParent();
    // checkForReviewPopup();
  }, []);

  const logout = () => {
    cookie.deleteCookie("_token");
    cookie.deleteCookie("_session");
    history.push("/");
  };

  return (
    <React.Fragment>
      {!reviewDetails.status && (
        <ReviewRecapModal
          sessionId={reviewDetails.sessionId}
          tutorId={reviewDetails.tutorId}
          schedule={reviewDetails.lastSchedule}
        />
      )}
      <header className="fixed-header bx-small desktop-header">
        <div className="container-fluid px-5-lg py-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo-wrapper">
              <a href={env.LANDING_URI}>
                <img src="/images/logo.svg" alt="site-logo" />
              </a>
            </div>
            <NavLinks/>
            <nav className="nav-wrapper" />
            <div className="d-flex align-items-center">
              <div
                className="d-flex align-items-center pointer"
                onClick={logout}
              >
                <MdPowerSettingsNew
                  className="mr-1 text-grey"
                  fontSize="22px"
                />
                <div className="txt-14 font-weight-bold text-uppercase">
                  Logout
                </div>
              </div>
              <div className="vl"></div>
              <Link to="profile">
                {auth.check().profile_picture ? (
                  <img
                    src={auth.check().profile_picture}
                    alt="student-avatar"
                    style={{ width: 50, height: 50, borderRadius: "50%" }}
                  />
                ) : (
                  <div className="icn-circle">
                    <img
                      src={`https://ui-avatars.com/api/?name=${
                        auth.check().first_name
                      }`}
                      alt="user"
                    />
                  </div>
                )}
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className="desktop-header">
        {path === "/add-profile" ? (
          <></>
        ) : (
          <Sidebar path={path} mode="sidebar" />
        )}
      </div>
      <div className="mobile-header">
        <header aria-label="mobile-header" className="fixed-header">
          <div className="d-flex align-items-center justify-content-between h-100 position-relative">
            <div className="mobile-header--icon pointer">
              <MdMenu fontSize="24px" />
            </div>
            <div className="logo-wrapper center-hr">
              <Link to="/">
                <img src="/images/logo.svg" alt="site-logo" />
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <div
                className="d-flex align-items-center pointer"
                onClick={logout}
              >
                <MdPowerSettingsNew
                  className="mr-1 text-grey"
                  fontSize="22px"
                />
              </div>
              {/* <div className="vl"></div>
              <Link to="profile">
                {auth.check().profile_picture ? (
                  <img
                    src={auth.check().profile_picture}
                    alt="student-avatar"
                    style={{ width: 50, height: 50, borderRadius: "50%" }}
                  />
                ) : (
                  <div className="icn-circle">
                    <img
                      src={`https://ui-avatars.com/api/?name=${
                        auth.check().first_name
                      }`}
                      alt="user"
                    />
                  </div>
                )}
              </Link> */}
            </div>
          </div>
        </header>

        <div className="mobile-header--overlay" />

        <aside className="mobile-nav">
          <div className="mobile-nav--close pointer">
            <div className="middle">
              <Link to="/">
                <img src="/images/logo.svg" alt="site-logo" />
              </Link>
            </div>

            <div className="rounded-circle border bg-light p-2 mr-3">
              <MdClose fontSize="22px" />
            </div>
          </div>
          <div className="nav-wrapper">
            <Sidebar path={path} mode="list" />
          </div>
        </aside>
      </div>
    </React.Fragment>
  );
}

AuthNav.propTypes = {
  path: PropTypes.string,
};
