/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import DropZone from "../DropZone";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import moment from "moment";
import { states, racicalCategories, disabilityList } from "../../config/variables";
import { FiPhoneForwarded } from "react-icons/fi";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';

class StudentS1 extends Component {
	constructor(props) {
        super(props);
		this.state = {
			gender: props.payload.gender?props.payload.gender : "",
			race: props.payload.race?props.payload.race : "",
			state: props.payload.state?props.payload.state : "",
			address: props.payload.address?props.payload.address : "",
			zip_code: props.payload.zip_code?props.payload.zip_code : "",
			phone_number: props.payload.phone_number?props.payload.phone_number : "",
            disablities: props.payload.disablities?props.payload.disablities : "",
            // special_needs: props.payload.special_needs?props.payload.special_needs : "",
			parent_name: props.payload.parent_name?props.payload.parent_name : "",
			parent_phone_number: props.payload.parent_phone_number?props.payload.parent_phone_number : "",
			parent_email: props.payload.parent_email?props.payload.parent_email : "",
			//validation
			isValid: false,
			loading: false,
			//image
			profile_picture: props.payload.profile_picture?props.payload.profile_picture : "",
            initialLoad: false,
            contact_teacher:props.payload.contact_teacher=== "yes" ? "yes" : "no",
            extra_info:props.payload.extra_info?props.payload.extra_info:"",
            tutoring_interest:props.payload.tutoring_interest?props.payload.tutoring_interest:"",
            individual_learning:props.payload.individual_learning== "yes" ? "yes" : "no",
            tutoringPicked: props.payload.tutoring_start_date?new Date(props.payload.tutoring_start_date):"",
            tutoring_start_date:props.payload.tutoring_start_date?props.payload.tutoring_start_date:"",
            teacher_name: props.payload.teacher_name?props.payload.teacher_name : "",
			teacher_phone_number: props.payload.teacher_phone_number?props.payload.teacher_phone_number : "",
			teacher_email: props.payload.teacher_email?props.payload.teacher_email : "",
            teacher_subject: props.payload.teacher_subject?props.payload.teacher_subject : "",
		};
    }

    componentDidUpdate() {
		if(!this.props?.studentData) return;
		if(this.state.initialLoad) return;
		this.setState({
			phone_number: this.props.studentData?.phone_number,
            parent_name: this.props.studentData?.details?.parent_name? this.props.studentData?.details?.parent_name :'',
			parent_phone_number:this.props.studentData?.details?.parent_phone_number?this.props.studentData?.details?.parent_phone_number  :'',
			parent_email: this.props.studentData?.details?.parent_email?this.props.studentData?.details?.parent_email :'',
            // zip_code:this.props.studentData?.details? this.props.studentData?.details?.zip_code :'',
            // address:this.props.studentData?.details? this.props.studentData?.details?.address :'',
            // state:this.props.studentData?.details? this.props.studentData?.details?.state :'',
            // race:this.props.studentData?.race? this.props.studentData?.race  :'',
            // gender:this.props.studentData?.gender? this.props.studentData?.gender  :'',
            // special_needs:this.props.studentData?.details?.special_needs? this.props.studentData?.details?.special_needs  :'',
            // disablities :this.props.studentData?.details?.disablities? this.props.studentData?.details?.disablities  :'',
            // profile_picture:this.props.studentData?.details?.profile_picture? this.props.studentData?.details?.profile_picture  :'',
            initialLoad: true,
            contact_teacher:this.props.studentData?.details?.contact_teacher==1 ? "yes" : (this.props.payload.contact_teacher=== "yes" ? "yes" : "no"),
            extra_info:this.props.studentData?.details?.extra_info?this.props.studentData?.details?.extra_info:(this.props.payload.extra_info?this.props.payload.extra_info:""),
            tutoring_interest:this.props.studentData?.details?.tutoring_interest?this.props.studentData?.details?.tutoring_interest:(this.props.payload.tutoring_interest?this.props.payload.tutoring_interest:""),
            individual_learning:this.props.studentData?.details?.individual_learning==1 ? "yes" : (this.props.payload.individual_learning=== "yes" ? "yes" : "no"),
            tutoringPicked: this.props.studentData?.details?.tutoring_start_date?new Date(this.props.studentData?.details?.tutoring_start_date):(this.props.payload.tutoring_start_date?new Date(this.props.payload.tutoring_start_date):""),
            tutoring_start_date:this.props.studentData?.details?.tutoring_start_date?this.props.studentData?.details?.tutoring_start_date:(this.props.payload.tutoring_start_date?this.props.payload.tutoring_start_date:""),
            teacher_name: this.props.studentData?.details?.teacher_name?this.props.studentData?.details?.teacher_name :(this.props.payload.teacher_name?this.props.payload.teacher_name:""),
			teacher_phone_number: this.props.studentData?.details?.teacher_phone_number?this.props.studentData?.details?.teacher_phone_number :(this.props.payload.teacher_phone_number?this.props.payload.teacher_phone_number:""),
			teacher_email: this.props.studentData?.details?.teacher_email?this.props.studentData?.details?.teacher_email  :(this.props.payload.teacher_email?this.props.payload.teacher_email:""),
            teacher_subject: this.props.studentData?.details?.teacher_subject?this.props.studentData?.details?.teacher_subject : (this.props.payload.teacher_subject?this.props.payload.teacher_subject : ""),
        })
	}

    
    onChange = (e) => {
        e.persist();
        console.log(e.target.value)
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            isValid: false
        })
    };
	onSubmit = () => {
		const { zip_code, address, state, race, gender, disablities, phone_number, parent_email, parent_phone_number, parent_name, special_needs, contact_teacher, 
            extra_info, 
            tutoring_interest, 
            individual_learning, 
            tutoring_start_date } = this.state;
        this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
            if(validation.text(state)
				&& validation.text(address)
				&& validation.text(gender)
				&& validation.zipCode(zip_code)
				&& validation.text(race)
                && validation.text(disablities)
                && validation.text(phone_number)
                && validation.text(contact_teacher)
                && validation.text(extra_info)
                && validation.text(tutoring_interest)
                && validation.text(individual_learning)
                && validation.text(tutoring_start_date)
                // && validation.email(parent_email)
                // && validation.mobile(phone_number)
                // && validation.text(parent_name)
                // && validation.text(special_needs)
                ) {
                    const data = JSON.parse(JSON.stringify(this.state));
                    console.log(data)
                    this.props.getData(_.omit(data, ['isValid', 'loading']));
                }else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    toast.error("Validation Error!")
                }
		});
	}

	getFile = (data) => {
        if(data.resp) {
            this.setState({
                ...this.state,
                loading: data.loading,
                profile_picture: data.resp
            });
        }
	}
    selectDisabilities=(values)=>{
		let studentDisabilities=[];
		values.map((item,index)=>{
			if(item){
				studentDisabilities = [...studentDisabilities, {
				label:item.label,
				value:item.value,
				key: index
				}]; 
			}
		})
		this.setState({
		...this.state, 
		disablities:studentDisabilities
		})
	}
    setTurorinBegins = (date) => {
        console.log(date)
        this.setState({
            ...this.state,
            tutoringPicked: date,
            tutoring_start_date: moment(date).format("YYYY-MM-DD"),
            isValid: false
        })
    }
	render() {
		const { zip_code, address, state, race, gender, disablities, parent_email, parent_phone_number, parent_name, special_needs, phone_number, isValid, loading, profile_picture,contact_teacher, 
            extra_info, tutoring_interest, individual_learning, tutoring_start_date,tutoringPicked, teacher_name, teacher_email, teacher_phone_number, teacher_subject} = this.state;
        return(
		<React.Fragment>
            <h3 className='text-center text-grey mb-4'>Your Profile</h3>
            <div id='signup-form'>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='state'>State of residence</label>
                        <select className={`custom-select form-control light ${(isValid && (validation.text(state) ? '' : 'error is-invalid')).toString()}`}
                                id="state"
                                name="state"
                                value={state} onChange={this.onChange}>
                            <option value="">Choose One</option>
                            {
                                states.map((state, key) => (
                                    <option key={key} value={state}>{state}</option>
                                ))
                            }
                        </select>
                        <div className="invalid-feedback">
                            Select your state.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='phone_number'>Phone Number</label>
                        <div className='input-group'>
                            <div class='input-group-prepend'>
                                <span class='input-group-text' id='phone_number'>
                                    <FiPhoneForwarded />
                                </span>
                            </div>
                            <input type='text'
                                    name='phone_number'
                                    value={phone_number}
                                    onChange={this.onChange}
                                    className={`form-control light ${(isValid && (validation.mobile(phone_number) ? '' : 'error is-invalid')).toString()}`}
                            />
                            <div className="invalid-feedback">
                                Please enter valid, 10 digits long mobile no.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='address'>Address</label>
                        <input
                            type='text'
                            name='address'
                            id="address"
                            value={address}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(address) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Select your address.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='gender'>Gender</label>
                        <select className={`custom-select form-control light ${(isValid && (validation.text(gender) ? '' : 'error is-invalid')).toString()}`}
                                id="gender"
                                name="gender"
                                value={gender} onChange={this.onChange}>
                            <option value="">Choose One</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                            <option value="I don't prefer to say">I don't prefer to say</option>
                        </select>
                        <div className="invalid-feedback">
                            Select your gender.
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='zip'>Zip Code</label>
                        <input
                            type='text'
                            name='zip_code'
                            id="zip_code"
                            value={zip_code}
                            maxLength="5"
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.zipCode(zip_code) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                        please enter zip-code.it should be a maximum of 5 digits
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='race'>Race</label>
                        <select className={`custom-select form-control light ${(isValid && (validation.text(race) ? '' : 'error is-invalid')).toString()}`}
                                id="race"
                                name="race"
                                value={race} onChange={this.onChange}>
                            <option value="">Choose One</option>
                            {
                                racicalCategories.map((race, key) => (
                                    <option key={key} value={race}>{race}</option>
                                ))
                            }
                        </select>
                        <div className="invalid-feedback">
                            Select your race.
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='parent_name'>Parent Name <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='parent_name'
                            id="parent_name"
                            value={parent_name}
                            onChange={this.onChange}
                            className={`form-control light `}
                            // className={`form-control light ${(isValid && (validation.text(parent_name) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Type your parent name.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='parent_phone_number'>Parent Phone Number <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='parent_phone_number'
                            id="parent_phone_number"
                            value={parent_phone_number}
                            onChange={this.onChange}
                            className={`form-control light `}
                            // className={`form-control light ${(isValid && (validation.mobile(parent_phone_number) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Type your parent's Ph number.
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-6'>
                        <label htmlFor='parent_email'>Parent Email <span className="sub-text">(optional)</span></label>
                        <input
                            type='email'
                            name='parent_email'
                            id="parent_email"
                            value={parent_email}
                            onChange={this.onChange}
                            // className={`form-control light ${(isValid && (validation.email(parent_email) ? '' : 'error is-invalid')).toString()}`}
                            className={`form-control light `}
                        />
                        <div className="invalid-feedback">
                            Type your parent email.
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='disablities'>Disabilities</label>
                        <Select
                        id='disablities' 
						className={`${(isValid && (validation.text(disablities) ? '' : 'error is-invalid')).toString()}`}isMulti
						value={disablities} 
						onChange={(value)=>this.selectDisabilities(value)}
						options={
						    disabilityList.map((items,index) => {
							return {
										label: items,
										value: items,
										key: index
								    }
									})
								}
						/>
                        <div className="invalid-feedback">
                            If you don't have any, select none.
                        </div>
                    </div>
                </div>
                <div className='form-row'>
							<div className='form-group col-md-12'>
                                            <label htmlFor='contact_teacher'>SiSTEM Tutoring Agency aligns students learning plans with their classroom instruction by collaborating with their teachers at school. Do you give SiSTEM permission to contact your student's teacher?   </label>
                                            <select className={`custom-select form-control light ${(isValid && (validation.text(contact_teacher) ? '' : 'error is-invalid')).toString()}`}
                                                    id="contact_teacher" name="contact_teacher" value={contact_teacher}
                                                    onChange={this.onChange}>
                                                <option value="">Choose One</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div> 
							</div>
                            {contact_teacher === "yes" ? <>
                            
                            <div className='form-row'>
                            <div className='form-group col-md-6'>
                        <label htmlFor='teacher_name'>Teacher's Name <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='teacher_name'
                            id="teacher_name"
                            value={teacher_name}
                            onChange={this.onChange}
                            className={`form-control light `}
                         /> 
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='teacher_phone_number'>Teacher's Phone Number <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='teacher_phone_number'
                            id="teacher_phone_number"
                            value={teacher_phone_number}
                            onChange={this.onChange}
                            className={`form-control light `}
                         /> 
                    </div>
                            <div className='form-group col-md-6'>
                                <label htmlFor='teacher_email'>Teacher's Email <span className="sub-text">(optional)</span></label>
                                <input
                                    type='email'
                                    name='teacher_email'
                                    id="teacher_email"
                                    value={teacher_email}
                                    onChange={this.onChange}
                                    className={`form-control light `}
                                /> 
                            </div>
                            <div className='form-group col-md-6'>
                        <label htmlFor='teacher_subject'>Subject they teach <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='teacher_subject'
                            id="teacher_subject"
                            value={teacher_subject}
                            onChange={this.onChange}
                            className={`form-control light `}
                         /> 
                    </div>
                                
                            </div></>: null }
							<div className='form-row'>
							<div className='form-group col-md-12'>
                                            <label htmlFor='individual_learning'>Will you or your student be needing an individualized learning plan from SiSTEM Tutoring Agency ?</label>
                                            <select className={`custom-select form-control light ${(isValid && (validation.text(individual_learning) ? '' : 'error is-invalid')).toString()}`}
                                                    id="individual_learning" name="individual_learning" value={individual_learning}
                                                    onChange={this.onChange}>
                                                <option value="">Choose One</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div>
							</div>
							<div className='form-row'>
							<div className='form-group col-md-6 signup-calender'>
                                            <label htmlFor='tutoring_start_date' className={`${(isValid && (validation.text(tutoring_start_date) ? '' : 'error is-invalid')).toString()}`}>When would you like tutoring to begin?</label>
                                            <DatePicker placeholderText="mm-dd-yyyy" dateFormat="MM-dd-yyyy" name='tutoring_start_date' id="tutoring_start_date" selected={tutoringPicked} minDate={addDays(new Date(), 1)} onChange={this.setTurorinBegins} dropdownMode="select" showMonthDropdown showYearDropdown className={`form-control light ${(isValid && (validation.text(tutoring_start_date)  ? '' : 'error is-invalid')).toString()}`} />
                                            <div className="invalid-feedback">
                                                Select a date.
                                            </div>
                                        </div> 
							</div>
							<div className='form-row'>
							<div className='form-group col-md-12'>
                                            <label htmlFor='tutoring_interest'>Are you interested in in-person tutoring, online tutoring or both?</label>
                                            <select className={`custom-select form-control light ${(isValid && (validation.text(tutoring_interest) ? '' : 'error is-invalid')).toString()}`}
                                                    id="tutoring_interest" name="tutoring_interest" value={tutoring_interest}
                                                    onChange={this.onChange}>
                                                <option value="">Choose One</option>
                                                <option value="in-person">In-person</option>
                                                <option value="online">Online</option>
                                                <option value="both">Both</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div>
							</div>
							<div className='form-row'>
							<div className='form-group col-md-12'>
                                            <label htmlFor='extra_info'>Is there anything else you'd like to share with us?</label>
                                            <textarea className={`form-control ${(this.state.isValid && (validation.text(extra_info) ? '' : 'error is-invalid')).toString()}`} name="extra_info" id="extra_info" rows="3" value={extra_info}  onChange={this.onChange}></textarea>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div> 
							</div>
                {/* <div className='form-row'>
                <div className='form-group col-md-6'>
                        <label htmlFor='special_needs'>Do you have any special needs?</label>
                        <input
                            type='text'
                            name='special_needs'
                            id="special_needs"
                            value={special_needs}
                            onChange={this.onChange}
                            className={`form-control light ${(isValid && (validation.text(special_needs) ? '' : 'error is-invalid')).toString()}`}
                        />
                        <div className="invalid-feedback">
                            Type your need (if any) or none.
                        </div>
                    </div>
                </div> */}
                <div className="d-flex mx-auto">
                        {
                        profile_picture ?
                        <img src={profile_picture ? profile_picture : "https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png?1592828498"} alt="avatar" className="profile-img"/>
                        :
                        <p></p>
                        }
                </div>
                <div className="text-center">
					<label className="mb-1">
						Upload clear photograph 
					</label>
					<DropZone 
						maxSize={5} 
						response={this.getFile.bind(this)} 
						prefix={"profile"} 
						mimeTypes="image/png, image/jpeg, image/jpg"
					/>
				</div>
				<div className='d-flex justify-content-center align-items-center my-5'>
					<button
						disabled={loading}
						className='btn btn-primary btn-padding--lg text-white'
						onClick={this.onSubmit}
					>
						Continue
					</button>
				</div>
            </div>
        </React.Fragment>
        );
	}
}

export default StudentS1;