/* eslint-disable */
import React, {Component} from 'react';
import _ from "lodash";
import ProfileImgUpload from '../../components/ProfileImgUpload';
import validation from "../../actions/validation";
import { FiCheckCircle } from "react-icons/fi";
import auth from "../../actions/auth";
import {toast} from "react-toastify";
import {get, put} from "../../services/Profile";
import guard from "../../actions/guard";
import LoadingSpinner from '../LoaderComponent/LoadingSpinner';
import { genders, learningStyle, numberOfKids, occupations, states, studentGrade } from '../../config/variables';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays,addDays } from 'date-fns';
//import momentTimezone from 'moment-timezone';
import {usTimeZones } from '../../config/variables.js'
import Select from 'react-select';
import { subjectList } from "../../services/Common";

class ParentStudentProfile extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            student_mapping: [],
            //validation
            isValid: false,
            loading: true,
            //image
            profile_picture: "https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png?1592828498",
            user_id:"",
            state:"",
            city:"",
            gender:"",
            zip_code:"",
            address:"",
            number_of_kids:"",
            learning_styles:"",
            occupation:"",
            timezone:"",
            current_grade:"",
            current_school:"",
            parent_name:"",
            parent_email:"",
            learning_style:"",
            struggle_topics:[],
            subjectList: [],
            user_struggle_topics:"",
            contact_teacher:"",
            extra_info:"",
            tutoring_interest:"",
            individual_learning:"",
            tutoringPicked: "",
            tutoring_start_date:"",
            teacher_name:"",
			teacher_phone_number:  "",
			teacher_email: "",
			teacher_subject: "",
        }
    }

    componentDidMount() {
        this.getProfile();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getProfile = () => {
        this._isMounted = true;
        get(auth.check().id).then((res) => {
            console.log("response",res);
            if(res.status === 200) {
                if(this._isMounted) {
                    this.setState({
                        ...this.state,
                        loading:false,
                        first_name: res.data.data.first_name,
                        last_name: res.data.data.last_name,
                        email: res.data.data.email,
                        phone_number: res.data.data.phone_number,
                        student_mapping: res.data.data.student_mapping,
                        profile_picture: res.data.data.details.profile_picture,
                        user_id:res.data.data.details.user_id,
                        gender:res.data.data.gender,
                        state:res.data.data.details.state,
                        city:res.data.data.details.city,
                        address:res.data.data.details.address,
                        zip_code:res.data.data.details.zip_code,
                        number_of_kids:res.data.data.details.number_of_kids,
                        occupation:res.data.data.details.occupation,
                        timezone:res.data.data.timezone,
                        current_grade:res.data.data.details.current_grade,
                        current_school:res.data.data.details.current_school,
                        parent_name:res.data.data.details.parent_name,
                        parent_email:res.data.data.details.parent_email,
                        learning_style:res.data.data.details.learning_style,
                        user_struggle_topics:res.data.data.details.struggle_topics,
                        contact_teacher:res.data.data.details.contact_teacher=== 1 ? "yes" : "no",
                        extra_info:res.data.data.details.extra_info,
                        tutoring_interest:res.data.data.details.tutoring_interest,
                        individual_learning:res.data.data.details.individual_learning=== 1 ? "yes" : "no",
                        tutoring_start_date:res.data.data.details.tutoring_start_date,
                        tutoringPicked:res.data.data.details.tutoring_start_date?new Date(res.data.data.details.tutoring_start_date):"",
                        teacher_name: res.data.data.details.teacher_name?res.data.data.details.teacher_name : "",
                        teacher_phone_number: res.data.data.details.teacher_phone_number?res.data.data.details.teacher_phone_number : "",
                        teacher_email: res.data.data.details.teacher_email?res.data.data.details.teacher_email : "",
                        teacher_subject: res.data.data.details.teacher_subject?res.data.data.details.teacher_subject : "", 
                }, () => {
                    this.fetchSubList();
                });
                }
            }else {
               
                toast.error("Something went wrong!");
                console.log("first error block");
            }
        }).catch((err) => {
           
            toast.error("Something went wrong!");
            console.log("second error block",err);
        })
    }

    fetchSubList = () => {
        subjectList().then((res) => {
          if (res.status === 200) {
            const userSturggletopics = this.state.user_struggle_topics;
            const dataFormated = userSturggletopics.split(",")
            const subjectNames = Object.values(res.data.subjects).map((item)=>item);
            let userTopics = [];
            const subjectList =_.flatten(subjectNames); 
              dataFormated.length && dataFormated.map(item=>{ 
				_.find(subjectList, (subject) => {
                        if (subject.sub_subject_name === item) {
						userTopics.push({
                                value:subject.id,
                                label : item
                            })
                        }
                    });
              })
            this.setState(   {
                    ...this.state,
                    struggle_topics: userTopics
                    });            
            this.setState({
              ...this.state,
              subjectList: Object.values(res.data.subjects).map((sub) => (
                  {
                    label: sub[0].subject_group_name,
                    options: sub.map((inner) => (
                      { value: inner.id, label: inner.sub_subject_name }
                    ))
                  }
                )),
            })
          }
        }).catch((err) => {
        })
      }
    update = () => {
        const { first_name, 
            last_name, 
            email, 
            phone_number, 
            profile_picture, 
            student_mapping,
            state,
            gender,
            zip_code,
            address,
            number_of_kids,
            occupation,
            city,
            timezone,
            current_grade,
            current_school,
            parent_name,
            parent_email,
            learning_style,
            struggle_topics,
            contact_teacher, 
            extra_info, 
            tutoring_interest, 
            individual_learning, 
            tutoring_start_date,
            teacher_name,
            teacher_phone_number,
            teacher_email,
            teacher_subject } = this.state;
        let input = {
            basic: {
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone_number: phone_number,
                timezone:timezone
            },
            details: {
                profile_picture,
                state,
                city,
                zip_code,
                address,
                number_of_kids,
                occupation,
                current_grade,
                current_school,
                parent_name,
                parent_email,
                learning_style,
                struggle_topics,
                contact_teacher:contact_teacher=="yes"?1:0, 
                extra_info, 
                tutoring_interest, 
                individual_learning:individual_learning=="yes"?1:0, 
                tutoring_start_date,
                teacher_name,
                teacher_phone_number,
                teacher_email,
                teacher_subject

            },
            student: []
        }
        this.setState({
            ...this.state,
            isValid: true,
            loading: true
        }, () => {
            if(guard.role()==='parent'?(validation.text(first_name)
                && validation.text(last_name)
                && validation.email(email)
                && validation.mobile(phone_number)
                && validation.text(state)
                && validation.text(city)
                // && validation.text(gender)
                && validation.zipCode(zip_code)
                && validation.text(address)
                // && validation.text(occupation)
                && validation.positiveNumber(number_of_kids)
                && validation.text(timezone))
                :(validation.text(first_name)
                && validation.text(last_name)
                && validation.email(email)
                && validation.mobile(phone_number)
                && validation.text(timezone)
                && validation.text(city)
                && validation.text(current_grade)
                && validation.text(current_school)
                && validation.text(parent_name)
                && validation.text(parent_email))
                && validation.text(learning_style)
                && validation.zipCode(zip_code)
                && validation.text(struggle_topics)
                && validation.text(contact_teacher)
                && validation.text(extra_info)
                && validation.text(tutoring_interest)
                && validation.text(individual_learning)
                && validation.text(tutoring_start_date)) {
                let flag = false;
                if(student_mapping.length === 0) {
                    flag = true;
                }
                for(let i = 0; i < student_mapping.length; i++) {
                    if(validation.text(student_mapping[i].student_user.first_name)
                        && validation.text(student_mapping[i].student_user.last_name)
                        && validation.email(student_mapping[i].student_user.email)
                        && validation.mobile(student_mapping[i].student_user.phone_number)
                        && validation.text(student_mapping[i].learning_style)
                        && validation.text(student_mapping[i].current_grade)
                        && validation.text(student_mapping[i].current_school)
                        && validation.text(student_mapping[i].student_user.timezone)
                    ) {
                        let newUser = student_mapping[i].new? true: false;
                        if(newUser){
                            if(validation.passwordStrength(student_mapping[i].student_user.password, "medium")
                            && validation.passwordStrength(student_mapping[i].student_user.password_confirmation, "medium")
                            && student_mapping[i].student_user.password === student_mapping[i].student_user.password_confirmation
                            && validation.text(student_mapping[i].student_user.dob)
                            && validation.text(student_mapping[i].student_user.timezone)){
                                flag = true;
                            }else {
                                flag = false;
                                break;
                            }
                            input.student.push({
                                id: student_mapping[i].student_user.id,
                                first_name: student_mapping[i].student_user.first_name,
                                last_name: student_mapping[i].student_user.last_name,
                                phone_number: student_mapping[i].student_user.phone_number,
                                email: student_mapping[i].student_user.email,
                                timezone:student_mapping[i].student_user.timezone,
                                verify_status:true,
                                new : true,
                                password:student_mapping[i].student_user.password,
                                details: {
                                    profile_picture: student_mapping[i].profile_picture,
                                    learning_style: student_mapping[i].learning_style,
                                    current_grade: student_mapping[i].current_grade,
                                    current_school:student_mapping[i].current_school,
                                    parent_id:student_mapping[i].parent_id,
                                    dob:student_mapping[i].student_user.dob,
                                    parent_name:student_mapping[i].parent_name,
                                    parent_phone_number:student_mapping[i].parent_phone_number,
                                    parent_email:student_mapping[i].parent_email,
                                    city:student_mapping[i].city,
                                    struggle_topics:student_mapping[i].struggle_topics
                                }
                            });

                        }else{
                            input.student.push({
                                id: student_mapping[i].student_user.id,
                                first_name: student_mapping[i].student_user.first_name,
                                last_name: student_mapping[i].student_user.last_name,
                                phone_number: student_mapping[i].student_user.phone_number,
                                email: student_mapping[i].student_user.email,
                                timezone:student_mapping[i].student_user.timezone,
                                details: {
                                    profile_picture: student_mapping[i].profile_picture,
                                    learning_style: student_mapping[i].learning_style,
                                    current_grade: student_mapping[i].current_grade,
                                    current_school:student_mapping[i].current_school,
                                    parent_id:student_mapping[i].parent_id,
                                    parent_name:student_mapping[i].parent_name,
                                    parent_phone_number:student_mapping[i].parent_phone_number,
                                    parent_email:student_mapping[i].parent_email,
                                    city:student_mapping[i].city,
                                    struggle_topics:student_mapping[i].struggle_topics
                                }
                            });
                        }
                        flag = true;
                    }else {
                        flag = false;
                        break;
                    }
                }
                if(flag) {
                    put(auth.check().id, input).then((res) => {
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                        if(res.status === 200) {
                            this.setState({
                                profile_picture:input.details.profile_picture
                            })
                            auth.update("first_name", input.basic.first_name);
                            auth.update("last_name", input.basic.last_name);
                            auth.update("profile_picture", profile_picture);
                            toast.success(res.data.message);
                            window.location.href="/profile";
                        }else {
                            toast.error(res.data.message);
                        }
                    }).catch((err) => {
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                        toast.error("Something went wrong!");
                    });
                }else {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Validation Error!");
                }
            }else {
                this.setState({
                    ...this.state,
                    loading: false
                })
                toast.error("Validation Error!");
            }
        })
    }

    onChange = (from, e) => {
        e.persist();
        if(from === "") {
            this.setState({
                ...this.state,
                [e.target.name]: e.target.value,
                isValid: false
            });
        }else {
            this.state.student_mapping[parseInt(from)].student_user[e.target.name] = e.target.value;
            this.setState({
                ...this.state,
                student_mapping: this.state.student_mapping,
                isValid: false
            });
        }
    };


    onLearningChange = (from, e) => {
        e.persist();
        this.state.student_mapping[parseInt(from)][e.target.name] = e.target.value;
            this.setState({
            
                ...this.state,
                student_mapping: this.state.student_mapping,
                isValid: false
            });
    };

    getFile = (data) => {
        if(data.status === "success") {
            if(data.prefix === "profile") {
                this.setState({
                    ...this.state,
                    loading: data.loading,
                    profile_picture: data.resp
                }, () => {
                    toast[data.status](data.msg);
                })
            }else {
                this.state.student_mapping[parseInt(data.prefix)].profile_picture = data.resp;
                this.setState({
                    ...this.state,
                    loading: data.loading,
                    student_mapping: this.state.student_mapping
                }, () => {
                    toast[data.status](data.msg);
                })
            }
        }else {
            toast[data.status](data.msg);
        }
	}
    selectDropdownValue=(e)=>{
        e.persist();
        if(e.target.name ==='stateofResidence'){
            this.setState({
                state: e.target.value
            })
        }
        if(e.target.name ==='gender'){
            this.setState({
                gender: e.target.value
            })
        }
        if(e.target.name === 'number_of_kids'){
            this.setState({
                number_of_kids: e.target.value
            })
        }
        if(e.target.name === 'occupation'){
            this.setState({
                occupation: e.target.value
            })
        }
    }
    newStudentCreation=()=>{

        const {email, student_mapping} = this.state;
        const count = student_mapping.length+1;
		const  getUsername= email.split("@")[0];
		const  emailService= email.split("@")[1];
		const random = Math.floor(100 + Math.random() * 900);
		const studentMail = getUsername+"+"+count+random+"@"+emailService;

        let  data = {
            profile_picture:"",
            learning_style: "",
            current_grade: "",
            current_school:"",
            timezone:"",
            parent_id:this.state.user_id,
            parent_name:this.state.first_name,
            parent_phone_number:this.state.phone_number,
            parent_email:this.state.email,
            student_user: {
                first_name:"",
                last_name: "",
                phone_number:"",
                email:studentMail, 
                verify_status:1,
                dob:"",
                dobPicked:""   
            },
            new:true,
        };
            this.setState({
                ...this.state,
                student_mapping: _.concat(this.state.student_mapping, data),
                
            });
    }
    setDob = (from,date)=>{
        this.state.student_mapping[parseInt(from)].student_user['dob'] = moment(date).format("YYYY-MM-DD"),
        this.state.student_mapping[parseInt(from)].student_user['dobPicked'] = date;
        this.setState({
            ...this.state,
            student_mapping: this.state.student_mapping,
            isValid: false
        }); 
    }
    handleMultiChange  = (option) =>  {
        console.log("option",option);
        this.setState(
        {
          ...this.state, 
          struggle_topics: option,
          isValid: false
        })
      }
      setTurorinBegins = (date) => {
        this.setState({
            ...this.state,
            tutoringPicked: date!=null?date:"",
            tutoring_start_date: date!=null?moment(date).format("YYYY-MM-DD"):"",
            isValid: false
        })
    }
    render() {
        const { first_name, 
            last_name, 
            phone_number, 
            email, 
            profile_picture, 
            student_mapping, 
            isValid, 
            loading,
            state,
            gender,
            zip_code,
            address,
            number_of_kids,
            occupation,
            city,
            timezone,
            current_grade,
            current_school,
            parent_name,
            parent_email,
            learning_style,
            struggle_topics,
            subjectList,
            contact_teacher, 
            extra_info, 
            tutoring_interest, 
            individual_learning, 
            tutoringPicked, 
            tutoring_start_date,
            teacher_name ,
			teacher_phone_number,
			teacher_email,
			teacher_subject,
        } = this.state;
		const yesterday = moment().add(-1, 'days');
		const maxdate = moment(yesterday).format('YYYY-MM-DD');
        //const getAlltimeZones = momentTimezone.tz.zonesForCountry("US")
        const getAlltimeZones = usTimeZones;
        return (
            <>
            {loading?<LoadingSpinner/>:
            
            <div className="profile-tab">
                <h5 className='bold serif mb-4'>Profile Settings</h5>
                <hr/>
                <div className="row">
                    <section className="col-md-2 mb-4">
                        <ProfileImgUpload
                            maxSize={5} 
                            response={this.getFile.bind(this)} 
                            prefix={"profile"} 
                            mimeTypes={['image/png', 'image/jpeg', 'image/jpg']} 
                            existingFile={profile_picture}
                        />
                    </section>
                    <section className="col-md-6 offset-md-1 px-md-4 py-md-3">
                        <label className="col-form-label text-teal bold mb-4 text-capitalize">{guard.role()} Details</label>
                        <div className='row'>
                            <div className='form-group col-md-6'>
                                <label className="txt-14" htmlFor='first_name'>First Name</label>
                                <div className=''>
                                    <input
                                        type='text'
                                        name='first_name'
                                        value={first_name}
                                        placeholder="Julian"
                                        disabled
                                        //onChange={this.onChange.bind(this, '')}
                                        className={`form-control light ${(isValid && (validation.text(first_name) ? '' : 'error is-invalid')).toString()}`}
                                    />
                                    <div className="invalid-feedback">
                                        Please enter your first name.
                                    </div>
                                </div>
                            </div>
                            <div className='form-group col-md-6'>
                                <label className="txt-14" htmlFor='last_name'>Last Name</label>
                                <div className=''>
                                    <input
                                        type='text'
                                        name='last_name'
                                        placeholder="Moore"
                                        value={last_name}
                                        disabled
                                        //onChange={this.onChange.bind(this, '')}
                                        className={`form-control light ${(isValid && (validation.text(last_name) ? '' : 'error is-invalid')).toString()}`}
                                    />
                                    <div className="invalid-feedback">
                                        Please enter your last name.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group col-md-6'>
                                <label className="txt-14" htmlFor='first_name'>Email</label>
                                <div className='input-group'>
                                    <input
                                        type='email'
                                        name='first_name'
                                        className={`form-control light`}
                                        placeholder="julianmoore@gmail.com"
                                        disabled
                                        value={email}
                                    />
                                    <div className="invalid-feedback">
                                        Please enter your first name.
                                    </div>
                                    <div className='input-group-append'>
                                        <span className='input-group-text' id='last_name'>
                                            <FiCheckCircle className="text-primary"/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group col-md-6'>
                                <label className="txt-14" htmlFor='last_name'>Mobile Number</label>
                                <div className=''>
                                    <input type='text'
                                        name='phone_number'
                                        placeholder="512-408-6025"
                                        value={phone_number}
                                        onChange={this.onChange.bind(this, '')}
                                        className={`form-control light ${(isValid && (validation.mobile(phone_number) ? '' : 'error is-invalid')).toString()}`}
                                    />
                                    <div className="invalid-feedback">
                                        Please enter valid, 10 digits long mobile no.
                                    </div>
                                </div>
                            </div>
                            <div className='form-group col-md-6'>
                            <label className="txt-14" htmlFor='timezone'>Time Zone</label>
                                <select className={`custom-select form-control light ${(isValid && (validation.text(timezone) ? '' : 'error is-invalid')).toString()}`}
                                        id="timezone"
                                        name="timezone"
                                        value={timezone} 
                                        onChange={this.onChange.bind(this, '')}>
                                        <option value="">Choose One</option>
                                        {Object.keys(getAlltimeZones).map((timeZoneName, key) => (
                                            <option key={key} value={getAlltimeZones[timeZoneName]} >{timeZoneName}</option>
                                        ))}
                                </select>
                            <div className="invalid-feedback">
                            Select time zone.
                             </div>
                         </div>
                            {guard.role()==='parent'? <><div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='state'>State Of Residence</label>
                                        <select
                                            id="stateofResidence"
                                            value={state}
                                            name="stateofResidence"
                                            onChange={(e) => this.selectDropdownValue(e)}
                                            className={`custom-select ${(isValid && (validation.text(state) ? '' : 'error is-invalid')).toString()}`}>
                                            <option value="">Choose One</option>
                                            {states.map((state, key) => (
                                                <option key={key} value={state}>{state}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            Please enter your state of residence.
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                            <label className="txt-14" htmlFor='city'>City</label>
                                            <input type='text'
                                                    name='city'
                                                    placeholder="city"
                                                    value={city}
                                                    onChange={this.onChange.bind(this, '')}
                                                    className={`form-control light ${(isValid && (validation.text(city) ? '' : 'error is-invalid')).toString()}`}
                                                />
                                            <div className="invalid-feedback">
                                            Please enter your city.
                                        </div>
                                        </div>
                                    <div className='form-group col-md-6'>
                                            <label className="txt-14" htmlFor='address'>Address</label>
                                            <input type='text'
                                                    name='address'
                                                    placeholder="address"
                                                    value={address}
                                                    onChange={this.onChange.bind(this, '')}
                                                    className={`form-control light ${(isValid && (validation.text(address) ? '' : 'error is-invalid')).toString()}`}
                                                />
                                            <div className="invalid-feedback">
                                            Please enter your address.
                                        </div>
                                        </div>
                                       {/* <div className='form-group col-md-6'>
                                            <label className="txt-14" htmlFor='gender'>Gender</label>
                                            <select
                                                id="gender"
                                                value={gender}
                                                name="gender"
                                                onChange={(e) => this.selectDropdownValue(e)}
                                                className={`custom-select ${(isValid && (validation.text(gender) ? '' : 'error is-invalid')).toString()}`}>
                                                <option value="">Choose One</option>
                                                {genders.map((gender, key) => (
                                                    <option key={key} value={gender}>{gender}</option>
                                                ))}
                                            </select>
                                            <div className="invalid-feedback">
                                            Please enter your gender.
                                            </div>
                                        </div> */}
                                        <div className='form-group col-md-6'>
                                            <label className="txt-14" htmlFor='zip_code'>Zip Code</label>
                                            <input type='text'
                                                    name='zip_code'
                                                    placeholder="zip code"
                                                    value={zip_code}
                                                    maxLength="5"
                                                    onChange={this.onChange.bind(this, '')}
                                                    className={`form-control light ${(isValid && (validation.zipCode(zip_code) ? '' : 'error is-invalid')).toString()}`}
                                                />
                                            <div className="invalid-feedback">
                                            please enter zip-code.it should be a maximum of 5 digits
                                        </div>
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label className="txt-14" htmlFor='number_of_kids'>Number Of Kids</label>
                                            <select
                                                id="number_of_kids"
                                                value={number_of_kids}
                                                name="number_of_kids"
                                                onChange={(e) => this.selectDropdownValue(e)}
                                                className={`custom-select ${(isValid && (validation.positiveNumber(number_of_kids) ? '' : 'error is-invalid')).toString()}`}>
                                                <option value="">Choose One</option>
                                                {numberOfKids.map((numberOfKids, key) => (
                                                    <option key={key} value={numberOfKids}>{numberOfKids}</option>
                                                ))}
                                            </select>
                                            <div className="invalid-feedback">
                                            Please enter number of kids.
                                        </div>
                                        </div>
                                        
                                        {/* <div className='form-group col-md-6'>
                                            <label className="txt-14" htmlFor='occupation'>Occupation</label>
                                            <select
                                                id="occupation"
                                                value={occupation}
                                                name="occupation"
                                                onChange={(e) => this.selectDropdownValue(e)}
                                                className={`custom-select ${(isValid && (validation.text(occupation) ? '' : 'error is-invalid')).toString()}`}>
                                                <option value="">Choose One</option>
                                                {occupations.map((occupations, key) => (
                                                    <option key={key} value={occupations}>{occupations}</option>
                                                ))}
                                            </select>
                                            <div className="invalid-feedback">
                                                Please choose your occupation.
                                            </div>
                                        </div> */}
                                        </>
                                    :
                                    <>
                                   <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='state'>State Of Residence</label>
                                        <select
                                            id="stateofResidence"
                                            value={state}
                                            name="stateofResidence"
                                            onChange={(e) => this.selectDropdownValue(e)}
                                            className={`custom-select ${(isValid && (validation.text(state) ? '' : 'error is-invalid')).toString()}`}>
                                            <option value="">Choose One</option>
                                            {states.map((state, key) => (
                                                <option key={key} value={state}>{state}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            Please enter your state of residence.
                                     </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                            <label className="txt-14" htmlFor='city'>City</label>
                                            <input type='text'
                                                    name='city'
                                                    placeholder="city"
                                                    value={city}
                                                    onChange={this.onChange.bind(this, '')}
                                                    className={`form-control light ${(isValid && (validation.text(city) ? '' : 'error is-invalid')).toString()}`}
                                                />
                                            <div className="invalid-feedback">
                                            Please enter your city.
                                        </div>
                                        </div>
                                    <div className='form-group col-md-6'>
                                            <label className="txt-14" htmlFor='zip_code'>Zip Code</label>
                                            <input type='text'
                                                    name='zip_code'
                                                    placeholder="zip code"
                                                    value={zip_code}
                                                    maxLength="5"
                                                    onChange={this.onChange.bind(this, '')}
                                                    className={`form-control light ${(isValid && (validation.zipCode(zip_code) ? '' : 'error is-invalid')).toString()}`}
                                                />
                                            <div className="invalid-feedback">
                                            please enter zip-code.it should be a maximum of 5 digits
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                            <label className="txt-14" htmlFor='current_grade'>Grade</label>
                                            <select
                                            id="current_grade" 
                                            value={current_grade}
                                            name="current_grade"
                                            className={`custom-select ${(isValid && (validation.text(current_grade) ? '' : 'error is-invalid')).toString()}`}
                                            onChange={this.onChange.bind(this, '')}>
                                            <option value="">Choose One</option>
                                            {
                                                studentGrade.map((studentgrade, key) => (
                                                    <option key={key} value={studentgrade}>{studentgrade}</option>
                                                ))
                                            }
                                        </select>
                                            <div className="invalid-feedback">
                                            Please enter your grade.
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='current_school'>School</label>
                                        <div>
                                            <input type='text'
                                                name='current_school'
                                                placeholder="school"
                                                value={current_school}
                                                onChange={this.onChange.bind(this,'')}
                                                className={`form-control light ${(isValid && (validation.text(current_school) ? '' : 'error is-invalid')).toString()}`}
                                            />
                                           <div className="invalid-feedback">
                                                Please enter your school.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='parent_name'>Parent Name</label>
                                        <div>
                                            <input type='text'
                                                name='parent_name'
                                                placeholder="parent name"
                                                value={parent_name}
                                                onChange={this.onChange.bind(this,'')}
                                                className={`form-control light ${(isValid && (validation.text(parent_name) ? '' : 'error is-invalid')).toString()}`}
                                            />
                                           <div className="invalid-feedback">
                                                Please enter your parent name.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='parent_email'>Parent Email</label>
                                        <div>
                                            <input type='text'
                                                name='parent_email'
                                                placeholder="parent email"
                                                value={parent_email}
                                                onChange={this.onChange.bind(this,'')}
                                                className={`form-control light ${(isValid && (validation.text(parent_email) ? '' : 'error is-invalid')).toString()}`}
                                            />
                                           <div className="invalid-feedback">
                                                Please enter your parent email.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='learning_style'>Learning Style</label>
                                        <select
                                            id="learning_style" 
                                            value={learning_style}
                                            name="learning_style"
                                            onChange={this.onChange.bind(this,'')}
                                            className={`custom-select ${(isValid && (validation.text(learning_style) ? '' : 'error is-invalid')).toString()}`}>
                                            <option value="">Choose One</option>
                                            {
                                                learningStyle.map((learning_style, key) => (
                                                    <option key={key} value={learning_style}>{learning_style}</option>
                                                ))
                                            }
                                        </select>
                                        <div className="invalid-feedback">
                                            Please enter your learning style.
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                    <label className="txt-14" htmlFor="struggle_topics">What subjects are you struggling in?</label>
                                    <Select
                                    id='struggle_topics' 
                                    className={`form-control h-auto border-0 p-0 ${(isValid && (validation.text(struggle_topics) ? '' : 'error is-invalid')).toString()}`} isMulti 
                                    value = {struggle_topics} 
                                    onChange={this.handleMultiChange} 
                                    options={subjectList} />
                                    <div className="invalid-feedback">
                                        Struggling subjects are required.
                                    </div>
                                    </div>
                                    <div className='form-group col-md-12'>
                                            <label htmlFor='contact_teacher'>SiSTEM Tutoring Agency aligns students learning plans with their classroom instruction by collaborating with their teachers at school. Do you give SiSTEM permission to contact your student's teacher?  </label>
                                            <select className={`custom-select form-control light ${(isValid && (validation.text(contact_teacher) ? '' : 'error is-invalid')).toString()}`}
                                                    id="contact_teacher" name="contact_teacher" value={contact_teacher}
                                                    onChange={this.onChange.bind(this, '')}>
                                                <option value="">Choose One</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div> 
                                        {contact_teacher === "yes" ? <>
                            
                            <div className='form-row'>
                            <div className='form-group col-md-6'>
                        <label htmlFor='teacher_name'>Teacher's Name <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='teacher_name'
                            id="teacher_name"
                            value={teacher_name}
                            onChange={this.onChange.bind(this, '')}
                            className={`form-control light `}
                         /> 
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='teacher_phone_number'>Teacher's Phone Number <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='teacher_phone_number'
                            id="teacher_phone_number"
                            value={teacher_phone_number}
                            onChange={this.onChange.bind(this, '')}
                            className={`form-control light `}
                         /> 
                    </div>
                            <div className='form-group col-md-6'>
                                <label htmlFor='teacher_email'>Teacher's Email <span className="sub-text">(optional)</span></label>
                                <input
                                    type='email'
                                    name='teacher_email'
                                    id="teacher_email"
                                    value={teacher_email}
                                    onChange={this.onChange.bind(this, '')}
                                    className={`form-control light `}
                                /> 
                            </div>
                            <div className='form-group col-md-6'>
                        <label htmlFor='teacher_subject'>Subject they teach <span className="sub-text">(optional)</span></label>
                        <input
                            type='text'
                            name='teacher_subject'
                            id="teacher_subject"
                            value={teacher_subject}
                            onChange={this.onChange.bind(this, '')}
                            className={`form-control light `}
                         /> 
                    </div>
                                
                            </div></>: null }
                                        <div className='form-group col-md-12'>
                                            <label htmlFor='individual_learning'>Will you or your student be needing an individualized learning plan from SiSTEM Tutoring Agency?</label>
                                            <select className={`custom-select form-control light ${(isValid && (validation.text(individual_learning) ? '' : 'error is-invalid')).toString()}`}
                                                    id="individual_learning" name="individual_learning" value={individual_learning}
                                                    onChange={this.onChange.bind(this, '')}>
                                                <option value="">Choose One</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div>
                                        <div className='form-group col-md-12 signup-calender'>
                                            <label htmlFor='tutoring_start_date' className={`${(isValid && (validation.text(tutoring_start_date) ? '' : 'error is-invalid')).toString()}`}>When would you like tutoring to begin?</label>
                                            <DatePicker placeholderText="mm-dd-yyyy" dateFormat="MM-dd-yyyy" name='tutoring_start_date' id="tutoring_start_date" selected={tutoringPicked} minDate={addDays(new Date(), 1)} onChange={this.setTurorinBegins} dropdownMode="select" showMonthDropdown showYearDropdown className={`form-control light ${(isValid && (validation.text(tutoring_start_date)  ? '' : 'error is-invalid')).toString()}`} />
                                            <div className="invalid-feedback">
                                                Select a date.
                                            </div>
                                        </div> 
                                        <div className='form-group col-md-12'>
                                            <label htmlFor='tutoring_interest'>Are you interested in in-person tutoring, online tutoring or both?</label>
                                            <select className={`custom-select form-control light ${(isValid && (validation.text(tutoring_interest) ? '' : 'error is-invalid')).toString()}`}
                                                    id="tutoring_interest" name="tutoring_interest" value={tutoring_interest}
                                                    onChange={this.onChange.bind(this, '')}>
                                                <option value="">Choose One</option>
                                                <option value="in-person">In-person</option>
                                                <option value="online">Online</option>
                                                <option value="both">Both</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div> 
                                        <div className='form-group col-md-12'>
                                            <label htmlFor='extra_info'>Is there anything else you'd like to share with us?</label>
                                            <textarea className={`form-control ${(this.state.isValid && (validation.text(this.state.extra_info) ? '' : 'error is-invalid')).toString()}`} name="extra_info" id="extra_info" rows="3" value={extra_info} onChange={(e) => this.setState({...this.state, extra_info: e.target.value})}></textarea>
                                            <div className="invalid-feedback">
                                                This field is required.
                                            </div>
                                        </div> 
                                </>
                                }
                        </div>
                        <hr className='profile-separate'/>
                    </section>
                </div>

                {/* student details */}
                {
                     student_mapping.length > 0 && student_mapping.map((student, key) => {
                        let pswError = '';
						if(!validation.text(student.student_user.password)){
							pswError = "Please enter a valid password."
						}else if(!validation.passwordStrength(student.student_user.password, "medium")){
							pswError = "You have entered a weak password."
						}else{
							pswError = "Password miss-matching."
						}
						let pswConfirmError = '';
						if(!validation.text(student.student_user.password_confirmation)){
							pswConfirmError = "Please confirm your password."
						}else if(student.student_user.password != student.student_user.password_confirmation){
							pswConfirmError = "Password miss-matching."
						}else if(!validation.passwordStrength(student.student_user.password_confirmation, "medium")){
							pswConfirmError = "You have entered a weak password."
						}
                        return <div className="row" key={key}>
                            <section className="col-md-2 mb-4">
                                <ProfileImgUpload
                                    maxSize={5} 
                                    response={this.getFile.bind(this)} 
                                    prefix={key} 
                                    mimeTypes={['image/png', 'image/jpeg', 'image/jpg']} 
                                    existingFile={student?.profile_picture}
                                />
                            </section>
                            <section className="col-md-6 offset-md-1 px-md-4 py-md-3">
                            <label className="col-form-label text-teal bold mb-4 text-capitalize">Student Details: {key + 1}</label>
                                <div className='row'>
                                 <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='first_name'>First Name</label>
                                        <div className=''>
                                        {student.new?
                                            <input
                                                type='text'
                                                name='first_name'
                                                value={student.student_user?.first_name}
                                                placeholder="Julian"
                                                onChange={this.onChange.bind(this, key)}
                                                className={`form-control light ${(isValid && (validation.text(student.student_user?.first_name) ? '' : 'error is-invalid')).toString()}`}
                                            />:
                                            <input
                                                type='text'
                                                name='first_name'
                                                value={student.student_user?.first_name}
                                                placeholder="Julian"
                                                disabled
                                                className={'form-control light'}
                                            />}
                                            <div className="invalid-feedback">
                                                Please enter your first name.
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='last_name'>Last Name</label>
                                        <div className=''>
                                        {student.new?<input
                                                type='text'
                                                name='last_name'
                                                placeholder="Moore"
                                                value={student.student_user?.last_name}
                                                onChange={this.onChange.bind(this, key)}
                                                className={`form-control light ${(isValid && (validation.text(student.student_user?.last_name) ? '' : 'error is-invalid')).toString()}`}
                                            />: <input
                                            type='text'
                                            name='last_name'
                                            placeholder="Moore"
                                            disabled
                                            value={student.student_user?.last_name}
                                            className={'form-control light'}
                                        />}
                                           <div className="invalid-feedback">
                                                Please enter your last name.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='email'>Email</label>
                                        <div className='input-group'>
                                            {student.new? <input
                                                type='email'
                                                name='email'
                                                disabled
                                                placeholder="julianmoore@gmail.com"
                                                value={student.student_user?.email}
                                                className={`form-control light ${(isValid && (validation.email(student.student_user?.email) ? '' : 'error is-invalid')).toString()}`}
                                            />: 
                                            <input
                                            type='email'
                                            name='email'
                                            value={student.student_user?.email}
                                            placeholder="julianmoore@gmail.com"
                                            disabled
                                            className={`form-control light`}
                                            />}
                                            <div className="invalid-feedback">
                                                Please enter your email.
                                            </div>
                                            <div
                                            className={`input-group-append ${(isValid && (validation.email(student.student_user?.email) ? '' : 'd-none'))}`}>
                                                <span className='input-group-text' id='email'>
                                                    <FiCheckCircle className="text-primary"/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='phone_number'>Mobile Number</label>
                                        <div>
                                            <input type='text'
                                                name='phone_number'
                                                placeholder="512-408-6025"
                                                value={student.student_user?.phone_number}
                                                onChange={this.onChange.bind(this, key)}
                                                className={`form-control light ${(isValid && (validation.mobile(student.student_user?.phone_number) ? '' : 'error is-invalid')).toString()}`}
                                            />
                                            <div className="invalid-feedback">
                                                Please enter valid, 10 digits long mobile no.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='timezone'>Time Zone</label>
                                        <select className={`custom-select ${(isValid && (validation.text(student.student_user?.timezone) ? '' : 'error is-invalid')).toString()}`}
                                            id="timezone"
                                            name="timezone"
                                            value={student.student_user?.timezone} 
                                            onChange={this.onChange.bind(this, key)}>
                                            <option value="">Choose One</option>
                                            {Object.keys(getAlltimeZones).map((timeZoneName, key) => (
                                            <option key={key} value={getAlltimeZones[timeZoneName]} >{timeZoneName}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                        Select time zone.
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='last_name'>Learning Style</label>
                                        <select
                                            id="learning_style" 
                                            value={student?.learning_style}
                                            name="learning_style"
                                            onChange={this.onLearningChange.bind(this, key)}
                                            className={`custom-select ${(isValid && (validation.text(student?.learning_style) ? '' : 'error is-invalid')).toString()}`}>
                                            <option value="">Choose One</option>
                                            {
                                                learningStyle.map((learning_style, key) => (
                                                    <option key={key} value={learning_style}>{learning_style}</option>
                                                ))
                                            }
                                        </select>
                                        <div className="invalid-feedback">
                                            Please enter your learning style.
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='current_grade'>Grade</label>
                                        <select
                                            id="current_grade" 
                                            value={student?.current_grade}
                                            name="current_grade"
                                            className={`custom-select ${(isValid && (validation.text(student?.current_grade) ? '' : 'error is-invalid')).toString()}`}
                                            onChange={this.onLearningChange.bind(this, key)}>
                                            <option value="">Choose One</option>
                                            {
                                                studentGrade.map((studentgrade, key) => (
                                                    <option key={key} value={studentgrade}>{studentgrade}</option>
                                                ))
                                            }
                                        </select>
                                        <div className="invalid-feedback">
                                            Please enter your Grade.
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label className="txt-14" htmlFor='current_school'>School</label>
                                        <div>
                                            {student.new?<input type='text'
                                                name='current_school'
                                                placeholder="school"
                                                value={student?.current_school}
                                                onChange={this.onLearningChange.bind(this,key)}
                                                className={`form-control light ${(isValid && (validation.text(student.current_school) ? '' : 'error is-invalid')).toString()}`}
                                            />: <input type='text'
                                            name='current_school'
                                            placeholder="school"
                                            value={student?.current_school}
                                            disabled
                                            className={'form-control light'}
                                        />}
                                           <div className="invalid-feedback">
                                                Please enter your school.
                                            </div>
                                        </div>
                                    </div>
                                    {student.new?
                                    <div className='form-group col-md-6 signup-calender'>
									<label htmlFor='dob' className={`${(isValid && (validation.text(student.student_user.dob) ? '' : 'error is-invalid')).toString()}`}>Date of Birth</label>
                                    <DatePicker placeholderText="mm-dd-yyyy" dateFormat="MM-dd-yyyy" name='dob' id="dob" selected={student.student_user.dobPicked} maxDate={subDays(new Date(), 1)} onChange={this.setDob.bind(this,key)} dropdownMode="select"  showMonthDropdown showYearDropdown className={`form-control light ${(isValid && (validation.text(student.student_user.dob) ? '' : 'error is-invalid')).toString()}`}/>

									<div className="invalid-feedback">
										Select your dob.
									</div>
								    </div>:<></>
                                    }
                                </div>
                                {student.new?
                                <div className='form-row'>
                                    <div className='form-group col-md-6'>
                                        <label htmlFor='password'>Password</label>
                                        <div className='input-group'>
                                            <input
                                                type='password'
                                                name='password'
                                                value={student.student_user.password}
                                                onChange={this.onChange.bind(this, key)}
                                                className={`form-control light ${(isValid && ((validation.passwordStrength(student.student_user.password, "medium") && student.student_user.password === student.student_user.password_confirmation) ? '' : 'error is-invalid')).toString()}`}
                                            />
                                            <div className="invalid-feedback">
                                                {pswError}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label htmlFor='password_confirmation'>Confirm Password</label>
                                        <div className='input-group'>
                                            <input
                                                type='password'
                                                name='password_confirmation'
                                                value={student.student_user.password_confirmation}
                                                onChange={this.onChange.bind(this, key)}
                                                className={`form-control light ${(isValid && ((validation.passwordStrength(student.student_user.password_confirmation, "medium") && student.student_user.password === student.student_user.password_confirmation) ? '' : 'error is-invalid')).toString()}`}
                                            />
                                            <div className="invalid-feedback">
                                            {pswConfirmError}
                                            </div>
                                        </div>
                                    </div>
                                </div>:<></>}
                                <hr className='profile-separate'/>
                            </section>
                        </div>
    })
                }
                {
                    guard.role()==='parent'? <div className='d-flex justify-content-center align-items-center mt-5'>
					<button
						disabled={loading}
						className='btn btn-link btn-padding--lg text-primary mb-3'
						onClick={this.newStudentCreation}
					>
						<FaPlus size={24}/> ADD STUDENT
					</button>
				</div>:null
                }
                <div className="d-flex justify-content-end profile-cta">
                    <button
                        className="btn btn-primary btn-padding--md text-white"
                        onClick={this.update}
                        disabled={loading}>
                        Update
                    </button>
                </div>
            </div>
    }
            </>
        );
    }
}

export default ParentStudentProfile;