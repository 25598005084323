import React from 'react'
import './Testimonial.style.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from 'prop-types';

export default function Testimonial({heading}) {
  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const userReviews =[
    {  
     userName: "Gloria Oyekanmi",
     userReview:"The tutors are very knowledgeable and also very reliable. They are very easy to work with and very accessible! Booking with this tutoring agency is not difficult at all and they are also transparent regarding the prices which is much appreciated. Would definitely recommend them to anyone needing tutoring assistance!!",
    },
    {
      userName: "Terrie Smith",
      userReview:"I was referred by neice to use this company. At first we started off a little rocky we had one tutor and was moving along then we had to change because she was leaving, and we hated to see her go. Then we got another one, and I would not change her for the world, my grandson ask about her so much I be shocked he calls her just like she is his sister or brother. I love their service and would highly recommend them to any family that has a child in need of their skills.",
    },
    { 
       userName: "Crystal A",
       userReview:"Originally when the pandemic happened  I was very worried about my kids schooling and how I could balance it. They were use to after school tutorials, but with school shut down, I did not know where to turn to. I found SISTEM tutoring in the Houston area and it has been the best investment ever! The tutors are very personable and reliable. They have worked one on one with my kids and I have seen a major improvement. The line of communication between their admin, the tutors and myself has been phenomenal. They cater to each child’s needs and tailor each session to your child’s speed. It was the best investment yet.",
    },
  ]
  return (
    <section className="py-5 bg-white">
      <div className="text-center intro-content mb-4">
        <h2 className="text-grey">{heading}</h2>
      </div>
      <section className="caraosel-testimonial">
      <Slider {...settings}>
        {
          userReviews.map((item, i) => (
            <div className="padding-2x" key={i}>
              <div className="t-card d-md-flex">
                <div className="left-image d-flex justify-content-center align-item-center">
                  <img src="/images/Sistem_0091.jpg" className='signuppahe-image' alt="Sistem_0091"/>
                </div>
                <div className="right-content">
                    <h3 className="member">—{item.userName}</h3>
                    <p className="role ml-3">(Parent)</p>
                    <p className="comment">
                    {item.userReview}
                    </p>
                </div>
                <div className="quote-image">
                  <img src="/images/Badge.svg" alt="quote"/>
                </div>
              </div>
            </div>
          ))
        }
        
      </Slider>
        
      </section>
    </section>
  )
}


Testimonial.propTypes = {
  heading: PropTypes.string
}
Testimonial.defaultProps = {
  heading: 'See what customers and parents are saying'
}