import React, {useState} from 'react';
import Modal from '@material-ui/core/Modal';
import { Link } from "react-router-dom";



const ViewPricing = ({students, open, handleClose, role}) => {
    return <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <div className="modal-container-student">
    <div className="d-flex justify-content-center align-items-center text-justify p-2">You do not have any upcoming sessions. Go to the Billing section of your account to purchase sessions so that you can book one of our amazing tutors! Don't worry, we are so confident in our tutors that if your sessions do not go well, contact us within 30 days of your first session, and we will offer you a refund for any unused hours!</div>
    {role=="parent" ? 
            <div className="subscription-table">
        {students && students.length > 0 && students.map( (student, i) => {
            return(
            <div className="row">
                <button className='close' style={{ position:"absolute", top:"4%" , right:"4%" ,fontSize:"40px" }} onClick={handleClose} >&times;</button>
                <div className="col student">{student?.first_name + ' ' + student?.last_name}</div> 
                <div className="col actions">
                    <Link to={`/profile?tab=billing`}>
                        <button className='btn btn-primary-darker text-white'>View Plans</button>
                    </Link>
                </div>
            </div> 
            );
        })}


        </div> 
    

    :(
        <div class="row d-flex justify-content-end mt-4 align-items-center">
                <div class="col-8 student">Student : {students?.first_name + ' ' + students?.last_name}</div>
                <button className='close' style={{ position:"absolute", top:"4%" , right:"4%" ,fontSize:"40px" }} onClick={handleClose} >&times;</button>
                <div className='col-4'><Link to={'/profile?tab=billing'}>
                    <button className='btn btn-primary-darker btn-padding text-white non-creditmodal-btn'>View Plans</button>
                </Link></div>
            </div>
          )
      
   

    }
    </div>
  </Modal>
}

export default ViewPricing;