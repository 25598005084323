/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import validation from "../../actions/validation";
import { toast } from "react-toastify";
import { subjectList } from "../../services/Common";
import { dayList } from "../../config/variables";

class TutorS4 extends Component {
	constructor(props) {
		super(props);
		this.state = {
            subjects: props.payload.subjects?props.payload.subjects :[],
            subjectList: [],
            otherSubjects: props.payload.otherSubjects?props.payload.otherSubjects :[],
			//validation
			isValid: false,
			loading: false
		};
    }
    
    componentDidMount() {
        this.fetchSubList();
    }

    fetchSubList = () => {
        subjectList().then((res) => {
            if(res.status === 200) {
                this.setState({
                    ...this.state,
                    subjectList: Object.values(res.data.subjects)
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    checkOtherSubjectExistById = (id) => {
        const otherIndex = 'other_'+id;
        this.state.otherSubjects.map( (otherSub, index) => {
            if(otherSub.id === otherIndex)
                this.state.otherSubjects.splice(index,1)
        })
    }

    updateOtherLocation = (action='', subjectValue) => {
        const {  subjectList} = this.state;
        subjectList && subjectList.map((subject) =>{
            const subname = _.filter(subject, function(item){ 
                return (item.id == parseInt(subjectValue) && (item.sub_subject_name=="Other")) });
            if(subname.length>0){//if other option
                const otherIndex = 'other_'+subname[0].id;
                if(action === 'insert') {
                    this.state['otherSubjects'].push({id: otherIndex,value : ''});
                } else {
                    this.checkOtherSubjectExistById(subname[0].id);
                }
            }
        })
    }

    handleTextChange = (id, e) => {
        
        const otherIndex = 'other_'+id;
        this.state.otherSubjects.map( (otherSub, index) => {
            if(otherSub.id === otherIndex) {
                this.state.otherSubjects[index].value = e.target.value;
            }
        })
        this.setState({
            ...this.state,
            otherSubjects: this.state['otherSubjects'],
        })
    }

	onChange = (e) => {
        e.persist();
        const {  subjectList} = this.state;
        if (e.target.type === "checkbox") {
            const subjectValue = e.target.value;
            if (e.target.checked) { 
                this.updateOtherLocation('insert', subjectValue);
                this.state[e.target.name].push(parseInt(subjectValue));
                this.setState({
                    ...this.state,
                    [e.target.name]: this.state[e.target.name],
                    otherSubjects: this.state['otherSubjects'],
                    isValid: false
                })
            } else {
                this.updateOtherLocation('delete', subjectValue);
                /*subjectList.map((subject) =>{
                    var subname = _.filter(subject, function(item){ 
                        return (item.id == parseInt(e.target.value) && (item.sub_subject_name=="Other")) });
                    
                    if(subname.length>0){//if other option
                        var subOtherName = _.filter(this.state['otherSubjects'], function(item){ 
                        return (item.id == parseInt(e.target.value) && (item.sub_subject_name=="Other")) });
                        let index = this.state['otherSubjects'].indexOf(e.target.value);
                        this.state['otherSubjects'].splice(index, 1);
                    }
                })*/
                let index = this.state[e.target.name].indexOf(e.target.value);
                this.state[e.target.name].splice(index, 1);
                this.setState({
                    ...this.state,
                    [e.target.name]: this.state[e.target.name],
                    otherSubjects: this.state['otherSubjects'],
                    isValid: false
                })
            }
        }
    };
	
	onSubmit = () => {
		const { subjects } = this.state;
		this.setState({
            ...this.state,
            loading: true,
            isValid: true,
        }, () => {
            
			if(subjects.length > 0) {
				const data = JSON.parse(JSON.stringify(this.state));
				this.props.getData(_.omit(data, ['isValid', 'loading', 'subjectList']));
			}else {
				this.setState({
					...this.state,
					loading: false
				});
				toast.error("Validation Error!")
			}
		});
	}

	render() {
		const {  subjectList, subjects, isValid, loading,otherSubjects} = this.state;

		return(
		<React.Fragment>
                <div id='signup-form tutor-step'>
                    <p className="mb-5">Select subject you would like to tutor in</p>
                    {
                        subjectList.length > 0 && subjectList.map((subSubjects, index) => (
                            <div className='form-group mb-3 subject-item mb-5' key={index}>
                            <label htmlFor='subjects' className='txt-18 bold text-grey text-capitalize mb-4'>{subSubjects[0].subject_group_name}</label>
                            <div className='select-subject-grid'>
                                    {
                                    subSubjects.length > 0 && subSubjects.map((subject, key) => { 
                                        const includesValue = subjects.includes(subject.id)
                                        /*const showInput = otherSubjects.includes(subject.id)*/
                                        const otherIndex = 'other_'+subject.id;
                                        let otherSubjectItem =  _.filter(otherSubjects, function(item){ 
                                            return (item.id == otherIndex) 
                                        });
                                        let showInput = false;
                                        let otherValue = '';
                                        if (otherSubjectItem.length > 0 ) {

                                            showInput = true;
                                            otherValue = otherSubjectItem[0].value;

                                        }

                                    
                                        return (
                                        <div className='custom-control custom-checkbox' key={key}>
                                            <input
                                                type='checkbox'
                                                className='custom-control-input'
                                                id={subject.id}
                                                value={subject.id}
                                                name="subjects"
                                                checked={includesValue}
                                                onChange={this.onChange}
                                            />
                                            <label className={`custom-control-label checkbox-2 ${(isValid && (subjects.length > 0 ? '' : 'error is-invalid')).toString()}`} htmlFor={subject.id}>
                                                {subject.sub_subject_name}</label>
                                            <div className="invalid-feedback">
                                                Select subjects.
                                            </div>
                                            {showInput? <input type="text" name={`other_${subject.id}`} id={`other_${subject.id}`} className="form-control other-input" value={otherValue}

                                                onChange={(e) => this.handleTextChange(subject.id, e)}
                                                
                                            />:<></>}
                                        </div>
                                        
                                        )
    })
                                }
                            </div>
                            </div>
                        )) 
                    }

                    <div className='d-flex justify-content-center align-items-center my-5'>
                        <button
                            disabled={loading}
                            className='btn btn-primary btn-padding--lg text-white'
                            onClick={this.onSubmit}
                        >
                            Continue
                        </button>
                    </div>
                </div>
		</React.Fragment>
		);
	}
}

export default TutorS4;