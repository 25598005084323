/* eslint-disable */
import React, { Component } from "react";
import Layout from "../components/Layout";
import Common from "../components/SignUp/Common";
import PropTypes from "prop-types";
import Testimonial from "../components/Home/Testimonial.component";
import { registration } from "../services/Auth";
import cookie from "../actions/cookie";
import { toast } from "react-toastify";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {},
      loading: false,
      signUpBtnDisable: false,
    };
  }

  componentDidMount() {
    if (cookie.getCookie("_token")) {
      this.props.history.push("/dashboard");
    }
  }

  getData = (data) => {
    this.setState(
      {
        ...this.state,
        payload: {
          ...this.state.payload,
          ...data,
        },
      },
      () => {
        this.register();
      }
    );
  };

  register = () => {
    const { payload } = this.state;
    const { history } = this.props;
    registration(payload)
      .then((res) => {
        if (res.status === 201) {
          this.setState({
            ...this.state,
            signupStep: 3,
          });
          history.push("/confirmation");
          toast.success("Registered Successfully!");
        } else {
          throw new Error(
            Object.values(res.response.data).reduce(
              (init, current) => init + " " + current.toString(),
              ""
            )
          );
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          loading: false,
          signUpBtnDisable: false,
        });
        toast.error(err.message);
      });
  };

  render() {
    const { signupStep, role, loading, signUpBtnDisable } = this.state;

    return (
      <Layout>
        <div className="layout-container bg-bubble">
          <div className="d-flex justify-content-center align-items-end p-5">
            <a
              class="btn btn-primary btn-padding--sm text-white"
              href="/tutor/signup"
            >
              Sign up as a Tutor
            </a>
          </div>
          <section
            className={`container signup-grid ${
              signupStep === 0 ? "" : "--onlyform"
            }`}
          >
            {signupStep === 0 && (
              <div className="heading-content">
                <p className="small-title mb-2">JOIN THE COMMUNITY</p>
                <h2 className="mb-5">
                  Take the first step to getting a tutor that matches your
                  needs.
                </h2>
                <img
                  src="/images/right-arrow.svg"
                  alt="ArrowRight"
                  className="flex-end"
                />
              </div>
            )}
            <div className="form-content">
              <Common
                signUpBtnDisable={signUpBtnDisable}
                getData={this.getData}
                role={role}
              />
            </div>
          </section>
        </div>
        {
          <Testimonial heading="Trusted by students, parents and school districts across Texas." />
        }
      </Layout>
    );
  }
}

SignUp.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SignUp;
